import React, { useMemo, useState } from 'react';
import {
    AddQuotesPayload,
    Case,
    CaseQuote,
    Category,
    CategoryLinkedQuote,
    CategoryQuoteType,
} from '../../../../../../types';
import { useLocale, usePromises } from '../../../../../../hooks';
import { APIService } from '../../../../../../services';
import { Modal, ModalFooter, ModalSize } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { AddQuotesModalQuote } from './components';
import { formatLocalizedText } from '../../../../../../tools';

export type CaseQuoteFormItem = {
    category: CategoryLinkedQuote;
    isSelected: boolean;
    count: number;
    list?: Array<CaseQuoteFormItemQuote>;
};

export type CaseQuoteFormItemQuote = {
    clientId: string;
    dependents?: Array<string>;
};

type AddQuotesModalProps = {
    onClose: () => void;
    refreshQuotesList: () => Promise<void>;
    currentCase: Case;
    caseQuotes: Array<CaseQuote>;
};

const AddQuotesModal = ({ currentCase, caseQuotes, refreshQuotesList, onClose }: AddQuotesModalProps) => {
    const locale = useLocale();
    const { t } = useTranslation();
    const [quotesList, setQuotesList] = useState<Array<CaseQuoteFormItem>>([]);

    const [promises, [clients, category]] = usePromises(
        () => APIService.cases().getClientsList(currentCase.id),
        () => fetchCategory()
    );

    const selectedQuotes = useMemo(() => {
        return quotesList.filter(({ isSelected }) => isSelected === true);
    }, [quotesList]);

    /**
     * Fetch category
     * @return {Promise}
     */
    const fetchCategory = async (): Promise<Category> => {
        const response = await APIService.categories().getCategory(currentCase.category.id);
        setQuotesList(
            response.quotes
                .filter((quote) => isAllowedToAddQuote(quote).isAllowed)
                .map((quote) => {
                    return {
                        category: quote,
                        isSelected: false,
                        count: 0,
                    };
                })
        );

        return response;
    };

    /**
     * Submit handler
     * @return {Promise}
     */
    const submitHandler = async () => {
        if (selectedQuotes.length === 0) {
            return onClose();
        }

        await promises.executePromise(async () => {
            const payload: Array<AddQuotesPayload> = [];

            for (const selectedQuote of selectedQuotes) {
                if (selectedQuote.category.rules.belongsToClient !== true) {
                    for (let i = 0; i < selectedQuote.count; i++) {
                        payload.push({ quoteId: selectedQuote.category.id });
                    }
                } else {
                    for (const client of selectedQuote.list!) {
                        const dependents =
                            selectedQuote.category.rules.hasDependent === true
                                ? client.dependents?.map((dependent, index) => ({
                                      fullName: dependent || `Dependent #${index + 1}`,
                                  })) ?? []
                                : undefined;

                        payload.push({
                            quoteId: selectedQuote.category.id,
                            clientId: client.clientId,
                            dependents: dependents,
                        });
                    }
                }
            }

            await APIService.cases().addQuotes(currentCase.id, { quotes: payload });
            await refreshQuotesList();
            onClose();
        });
    };

    /**
     * Update selection
     * @param {CategoryLinkedQuote} categoryQuoteId
     * @param {boolean} isSelected
     */
    const updateSelection = (categoryQuote: CategoryLinkedQuote, isSelected: boolean) => {
        setQuotesList((prev) =>
            prev.map((prevItem) => {
                if (prevItem.category.id === categoryQuote.id) {
                    const updatedCount = isSelected ? prevItem.count || 1 : prevItem.count;

                    if (categoryQuote.rules.belongsToClient === true && isSelected === true) {
                        const list =
                            prevItem.list != null && prevItem.list.length > 0
                                ? prevItem.list
                                : [getInitialClientQuote(categoryQuote)];
                        return {
                            ...prevItem,
                            isSelected,
                            list: list,
                            count: updatedCount,
                        };
                    } else {
                        return {
                            ...prevItem,
                            isSelected: isSelected,
                            count: updatedCount,
                        };
                    }
                } else {
                    return prevItem;
                }
            })
        );
    };

    /**
     * Add quote
     * @param {CategoryLinkedQuote} categoryQuote
     */
    const addQuote = (categoryQuote: CategoryLinkedQuote) => {
        const { maxCount, isAllowed } = isAllowedToAddQuote(categoryQuote);
        if (isAllowed !== true) {
            const message = `Only ${maxCount} "${formatLocalizedText(categoryQuote.title, locale)}" allowed.`;
            promises.setError(message);
            return;
        }

        setQuotesList((prev) =>
            prev.map((prevItem) => {
                if (prevItem.category.id === categoryQuote.id) {
                    const list = prevItem.list;

                    if (categoryQuote.rules.belongsToClient === true && list != null) {
                        list.push(getInitialClientQuote(categoryQuote));
                    }

                    return {
                        ...prevItem,
                        list: list,
                        count: prevItem.count + 1,
                    };
                } else {
                    return prevItem;
                }
            })
        );
    };

    /**
     * Update quote
     * @param quote
     * @param index
     */
    const updateQuote = (categoryQuoteId: string, index: number, quote: CaseQuoteFormItemQuote) => {
        setQuotesList((prev) =>
            prev.map((prevItem) => {
                if (prevItem.category.id === categoryQuoteId) {
                    const updatedQuotes = structuredClone(prevItem.list!);
                    updatedQuotes[index] = quote;
                    return {
                        ...prevItem,
                        list: updatedQuotes,
                    };
                } else {
                    return prevItem;
                }
            })
        );
    };

    /**
     * Remove quote
     * @param categoryQuoteId
     * @param index
     */
    const removeQuote = (categoryQuoteId: string, index: number) => {
        setQuotesList((prev) =>
            prev.map((prevItem) => {
                if (prevItem.category.id === categoryQuoteId) {
                    const updatedCount = prevItem.count - 1;

                    return {
                        ...prevItem,
                        list: prevItem.list?.filter((_, itemIndex) => itemIndex !== index),
                        count: updatedCount,
                        isSelected: updatedCount > 0,
                    };
                } else {
                    return prevItem;
                }
            })
        );
    };

    const getInitialClientQuote = (categoryQuote: CategoryLinkedQuote): CaseQuoteFormItemQuote => {
        const newQuote: CaseQuoteFormItemQuote = {
            clientId: '',
        };

        if (categoryQuote.rules.hasDependent === true) {
            newQuote.dependents = [];
        }

        return newQuote;
    };

    /**
     * Check if is allowed to add quote
     * @param {CategoryLinkedQuote} quote
     * @return {void}
     */
    const isAllowedToAddQuote = (quote: CategoryLinkedQuote) => {
        const result = {
            maxCount: quote.rules.quoteType === CategoryQuoteType.UNIQUE ? 1 : quote.rules.maxCount ?? null,
            currentCount: 0,
            isAllowed: true,
        };

        if (result.maxCount !== null) {
            for (const selectedQuote of selectedQuotes) {
                if (selectedQuote.category.id === quote.id) {
                    result.currentCount += selectedQuote.count;
                }
            }

            for (const caseQuote of caseQuotes) {
                if (caseQuote.category.id === quote.id) {
                    result.currentCount += 1;
                }
            }

            if (result.currentCount >= result.maxCount) {
                result.isAllowed = false;
            }
        }

        return result;
    };

    return (
        <Modal
            title={t('case.quote.modals.add_quotes.title')}
            onClose={onClose}
            error={promises.error}
            clearError={promises.clearError}
            size={ModalSize.LARGE}
            isLoading={clients.isLoading || category.isLoading}
        >
            {category.data != null && clients.data != null && (
                <>
                    {quotesList.map((quoteListItem, index) => (
                        <AddQuotesModalQuote
                            key={index}
                            quote={quoteListItem}
                            clients={clients.data}
                            updateSelection={updateSelection}
                            addQuote={addQuote}
                            updateQuote={updateQuote}
                            removeQuote={removeQuote}
                            caseQuotes={caseQuotes}
                            isAllowedToAdd={isAllowedToAddQuote(quoteListItem.category).isAllowed}
                        />
                    ))}
                </>
            )}

            <ModalFooter
                confirmationLabel={t('case.quote.modals.add_quotes.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
                isLoading={promises.isExecutingPromise}
            />
        </Modal>
    );
};

export default AddQuotesModal;
