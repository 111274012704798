import { APIService } from '../services';
import {
    clearUser,
    setStatusList,
    setUser,
    setClientCasesList,
    REDUX_Organization,
} from '../services/ReduxService';
import { AppDispatch, AppState } from '../services/ReduxService/store';
import { User } from '../types';
import { OrganizationBusiness } from './organization';
import { UserBusiness } from './user';

export class AuthenticationBusiness {
    /**
     * Login method
     * @param {string} email
     * @param {string} password
     * @param {AppDispatch} dispatch
     * @param {AppState} state
     * @returns {Promise<User | null>}
     */
    static async login(
        email: string,
        password: string,
        dispatch: AppDispatch,
        state: AppState
    ): Promise<User | null> {
        // Login to API and get tokens
        const response = await APIService.auth().login(email, password);

        // Set tokens on API Service instance
        APIService.setCredentials(response.tokens.accessToken, response.tokens.refreshToken);

        // Init app
        return this.initApp(dispatch, state);
    }

    /**
     * On load app
     * @param {AppDispatch} dispatch
     * @returns {Promise<User | null>}
     */
    static async load(dispatch: AppDispatch, state: AppState): Promise<User | null> {
        // Init API url
        APIService.initialize(process.env.REACT_APP_API_URL!);

        // Init public organizations list
        const organizations = await OrganizationBusiness.initializeOrganizations(dispatch);

        // Check if user is authenticated
        if (APIService.isAuthenticated() !== true) {
            return null;
        }

        // Init app
        return this.initApp(dispatch, state, organizations);
    }

    /**
     * UUID handler
     * @param {string} role
     * @param {string} signature
     * @param {AppDispatch} dispatch
     * @param {AppState} state
     * @return {Promise<User | null>}
     */
    static async uuid(
        role: string,
        signature: string,
        dispatch: AppDispatch,
        state: AppState
    ): Promise<User | null> {
        // Login to API via uuid signature and get tokens
        const response = await APIService.auth().uuid(role, signature);

        // Set tokens on API Service instance
        APIService.setCredentials(response.tokens.accessToken, response.tokens.refreshToken);

        // Init app
        return this.initApp(dispatch, state);
    }

    /**
     * Logout handler
     * @param {AppDispatch} dispatch
     */
    static async logout(dispatch: AppDispatch, sendRequest = true) {
        if (sendRequest === true) {
            APIService.auth().logout();
        }
        APIService.removeCredentials();
        dispatch(clearUser());
    }

    // ********************************************************************************************************
    // Private method
    // ********************************************************************************************************

    /**
     * Initialize app
     * @param {AppDispatch} dispatch
     * @param {AppState} state
     * @param {Array<REDUX_Organization>} [organizationsList]
     * @return {Promise<User>}
     */
    private static async initApp(
        dispatch: AppDispatch,
        state: AppState,
        organizationsList?: Array<REDUX_Organization>
    ): Promise<User | null> {
        // Get user profile
        const user = await APIService.users().getProfile();
        if (user == null) {
            throw new Error('Failed to get user profile');
        }

        // Get status list
        const statusList = await APIService.cases().getStatusList();
        if (statusList == null) {
            throw new Error('Failed to get status list');
        }

        if (user != null && statusList != null) {
            dispatch(setStatusList(statusList));
            dispatch(setUser(user));
            UserBusiness.updateLocale(user.preferences.locale);

            const organizations = organizationsList ?? state.organizations.list;
            const organization = organizations.find(
                (item) => item.internalId === user.organization.internalId
            );
            if (organization != null) {
                OrganizationBusiness.applyOrganization(organization, dispatch);
            }
        }

        if (user.isEmployee !== true) {
            const cases = await APIService.cases().getList();
            dispatch(setClientCasesList(cases));
        }

        return user;
    }
}
