import styled from 'styled-components';

export const CategoryModalGroups = styled.div`
    grid-column: 1 / -1;

    .draggable-list {
        padding: ${({ theme }) => theme.box.spacing.md};

        &__list__item {
            background-color: ${({ theme }) => theme.colors.background.secondary};
            margin-bottom: ${({ theme }) => theme.box.spacing.md};

            &__drag-icon {
                position: absolute;
                top: ${({ theme }) => theme.box.spacing.md};
                left: ${({ theme }) => theme.box.spacing.md};
                z-index: 2;

                svg {
                    font-size: ${({ theme }) => theme.font.size.xl};
                }
            }
        }
    }
`;
