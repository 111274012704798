import React, { useState } from 'react';
import { Case, CaseFolder, CaseFile } from '../../../../../../types';
import { Modal, ModalFooter, ModalSize } from '../../../../../../components';
import { useLocale, usePromises } from '../../../../../../hooks';
import { formatCaseFolderDescription } from '../../../../../../tools';
import { FilesList } from './BatchUpdateFilesModal-styles';
import { UpdateFileItem } from '../config/components';
import { BatchUpdateFileItem } from '../config/types';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../../services';

type BatchUpdateFilesModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFiles: Array<CaseFile>;
    caseFolders: Array<CaseFolder>;
    updateFileUrl: (id: string, url: { path: string; expiredAt: Date }) => void;
};

const getInitialFileItems = (caseFileItems: Array<CaseFile>, caseFolder: CaseFolder) => {
    return caseFileItems.map((caseFileItem) => {
        let categoryFileId = '';
        for (const childFolder of caseFolder.folders) {
            for (const grandChildFolder of childFolder.folders) {
                if (grandChildFolder.files.some((file) => file.id === caseFileItem.id)) {
                    categoryFileId = grandChildFolder.id;
                }
            }
        }
        return {
            id: caseFileItem.id,
            quoteId: caseFolder.id,
            categoryFileId: categoryFileId,
            initialCategoryFileId: categoryFileId,
            file: caseFileItem,
        };
    });
};

const BatchUpdateFilesModal = ({
    onClose,
    refresh,
    currentCase,
    selectedFiles,
    currentCaseFolder,
    caseFolders,
    updateFileUrl,
}: BatchUpdateFilesModalProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, setError, executePromise, isExecutingPromise }] = usePromises();
    const [fileItems, setFileItems] = useState<Array<BatchUpdateFileItem>>(
        getInitialFileItems(selectedFiles, currentCaseFolder)
    );

    const onSubmitHandler = async () => {
        if (fileItems.length === 0) {
            onClose();
            return;
        }

        if (fileItems.some((fileItem) => fileItem.categoryFileId === '')) {
            setError(t('case.documents.modals.upload.error.missing_folder'));
            return;
        }

        await executePromise(async () => {
            const payload = fileItems.map((fileItem) => ({
                fileId: fileItem.id,
                quoteId: fileItem.quoteId,
                categoryFileId: fileItem.categoryFileId,
            }));
            await APIService.cases().moveFiles(currentCase.id, currentCaseFolder.id, {
                files: payload,
            });
            await refresh();
            onClose();
        });
    };

    const onRemoveHandler = (fileId: string) => {
        setFileItems((prev) => prev.filter((prevItem) => prevItem.id !== fileId));
    };

    const onSelectFolderHandler = (fileId: string, quoteId: string, categoryFileId: string) => {
        setFileItems((prev) =>
            prev.map((prevItem) => {
                if (prevItem.id === fileId) {
                    return {
                        ...prevItem,
                        quoteId: quoteId,
                        categoryFileId: categoryFileId,
                    };
                } else {
                    return prevItem;
                }
            })
        );
    };

    return (
        <Modal
            onClose={onClose}
            error={error}
            clearError={clearError}
            title={formatCaseFolderDescription(currentCaseFolder, locale)}
            size={ModalSize.LARGE}
        >
            <FilesList>
                {fileItems.map((fileItem) => (
                    <UpdateFileItem
                        key={fileItem.id}
                        fileItem={fileItem}
                        caseFolders={caseFolders}
                        onClickRemove={() => onRemoveHandler(fileItem.id)}
                        onSelectFolder={onSelectFolderHandler}
                        isEditable={isExecutingPromise === false}
                        currentCase={currentCase}
                        updateFileUrl={updateFileUrl}
                        isRemovable={fileItems.length > 1}
                    />
                ))}
            </FilesList>
            <ModalFooter
                confirmationLabel={t('generic.update')}
                onClick={onSubmitHandler}
                isLoading={isExecutingPromise}
                secondary={{
                    onClick: onClose,
                }}
            />
        </Modal>
    );
};

export default BatchUpdateFilesModal;
