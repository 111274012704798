import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../../../../hooks';
import { CaseDocumentsFolderContext, ModalActions, MultipleSelectMode } from '../../CaseDocumentsFolder';
import { ClearSelection, CloseIcon, Container, Label } from './CaseDocumentsFolderMultiSelect-styles';
import { SecondaryOutlinedButton } from '../../../../../../../../components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaList } from 'react-icons/fa';

const CaseDocumentsFolderMultiSelect = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const {
        multipleSelectMode,
        selectedItemsCount,
        openModalHandler,
        closeModalHandler,
        setMultipleSelectMode,
    } = useContext(CaseDocumentsFolderContext);

    const multipleSelectList = useMemo(() => {
        if (multipleSelectMode === MultipleSelectMode.BY_FOLDERS) {
            return [
                {
                    label: t('case.documents.multiselect.validate_selections'),
                    onClick: () => openModalHandler(ModalActions.UPDATE_VALIDATED_FILES),
                },
                {
                    label: t('case.documents.multiselect.invalidate_selections'),
                    onClick: () => openModalHandler(ModalActions.UPDATE_INVALIDATED_FILES),
                },
            ];
        }

        if (multipleSelectMode === MultipleSelectMode.BY_FILES) {
            return [
                {
                    label: t('case.documents.multiselect.move_files'),
                    onClick: () => openModalHandler(ModalActions.BATCH_UPDATE_FILES),
                },
                {
                    label: t('case.documents.multiselect.remove_files'),
                    onClick: () => openModalHandler(ModalActions.REMOVE_FILES),
                },
            ];
        }

        return undefined;
    }, [locale, multipleSelectMode]);

    const mainList = useMemo(() => {
        return [
            {
                label: t('case.documents.multiselect.by_folders'),
                onClick: () => setMultipleSelectMode(MultipleSelectMode.BY_FOLDERS),
            },
            {
                label: t('case.documents.multiselect.by_files'),
                onClick: () => setMultipleSelectMode(MultipleSelectMode.BY_FILES),
            },
        ];
    }, [locale]);

    return (
        <>
            {multipleSelectMode != null && (
                <ClearSelection
                    onClick={(e) => {
                        e.stopPropagation();
                        closeModalHandler();
                    }}
                >
                    {t('case.documents.clear_selection')}
                </ClearSelection>
            )}
            <Container isSelectingMultiple={multipleSelectMode != null} onClick={(e) => e.stopPropagation()}>
                {multipleSelectMode != null ? (
                    <SecondaryOutlinedButton list={multipleSelectList}>
                        <CloseIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                setMultipleSelectMode(null);
                            }}
                            className="close-icon"
                        >
                            <AiOutlineCloseCircle />
                        </CloseIcon>
                        <Label>{t('generic.selected', { count: selectedItemsCount })}</Label>
                    </SecondaryOutlinedButton>
                ) : (
                    <SecondaryOutlinedButton
                        leadingIcon={<FaList />}
                        list={mainList}
                        listRightSide
                        listHideToggleIcon
                    />
                )}
            </Container>
        </>
    );
};

export default CaseDocumentsFolderMultiSelect;
