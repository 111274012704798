import styled from 'styled-components';

export const Container = styled.div<{ isToggled: boolean; level: number; bodyHeight: number }>`
    overflow: hidden;
    width: 100%;
    height: ${({ isToggled, bodyHeight }) => (isToggled ? `${bodyHeight}px` : '0px')};
    transition: all 0.2s ease-in;
`;

export const Content = styled.div`
    min-height: max-content;
    width: 100%;

    .table-last-row {
        border-bottom: none;
    }
`;
