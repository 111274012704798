import { PermissionsBusiness } from '../business';
import { CaseFolder_L1, CategoryQuoteGroupAuthorization, EmployeeRole } from '../types';
import { useStore } from './useStore';

export const useLoggedInUser = () => {
    const { user } = useStore();

    /**
     * Returns true if user is authenticated
     * @returns {boolean}
     */
    const isLoggedIn = (): boolean => typeof user?.email === 'string';

    /**
     * Returns true if user is an admin
     * @returns {boolean}
     */
    const isAdmin = (): boolean => user?.roles?.includes(EmployeeRole.ADMIN) ?? false;

    /**
     * Returns true if user is a lawyer
     * @returns {boolean}
     */
    const isLawyer = (): boolean => user?.roles?.includes(EmployeeRole.LAWYER) ?? false;

    /**
     * Returns true if user is an employee
     * @returns {boolean}
     */
    const isEmployee = (): boolean => (user.roles != null ? user.roles.length > 0 : false);

    /**
     * Returns true if user is a client
     * @returns {boolean}
     */
    const isClient = (): boolean => user?.isEmployee === false ?? user.roles == null ?? user.roles.length > 0;

    /**
     * Returns true if user has required permissions
     * @param { Array<string | Array<string>>} permissions
     * @return {Boolean}
     */
    const hasPermissions = (...permissions: Array<string | Array<string>>): boolean => {
        if (user.roles == null || user.roles.length === 0) {
            return false;
        } else {
            return PermissionsBusiness.checkPermissions(user.roles, ...permissions);
        }
    };

    const belongsToDefaultOrganization = (): boolean => {
        return user.organization.internalId === 'default';
    };

    const isAllowedToUpload = (fileGroup: CaseFolder_L1) => {
        switch (fileGroup.authorization) {
            case CategoryQuoteGroupAuthorization.NONE:
                return true;
            case CategoryQuoteGroupAuthorization.EMPLOYEE:
                return isEmployee();
            case CategoryQuoteGroupAuthorization.CLIENT:
                return isClient();
        }
    };

    return {
        user: user,
        isLoggedIn: isLoggedIn,
        isAdmin: isAdmin,
        isEmployee: isEmployee,
        isLawyer: isLawyer,
        isClient: isClient,
        hasPermissions: hasPermissions,
        belongsToDefaultOrganization: belongsToDefaultOrganization,
        isAllowedToUpload: isAllowedToUpload,
    };
};
