import { CaseForm, Locale } from '../../types';
import { formatLocalizedText } from './formatLocalizedText';

/**
 * Format case form title
 * @param caseForm
 * @param locale
 * @return string
 */
export const formatCaseFormTitle = (caseForm: CaseForm, locale: Locale) => {
    const title = formatLocalizedText(caseForm.form.title, locale);
    const client =
        caseForm.client != null ? `${caseForm.client.firstName} ${caseForm.client.lastName}` : null;

    return client != null ? `${title} - ${client}` : title;
};
