import moment from 'moment';
import {
    CaseForm,
    CaseFormSection,
    ServerFormCondition,
    ServerFormConditionActionType,
    ServerFormConditionRequirementType,
} from '../../../types';
import { DynamicFormValues } from '../types';

/**
 * Get displayed sections
 * @param {CaseForm['form']} form
 * @param {DynamicFormValues} [values]
 * @return {Array<CaseFormSection>}
 */
export const getDisplayedSections = (
    form: CaseForm['form'],
    values?: DynamicFormValues
): Array<CaseFormSection> => {
    if (form.conditions == null || form.conditions.length === 0) {
        return form.sections;
    }

    const hiddenSectionIds = new Set();
    const hiddenQuestionIds: Map<string, Set<string>> = new Map();

    for (const condition of form.conditions) {
        const sectionSource = form.sections.find((section) => section.id === condition.source.section.id);
        if (sectionSource == null || (sectionSource as any).isDisplayed === false) {
            continue;
        }

        const questionSource = sectionSource.questions.find(
            (question) => question.id === condition.source.question.id
        );
        if (questionSource == null || (questionSource as any).isDisplayed === false) {
            continue;
        }

        const sourceValue = getConditionSourceValue(form, condition.source, values);
        const isApplied = evaluateConditionRequirement(sourceValue, condition.requirement);
        const targetSectionId = condition.target.section.id;
        const targetQuestionId = condition.target.question?.id;

        switch (condition.action.actionType) {
            case ServerFormConditionActionType.HIDE:
                if (isApplied === true) {
                    if (targetQuestionId == null) {
                        // targeting all section
                        hiddenSectionIds.add(targetSectionId);
                    } else {
                        // targeting one question of a section
                        const hiddenSectionQuestions = hiddenQuestionIds.get(targetSectionId) ?? new Set();
                        hiddenSectionQuestions.add(targetQuestionId);
                        hiddenQuestionIds.set(targetSectionId, hiddenSectionQuestions);
                    }
                }
                break;
            case ServerFormConditionActionType.SHOW:
                if (isApplied !== true) {
                    if (targetQuestionId == null) {
                        // targeting all section
                        hiddenSectionIds.add(targetSectionId);
                    } else {
                        // targeting one question of a section
                        const hiddenSectionQuestions = hiddenQuestionIds.get(targetSectionId) ?? new Set();
                        hiddenSectionQuestions.add(targetQuestionId);
                        hiddenQuestionIds.set(targetSectionId, hiddenSectionQuestions);
                    }
                }
                break;
        }
    }

    return form.sections
        .sort((a, b) => a.index - b.index)
        .map((section) => {
            const hiddenQuestions = hiddenQuestionIds.get(section.id) ?? new Set();
            const displayedQuestions = section.questions.map((question) => ({
                ...question,
                isDisplayed:
                    (question as any).isDisplayed !== false && hiddenQuestions.has(question.id) !== true,
            }));

            const isDisplayed =
                (section as any).isDisplayed !== false &&
                hiddenSectionIds.has(section.id) !== true &&
                displayedQuestions.length > 0;

            return {
                ...section,
                isDisplayed: isDisplayed,
                questions: displayedQuestions,
            };
        });
};

// *****************************************************************************************
// HELPERS
// *****************************************************************************************

/**
 * Get condition source value
 * @param form
 * @param conditionSource
 * @param viewMode
 * @param values
 * @return {string}
 */
const getConditionSourceValue = (
    form: CaseForm['form'],
    conditionSource: ServerFormCondition['source'],
    values?: DynamicFormValues
): string => {
    let conditionSourceValue = '';

    if (values != null) {
        for (const { sectionId, questions } of Object.values(values)) {
            if (sectionId === conditionSource.section.id) {
                for (const [questionId, questionAnswerValue] of Object.entries(questions)) {
                    if (questionId === conditionSource.question.id) {
                        conditionSourceValue = questionAnswerValue;
                    }
                }
            }
        }
    } else {
        const sectionSource = form.sections.find((section) => section.id === conditionSource.section.id);
        if (sectionSource != null) {
            const questionSource = sectionSource.questions.find(
                (question) => question.id === conditionSource.question.id
            );
            if (questionSource != null) {
                conditionSourceValue = 'answer' in questionSource ? (questionSource.answer as any).value : '';
            }
        }
    }

    return conditionSourceValue ?? '';
};

/**
 * Evaluate condition requirement
 * @param value
 * @param conditionRequirement
 * @return {boolean}
 */
const evaluateConditionRequirement = (
    value: string,
    conditionRequirement: ServerFormCondition['requirement']
): boolean => {
    const testValue = conditionRequirement.value;
    switch (conditionRequirement.requirementType) {
        case ServerFormConditionRequirementType.CONTAINS:
            return value.toLowerCase().includes(testValue.toString().toLowerCase());
        case ServerFormConditionRequirementType.EQUAL:
            return value.toLowerCase() === testValue.toString().toLowerCase();
        case ServerFormConditionRequirementType.START_WITH:
            return value.toLowerCase().startsWith(testValue.toString().toLowerCase());
        case ServerFormConditionRequirementType.END_WITH:
            return value.toLowerCase().endsWith(testValue.toString().toLowerCase());
        case ServerFormConditionRequirementType.LOWER_THAN:
            return value < testValue;
        case ServerFormConditionRequirementType.GREATER_THAN:
            return value > testValue;
        case ServerFormConditionRequirementType.AFTER_DATE:
            return moment(value).isAfter(moment(testValue));
        case ServerFormConditionRequirementType.BEFORE_DATE:
            return moment(value).isBefore(moment(testValue));
    }
};
