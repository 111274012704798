import styled from 'styled-components';

export const Container = styled.div``;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled.h2`
    padding: ${({ theme }) => theme.box.spacing.md};
    text-align: center;
`;

export const Item = styled.div`
    padding: ${({ theme }) => `${theme.box.spacing.sm} ${theme.box.spacing.md}`};
`;

export const ItemGroup = styled.div`
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    padding: ${({ theme }) => theme.box.spacing.lg};
    padding-top: ${({ theme }) => theme.box.spacing.xl};
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-radius: 8px;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    position: relative;
`;

export const ItemGroupRemove = styled.div<{ isDisabled: boolean }>`
    position: absolute;
    right: ${({ theme }) => theme.box.spacing.sm};
    top: ${({ theme }) => theme.box.spacing.sm};
    font-size: ${({ theme }) => theme.font.size.md};

    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                svg: {
                    color: theme.colors.text.disabled,
                    cursor: 'not-allowed',
                },
            };
        }
    }}
`;

export const ItemLabel = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    line-height: 1.4;
`;

export const ItemValue = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
`;

export const ItemCta = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.box.spacing.md};
`;

export const ItemCtaText = styled.div`
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const ItemArray = styled.div<{ nbColumns: number }>`
    display: grid;
    ${({ theme, nbColumns }) => {
        return {
            gridTemplateColumns: `max-content repeat(${nbColumns}, 1fr)`,
            gridGap: theme.box.spacing.sm,
        };
    }}
`;

export const ItemArrayHeader = styled.div<{ nbColumns?: number }>`
    font-size: ${({ theme }) => theme.font.size.sm};
    padding: ${({ theme }) => theme.box.spacing.sm};
    text-align: center;

    ${({ nbColumns }) => {
        if (nbColumns != null) {
            return {
                gridColumn: `span ${nbColumns}`,
            };
        }
    }}
`;

export const ItemArrayLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    display: flex;
    align-items: center;
`;

export const ItemArrayValue = styled.div``;

export const ItemText = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin: ${({ theme }) => theme.box.spacing.md} 0;
`;
