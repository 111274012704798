import React from 'react';
import { Case, CaseFolder, CaseFolder_L2 } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../../hooks';
import { LocalizedText, Modal, ModalFooter } from '../../../../../../components';
import { formatCaseFolderDescription } from '../../../../../../tools';
import { List, ListItem, Message } from './UpdateValidatedFilesModal-styles';
import { APIService } from '../../../../../../services';

type UpdateValidatedFilesModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFolders: Array<CaseFolder_L2>;
    areFoldersValid: boolean;
};

const UpdateValidatedFilesModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
    selectedFolders,
    areFoldersValid,
}: UpdateValidatedFilesModalProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    const onSubmitHandler = async () => {
        await executePromise(async () => {
            await APIService.cases().updateValidatedFiles(currentCase.id, currentCaseFolder.id, {
                validatedCategoryFileIds: selectedFolders.map((item) => item.id),
                areValid: areFoldersValid,
            });
            await refresh();
            onClose();
        });
    };

    return (
        <Modal
            onClose={onClose}
            error={error}
            clearError={clearError}
            title={formatCaseFolderDescription(currentCaseFolder, locale)}
        >
            <Message>
                {t(
                    `case.documents.modals.update_validated_files.message_${
                        selectedFolders ? 'validate' : 'invalidate'
                    }`
                )}
                :
            </Message>
            <List>
                {selectedFolders.map((selectedFolder) => (
                    <ListItem key={selectedFolder.id}>
                        <LocalizedText text={selectedFolder.title} />
                    </ListItem>
                ))}
            </List>
            <ModalFooter
                confirmationLabel={t(`generic.${areFoldersValid ? 'validate' : 'invalidate'}`)}
                onClick={onSubmitHandler}
                isLoading={isExecutingPromise}
                secondary={{
                    onClick: onClose,
                }}
                isDestructive={areFoldersValid !== true}
            />
        </Modal>
    );
};

export default UpdateValidatedFilesModal;
