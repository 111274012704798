import styled from 'styled-components';

export const LogoContainer = styled.div<{ color: string }>`
    background-color: ${({ color }) => color};
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${({ theme }) => theme.box.spacing.md};
    padding: 0 ${({ theme }) => theme.box.spacing.sm};
`;

export const Logo = styled.img`
    object-fit: contain;
    height: 3rem;
`;

export const Favicon = styled.img`
    object-fit: contain;
    height: 3rem;
`;
