import React, { useMemo } from 'react';
import { CaseQuote } from '../../../../../../types';
import { TableBodyRowAction } from '../../../../../../components';
import { CaseQuoteActionsTypes } from '../../CaseQuote';

type CaseQuotesTableMenuProps = {
    quote: CaseQuote;
    openModalHandler: (selectedAction: CaseQuoteActionsTypes, selectedQuote?: CaseQuote) => void;
    isDependent?: boolean;
};

const CaseQuotesTableMenu = ({ quote, openModalHandler, isDependent = false }: CaseQuotesTableMenuProps) => {
    const actions = useMemo(() => {
        const array: Array<{
            label: string;
            onClick: (item: CaseQuote) => void;
        }> = [];

        if (isDependent === true) {
            array.push({
                label: 'Update dependent',
                onClick: () => openModalHandler(CaseQuoteActionsTypes.UPDATE_DEPENDENT, quote),
            });
            array.push({
                label: 'Remove dependent',
                onClick: () => openModalHandler(CaseQuoteActionsTypes.REMOVE_DEPENDENT, quote),
            });
        } else {
            if (quote.isAnonymous === true) {
                array.push({
                    label: 'Update quote',
                    onClick: () => openModalHandler(CaseQuoteActionsTypes.UPDATE_QUOTE, quote),
                });
            }

            if (quote.dependents != null) {
                array.push({
                    label: 'Add dependents',
                    onClick: () => openModalHandler(CaseQuoteActionsTypes.ADD_DEPENDENT, quote),
                });
            }

            array.push({
                label: 'Remove quote',
                onClick: () => openModalHandler(CaseQuoteActionsTypes.REMOVE_QUOTE, quote),
            });
        }

        return array;
    }, [quote]);

    return <TableBodyRowAction actions={actions} />;
};

export default CaseQuotesTableMenu;
