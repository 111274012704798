import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin-right: ${({ theme }) => theme.box.spacing.sm};
    }
`;
