import React, { useContext, useMemo } from 'react';
import { InputMode } from '../../Inputs';
import DateInput, { DateInputProps } from '../../Inputs/DateInput/DateInput';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';

const FormDateInput = (props: Omit<DateInputProps, 'value' | 'onChange'>) => {
    const { formik } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);

    const onChangeHandler = (value: Date) => {
        formik.setFieldValue(name, value);
    };

    const value = useMemo(() => {
        let result = formik.values;
        const keys = name.split('.');

        for (const key of keys) {
            if (result[key] != null) {
                result = result[key];
            }
        }
        return result instanceof Date || (typeof result === 'string' && !isNaN(new Date(result).getTime()))
            ? new Date(result)
            : undefined;
    }, [formik.values, name]);

    return <DateInput {...props} value={value} onChange={onChangeHandler} mode={InputMode.FORM} />;
};

export default FormDateInput;
