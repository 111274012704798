import React from 'react';
import { CategoryDescription } from './CaseOverview-styles';
import { useOutletContext } from 'react-router-dom';
import { CasesPagesContext } from '../CasePagesContext';
import { LocalizedText, PageContainer, PageHeader, TextEditorInput } from '../../../../components';
import { formatLocalizedText } from '../../../../tools';
import { useLocale } from '../../../../hooks';
import { CaseChildPageTitle } from '../CasePages-styles';
import { BiCategory } from 'react-icons/bi';

const CaseOverview = () => {
    const locale = useLocale();
    const { currentCase } = useOutletContext<CasesPagesContext>();
    return (
        <PageContainer>
            <PageHeader display="row">
                <CaseChildPageTitle>
                    <BiCategory />
                    <LocalizedText text={currentCase.category.title} />
                </CaseChildPageTitle>
            </PageHeader>
            <CategoryDescription>
                <TextEditorInput
                    initialValue={formatLocalizedText(currentCase.category.description, locale)}
                    isDisabled
                />
            </CategoryDescription>
        </PageContainer>
    );
};

export default CaseOverview;
