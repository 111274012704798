export const config = {
    topbar: {
        height: {
            default: 60,
        },
    },
    sidebar: {
        width: {
            default: 250,
            max: 380,
            collapsed: 72,
        },
    },
    input: {
        height: {
            default: 45,
            small: 35,
        },
    },
};
