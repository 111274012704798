import React from 'react';
import moment from 'moment';
import { generateId } from '../../../../../tools';
import { Routes } from '../../../../../routes';

// hooks
import { useLoggedInUser, useTable } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';

// types
import { Case } from '../../../../../types';

// components
import { Table, TableColumnConfig, TableFilter, UserAvatar } from '../../../../../components';

// styles
import { AssignedLawyersContainer, CaseInternalIdContainer, CreatedBy } from './CasesListTable-styles';
import { AiFillFolder } from 'react-icons/ai';
import { Breakpoints } from '../../../../../theme';

export interface UserInformation {
    id: string;
    internalId: string;
    firstName: string;
    lastName: string;
    email: string;
}

type Props = {
    isLoading?: boolean;
    cases: null | Array<Case>;
    searchTerm: string;
    filters: TableFilter<Case>;
};

const CasesListTable = ({ cases, searchTerm, isLoading, filters }: Props) => {
    const { user } = useLoggedInUser();
    const { i18n } = useTranslation();

    const columns: Array<TableColumnConfig<Case>> = [
        {
            id: 'internalId',
            headerTitle: 'cases.internal_id',
            getValue: ({ internalId }) => internalId,
            getDisplayedValue: ({ internalId, id }) => (
                <CaseInternalIdContainer to={`/${Routes.CASES}/${id}`}>
                    <AiFillFolder />
                    {internalId}
                </CaseInternalIdContainer>
            ),
            width: 'minmax(max-content, 220px)',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'organization',
            headerTitle: 'generic.organization',
            getValue: ({ organization }) => organization.name,
            width: 'min-content',
            isSortable: true,
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'client',
            headerTitle: 'generic.client',
            getValue: ({ clients }) =>
                clients.map((client) => `${client.firstName} ${client.lastName}`).join(', '),
            isSortable: true,
            isSearchable: true,
            width: 'minmax(min-content, 1fr)',
            maxWidth: '400px',
            isTruncated: true,
            breakPoints: [
                {
                    width: 'minmax(200px, 1fr)',
                    breakpoint: Breakpoints.DESKTOP,
                    maxWidth: '200px',
                },
                {
                    width: 'minmax(300px, 1fr)',
                    breakpoint: 1800,
                    maxWidth: '300px',
                },
            ],
        },
        {
            id: 'assignedLawyers',
            headerTitle: 'generic.assigned_lawyers',
            getValue: ({ employees }) =>
                employees.map((employee) => `${employee.firstName} ${employee.lastName}`).join(' '),
            getDisplayedValue: ({ employees }, { t }) => {
                if (employees.length === 0) {
                    return t('generic.none');
                }
                return (
                    <AssignedLawyersContainer>
                        {employees.map((employee) => {
                            return (
                                <UserAvatar key={generateId()} {...employee} path={`/${Routes.EMPLOYEES}`} />
                            );
                        })}
                    </AssignedLawyersContainer>
                );
            },
            width: 'minmax(360px, 500px)',
            isSortable: true,
            isSearchable: true,
            isExpanded: true,
            breakPoints: [
                {
                    width: 'minmax(200px, 400px)',
                    breakpoint: Breakpoints.DESKTOP,
                },
            ],
        },
        {
            id: 'status',
            headerTitle: 'generic.status',
            getValue: ({ status }) => status.locale[i18n.language as 'fr' | 'en'],
            width: 'max-content',
            isSortable: true,
            isCentered: true,
        },
        {
            id: 'createdBy',
            headerTitle: 'generic.created_by',
            getValue: ({ createdBy }) => {
                if (createdBy.id === 'me') {
                    return `${user.firstName} ${user.lastName}`;
                } else {
                    return `${createdBy.firstName} ${createdBy.lastName}`;
                }
            },
            getDisplayedValue: ({ createdBy }) => {
                const fullName =
                    createdBy.id === 'me'
                        ? `${user.firstName} ${user.lastName}`
                        : `${createdBy.firstName} ${createdBy.lastName}`;
                return <CreatedBy to={`/${Routes.EMPLOYEES}`}>{fullName}</CreatedBy>;
            },
            width: 'max-content',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'createdAt',
            headerTitle: 'generic.created_at',
            getValue: ({ createdAt }) => moment(createdAt).format('LL'),
            width: 'max-content',
            isSortable: true,
            isCentered: true,
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(
        cases ?? [],
        columns,
        {
            searchTerm: searchTerm,
            filters: filters,
        }
    );

    return (
        <Table
            columns={displayedColumns}
            data={data}
            handleSort={handleSort}
            sortedColumn={sortedColumn}
            isSortingDown={isSortingDown}
            isLoading={isLoading}
            styles={{
                table: {
                    minWidth: '800px',
                },
            }}
        />
    );
};

export default CasesListTable;
