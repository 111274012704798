import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../routes';
import { i18n } from '../../translations';
import { Container, List, ListItem, ActiveIndicator, SelectContainer } from './Navbar-styles';
import { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'usehooks-ts';
import { SelectInput } from '../../components';
import { NavLink } from 'react-router-dom';

type NavbarProps = {
    list: Array<{ id: string; path: Routes; translation: string }>;
    mobileBreakPoint?: number;
};

const Navbar = ({ list, mobileBreakPoint }: NavbarProps) => {
    const { t } = useTranslation();
    const listRef = useRef<HTMLDivElement>(null);
    const windowSize = useWindowSize();
    const { pathname } = useLocation();
    const [isMounted, setIsMounted] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [indicatorConfig, setIndicatorConfig] = useState({
        width: 0,
        offset: 0,
    });

    useEffect(() => {
        const index = list.findIndex((item) => pathname.includes(item.path) && item.path !== '');
        setTimeout(() => {
            updateIndicatorConfig(Math.max(index, 0));
            setIsMounted(true);
        });
    }, []);

    useEffect(() => {
        if (isMounted === true && useMobile !== true) {
            updateIndicatorConfig(activeTabIndex);
        }
    }, [i18n.language, windowSize.width]);

    useEffect(() => {
        if (isMounted === true) {
            const index = list.findIndex((item) => pathname.includes(item.path) && item.path !== '');
            if (index > -1 && index !== activeTabIndex) {
                updateIndicatorConfig(index);
            }
        }
    }, [pathname]);

    const useMobile = useMemo(() => {
        if (mobileBreakPoint == null) {
            return false;
        } else {
            return windowSize.width <= mobileBreakPoint;
        }
    }, [windowSize.width, mobileBreakPoint]);

    const mobileOptions = useMemo(() => {
        return list.map((item, index) => ({
            label: t(item.translation as ResourceKey),
            value: index,
            data: item,
        }));
    }, [list, i18n.language]);

    /**
     * Update indicator config
     * @param {number} index
     */
    const updateIndicatorConfig = (index: number) => {
        if (activeTabIndex !== index) {
            setActiveTabIndex(index);
        }
        const el = document.getElementById(list[index].id);
        if (el != null) {
            setIndicatorConfig({
                width: el.offsetWidth,
                offset: el.offsetLeft,
            });
        }
    };

    return (
        <Container>
            {useMobile === true ? (
                <SelectContainer>
                    <SelectInput
                        label="Current page"
                        value={activeTabIndex}
                        options={mobileOptions}
                        onChange={(value) => setActiveTabIndex(value)}
                        renderOptionLabel={(_, item) => (
                            <NavLink to={item!.path} id={item!.id} key={item!.id} end>
                                {t(item!.translation as ResourceKey)}
                            </NavLink>
                        )}
                    />
                </SelectContainer>
            ) : (
                <List ref={listRef}>
                    {list.map((item, index) => (
                        <ListItem
                            to={item.path}
                            id={item.id}
                            key={item.id}
                            onClick={() => updateIndicatorConfig(index)}
                            end
                        >
                            {t(item.translation as ResourceKey)}
                        </ListItem>
                    ))}
                    <ActiveIndicator {...indicatorConfig} />
                </List>
            )}
        </Container>
    );
};

export default Navbar;
