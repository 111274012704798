import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeyPress } from '../../../../../hooks';

// types
import { OPTION_ALL_VALUE, MultiSelectInputOption } from '../../MultiSelectInput';
import { InputMode } from '../../../Inputs-types';

// styles
import { Container, Empty } from './MultiSelectInputList-styles';

// components
import MultiSelectInputListItem from './MultiSelectInputListItem';

// helpers
import { generateId } from '../../../../../tools';

type MultiSelectInputListProps<T, U> = {
    options: Array<MultiSelectInputOption<T, U>>;
    displayedOptions: Array<MultiSelectInputOption<T, U>>;
    onChangeHandler: (currentOptions: Array<T>) => void;
    value: Array<T>;
    mode: InputMode;
    displayListLeftSide: boolean;
    maxHeight: number;
    useAllOption: boolean;
    enabledCustomOptions: Array<T>;
    setEnabledCustomOptions: React.Dispatch<React.SetStateAction<Array<T>>>;
};

const MultiSelectInputList = <T, U>({
    options,
    displayedOptions,
    onChangeHandler,
    value,
    mode,
    displayListLeftSide,
    maxHeight,
    useAllOption,
    enabledCustomOptions,
    setEnabledCustomOptions,
}: MultiSelectInputListProps<T, U>) => {
    const { t } = useTranslation();
    const [hoveredOptionIndex, setHoveredOptionIndex] = useState(0);
    const [isMovingMouse, setIsMovingMouse] = useState(false);
    const hasSelectedAll = useMemo(
        () => options.filter((option) => option.onClickCustom == null).length === value.length,
        [options, value]
    );

    useKeyPress('ArrowUp', () => {
        setIsMovingMouse(false);
        if (hoveredOptionIndex > 0) {
            setHoveredOptionIndex(hoveredOptionIndex - 1);
        }
    });

    useKeyPress('ArrowDown', () => {
        setIsMovingMouse(false);
        if (hoveredOptionIndex < options.length - 1) {
            setHoveredOptionIndex(hoveredOptionIndex + 1);
        }
    });

    useKeyPress('Enter', () => {
        if (hoveredOptionIndex != null && options[hoveredOptionIndex] != null) {
            onClickCheckboxInput(
                !value.includes(options[hoveredOptionIndex].value),
                options[hoveredOptionIndex]
            );
        }
    });

    /**
     * On change handler
     * @param isChecked
     * @param selectedOptionValue
     */
    const onClickCheckboxInput = (isChecked: boolean, selectedOption: MultiSelectInputOption<T, U>) => {
        if (selectedOption.value === OPTION_ALL_VALUE) {
            const updatedValue = isChecked
                ? options.filter((option) => option.onClickCustom == null).map((option) => option.value)
                : [];
            onChangeHandler(updatedValue);

            if (isChecked === false) {
                setEnabledCustomOptions([]);
            }
            return;
        }

        if (selectedOption.onClickCustom != null) {
            onChangeHandler(
                options
                    .filter((option) => {
                        if (option.onClickCustom != null) {
                            return false;
                        }

                        const isRelevant = selectedOption.onClickCustom!(option);
                        return isChecked ? isRelevant : !isRelevant;
                    })
                    .map((option) => option.value)
            );

            setEnabledCustomOptions((prev) =>
                isChecked
                    ? [...prev, selectedOption.value]
                    : prev.filter((customOption) => customOption !== selectedOption.value)
            );

            return;
        }

        const updatedValue =
            isChecked === true
                ? [...value, selectedOption.value]
                : value.filter((i) => i !== selectedOption.value);

        onChangeHandler(updatedValue);
    };

    const renderOptions = () => {
        return displayedOptions.map((option, index) => {
            const isChecked =
                hasSelectedAll ||
                value.includes(option.value) ||
                (option.onClickCustom != null && enabledCustomOptions.includes(option.value));

            return (
                <MultiSelectInputListItem
                    mode={mode}
                    isMovingMouse={isMovingMouse}
                    key={generateId()}
                    isChecked={isChecked}
                    option={option}
                    onClickCheckboxInput={onClickCheckboxInput}
                    hoveredOptionIndex={hoveredOptionIndex}
                    index={index}
                    setHoveredOptionIndex={setHoveredOptionIndex}
                    useAllOption={useAllOption}
                />
            );
        });
    };

    return (
        <Container
            className="multi-select-input__content__list"
            onMouseMove={() => setIsMovingMouse(true)}
            displayListLeftSide={displayListLeftSide}
            maxHeight={maxHeight}
        >
            {displayedOptions.length > 0 ? renderOptions() : <Empty>{t('generic.no_results')}</Empty>}
        </Container>
    );
};

export default MultiSelectInputList;
