import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Form = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main} !important;
    text-decoration: underline;

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        fill: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }
`;

export const FormLink = styled(Link)`
    color: ${({ theme }) => theme.colors.primary.main} !important;
`;
