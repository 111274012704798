import React, { useState } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle, PrimaryContainedAddButton } from '../../../components';
import { usePromises, useToggle } from '../../../hooks';
import { APIService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { HiBars3BottomLeft } from 'react-icons/hi2';
import { Actions, ServerFormSection } from '../../../types';
import { CreateFormsSectionModal, DeleteFormsSectionModal, UpdateFormsSectionModal } from './modals';
import { FormsGroupsListTable } from './components';

const FormSectionsList = () => {
    const { t } = useTranslation();
    const [promises, [sections]] = usePromises(() => APIService.forms().getSectionsList());
    const [action, openModal, closeModal] = useToggle<null | Actions>(null);
    const [selectedSection, setSelectedSection] = useState<null | ServerFormSection>(null);

    const openModalHandler = (selectedAction: Actions, section: ServerFormSection) => {
        openModal(selectedAction);
        setSelectedSection(section);
    };

    return (
        <>
            {action === Actions.CREATE && (
                <CreateFormsSectionModal
                    onClose={closeModal}
                    refreshSectionsList={sections.refresh}
                    sectionsList={sections.data ?? []}
                />
            )}
            {action === Actions.UPDATE && (
                <UpdateFormsSectionModal
                    onClose={closeModal}
                    refreshSectionsList={sections.refresh}
                    sectionsList={sections.data ?? []}
                    selectedSection={selectedSection!}
                />
            )}
            {action === Actions.DELETE && (
                <DeleteFormsSectionModal
                    onClose={closeModal}
                    refreshSectionsList={sections.refresh}
                    selectedSection={selectedSection!}
                />
            )}
            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <PageHeaderTitle>
                        <HiBars3BottomLeft />
                        {t('forms.sections.page_title')}
                    </PageHeaderTitle>
                    <PrimaryContainedAddButton onClick={() => openModal(Actions.CREATE)}>
                        {t('forms.sections.modals.add.title')}
                    </PrimaryContainedAddButton>
                </PageHeader>

                <FormsGroupsListTable sections={sections.data ?? []} openModalHandler={openModalHandler} />
            </PageContainer>
        </>
    );
};

export default FormSectionsList;
