import React from 'react';
import { Table, TableBodyRowAction, TableColumnConfig } from '../../../../../components';
import { Client } from '../../../../../types';
import { useLoggedInUser, useTable } from '../../../../../hooks';
import { CasesList, CasesListItem } from './ClientsListTable-styles';
import { generateId } from '../../../../../tools';
import { Routes } from '../../../../../routes';
import { Breakpoints } from '../../../../../theme';

type Props = {
    isLoading?: boolean;
    clients: null | Array<Client>;
    searchTerm: string;
    onClickClient: (client: Client) => void;
    onClickUpdateClient: (client: Client, isUpdatingPassword?: boolean) => void;
    onUpdateOrganization: (client: Client) => void;
    onClickDeleteClient: (client: Client) => void;
};

const ClientsListTable = ({
    isLoading,
    clients,
    searchTerm,
    onClickClient,
    onClickUpdateClient,
    onUpdateOrganization,
    onClickDeleteClient,
}: Props) => {
    const user = useLoggedInUser();

    const columns: Array<TableColumnConfig<Client>> = [
        {
            id: 'fullName',
            headerTitle: 'generic.fullName',
            getValue: ({ firstName, lastName }) => `${firstName} ${lastName}`,
            width: 'minmax(min-content, 1fr)',
            isSortable: true,
            isSearchable: true,
            isDefaultSort: true,
            maxWidth: '360px',
            isTruncated: true,
            breakPoints: [
                {
                    breakpoint: Breakpoints.MOBILE,
                    width: 'minmax(200px, 1fr)',
                    maxWidth: '200px',
                },
                {
                    breakpoint: Breakpoints.DESKTOP,
                    width: 'minmax(250px, 1fr)',
                    maxWidth: '250px',
                },
            ],
        },
        {
            id: 'email',
            headerTitle: 'generic.email',
            getValue: ({ email }) => email,
            width: 'minmax(max-content, 1fr)',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'internalId',
            headerTitle: 'clients.internal_id',
            getValue: ({ internalId }) => internalId,
            width: 'minmax(max-content, 150px)',
            isSortable: true,
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'organization',
            headerTitle: 'generic.organization',
            getValue: ({ organization }) => organization.name,
            width: 'minmax(max-content, 200px)',
            isSortable: true,
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'cases',
            headerTitle: 'generic.cases',
            getValue: ({ cases }) => cases.map((caseDocument) => caseDocument.internalId).join(','),
            getDisplayedValue: ({ cases }) => (
                <CasesList>
                    {cases.map((caseDocument) => (
                        <CasesListItem key={generateId()} to={`/${Routes.CASES}/${caseDocument.id}`}>
                            {caseDocument.internalId}
                        </CasesListItem>
                    ))}
                </CasesList>
            ),
            width: 'minmax(200px, 2fr)',
            isSortable: true,
            isSearchable: true,
            isExpanded: true,
        },
        {
            id: 'contact',
            headerTitle: 'generic.contact',
            getValue: ({ contacts }) =>
                contacts ? contacts.map((contact) => contact.phoneNumber).join(', ') : '',
            width: 'minmax(max-content, 150px)',
            isSearchable: true,
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (employee, { t, index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index >= total - 2}
                    actions={[
                        {
                            label: t('clients.modal.update_client.title'),
                            onClick: () => onClickUpdateClient(employee),
                        },
                        {
                            label: t('clients.modal.update_organization.title'),
                            onClick: () => onUpdateOrganization(employee),
                        },
                        {
                            label: t('clients.modal.change_password.title'),
                            onClick: () => onClickUpdateClient(employee, true),
                        },
                        {
                            label: t('clients.modal.delete.title'),
                            onClick: () => onClickDeleteClient(employee),
                            isDisplayed: user.hasPermissions('clients.delete'),
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.hasPermissions('clients.update'),
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(
        clients ?? [],
        columns,
        {
            searchTerm: searchTerm,
        }
    );

    return (
        <Table
            columns={displayedColumns}
            data={data}
            handleSort={handleSort}
            handleClickRow={(client) => onClickClient(client)}
            sortedColumn={sortedColumn}
            isSortingDown={isSortingDown}
            isLoading={isLoading}
            styles={{
                table: {
                    minWidth: '500px',
                },
            }}
        />
    );
};

export default ClientsListTable;
