import { Organization } from './organizations';

export enum EmployeeRole {
    ADMIN = 'admin',
    LAWYER = 'lawyer',
    EMPLOYEE = 'employee',
}

export type Employee = {
    id: string;
    internalId: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<EmployeeRole>;
    contacts: Array<{ identifier?: string; phoneNumber: string }>;
    organization: Organization;
};

export type CreateEmployeePayload = {
    firstName: string;
    lastName: string;
    email: string;
    organizationInternalId: string;
    password: string;
    roles: Array<EmployeeRole>;
    contacts?: Array<{ identifier?: string; phoneNumber: string }>;
};

export type UpdateEmployeeProfilePayload = Omit<CreateEmployeePayload, 'password' | 'organizationInternalId'>;
