import { Locale } from '../types';
import { permissions } from './permissions';
import { supportedFiles } from './supported-files';

const config = {
    locales: {
        supported: [...Object.values(Locale)],
        default: Locale.EN,
    },
    permissions: permissions,
    supportedFiles: supportedFiles,
    cookies: {
        consent: {
            name: 'NAIA-Cookie-Consent',
        },
    },
};

export default config;
