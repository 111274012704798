import React from 'react';
import { Modal, ModalParagraph, ModalFooter } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { Case, CaseQuote } from '../../../../../../types';
import { APIService } from '../../../../../../services';
import { useLocale, usePromises } from '../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../tools';

type RemoveQuoteModalProps = {
    onClose: () => void;
    refreshQuotesList: () => Promise<void>;
    selectedQuote: CaseQuote | null;
    currentCase: Case;
};

const RemoveQuoteModal = ({
    onClose,
    selectedQuote,
    currentCase,
    refreshQuotesList,
}: RemoveQuoteModalProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        await executePromise(async () => {
            clearError();
            await APIService.cases().removeQuote(currentCase.id, selectedQuote!.id);
            await refreshQuotesList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.quote.modals.remove_quote.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('case.quote.modals.remove_quote.message', {
                    identifier: formatLocalizedText(selectedQuote!.category.title, locale),
                    internalId: currentCase.internalId,
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('case.quote.modals.remove_quote.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
                isLoading={isExecutingPromise}
                isDestructive
            />
        </Modal>
    );
};

export default RemoveQuoteModal;
