import React from 'react';
import { AddOption, AddOptionLabel, Container, List } from './FormQuestionsSelectOptions-styles';
import { DraggableList, FormGroupLabel } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import FormQuestionSelectOption from './FormQuestionSelectOption';
import { Locale } from '../../../../../../types';

type FormQuestionsSelectOptionsProps = {
    questionIndex?: number;
    options: Array<{ label_en: string; label_fr: string; value: string }>;
    onChangeOptions: (options: Array<{ label_en: string; label_fr: string; value: string }>) => void;
    addOptionsHandler: () => void;
    removeOptionHandler: (optionIndex: number) => void;
};

const FormQuestionsSelectOptions = ({
    questionIndex,
    options,
    onChangeOptions,
    addOptionsHandler,
    removeOptionHandler,
}: FormQuestionsSelectOptionsProps) => {
    const { t } = useTranslation();

    const geOptionLabel = (locale: Locale, optionIndex: number) => {
        const label = `options.${optionIndex}.label_${locale}`;
        return questionIndex != null ? `questions.${questionIndex}.${label}` : label;
    };

    return (
        <Container>
            <FormGroupLabel>{t('generic.options')}</FormGroupLabel>
            <List>
                <DraggableList
                    list={options}
                    renderListItem={(_, optionIndex) => (
                        <FormQuestionSelectOption
                            label_en={geOptionLabel(Locale.EN, optionIndex)}
                            label_fr={geOptionLabel(Locale.FR, optionIndex)}
                            removeOptionHandler={() => removeOptionHandler(optionIndex)}
                            isRemovable={!(optionIndex === 0 && options.length === 1)}
                        />
                    )}
                    getItemId={(option) => option.value}
                    onChange={(updatedOptions) => onChangeOptions(updatedOptions)}
                />
                <AddOption>
                    <AddOptionLabel onClick={() => addOptionsHandler()}>
                        {t('forms.questions.add_options')}
                    </AddOptionLabel>
                </AddOption>
            </List>
        </Container>
    );
};

export default FormQuestionsSelectOptions;
