import React from 'react';
import { Content } from './AccordionHeader-styles';

type AccordionHeaderContentProps = {
    children: React.ReactNode;
};

const AccordionHeaderContent = ({ children }: AccordionHeaderContentProps) => {
    return <Content className="accordion__header__content">{children}</Content>;
};

export default AccordionHeaderContent;
