import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { useLoggedInUser, usePromises, useToggle } from '../../../../hooks';
import { APIService } from '../../../../services';
import { CasesPagesContext } from '../CasePagesContext';
import { PageContainer, PageHeader, PrimaryContainedButton } from '../../../../components';

// types
import { CaseQuote } from '../../../../types';

// styles
import { FiUsers } from 'react-icons/fi';
import { CaseChildPageTitle } from '../CasePages-styles';

// modals
import {
    AddQuotesModal,
    RemoveQuoteModal,
    AddDependentsModal,
    UpdateDependentModal,
    RemoveDependentModal,
    UpdateQuoteModal,
} from './modals';

// custom components
import { CaseQuoteTable } from './components';

export enum CaseQuoteActionsTypes {
    ADD_QUOTES,
    REMOVE_QUOTE,
    UPDATE_QUOTE,
    ADD_DEPENDENT,
    UPDATE_DEPENDENT,
    REMOVE_DEPENDENT,
}

const CaseQuotePage = () => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const [action, openModal, closeModal] = useToggle<null | CaseQuoteActionsTypes>(null);
    const { currentCase } = useOutletContext<CasesPagesContext>();
    const [selectedQuote, setSelectedQuotePackage] = useState<null | CaseQuote>(null);
    const [promises, [quotes]] = usePromises(() => APIService.cases().getQuotesList(currentCase.id));

    /**
     * Open modal Handler
     * @param {CaseQuoteActionsTypes} selectedAction
     * @param {CaseQuote} [quote]
     */
    const openModalHandler = (selectedAction: CaseQuoteActionsTypes, quote?: CaseQuote) => {
        openModal(selectedAction);
        if (quote != null) {
            setSelectedQuotePackage(quote);
        }
    };

    return (
        <PageContainer error={promises.error} clearError={promises.clearError}>
            {action === CaseQuoteActionsTypes.ADD_QUOTES && (
                <AddQuotesModal
                    currentCase={currentCase}
                    refreshQuotesList={quotes.refresh}
                    onClose={closeModal}
                    caseQuotes={quotes.data ?? []}
                />
            )}
            {action === CaseQuoteActionsTypes.REMOVE_QUOTE && (
                <RemoveQuoteModal
                    currentCase={currentCase}
                    refreshQuotesList={quotes.refresh}
                    onClose={closeModal}
                    selectedQuote={selectedQuote}
                />
            )}
            {action === CaseQuoteActionsTypes.UPDATE_QUOTE && (
                <UpdateQuoteModal
                    currentCase={currentCase}
                    refreshQuotesList={quotes.refresh}
                    onClose={closeModal}
                    selectedQuote={selectedQuote}
                    caseQuotes={quotes.data ?? []}
                />
            )}
            {action === CaseQuoteActionsTypes.ADD_DEPENDENT && (
                <AddDependentsModal
                    currentCase={currentCase}
                    refreshQuotesList={quotes.refresh}
                    onClose={closeModal}
                    selectedQuote={selectedQuote}
                />
            )}
            {action === CaseQuoteActionsTypes.UPDATE_DEPENDENT && (
                <UpdateDependentModal
                    currentCase={currentCase}
                    refreshQuotesList={quotes.refresh}
                    onClose={closeModal}
                    selectedQuote={selectedQuote}
                />
            )}
            {action === CaseQuoteActionsTypes.REMOVE_DEPENDENT && (
                <RemoveDependentModal
                    currentCase={currentCase}
                    refreshQuotesList={quotes.refresh}
                    onClose={closeModal}
                    selectedQuote={selectedQuote}
                />
            )}
            <PageHeader display="row">
                <CaseChildPageTitle>
                    <FiUsers />
                    {t('case.quote.page_title')}
                </CaseChildPageTitle>
                {user.isEmployee() && (
                    <PrimaryContainedButton onClick={() => openModal(CaseQuoteActionsTypes.ADD_QUOTES)}>
                        {t('case.quote.modals.add_quotes.title')}
                    </PrimaryContainedButton>
                )}
            </PageHeader>
            <CaseQuoteTable quotes={quotes.data} openModalHandler={openModalHandler} />
        </PageContainer>
    );
};

export default CaseQuotePage;
