import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { usePromises } from '../../../../hooks';
import * as Yup from 'yup';
import { GCSFile } from '../../../../types';
import {
    Form,
    Modal,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    FormFooter,
    FormFileInput,
    FormColorInput,
} from '../../../../components';
import { APIService } from '../../../../services';
import { PAGE_ORGANIZATIONS_Organization } from '../../OrganizationsPages';

type Props = {
    onClose: () => void;
    refreshOrganizationsList: () => Promise<void>;
    updatedOrganization: PAGE_ORGANIZATIONS_Organization;
};

const UpdateOrganizationModal = ({ onClose, refreshOrganizationsList, updatedOrganization }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        name: Yup.string().required(t('generic.error.required_field')),
        primaryColor: Yup.string().required(t('generic.error.required_field')),
        loginUrl: Yup.string().required(t('generic.error.required_field')),
        logo: Yup.mixed().required(t('generic.error.required_field')),
        favicon: Yup.mixed().required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            name: updatedOrganization.name,
            primaryColor: updatedOrganization.theme.primary,
            loginUrl: updatedOrganization.config.loginUrl,
            logo: [
                {
                    originalName: updatedOrganization.assets.logo.originalName,
                    url: updatedOrganization.assets.logo.url,
                } as GCSFile | File,
            ],
            favicon: [
                {
                    originalName: updatedOrganization.assets.favicon.originalName,
                    url: updatedOrganization.assets.favicon.url,
                } as GCSFile | File,
            ],
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const logo = submittedValues.logo![0];
                const favicon = submittedValues.favicon![0];

                await APIService.organizations().updateOrganization(updatedOrganization.id!, {
                    name: submittedValues.name,
                    primaryColor: submittedValues.primaryColor,
                    loginUrl: submittedValues.loginUrl,
                    logo: logo instanceof File ? logo : undefined,
                    favicon: favicon instanceof File ? favicon : undefined,
                });
                await refreshOrganizationsList();
                onClose();
            });
        },
    });

    return (
        <Modal
            title="organizations.modals.update_organization.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="name">
                    <FormGroupLabel>{t('organizations.name')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('organizations.name')} />
                </FormGroup>
                <FormGroup name="primaryColor">
                    <FormGroupLabel>{t('organizations.primary_color')}</FormGroupLabel>
                    <FormColorInput />
                </FormGroup>
                <FormGroup name="loginUrl" expand>
                    <FormGroupLabel>{t('organizations.login_url')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('organizations.login_url')} />
                </FormGroup>
                <FormGroup name="logo" expand>
                    <FormGroupLabel>Logo</FormGroupLabel>
                    <FormFileInput
                        maxFiles={1}
                        supported={{ image: ['image/png', 'image/jpg', 'image/jpeg'] }}
                    />
                </FormGroup>
                <FormGroup name="favicon" expand>
                    <FormGroupLabel>Favicon</FormGroupLabel>
                    <FormFileInput
                        maxFiles={1}
                        supported={{ image: ['image/png', 'image/jpg', 'image/jpeg'] }}
                    />
                </FormGroup>
                <FormFooter
                    submitLabel={t('organizations.modals.update_organization.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateOrganizationModal;
