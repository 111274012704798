import styled from 'styled-components';

export const Container = styled.div<{ isMobile: boolean; mobileGrid: Array<Number> }>`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.box.spacing.md};

    ${({ isMobile, mobileGrid }) => {
        if (isMobile === true) {
            const obj: { [key: string]: any } = {
                flexWrap: 'wrap',
                '> *': {
                    height: '35px !important',
                    maxWidth: '100%',
                },
            };
            mobileGrid.forEach((width, index) => {
                obj[`> div:nth-child(${index + 1})`] = {
                    flex: `1 0 ${width}px`,
                };
            });
            return obj;
        }
    }}
`;
