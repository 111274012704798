import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableColumnConfig, TableFilter, GetDisplayedValueOptions } from '../components';
import { i18n } from '../translations';
import { useDidMountEffect } from './useDidMountEffect';
import { useRefState } from './useRefState';

export const useTable = <T>(
    initialData: null | Array<T>,
    initialColumns: Array<TableColumnConfig<T>>,
    options?: {
        searchTerm?: string;
        filters?: TableFilter<T>;
    }
) => {
    const { t } = useTranslation();
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(data?.length ? false : true);
    const [isSortingDown, setIsSortingDown, isSortingDownRef] = useRefState(true);
    const [sortedColumn, setSortedColumn, sortedColumnRef] = useRefState(
        initialColumns.find((column) => column.isDefaultSort === true)
    );
    const timeout = useRef<null | NodeJS.Timeout>(null);

    useDidMountEffect(() => {
        timeout.current = setTimeout(() => {
            updateData();
        }, 500);
        return () => {
            if (timeout.current != null) {
                clearTimeout(timeout.current);
            }
        };
    }, [options?.searchTerm]);

    useDidMountEffect(() => {
        updateData();
        if (initialData != null && isLoading === true) {
            setIsLoading(false);
        }
    }, [initialData]);

    useDidMountEffect(() => {
        if (options?.filters?.dependencies != null) {
            updateData();
        }
    }, options?.filters?.dependencies ?? []);

    /**
     * Handle sort
     * @param newSortedColumn
     */
    const handleSort = (newSortedColumn: TableColumnConfig<any>) => {
        const sortDown = newSortedColumn.id === sortedColumn?.id ? !isSortingDownRef : true;
        setIsSortingDown(sortDown);
        setSortedColumn(newSortedColumn);
        updateData();
    };

    // ******************************************************************************************
    // HELPERS
    // ******************************************************************************************

    /**
     * Update data
     */
    const updateData = () => {
        if (initialData == null) {
            setData(initialData);
        } else {
            const filteredData = filterDataHandler(initialData);
            const sortedData = sortDataHandler(filteredData);
            setData(sortedData);
        }
    };

    /**
     * Sort data
     * @param array
     * @returns
     */
    const sortDataHandler = (array: Array<T>): Array<T> => {
        if (sortedColumnRef == null) {
            return array;
        } else {
            return array.sort((a, b) => {
                const aValue = getItemValue(a, sortedColumnRef, 'sort');
                const bValue = getItemValue(b, sortedColumnRef, 'sort');

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return isSortingDownRef === true
                        ? aValue.localeCompare(bValue, i18n.language)
                        : bValue.localeCompare(aValue, i18n.language);
                } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return isSortingDownRef === true ? aValue - bValue : bValue - aValue;
                }

                return 0;
            });
        }
    };

    /**
     * Filter data
     * @param array
     * @returns
     */
    const filterDataHandler = (array: Array<T>): Array<T> => {
        return array.filter((item) => {
            let isValid = true;

            if (options?.filters != null) {
                for (const filter of options.filters.filterFunctions) {
                    if (isValid === true) {
                        isValid = filter(item);
                    }
                }
            }
            if (isValid === true && options?.searchTerm != null && options?.searchTerm?.length > 0) {
                for (const key of options.searchTerm.split(' ')) {
                    if (isValid === true && key.length > 0) {
                        isValid = initialColumns.some((column) => {
                            if (column.isSearchable === true) {
                                const value = getItemValue(item, column, 'search');
                                if (typeof value === 'string') {
                                    return value.toLowerCase().includes(key.toLowerCase());
                                }
                            }
                            return false;
                        });
                    }
                }
            }

            return isValid;
        }) as Array<T>;
    };

    /**
     * Get item value for a column
     * @param item
     * @param column
     * @param action
     * @returns
     */
    const getItemValue = (item: any, column: TableColumnConfig<T>, action?: 'sort' | 'search') => {
        let value = column.getValue(item, { t } as GetDisplayedValueOptions);
        switch (action) {
            case 'sort':
                if (typeof column.getSortValue === 'function') {
                    value = column.getSortValue(item);
                }
                break;
            case 'search':
                if (typeof column.getSearchValue === 'function') {
                    value = column.getSearchValue(item);
                }
                break;
        }
        return value;
    };

    const displayedColumns = useMemo(() => {
        return initialColumns.filter((column) => column.isDisplayed !== false);
    }, [initialColumns]);

    return {
        data: data ?? [],
        sortedColumn,
        isSortingDown,
        isLoading,
        displayedColumns,
        handleSort,
    };
};
