import { ServerFormSection, ServerFormSectionType } from '../../../../../types';

export type SelectedSection = {
    section: ServerFormSection;
    sectionType: ServerFormSectionType;
};

export const INITIAL_FORM_SECTION = {
    section: {
        id: '',
        name: '',
    } as ServerFormSection,
    sectionType: ServerFormSectionType.UNIQUE,
};
