import React, { useMemo } from 'react';
import { Container } from '../Topbar-styles';
import { Logo, LocaleSelect } from '../components';
import { Left, Right } from './AppTopbar-styles';
import { MenuToggle, UserProfile } from './components';
import { Link } from 'react-router-dom';
import { useLoggedInUser } from '../../../hooks';
import { Routes } from '../../../routes';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '../../../theme';

const AppTopbar = () => {
    const user = useLoggedInUser();
    const windowSize = useWindowSize();

    const localeSelectMenuToggleBreakPoint = useMemo(() => {
        return user.isClient() === true ? Breakpoints.MOBILE : Breakpoints.DESKTOP;
    }, [user]);

    return (
        <Container>
            <Left>
                <Link to={user.isLoggedIn() ? '/' : `/${Routes.LOGIN}`}>
                    <Logo />
                </Link>
            </Left>
            <Right>
                {windowSize.width > Breakpoints.MOBILE && <UserProfile />}
                {windowSize.width > localeSelectMenuToggleBreakPoint ? <LocaleSelect /> : <MenuToggle />}
            </Right>
        </Container>
    );
};

export default AppTopbar;
