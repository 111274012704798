import { useEffect } from 'react';

export const useDebounce = (callback: () => void, dependencies: Array<any>, delay = 200) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [...dependencies]);
};
