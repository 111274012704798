import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateId } from '../../../tools';
import PasswordInput, {
    PasswordRequirementsList,
    PasswordRequirementsListItem,
} from '../../Inputs/PasswordInput';
import { TextInputProps } from '../../Inputs/TextInput';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { ResourceKey } from 'i18next';
import { InputMode } from '../../Inputs';

type FormPasswordInputProps = TextInputProps & {
    validate?: boolean;
};

const FormPasswordInput = forwardRef<HTMLInputElement, FormPasswordInputProps>((props, ref) => {
    const { t } = useTranslation();
    const { formik } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);

    const [isFocused, setIsFocused] = useState(false);
    const [requirements, setRequirements] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        number_or_special_characters: false,
    });

    useEffect(() => {
        if (props.validate === true) {
            validatePassword(formik.values[name]);
        }
    }, [formik.values[name]]);

    /**
     * Validate password
     * @param {string} value
     * @return {boolean}
     */
    const validatePassword = (value: string): boolean => {
        const currentRequirements = {
            length: value.length >= 8,
            lowercase: value.toUpperCase() !== value,
            uppercase: value.toLowerCase() !== value,
            number_or_special_characters: /\d/.test(value) || /[ #?!@$%^&*-]+/.test(value),
        };
        setRequirements(currentRequirements);
        return Object.values(currentRequirements).every((currentRequirement) => currentRequirement === true);
    };

    /**
     * Returns true if should display requirements
     * @return {boolean}
     */
    const showRequirements = (): boolean => {
        return (
            props.validate === true && // validation is enabled
            formik.errors[name] !== t('generic.error.required_field') && // error is not equal to required field
            (formik.touched[name] === true || isFocused) && // either touched or focused
            Object.values(requirements).some((value) => value !== true) // at least one requirement is not met
        );
    };

    return (
        <>
            <PasswordInput
                {...props}
                ref={ref}
                name={name}
                onChange={formik.handleChange}
                value={formik.values[name]}
                onFocus={() => setIsFocused(true)}
                mode={InputMode.FORM}
                onBlur={(e) => {
                    setIsFocused(false);
                    formik.handleBlur(e);
                }}
            />
            {showRequirements() && (
                <PasswordRequirementsList>
                    {Object.keys(requirements).map((requirement) => {
                        const isValid = requirements[requirement as keyof typeof requirements];
                        return (
                            <PasswordRequirementsListItem key={generateId()} isValid={isValid}>
                                {isValid ? <AiOutlineCheckCircle /> : <AiOutlineCloseCircle />}
                                {t(`generic.password_requirements.${requirement}` as ResourceKey)}
                            </PasswordRequirementsListItem>
                        );
                    })}
                </PasswordRequirementsList>
            )}
        </>
    );
});

export default FormPasswordInput;
