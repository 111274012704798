import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-left: 30rem;
    min-height: 100%;
    background-color: ${({ theme }) => theme.colors.surface.main};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
