import React, { useMemo, useEffect, useState } from 'react';
import {
    Banners,
    CaseChildPageTitleLink,
    CaseChildPageTitleSelect,
    EmptyPlaceholder,
} from './CaseDocuments-styles';
import { CaseChildPageTitle } from '../CasePages-styles';
import { BiFolder } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Promised, useLocale, usePromises, useToggle } from '../../../../hooks';
import { APIService } from '../../../../services';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CasesPagesContext } from '../CasePagesContext';
import { PageContainer, PageHeader, PrimaryContainedButton, SelectInput } from '../../../../components';
import { Case, CaseFolder, CaseFile, LocaleObject } from '../../../../types';
import { formatCaseFolderDescription } from '../../../../tools';
import { Routes } from '../../../../routes';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { BatchUploadFilesModal } from './modals';
import { BiUpload } from 'react-icons/bi';
import { CaseFolderManager, CaseFolderOCR } from '../../../../managers';
import CaseDocumentsOCRBanner from './CaseDocumentsOCRBanner';

export type CaseDocumentsContextType = {
    currentCase: Case;
    caseFolders: Promised<Array<CaseFolder>>;
    currentCaseFolder: CaseFolder | null;
    selectedOCRFileId: string;
    setSelectedOCRFileId: React.Dispatch<React.SetStateAction<string>>;
};

export type SelectedCaseFile = {
    caseFolder: CaseFolder;
    categoryFile: {
        id: string;
        title: LocaleObject;
    };
    fileItem?: CaseFile;
};

enum CustomActions {
    BATCH_UPLOAD = 'batch_upload',
}

const CaseDocuments = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const params = useParams();
    const navigate = useNavigate();
    const { currentCase } = useOutletContext<CasesPagesContext>();
    const [action, openModal, closeModal] = useToggle<null | CustomActions>(null);
    const [promises, [caseFolders]] = usePromises(() => APIService.cases().getDocumentsList(currentCase.id));
    const [caseFolderOCRList, setCaseFolderOCRList] = useState<Array<CaseFolderOCR>>([]);
    const [selectedOCRFileId, setSelectedOCRFileId] = useState('');

    const currentCaseFolderList = useMemo(() => {
        if (caseFolders.data == null) {
            return [];
        }
        return caseFolders.data.map((caseFolder) => ({
            label: formatCaseFolderDescription(caseFolder, locale),
            value: caseFolder.id,
            data: caseFolder,
        }));
    }, [caseFolders.data, locale]);

    const currentCaseFolderOCRList = useMemo(() => {
        return caseFolderOCRList.filter((ocr) => ocr.isDisplayed === true);
    }, [caseFolderOCRList]);

    const currentCaseFolder = useMemo(() => {
        if (caseFolders.data == null || params.folder_id == null) {
            return null;
        } else {
            return caseFolders.data.find((caseFolder) => caseFolder.id === params.folder_id);
        }
    }, [caseFolders.data, params.folder_id]);

    useEffect(() => {
        if (params.folder_id != null && caseFolders.data != null && currentCaseFolder == null) {
            navigate(`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_DOCUMENTS}`);
        }
    }, [params.folder_id, caseFolders.data, currentCaseFolder]);

    useEffect(() => {
        if (caseFolders.data != null) {
            const list: Array<CaseFolderOCR> = [];
            for (const caseFolder of caseFolders.data) {
                const managedCaseFolder = new CaseFolderManager(currentCase, caseFolder);
                list.push(...managedCaseFolder.getOCRList());
            }
            setCaseFolderOCRList(list);
        }
    }, [caseFolders.data]);

    return (
        <>
            {action === CustomActions.BATCH_UPLOAD && (
                <BatchUploadFilesModal
                    currentCase={currentCase}
                    onClose={closeModal}
                    refresh={caseFolders.refresh}
                    caseFolders={caseFolders.data ?? []}
                />
            )}
            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <CaseChildPageTitle>
                        <CaseChildPageTitleLink
                            to={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_DOCUMENTS}`}
                        >
                            <BiFolder />
                            {currentCaseFolder ? t('case.documents.all_documents') : t('generic.documents')}
                        </CaseChildPageTitleLink>
                        {currentCaseFolder != null && (
                            <CaseChildPageTitleSelect>
                                <FiChevronRight />
                                <SelectInput
                                    value={currentCaseFolder.id}
                                    options={currentCaseFolderList}
                                    onChange={() => ({})}
                                    renderOptionLabel={(value, data) => (
                                        <Link
                                            to={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_DOCUMENTS}/${value}`}
                                        >
                                            {formatCaseFolderDescription(data!, locale)}
                                        </Link>
                                    )}
                                />
                            </CaseChildPageTitleSelect>
                        )}
                    </CaseChildPageTitle>
                    <PrimaryContainedButton
                        leadingIcon={<BiUpload />}
                        onClick={() => openModal(CustomActions.BATCH_UPLOAD)}
                    >
                        {t('generic.upload')}
                    </PrimaryContainedButton>
                </PageHeader>

                {currentCaseFolderOCRList.length > 0 && (
                    <Banners>
                        {currentCaseFolderOCRList.map((caseFolderOCR, index) => {
                            return (
                                <CaseDocumentsOCRBanner
                                    key={index}
                                    caseFolderOCR={caseFolderOCR}
                                    refresh={caseFolders.refresh}
                                    setSelectedOCRFileId={setSelectedOCRFileId}
                                    setCaseFolderOCRList={setCaseFolderOCRList}
                                />
                            );
                        })}
                    </Banners>
                )}

                {caseFolders.isLoading !== true && caseFolders.data.length === 0 && (
                    <EmptyPlaceholder>{t('case.documents.empty_placeholder')}</EmptyPlaceholder>
                )}
                <Outlet
                    context={{
                        caseFolders: caseFolders,
                        currentCase: currentCase,
                        currentCaseFolder: currentCaseFolder,
                        selectedOCRFileId: selectedOCRFileId,
                        setSelectedOCRFileId: setSelectedOCRFileId,
                    }}
                />
            </PageContainer>
        </>
    );
};

export default CaseDocuments;
