import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useLocale, useLoggedInUser } from '../../hooks';
import { useWindowSize } from 'usehooks-ts';
import { Routes } from '../../routes';
import { Breakpoints } from '../../theme';
import { AuthenticationBusiness, UserBusiness } from '../../business';
import { SidebarItem } from './Sidebar-types';
import { Locale } from '../../types';

// ICONS
import { BiFolder, BiCategory } from 'react-icons/bi';
import { RiUserSettingsLine } from 'react-icons/ri';
import { HiOutlineCog } from 'react-icons/hi';
import { GoGraph } from 'react-icons/go';
import { FiUsers } from 'react-icons/fi';
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import { MdLogout } from 'react-icons/md';
import { CiGlobe } from 'react-icons/ci';
import { LuNewspaper } from 'react-icons/lu';

export const useSidebarItems = () => {
    const locale = useLocale();
    const { pathname } = useLocation();
    const user = useLoggedInUser();
    const windowSize = useWindowSize();
    const dispatch = useAppDispatch();

    return useMemo(() => {
        const list: Array<SidebarItem> = [
            {
                id: 'dashboard',
                path: Routes.DASHBOARD,
                icon: <GoGraph />,
                isDisplayed: () => user.isEmployee(),
            },
            {
                id: 'cases',
                path: Routes.CASES,
                icon: <BiFolder />,
                isDisplayed: () => true,
                children: user.isEmployee()
                    ? [
                          {
                              id: 'cases_list',
                              path: Routes.CASES_LIST,
                              isActive: /^\/cases\/[a-zA-Z0-9]{24}(\/\w+)?$/.test(pathname),
                          },
                          {
                              id: 'cases_new',
                              path: Routes.CASES_NEW,
                              isActive: pathname.includes(`${Routes.CASES}/${Routes.CASES_NEW}`),
                          },
                      ]
                    : undefined,
            },
            {
                id: 'clients',
                path: Routes.CLIENTS,
                icon: <FiUsers />,
                isDisplayed: () => user.isEmployee(),
            },
            {
                id: 'employees',
                path: Routes.EMPLOYEES,
                icon: <RiUserSettingsLine />,
                isDisplayed: () => user.hasPermissions('employees.read'),
            },
            {
                id: 'organizations',
                path: Routes.ORGANIZATIONS,
                icon: <HiOutlineBuildingLibrary />,
                isDisplayed: () =>
                    user.hasPermissions('organizations.read') && user.belongsToDefaultOrganization(),
            },
            {
                id: 'categories',
                path: Routes.CATEGORIES,
                icon: <BiCategory />,
                isDisplayed: () =>
                    user.hasPermissions('categories.read') && user.belongsToDefaultOrganization(),
            },
            {
                id: 'forms',
                path: Routes.FORMS,
                icon: <LuNewspaper />,
                isDisplayed: () => user.hasPermissions('forms.read') && user.belongsToDefaultOrganization(),
            },
            {
                id: 'settings',
                path: Routes.SETTINGS,
                icon: <HiOutlineCog />,
                isDisplayed: () => true,
            },
            {
                id: 'locale',
                icon: <CiGlobe />,
                isDisplayed: () => windowSize.width <= Breakpoints.MOBILE,
                onClick: () => UserBusiness.updateLocale(locale === Locale.EN ? Locale.FR : Locale.EN, true),
            },
            {
                id: 'logout',
                path: Routes.LOGIN,
                icon: <MdLogout />,
                isDisplayed: () => windowSize.width <= Breakpoints.MOBILE,
                onClick: () => AuthenticationBusiness.logout(dispatch),
            },
        ];
        return list;
    }, [pathname, user.user.email, windowSize.width, locale]);
};
