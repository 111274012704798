import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderName = styled.span`
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
`;

export const InfoList = styled.ul`
    padding: ${({ theme }) => theme.box.spacing.lg};
    background-color: ${({ theme }) => theme.colors.onSurface.main};
`;

export const InfoListItem = styled.li`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }

    &:last-child {
        > span {
            align-self: flex-start;
            transform: translateY(5px);
        }
    }
`;

export const InfoListItemIcon = styled.span`
    font-size: ${({ theme }) => theme.font.size.lg};
    margin-right: ${({ theme }) => theme.font.size.md};
    height: min-content;
`;

export const InfoListItemText = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.box.spacing.md};
    margin-top: ${({ theme }) => theme.box.spacing.md};
`;

export const CasesList = styled.ul``;

export const CasesListItem = styled(Link)`
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    margin-right: ${({ theme }) => theme.box.spacing.md};
    padding: ${({ theme }) => theme.box.spacing.xs} 0;
    display: inline-block;
`;
