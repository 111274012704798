import React from 'react';
import { PAGE_DASHBOARD_Case } from '../../../DashboardPages';
import { Draggable } from 'react-beautiful-dnd';
import {
    ClientsList,
    ClientsListItem,
    ClientsPlaceholder,
    Container,
    Header,
    HeaderImage,
    HeaderLabel,
} from './DashboardTableCard-styles';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../routes';

type DashboardTableCardProps = {
    caseItem: PAGE_DASHBOARD_Case;
    index: number;
};

const DashboardTableCard = ({ caseItem, index }: DashboardTableCardProps) => {
    const { t } = useTranslation();
    return (
        <Draggable draggableId={caseItem.id} index={index} key={caseItem.id}>
            {(provided, snapshot) => (
                <Container
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    isDragging={snapshot.isDragging}
                >
                    <Header>
                        <HeaderImage src={caseItem.organization.assets.favicon.url} alt="favicon" />
                        <HeaderLabel to={`${Routes.CASES}/${caseItem.id}`}>
                            {caseItem.category.internalId}
                        </HeaderLabel>
                    </Header>
                    {caseItem.clients.length === 0 ? (
                        <ClientsPlaceholder>{t('generic.no_clients')}</ClientsPlaceholder>
                    ) : (
                        <ClientsList>
                            {caseItem.clients.map((client) => (
                                <ClientsListItem key={client.id}>
                                    {client.firstName} {client.lastName}
                                </ClientsListItem>
                            ))}
                        </ClientsList>
                    )}
                </Container>
            )}
        </Draggable>
    );
};

export default DashboardTableCard;
