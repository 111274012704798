import { useMemo } from 'react';
import { CaseClientRole } from '../types';
import { useTranslation } from 'react-i18next';

export const useCaseClientRoles = () => {
    const { t } = useTranslation();
    return useMemo(() => {
        return [
            {
                label: t('generic.client_role.employer'),
                value: CaseClientRole.EMPLOYER,
            },
            {
                label: t('generic.client_role.employee'),
                value: CaseClientRole.EMPLOYEE,
            },
            {
                label: t('generic.client_role.entrepreneur'),
                value: CaseClientRole.ENTREPRENEUR,
            },
        ];
    }, [t]);
};
