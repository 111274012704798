import React, { Fragment, useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { updateSidebar } from '../../services/ReduxService';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useLoggedInUser, useStore } from '../../hooks';
import { ResourceKey } from 'i18next';
import { useTheme } from 'styled-components';
import { useWindowSize } from 'usehooks-ts';
import { useSidebarItems } from './Sidebar-hooks';
import {
    Container,
    NavigationList,
    NavigationListItem,
    NavigationListItemContainer,
    NavigationListItemIcon,
    NavigationListItemLabel,
    NavigationListItemSubList,
    NavigationListItemSubListItem,
    NavigationListItemToggle,
    Placeholder,
    ResizeHandler,
    Toggler,
    Wrapper,
    Backdrop,
} from './Sidebar-styles';

import { Breakpoints } from '../../theme';
import { SidebarItem } from './Sidebar-types';

// ICONS
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const MIN_SHOW_WIDTH = 173;

const Sidebar = () => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const windowSize = useWindowSize();

    const list = useSidebarItems();
    const sidebarRef = useRef<HTMLDivElement>(null);
    const { sidebar } = useStore().settings.ui;
    const [sidebarWidth, setSidebarWidth] = useState(sidebar.width);
    const [isResizing, setIsResizing] = useState(false);
    const [openedSubNavigation, setOpenedSubNavigation] = useState<Array<string>>([]);

    // *************************************************************************************
    // Sidebar functions
    // *************************************************************************************

    /**
     * Toggle sidebar
     * @param event
     */
    const toggleSidebarHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        const payload = {
            displayed: !sidebar.displayed,
            width: sidebarWidth,
        };

        if (sidebar.displayed === true) {
            setOpenedSubNavigation([]);
        } else {
            if (sidebarWidth === theme.config.sidebar.width.collapsed) {
                setSidebarWidth(theme.config.sidebar.width.default);
                payload.width = theme.config.sidebar.width.default;
            }
        }

        dispatch(updateSidebar(payload));
    };

    /**
     * Toggle sidebar sublist
     * @param {string} subNavigationId
     */
    const toggleSidebarSubNavigationHandler = (subNavigationId: string) => {
        setOpenedSubNavigation((prev) =>
            prev.includes(subNavigationId)
                ? prev.filter((prevItem) => prevItem !== subNavigationId)
                : [...prev, subNavigationId]
        );
    };

    /**
     * Click sidebar item handler
     * @param item
     */
    const clickSidebarItemHandler = (
        event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement, MouseEvent>,
        item: SidebarItem
    ) => {
        event.stopPropagation();

        if (item.onClick != null) {
            item.onClick();
        }

        if (item.children != null && openedSubNavigation.includes(item.id) === false) {
            toggleSidebarSubNavigationHandler(item.id);
        }

        if (windowSize.width < Breakpoints.DESKTOP && sidebar.displayed === true) {
            dispatch(updateSidebar({ displayed: !sidebar.displayed }));
        }
    };

    // *************************************************************************************
    // Resize functions
    // *************************************************************************************

    /**
     * Start resizing
     */
    const startResizing = useCallback(() => setIsResizing(true), []);

    /**
     * Stop resizing
     */
    const stopResizing = useCallback(() => {
        if (isResizing === true) {
            setIsResizing(false);
            if (sidebarWidth <= MIN_SHOW_WIDTH) {
                setSidebarWidth(theme.config.sidebar.width.collapsed);
                if (sidebar.displayed === true) {
                    dispatch(updateSidebar({ displayed: !sidebar.displayed }));
                }
            }
            if (sidebarWidth > MIN_SHOW_WIDTH && sidebar.displayed === false) {
                dispatch(updateSidebar({ displayed: !sidebar.displayed }));
            }
            if (sidebarWidth < MIN_SHOW_WIDTH + 50) {
                setOpenedSubNavigation([]);
            }

            dispatch(updateSidebar({ width: sidebarWidth }));
        }
    }, [sidebarWidth, sidebar.displayed]);

    /**
     * Handle resize callback
     */
    const resize = useCallback(
        (mouseMoveEvent: MouseEvent) => {
            if (isResizing) {
                let width = mouseMoveEvent.clientX - sidebarRef.current!.getBoundingClientRect().left;
                const config = theme.config.sidebar.width;
                if (width < config.collapsed) {
                    width = config.collapsed;
                }
                if (width > config.max) {
                    width = config.max;
                }
                setSidebarWidth(width);
            }
        },
        [isResizing]
    );

    /**
     * Add listeners
     */
    const addEventListeners = () => {
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResizing);
    };

    /**
     * Remove listeners
     */
    const removeEventListeners = () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResizing);
    };

    useEffect(() => {
        addEventListeners();
        return () => {
            removeEventListeners();
        };
    }, [resize, stopResizing]);

    useEffect(() => {
        if (isResizing === false) {
            removeEventListeners();
        }
    }, [isResizing]);

    const renderSidebarItemContent = (item: SidebarItem): React.ReactNode => (
        <>
            <NavigationListItemIcon>{item.icon}</NavigationListItemIcon>
            <NavigationListItemLabel>{t(`sidebar.${item.id}` as ResourceKey)}</NavigationListItemLabel>
            {item.children != null && (
                <NavigationListItemToggle
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleSidebarSubNavigationHandler(item.id);
                    }}
                >
                    {openedSubNavigation.includes(item.id) ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                </NavigationListItemToggle>
            )}
        </>
    );

    const currentSidebarWidth = useMemo(() => {
        if (isResizing === true || sidebar.displayed === true) {
            return sidebarWidth;
        } else {
            return theme.config.sidebar.width.collapsed;
        }
    }, [sidebarWidth, sidebar.displayed, isResizing]);

    if (
        user.isLoggedIn() === false ||
        (user.isEmployee() !== true && windowSize.width > Breakpoints.MOBILE)
    ) {
        return <Fragment></Fragment>;
    }

    return (
        <>
            <Backdrop
                isDisplayed={sidebar.displayed}
                onClick={() => dispatch(updateSidebar({ displayed: !sidebar.displayed }))}
            />
            <Placeholder
                width={currentSidebarWidth}
                isResizing={isResizing}
                isDisplayed={
                    sidebar.displayed || (sidebarWidth > theme.config.sidebar.width.collapsed && isResizing)
                }
            />
            <Wrapper
                ref={sidebarRef}
                onMouseDown={(e) => e.preventDefault()}
                width={currentSidebarWidth}
                isResizing={isResizing}
                isDisplayed={
                    sidebar.displayed || (sidebarWidth > theme.config.sidebar.width.collapsed && isResizing)
                }
            >
                <Container>
                    <ResizeHandler onMouseDown={startResizing} />
                    <Toggler onClick={toggleSidebarHandler}>
                        {sidebar.displayed ? <FiChevronLeft /> : <FiChevronRight />}
                    </Toggler>
                    <NavigationList>
                        {list
                            .filter((item) => item.isDisplayed())
                            .map((item) => (
                                <NavigationListItemContainer
                                    key={item.id}
                                    isOpened={openedSubNavigation.includes(item.id)}
                                >
                                    {item.path == null ? (
                                        <NavigationListItem
                                            onClick={(e: any) => clickSidebarItemHandler(e, item)}
                                            as={item.path == null ? 'div' : 'a'}
                                        >
                                            {renderSidebarItemContent(item)}
                                        </NavigationListItem>
                                    ) : (
                                        <NavigationListItem
                                            onClick={(e: any) => clickSidebarItemHandler(e, item)}
                                            to={item.path}
                                        >
                                            {renderSidebarItemContent(item)}
                                        </NavigationListItem>
                                    )}

                                    {item.children != null &&
                                        item.children?.length > 0 &&
                                        sidebar.displayed === true &&
                                        openedSubNavigation.includes(item.id) && (
                                            <NavigationListItemSubList>
                                                {item.children.map((child) => {
                                                    let path = `${item.path}`;
                                                    if (child.path.length > 0) {
                                                        path += `/${child.path}`;
                                                    }
                                                    return (
                                                        <NavigationListItemSubListItem
                                                            to={path}
                                                            key={child.id}
                                                            isActive={child.isActive}
                                                            end
                                                            onClick={() => {
                                                                if (
                                                                    windowSize.width < Breakpoints.DESKTOP &&
                                                                    sidebar.displayed === true
                                                                ) {
                                                                    dispatch(
                                                                        updateSidebar({
                                                                            displayed: !sidebar.displayed,
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {t(`sidebar.${child.id}` as ResourceKey)}
                                                        </NavigationListItemSubListItem>
                                                    );
                                                })}
                                            </NavigationListItemSubList>
                                        )}
                                </NavigationListItemContainer>
                            ))}
                    </NavigationList>
                </Container>
            </Wrapper>
        </>
    );
};

export default Sidebar;
