import React, { useMemo, useRef, useState } from 'react';
import { BatchUploadFileItem } from '../../types';
import {
    Container,
    Cta,
    CtaLabel,
    CtaLabelTrailingIcon,
    CtaLabelText,
    IconContainer,
    Info,
    InfoName,
    InfoSize,
    CtaLabelLeadingIcon,
    RemoveIcon,
    IconSpinner,
    UploadResultLabel,
} from './UploadFileItem-styles';
import { FileIcon, Spinner, SpinnerAppearance } from '../../../../../../../../components';
import { formatBytes, formatCaseFolderDescription, formatLocalizedText } from '../../../../../../../../tools';
import { BiChevronDown } from 'react-icons/bi';
import { useOnClickOutside } from 'usehooks-ts';
import { CaseFolder, CaseFolder_L2 } from '../../../../../../../../types';
import { useLocale } from '../../../../../../../../hooks';
import { AiFillFolder } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa6';
import FileItemQuotesList from '../FileItemQuotesList';

type UploadFileItemProps = {
    fileItem: BatchUploadFileItem;
    caseFolders: Array<CaseFolder>;
    isUploading: boolean;
    isUploaded: boolean;
    assesUploadResults: boolean;
    onClickRemove: () => void;
    onSelectFolder: (fileId: string, quoteId: string, categoryFileId: string) => void;
};

const UploadFileItem = ({
    fileItem,
    caseFolders,
    onClickRemove,
    isUploading,
    isUploaded,
    assesUploadResults,
    onSelectFolder,
}: UploadFileItemProps) => {
    const locale = useLocale();
    const { t } = useTranslation();
    const [selectedFolder, setSelectedFolder] = useState<null | CaseFolder>(null);
    const [selectedGrandChildFolder, setSelectedGrandChildFolder] = useState<null | CaseFolder_L2>(null);
    const [isOpened, setIsOpened] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => {
        setIsOpened(false);
    });

    const selectFolderHandler = (caseFolder: CaseFolder) => {
        setSelectedFolder(caseFolder);
        setSelectedGrandChildFolder(null);
    };

    const selectGrandChildFolderHandler = (caseGrandChildFolder: CaseFolder_L2) => {
        setSelectedGrandChildFolder(caseGrandChildFolder);
        setIsOpened(false);
        onSelectFolder(fileItem.id, selectedFolder!.id, caseGrandChildFolder!.id);
    };

    const url = useMemo(() => {
        return URL.createObjectURL(fileItem.file);
    }, [fileItem.file]);

    const isEditable = useMemo(() => {
        return isUploading === false && isUploaded === false;
    }, [isUploading, isUploaded]);

    const renderLabel = () => {
        if (selectedFolder == null || selectedGrandChildFolder == null) {
            return t('case.documents.select_folder');
        } else {
            return `${formatCaseFolderDescription(selectedFolder, locale)} / ${formatLocalizedText(
                selectedGrandChildFolder.title,
                locale
            )}`;
        }
    };

    return (
        <Container ref={containerRef}>
            {assesUploadResults === true && (
                <UploadResultLabel isSuccess={isUploaded}>
                    {isUploaded ? <FaCheck /> : <FaExclamation />}
                </UploadResultLabel>
            )}

            {isEditable === true && (
                <RemoveIcon onClick={() => onClickRemove()}>
                    <AiOutlineCloseCircle />
                </RemoveIcon>
            )}

            <IconContainer isUploading={isUploading === true && isUploaded === false}>
                <FileIcon mimeType={fileItem.file.type} />
                {isUploading === true && isUploaded === false && (
                    <IconSpinner>
                        <Spinner appearance={SpinnerAppearance.PRIMARY} />
                    </IconSpinner>
                )}
            </IconContainer>
            <Info>
                <InfoName href={url} target="_blank">
                    {fileItem.file.name}
                </InfoName>
                <InfoSize>{formatBytes(fileItem.file.size)}</InfoSize>
            </Info>
            <Cta isEditable={isEditable}>
                <CtaLabel
                    onClick={() => {
                        if (isEditable === true) {
                            setIsOpened((prev) => !prev);
                        }
                    }}
                >
                    <CtaLabelLeadingIcon>
                        <AiFillFolder />
                    </CtaLabelLeadingIcon>
                    <CtaLabelText isPlaceholder={selectedGrandChildFolder == null}>
                        {renderLabel()}
                    </CtaLabelText>
                    <CtaLabelTrailingIcon>
                        <BiChevronDown />
                    </CtaLabelTrailingIcon>
                </CtaLabel>
                {isOpened === true && (
                    <FileItemQuotesList
                        caseFolders={caseFolders}
                        selectedFolder={selectedFolder}
                        selectFolderHandler={selectFolderHandler}
                        selectedGrandChildFolder={selectedGrandChildFolder}
                        selectGrandChildFolderHandler={selectGrandChildFolderHandler}
                    />
                )}
            </Cta>
        </Container>
    );
};

export default UploadFileItem;
