import { Locale, LocaleObject, User } from '../../types';
import { i18n } from '../../translations';
import { formatLocalizedText } from './formatLocalizedText';

type Folder = {
    category: { title: LocaleObject };
    client?: Pick<User, 'firstName' | 'lastName'>;
    isAnonymous?: boolean;
};

export const formatCaseFolderDescription = ({ category, client, isAnonymous }: Folder, locale: Locale) => {
    let description = formatLocalizedText(category.title, locale);
    if (client != null) {
        description += ` : ${client.firstName} ${client.lastName}`;
    }
    if (isAnonymous === true && client == null) {
        description += ` : ${i18n.t('generic.tbd', { lng: locale })}`;
    }

    return description;
};
