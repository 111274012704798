import { Locale, LocaleObject } from '../../types';
import { formatLocalizedText } from '../formatting';

export const sortLocalizedText = (
    text1: LocaleObject,
    text2: LocaleObject,
    currentLocale: Locale
): number => {
    return formatLocalizedText(text1, currentLocale).localeCompare(formatLocalizedText(text2, currentLocale));
};
