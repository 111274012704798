import { CategoryQuote, Locale } from '../../../../../types';
import { FileGroup } from '../components/types';

/**
 * Get initial file groups
 * @param {CategoryQuote} quote
 * @return {Array<FileGroup>}
 */
export const getInitialFileGroups = (quote: CategoryQuote): Array<FileGroup> => {
    return quote.fileGroups.map((fileGroup) => ({
        title: fileGroup.title[Locale.EN],
        authorization: fileGroup.authorization,
        files: fileGroup.files.map((file) => ({
            label: file.title[Locale.EN],
            value: file.id,
        })),
    }));
};

/**
 * Get initial dependent file groups
 * @param {CategoryQuote} quote
 * @return {Array<FileGroup>}
 */
export const getInitialDependentFileGroups = (quote: CategoryQuote): Array<FileGroup> => {
    if (quote.dependent == null) {
        return [];
    }

    return quote.dependent.fileGroups.map((fileGroup) => ({
        title: quote.title[Locale.EN],
        authorization: fileGroup.authorization,
        files: fileGroup.files.map((file) => ({
            label: file.title[Locale.EN],
            value: file.id,
        })),
    }));
};
