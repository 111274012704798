import React from 'react';
import { generateId } from '../../../tools';
import CheckboxInput from '../CheckboxInput/CheckboxInput';
import { Container } from './CheckboxInputs-styles';

export type CheckBoxInputsOptions<T> = {
    value: T;
    label: string | React.ReactNode;
};

export type CheckboxInputsProps<T> = {
    value: Array<T> | T;
    options: Array<CheckBoxInputsOptions<T>>;
    onChange: (selected: Array<T> | T) => void;
    multiple?: boolean;
};

const CheckboxInputs = <T,>({ options, onChange, value, multiple = true }: CheckboxInputsProps<T>) => {
    /**
     * On change handler
     * @param isChecked
     * @param selectedOptionValue
     */
    const onChangeHandler = (isChecked: boolean, selectedOptionValue: T) => {
        if (multiple === true) {
            const currentValue = value as Array<T>;
            const updatedValue =
                isChecked === true
                    ? [...currentValue, selectedOptionValue]
                    : currentValue.filter((i) => i !== selectedOptionValue);
            onChange(updatedValue);
        } else {
            onChange(selectedOptionValue);
        }
    };

    return (
        <Container>
            {options.map((option) => (
                <CheckboxInput
                    key={generateId()}
                    label={option.label}
                    isChecked={Array.isArray(value) ? value.includes(option.value) : value === option.value}
                    onChange={(isChecked) => onChangeHandler(isChecked, option.value)}
                />
            ))}
        </Container>
    );
};

export default CheckboxInputs;
