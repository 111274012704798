import styled from 'styled-components';

export const AppContainer = styled.div`
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    padding-top: ${({ theme }) => theme.config.topbar.height.default}px;
`;

export const RoutesContainer = styled.div`
    flex: 1;
    display: flex;
    overflow-x: hidden;
    overflow-y: overlay;
`;
