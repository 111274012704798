import React from 'react';
import { Container, Line } from './MenuToggle-styles';
import { useAppDispatch, useStore } from '../../../../../hooks';
import { updateSidebar } from '../../../../../services/ReduxService';

const MenuToggle = () => {
    const dispatch = useAppDispatch();
    const { displayed } = useStore().settings.ui.sidebar;

    return (
        <Container onClick={() => dispatch(updateSidebar({ displayed: !displayed }))} isOpened={displayed}>
            <Line />
            <Line />
            <Line />
        </Container>
    );
};

export default MenuToggle;
