import React, { useContext, useMemo } from 'react';
import { FormContext } from '../Form/Form';
import { TextEditorInput } from '../../Inputs';
import { FormGroupContext } from '../FormGroup';

const FormTextEditorInput = () => {
    const { formik } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);

    const value = useMemo(() => {
        let result = formik.values;
        const keys = name.split('.');

        for (const key of keys) {
            if (result[key] != null) {
                result = result[key];
            }
        }
        return typeof result === 'string' ? result : '';
    }, [formik.values, name]);

    return <TextEditorInput initialValue={value} onChange={(val) => formik.setFieldValue(name, val)} />;
};

export default FormTextEditorInput;
