import styled from 'styled-components';

export const Container = styled.div<{ isDisabled: boolean }>`
    border-radius: 0.8rem;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    overflow: hidden;

    .DraftEditor-root {
        height: 30rem;
    }

    .rdw-editor-toolbar {
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .rdw-editor-wrapper {
        background-color: ${({ theme }) => theme.colors.surface.main};
    }

    .rdw-editor-main {
        font-size: ${({ theme }) => theme.font.size.md};
        box-sizing: border-box;
    }

    .DraftEditor-editorContainer {
        height: max-content;
        padding: 0 ${({ theme }) => theme.box.spacing.md};
        padding-bottom: ${({ theme }) => theme.box.spacing.md};
    }

    ${({ isDisabled }) => {
        if (isDisabled === true) {
            return {
                border: 'none',
                '.rdw-editor-toolbar': {
                    display: 'none',
                },
                '.DraftEditor-editorContainer': {
                    padding: 0,
                    cursor: 'default',
                    caretColor: 'transparent',
                    backgroundColor: 'transparent',
                },
                '.DraftEditor-root': {
                    height: 'max-content',
                    minHeight: '5rem',
                },
            };
        }
    }}
`;
