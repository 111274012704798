import React from 'react';
import { CategoryFile } from '../../../../../types';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../components';
import { usePromises } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../services';

type Props = {
    onClose: () => void;
    refreshFilesList: () => Promise<void>;
    selectedCategoryFile: CategoryFile;
};

const DeleteCategoryFileModal = ({ onClose, refreshFilesList, selectedCategoryFile }: Props) => {
    const { t } = useTranslation();

    const [{ error, clearError, executePromise }] = usePromises();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.categories().archiveFile(selectedCategoryFile.id);
            await refreshFilesList();
            onClose();
        });
    };

    return (
        <Modal
            title="categories.files.modals.remove.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('categories.files.modals.remove.message', { title: selectedCategoryFile.title.en })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('categories.files.modals.remove.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteCategoryFileModal;
