import React, { useState, useMemo, useEffect } from 'react';

// services
import { APIService } from '../../../services';

// hooks
import { usePromises } from '../../../hooks';
import { useTranslation } from 'react-i18next';

// types
import { Case, EmployeeRole } from '../../../types';

// components
import { CasesListTable, CasesListActions } from './components';
import { PageContainer, PageHeader, PageHeaderTitle, TableFilter } from '../../../components';

// styles
import { BiFolder } from 'react-icons/bi';

const CasesList = () => {
    const { t } = useTranslation();
    const [promises, [cases, lawyers]] = usePromises(
        () => APIService.cases().getList(),
        () => APIService.employees().getList([EmployeeRole.LAWYER])
    );
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [selectedLawyers, setSelectedLawyers] = useState<Array<string>>([]);

    useEffect(() => {
        if (promises.isInitializing === false) {
            setSelectedLawyers(lawyers.data.map((lawyer) => lawyer.id));
        }
    }, [promises.isInitializing]);

    const filters = useMemo(() => {
        const currentFilters: TableFilter<Case> = {
            dependencies: [selectedStatus, selectedLawyers],
            filterFunctions: [
                (caseDocument) => (selectedStatus == null ? true : caseDocument.status.id === selectedStatus),
                (caseDocument) =>
                    caseDocument.employees.some((employee) => selectedLawyers.includes(employee.id)),
            ],
        };
        return currentFilters;
    }, [selectedStatus, selectedLawyers]);

    return (
        <PageContainer error={promises.error} clearError={promises.clearError}>
            <PageHeader>
                <PageHeaderTitle>
                    <BiFolder />
                    {t('cases.list')}
                </PageHeaderTitle>
                <CasesListActions
                    lawyers={lawyers.data || []}
                    isLoading={promises.isInitializing || lawyers.isLoading}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    selectedLawyers={selectedLawyers}
                    setSelectedLawyers={setSelectedLawyers}
                />
            </PageHeader>
            <CasesListTable
                cases={cases.data}
                isLoading={promises.isInitializing || cases.isLoading}
                searchTerm={searchTerm}
                filters={filters}
            />
        </PageContainer>
    );
};

export default CasesList;
