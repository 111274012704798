import React, { useContext } from 'react';
import { Container } from './PageHeaderActionsLeft-styles';
import { PageHeaderActionsContext } from '../../PageHeaderActions';

type Props = {
    children: React.ReactNode;
};

const PageHeaderActionsLeft = ({ children }: Props) => {
    const { grid } = useContext(PageHeaderActionsContext);
    return (
        <Container className="page-header-actions-left" grid={grid.default} isLeftSide={grid.isLeftSide}>
            {children}
        </Container>
    );
};

export default PageHeaderActionsLeft;
