import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CaseInternalIdContainer = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main} !important;
    text-decoration: underline;

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        fill: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }
`;

export const CreatedBy = styled(Link)`
    color: ${({ theme }) => theme.colors.text.main} !important;

    :hover {
        color: ${({ theme }) => theme.colors.primary.main} !important;
        text-decoration: underline;
    }
`;

export const AssignedLawyersContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: ${({ theme }) => theme.box.spacing.md};

    > a {
        min-height: 45px;
        display: flex;
        align-self: center;

        &:first-child {
            min-height: 55px;
        }
    }
`;
