import * as yup from 'yup';
import { STORED_OCR } from './LocalStorageService-types';
import { OCR_VALIDATION_SEVERITY } from '../../types';

const storedOCRSchema = yup.object({
    caseFileId: yup.string().required(),
    caseQuoteId: yup.string().required(),
    ocrKey: yup.string().required(),
    severity: yup.string().oneOf(Object.values(OCR_VALIDATION_SEVERITY)).required(),
});

export const isValidStoredOCR = (obj: any): obj is STORED_OCR => {
    try {
        storedOCRSchema.validateSync(obj);
        return true;
    } catch (error) {
        return false;
    }
};
