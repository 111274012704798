import React, { useState } from 'react';
import { Modal, ModalFooter, TextInput } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../../services';
import { CaseQuote, Case } from '../../../../../../types';
import { InputLabel, InputContainer } from './UpdateDependentModal-styles';
import { usePromises } from '../../../../../../hooks';

type UpdateDependentModalProps = {
    onClose: () => void;
    selectedQuote: CaseQuote | null;
    currentCase: Case;
    refreshQuotesList: () => Promise<void>;
};

const UpdateDependentModal = ({
    onClose,
    selectedQuote,
    currentCase,
    refreshQuotesList,
}: UpdateDependentModalProps) => {
    const { t } = useTranslation();
    const [fullName, setFullName] = useState(selectedQuote!.fullName!);
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        if (selectedQuote!.fullName === fullName) {
            return onClose();
        }
        await executePromise(async () => {
            clearError();
            await APIService.cases().updateQuoteDependent(currentCase.id, selectedQuote!.id, {
                fullName: fullName,
            });
            await refreshQuotesList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.quote.modals.update_dependent.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <InputLabel>{t('generic.fullName')}:</InputLabel>
            <InputContainer>
                <TextInput value={fullName} onChange={(e) => setFullName(e.target.value)} />
            </InputContainer>
            <ModalFooter
                confirmationLabel={t('case.quote.modals.update_dependent.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
                isLoading={isExecutingPromise}
            />
        </Modal>
    );
};

export default UpdateDependentModal;
