import { CaseFile, OCR_RESULT, OCR_TYPE, OCR_VALIDATION, OCR_VALIDATION_SEVERITY } from '../../../types';
import { CaseFileManagerContracts, CaseFolderTooltipMessage } from '../CaseFolderManager-contracts';

class CaseFileManager implements CaseFileManagerContracts {
    readonly id: string;
    readonly mimeType: string;
    readonly originalName: string;
    readonly size?: number;
    readonly uploadedAt: Date;
    readonly uploadedBy: {
        client?: string;
        employee?: string;
    };
    readonly url?: {
        path: string;
        expiredAt: Date;
    };
    readonly ocr?: {
        text: string;
        type?: OCR_TYPE;
        results?: Array<OCR_RESULT>;
        validations?: Array<OCR_VALIDATION>;
        executedAt: Date;
    };

    /**
     * Constructor
     * @param {CaseFile} caseFile
     */
    constructor(caseFile: CaseFile) {
        this.id = caseFile.id;
        this.mimeType = caseFile.mimeType;
        this.originalName = caseFile.originalName;
        this.size = caseFile.size;
        this.uploadedAt = caseFile.uploadedAt;
        this.uploadedBy = caseFile.uploadedBy;
        this.url = caseFile.url;
        this.ocr = caseFile.ocr;
    }

    getTooltipMessages(): Array<CaseFolderTooltipMessage> {
        if (this.ocr?.validations == null) {
            return [];
        } else {
            return this.ocr.validations.map((validation) => ({
                text: validation.message,
                severity: validation.severity,
            }));
        }
    }

    hasErrors(): boolean {
        return this.checkOCRValidation(OCR_VALIDATION_SEVERITY.CRITICAL);
    }

    hasWarnings(): boolean {
        return this.checkOCRValidation(OCR_VALIDATION_SEVERITY.MEDIUM);
    }

    // **********************************************************************************************************
    // Helpers
    // **********************************************************************************************************

    /**
     * Check if some files have the specified validation severity
     * @param {OCR_VALIDATION_SEVERITY} severity
     * @return {boolean}
     */
    private checkOCRValidation(severity: OCR_VALIDATION_SEVERITY): boolean {
        if (this.ocr?.validations == null) {
            return false;
        } else {
            return this.ocr.validations.some((validation) => validation.severity === severity);
        }
    }
}

export default CaseFileManager;
