import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Locale,
    ServerFormQuestion,
    ServerFormQuestionInputType,
    ServerFormQuestionPayload,
} from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    FormTextEditorInput,
    FormTextInput,
    Modal,
    ModalSize,
} from '../../../../../components';
import { generateId } from '../../../../../tools';
import { FormQuestionsSelectOptions } from '../components';

type Props = {
    onClose: () => void;
    refreshQuestionsList: () => Promise<void>;
    selectedQuestion: ServerFormQuestion;
    questionsList: Array<ServerFormQuestion>;
};

const getInitialOption = () => {
    return {
        label_en: '',
        label_fr: '',
        value: `temp_${generateId()}`,
    };
};

const UpdateFormQuestionModal = ({
    onClose,
    refreshQuestionsList,
    selectedQuestion,
    questionsList,
}: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise, setError }] = usePromises();

    const validationSchema = Yup.object({
        name: Yup.string().required(t('generic.error.required_field')),
        inputType: Yup.string().required(),
        label_en: Yup.string().required(t('generic.error.required_field')),
        label_fr: Yup.string().notRequired(),
        placeholder_en: Yup.string().notRequired(),
        placeholder_fr: Yup.string().notRequired(),
        tooltip_en: Yup.string().notRequired(),
        tooltip_fr: Yup.string().notRequired(),
        options: Yup.array().of(
            Yup.object().shape({
                label_en: Yup.string().when('inputType', {
                    is: ServerFormQuestionInputType.SELECT_INPUT,
                    then: (schema) => schema.required(t('generic.error.required_field')),
                    otherwise: (schema) => schema.notRequired(),
                }),
                label_fr: Yup.string().notRequired(),
                value: Yup.string().notRequired(),
            })
        ),
    });

    const formik = useFormik({
        initialValues: {
            name: selectedQuestion.name,
            inputType: selectedQuestion.inputType,
            label_en: selectedQuestion.label[Locale.EN],
            label_fr: selectedQuestion.label[Locale.FR],
            placeholder_en: selectedQuestion.placeholder[Locale.EN],
            placeholder_fr: selectedQuestion.placeholder[Locale.FR],
            tooltip_en: selectedQuestion.tooltip[Locale.EN],
            tooltip_fr: selectedQuestion.tooltip[Locale.FR],
            options:
                selectedQuestion.inputType === ServerFormQuestionInputType.SELECT_INPUT
                    ? selectedQuestion.options.map((option) => ({
                          label_en: option.label[Locale.EN],
                          label_fr: option.label[Locale.FR],
                          value: option.value,
                      }))
                    : [getInitialOption()],
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (
                questionsList.some(
                    (question) =>
                        question.name === submittedValues.name && question.id !== selectedQuestion.id
                )
            ) {
                setError(
                    t('forms.questions.modals.errors.question_name_exists', {
                        questionName: submittedValues.name,
                    })
                );
                return;
            }

            await executePromise(async () => {
                const payload: ServerFormQuestionPayload = {
                    name: submittedValues.name,
                    label: {
                        [Locale.EN]: submittedValues.label_en,
                        [Locale.FR]: submittedValues.label_fr ?? '',
                    },
                    placeholder: {
                        [Locale.EN]: submittedValues.placeholder_en ?? '',
                        [Locale.FR]: submittedValues.placeholder_fr ?? '',
                    },
                    tooltip: {
                        [Locale.EN]: submittedValues.tooltip_en ?? '',
                        [Locale.FR]: submittedValues.tooltip_fr ?? '',
                    },
                    inputType: submittedValues.inputType,
                    options:
                        submittedValues.inputType === ServerFormQuestionInputType.SELECT_INPUT
                            ? submittedValues.options.map((option) => ({
                                  label: {
                                      [Locale.EN]: option.label_en,
                                      [Locale.FR]: option.label_fr,
                                  },
                                  value: option.value.includes('temp_') ? undefined : option.value,
                              }))
                            : undefined,
                };

                await APIService.forms().updateQuestion(selectedQuestion.id, payload);
                await refreshQuestionsList();
                onClose();
            });
        },
    });

    const addOptionsHandler = () => {
        const updatedOptions = [...formik.values.options, getInitialOption()];
        formik.setFieldValue('options', updatedOptions);
    };

    const removeOptionHandler = (index: number) => {
        if (index === 0 && formik.values.options.length === 1) {
            return;
        }
        const updatedOptions = formik.values.options.filter((_, optionIndex) => optionIndex !== index);
        formik.setFieldValue('options', updatedOptions);
    };

    const inputTypesList = useMemo(() => {
        return [
            {
                label: t('generic.form_inputs_type.text_input'),
                value: ServerFormQuestionInputType.TEXT_INPUT,
            },
            {
                label: t('generic.form_inputs_type.number_input'),
                value: ServerFormQuestionInputType.NUMBER_INPUT,
            },
            {
                label: t('generic.form_inputs_type.date_input'),
                value: ServerFormQuestionInputType.DATE_INPUT,
            },
            {
                label: t('generic.form_inputs_type.select_input'),
                value: ServerFormQuestionInputType.SELECT_INPUT,
            },
        ];
    }, [locale]);

    return (
        <Modal
            title={t('forms.questions.modals.update.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name={`name`}>
                    <FormGroupLabel>{t('generic.name')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name={`inputType`}>
                    <FormGroupLabel>{t('generic.type')}</FormGroupLabel>
                    <FormSelectInput options={inputTypesList} isDisabled />
                </FormGroup>

                <FormGroup name={`label_en`}>
                    <FormGroupLabel>{t('generic.label')} EN</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name={`label_fr`}>
                    <FormGroupLabel>{t('generic.label')} FR</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name={`placeholder_en`}>
                    <FormGroupLabel>Placeholder EN</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name={`placeholder_fr`}>
                    <FormGroupLabel>Placeholder FR</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name={`tooltip_en`}>
                    <FormGroupLabel>Tooltip EN</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormGroup name={`tooltip_fr`}>
                    <FormGroupLabel>Tooltip FR</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>

                {formik.values.inputType === ServerFormQuestionInputType.SELECT_INPUT && (
                    <FormQuestionsSelectOptions
                        onChangeOptions={(options) => formik.setFieldValue('options', options)}
                        options={formik.values.options}
                        addOptionsHandler={addOptionsHandler}
                        removeOptionHandler={removeOptionHandler}
                    />
                )}

                <FormFooter
                    submitLabel={t('forms.questions.modals.update.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateFormQuestionModal;
