import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useLoggedInUser, usePromises } from '../../../../hooks';
import { formatPhoneNumber, validatePhoneNumber } from '../../../../tools';
import { UserBusiness } from '../../../../business';
import { Form, FormFooter, FormGroup, FormGroupLabel, FormTextInput, Modal } from '../../../../components';

type Props = {
    onClose: () => void;
};

const SettingsUpdateProfileModal = ({ onClose }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();
    const { user } = useLoggedInUser();
    const dispatch = useAppDispatch();

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('generic.error.required_field')),
        lastName: Yup.string().required(t('generic.error.required_field')),
        phoneNumber1: Yup.string()
            .test('phoneNumber1', t('generic.error.invalid_phone_number'), (value) => {
                if (value) {
                    return validatePhoneNumber(value);
                } else {
                    return true;
                }
            })
            .notRequired(),
        phoneNumber2: Yup.string()
            .test('phoneNumber2', t('generic.error.invalid_phone_number'), (value) => {
                if (value) {
                    return validatePhoneNumber(value);
                } else {
                    return true;
                }
            })
            .notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber1: user.contacts[0]?.phoneNumber ?? '',
            phoneNumber2: user.contacts[1]?.phoneNumber ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload = {
                    firstName: submittedValues.firstName,
                    lastName: submittedValues.lastName,
                    contacts: [] as Array<{ identifier: string; phoneNumber: string }>,
                };

                if (submittedValues.phoneNumber1 !== '') {
                    payload.contacts.push({
                        identifier: '',
                        phoneNumber: formatPhoneNumber(submittedValues.phoneNumber1),
                    });
                }

                if (submittedValues.phoneNumber2 !== '') {
                    payload.contacts.push({
                        identifier: '',
                        phoneNumber: formatPhoneNumber(submittedValues.phoneNumber2),
                    });
                }

                await UserBusiness.updateUserProfile(user, payload, dispatch);
                onClose();
            });
        },
    });

    return (
        <Modal
            title={t('settings.modals.update_profile.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="firstName">
                    <FormGroupLabel>{t('generic.firstName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.firstName')} />
                </FormGroup>
                <FormGroup name="lastName">
                    <FormGroupLabel>{t('generic.lastName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.lastName')} />
                </FormGroup>
                <FormGroup name="phoneNumber1" expand>
                    <FormGroupLabel>{t('generic.phone_number')} 1</FormGroupLabel>
                    <FormTextInput placeholder={t('generic.phone_number')} />
                </FormGroup>
                <FormGroup name="phoneNumber2" expand>
                    <FormGroupLabel>{t('generic.phone_number')} 2</FormGroupLabel>
                    <FormTextInput placeholder={t('generic.phone_number')} />
                </FormGroup>
                <FormFooter
                    submitLabel={t('settings.modals.update_profile.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default SettingsUpdateProfileModal;
