import styled from 'styled-components';
import { ModalSize } from './Modal';

export const Container = styled.div<{ isMounted: boolean; size: ModalSize }>`
    width: 90vw;
    max-width: ${({ size }) => {
        if (size === ModalSize.EXTRA_LARGE) {
            return '150rem';
        }

        if (size === ModalSize.LARGE) {
            return '100rem';
        }

        return '60rem';
    }};

    padding: ${({ theme }) => theme.box.spacing.lg};
    padding-bottom: ${({ theme }) => theme.box.spacing.xl};
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.surface.main};
    opacity: 0.65;
    transform: scale(0.65);
    transition: all 0.1s ease-in;

    ${({ isMounted }) => {
        if (isMounted === true) {
            return {
                opacity: 1,
                transform: `scale(1)`,
            };
        }
    }}
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const HeaderTitle = styled.h2`
    font-size: ${({ theme }) => theme.font.size.xl};
`;

export const CloseIcon = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    align-self: flex-start;

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    :hover {
        background: ${({ theme }) => theme.colors.onSurface.main};
    }
`;
