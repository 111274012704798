import styled, { DefaultTheme } from 'styled-components';

const getGridTemplateColumns = (theme: DefaultTheme) => {
    const defaultMin = theme.box.spacing.lg;
    const defaultMax = theme.box.spacing.xxl;

    const mobileMin = theme.box.spacing.md;
    const mobileMax = theme.box.spacing.lg;
    return {
        display: 'grid',
        gridAutoRows: 'max-content',
        gridTemplateColumns: `minmax(${defaultMin}, ${defaultMax}) 1fr minmax(${defaultMin}, ${defaultMax})`,
        [theme.breakpoints.mobile]: {
            gridTemplateColumns: `minmax(${mobileMin}, ${mobileMax}) 1fr minmax(${mobileMin}, ${mobileMax})`,
        },
    };
};

export const Container = styled.div<{ expand: boolean; useBackgroundColor?: boolean }>`
    flex: 1;
    padding: ${({ theme }) => theme.box.spacing.lg} 0px;
    width: 100%;
    overflow: hidden;
    ${({ theme }) => getGridTemplateColumns(theme)};
    grid-auto-rows: 1fr;

    ${({ theme, useBackgroundColor }) => {
        if (useBackgroundColor) {
            return {
                background: theme.colors.background.secondary,
            };
        }
    }}
`;

export const Content = styled.div<{ expand: boolean }>`
    grid-column: ${({ expand }) => (expand ? '1 / 4' : '2 / 3')};

    grid-column: 2 / 3;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > div {
        flex: 1;
    }
`;
