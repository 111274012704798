import React from 'react';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../../components';
import { useTranslation } from 'react-i18next';

type UnauthorizedToRemoveEmployeeModalProps = {
    onClose: () => void;
};

const UnauthorizedToRemoveEmployeeModal = ({ onClose }: UnauthorizedToRemoveEmployeeModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('case.access_management.modals.remove_unauthorized.title')} onClose={onClose}>
            <ModalParagraph>{t('case.access_management.modals.remove_unauthorized.message')}</ModalParagraph>
            <ModalFooter confirmationLabel="OK" onClick={onClose} />
        </Modal>
    );
};

export default UnauthorizedToRemoveEmployeeModal;
