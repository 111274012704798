import React from 'react';
import { OutletContainer } from '../../components';
import { Navigate, Outlet } from 'react-router-dom';
import { useLoggedInUser } from '../../hooks';
import { Organization } from '../../types';

export type PAGE_ORGANIZATIONS_Organization = Organization<{
    id: true;
    assets: true;
    theme: true;
    config: true;
}>;

const OrganizationsPages = () => {
    const user = useLoggedInUser();

    if (user.belongsToDefaultOrganization() !== true) {
        return <Navigate to="/" />;
    }

    return (
        <OutletContainer>
            <Outlet />
        </OutletContainer>
    );
};

export default OrganizationsPages;
