import React, { useMemo } from 'react';
import {
    MultiSelectInput,
    PageHeaderActions,
    PageHeaderActionsLeft,
    PageHeaderActionsRight,
    PrimaryContainedAddButton,
} from '../../../../../components';
import { useLocale, useStore } from '../../../../../hooks';
import { formatLocalizedText } from '../../../../../tools';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../routes';
import { MultiSelectInputOption } from '../../../../../components/Inputs/MultiSelectInput';
import { StatusListItem } from '../../../../../types';
import { DashboardConfig } from '../../DashboardTable';

type DashboardTableActionsProps = {
    dashboardConfig: DashboardConfig;
    selectedStatus: Array<string>;
    onSelectStatusHandler: (selectedStatus: Array<string>) => void;
};

const DashboardTableActions = ({
    selectedStatus,
    onSelectStatusHandler,
    dashboardConfig,
}: DashboardTableActionsProps) => {
    const { t } = useTranslation();
    const { statusList } = useStore().caseData;
    const locale = useLocale();

    const options = useMemo(() => {
        const list: Array<
            MultiSelectInputOption<string, { status: StatusListItem; casesIds: Array<string> }>
        > = statusList.map((status) => {
            return {
                value: status.id,
                label: formatLocalizedText(status.locale, locale),
                data: {
                    status: status,
                    casesIds: dashboardConfig.columns[status.id]?.casesIds ?? [],
                },
            };
        });

        return list;
    }, [statusList, locale, dashboardConfig]);

    const customOptions = useMemo(() => {
        const list: Array<
            MultiSelectInputOption<string, { status: StatusListItem; casesIds: Array<string> }>
        > = [];
        if (Object.values(dashboardConfig.columns).some((column) => column.casesIds.length === 0)) {
            list.push({
                value: 'not_empty',
                label: t('dashboard.not_empty'),
                onClickCustom: (option) => option.data!.casesIds.length > 0,
            });
        }

        return list;
    }, [dashboardConfig]);

    return (
        <PageHeaderActions
            breakpoint={700}
            grid={{
                isLeftSide: false,
                default: 'minmax(max-content, 300px) max-content',
                mobile: [200, 200],
            }}
        >
            <PageHeaderActionsLeft>
                <React.Fragment />
            </PageHeaderActionsLeft>
            <PageHeaderActionsRight>
                <MultiSelectInput
                    isSearchable={false}
                    value={selectedStatus}
                    onChange={onSelectStatusHandler}
                    options={options}
                    customOptions={customOptions}
                    label={t('generic.status')}
                />
                <PrimaryContainedAddButton path={`${Routes.CASES}/${Routes.CASES_NEW}`}>
                    {t('cases.new_case.title')}
                </PrimaryContainedAddButton>
            </PageHeaderActionsRight>
        </PageHeaderActions>
    );
};

export default DashboardTableActions;
