import styled from 'styled-components';

export const Container = styled.div`
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
`;

export const Section = styled.section``;

export const SectionTitle = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const SectionAddClient = styled.div`
    margin: ${({ theme }) => theme.box.spacing.sm} 0;
    font-size: ${({ theme }) => theme.font.size.md};
    text-align: end;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    :hover {
        text-decoration: underline;
    }
`;
