import React from 'react';
import { Category } from '../../../../../types';
import { Container } from './CategoriesListTable-styles';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    LocalizedText,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
} from '../../../../../components';
import { useLoggedInUser } from '../../../../../hooks';
import { BiPencil } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import CategoriesListTableItem from './CategoriesListTableItem';

type Props = {
    categories: Array<Category>;
    openUpdateCategoryModal: (category: Category) => void;
    openDeleteCategoryModal: (category: Category) => void;
};

const CategoriesListTable = ({ categories, openUpdateCategoryModal, openDeleteCategoryModal }: Props) => {
    const user = useLoggedInUser();

    return (
        <Container>
            {categories.map((category) => (
                <AccordionContainer key={category.id} triggerUpdate={category}>
                    <Accordion level={0} index={0} parentIndex={0}>
                        <AccordionHeader>
                            <AccordionHeaderTitle>
                                {category.internalId}&nbsp;-&nbsp;
                                <LocalizedText text={category.title} />
                            </AccordionHeaderTitle>
                            {user.hasPermissions('categories.created') && (
                                <AccordionHeaderContent>
                                    <SecondaryOutlinedButton
                                        leadingIcon={<BsTrash />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openDeleteCategoryModal(category);
                                        }}
                                    />
                                    <PrimaryContainedButton
                                        leadingIcon={<BiPencil />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openUpdateCategoryModal(category);
                                        }}
                                    />
                                </AccordionHeaderContent>
                            )}
                        </AccordionHeader>
                        <AccordionBody>
                            <CategoriesListTableItem quotes={category.quotes} />
                        </AccordionBody>
                    </Accordion>
                </AccordionContainer>
            ))}
        </Container>
    );
};

export default CategoriesListTable;
