import moment from 'moment';
import { APIService, LocalStorageService } from '../services';
import { i18n, validateLocale } from '../translations';
import { Locale, User } from '../types';
import { AppDispatch, setUser } from '../services/ReduxService';
import { AuthenticationBusiness } from './authentication';

export class UserBusiness {
    /**
     * Update locale
     * @param {Locale} locale
     * @param {Boolean} [sendAPiRequest] - default false
     * @return {Promise<void>}
     */
    static async updateLocale(locale: Locale, sendAPiRequest = false): Promise<void> {
        const currentLocale = locale.toLowerCase() as Locale;
        if (currentLocale === i18n.language) {
            return;
        }
        if (validateLocale(currentLocale) !== true) {
            return;
        }
        i18n.changeLanguage(currentLocale);
        LocalStorageService.saveLocale(currentLocale);
        moment.locale(currentLocale);

        if (sendAPiRequest === true) {
            try {
                await APIService.users().updatePreferences({ locale: currentLocale });
            } catch (err) {
                // Do something
            }
        }
    }

    static async updateUserProfile(
        user: User,
        payload: {
            firstName: string;
            lastName: string;
            contacts: Array<{ identifier: string; phoneNumber: string }>;
        },
        dispatch: AppDispatch
    ) {
        await APIService.users().updateProfile(payload);
        const updatedProfile = await APIService.users().getProfile();
        dispatch(
            setUser({
                ...user,
                ...updatedProfile,
            })
        );
    }

    static async updatePassword(payload: { password: string; logoutAll: boolean }, dispatch: AppDispatch) {
        await APIService.users().updatePassword({ password: payload.password, logoutAll: payload.logoutAll });
        if (payload.logoutAll === true) {
            await AuthenticationBusiness.logout(dispatch);
        }
    }
}
