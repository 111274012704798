import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { generateId } from '../../../tools';
import { useToggle } from '../../../hooks';
import { InputMode } from '../Inputs-types';
import TextInput from '../TextInput';
import {
    Container,
    Content,
    ListWrapper,
    List,
    ListItem,
    ContentLabel,
    ContentLabelWrapper,
    ContentLabelText,
    ContentLabelIcon,
} from './SelectInput-styles';
import { BiChevronDown } from 'react-icons/bi';
import Spinner, { SpinnerAppearance } from '../../Spinner';

export type SelectInputOptions<T, U> = {
    label: string;
    value: T;
    data?: U;
};

export type SelectInputProps<T, U> = {
    value: T;
    onChange: (value: T, data?: U) => void;
    onChangeSearchTerm?: (value: string) => void;
    renderOptionLabel?: (value: T, data?: U) => string | React.ReactNode;
    options: Array<SelectInputOptions<T, U>>;
    defaultOptions?: Array<SelectInputOptions<T, U>>; // Options to display when search term is empty.
    mode?: InputMode;
    isSearchable?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    label?: string | React.ReactNode;
    isSearchTermRequired?: boolean; // If true, options will be displayed only when search term is not empty,
    maxDisplayedOptions?: number;
    maxHeight?: number;
    placeholder?: string;
    leadingIcon?: React.ReactNode;
    index?: number;
};

const SelectInput = <T, U>({
    options,
    value,
    isSearchable = false,
    isLoading = false,
    isDisabled = false,
    mode = InputMode.STAND_ALONE,
    maxHeight = 300,
    label,
    onChange,
    onChangeSearchTerm,
    renderOptionLabel,
    isSearchTermRequired = false,
    defaultOptions = [],
    maxDisplayedOptions,
    placeholder,
    leadingIcon,
}: SelectInputProps<T, U>) => {
    const windowSize = useWindowSize();
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [currentOption, setCurrentOption] = useState(
        [...options, ...defaultOptions].find((option) => option.value === value)
    );
    const [searchTerm, setSearchTerm] = useState(currentOption?.label ?? '');
    const [isOpened, openList, closeList, toggleList] = useToggle(false);

    useEffect(() => {
        const updatedSearchTerm = [...options, ...defaultOptions].find(
            (option) => option.value === value
        )?.label;
        if (updatedSearchTerm != null) {
            setSearchTerm(updatedSearchTerm);
        }
    }, [options]);

    useEffect(() => {
        if (
            isSearchable === true &&
            currentOption != null &&
            currentOption?.label !== searchTerm &&
            isOpened !== true
        ) {
            openList();
            inputRef.current?.focus();
        }
    }, [searchTerm]);

    useEffect(() => {
        if (currentOption != null && value != null && value !== currentOption.value) {
            const updatedCurrentOption = options.find((option) => option.value === value);

            if (updatedCurrentOption != null) {
                setCurrentOption(updatedCurrentOption);
                setSearchTerm(updatedCurrentOption.label);
            } else {
                setCurrentOption(undefined);
                setSearchTerm('');
            }
        }
    }, [value, currentOption]);

    useOnClickOutside(containerRef, () => {
        if (isOpened) {
            closeList();
        }
    });

    /**
     * Selection option
     * @param option
     */
    const onSelectOptionHandler = (option: SelectInputOptions<T, U>) => {
        setCurrentOption(option);
        setSearchTerm(option.label);
        onChange(option.value, option.data);
        closeList();
    };

    /**
     * Get displayed options
     * @returns {Array<SelectInputOptions<T>>} - Returns true if option match search term
     */
    const getDisplayedOptions = (): Array<SelectInputOptions<T, U>> => {
        if (isSearchTermRequired === true && searchTerm === '') {
            return defaultOptions;
        }

        const currentOptions = options.filter((option) => {
            if (searchTerm.length === 0 || searchTerm === currentOption?.label) {
                return true;
            }

            if (option.value === currentOption?.value) {
                return false;
            }

            if (isSearchable === true) {
                const keys = searchTerm.split(' ');
                let isValid = true;
                for (const key of keys) {
                    if (isValid === true) {
                        isValid = option.label.toLocaleLowerCase().includes(key.toLocaleLowerCase());
                    }
                }
                return isValid;
            }

            return true;
        });

        const displayedOptions =
            maxDisplayedOptions != null ? currentOptions.slice(0, maxDisplayedOptions) : currentOptions;

        return [
            ...defaultOptions.filter((defaultOption) => defaultOption.value !== currentOption?.value),
            ...displayedOptions,
        ];
    };

    /**
     * Change search term
     * @param updatedSearchTerm
     */
    const onChangeSearchTermHandler = (updatedSearchTerm: string) => {
        setSearchTerm(updatedSearchTerm);
        if (typeof onChangeSearchTerm === 'function') {
            onChangeSearchTerm(updatedSearchTerm);
        }
    };

    const listDisplayedAtBottom = useMemo(() => {
        if (containerRef.current != null) {
            const rect = containerRef.current.getBoundingClientRect();
            const distance = windowSize.height - rect.bottom;
            if (distance < 300) {
                return false;
            }
        }
        return true;
    }, [containerRef.current]);

    return (
        <Container ref={containerRef} className="select-input-container">
            <Content
                ref={contentRef}
                onClick={() => {
                    if (isDisabled === false) toggleList();
                }}
                isLoading={isLoading}
                isDisabled={isDisabled}
                className="select-input-content"
            >
                {isSearchable === true ? (
                    <TextInput
                        type="text"
                        value={searchTerm}
                        onChange={(e) => onChangeSearchTermHandler(e.currentTarget.value)}
                        trailingIcon={
                            isLoading === true ? (
                                <Spinner appearance={SpinnerAppearance.PRIMARY} />
                            ) : (
                                <BiChevronDown />
                            )
                        }
                        leadingIcon={leadingIcon}
                        ref={inputRef}
                        label={label as string}
                        placeholder={placeholder}
                        mode={mode}
                        onFocus={() => {
                            inputRef.current?.select();
                        }}
                    />
                ) : (
                    <ContentLabelWrapper mode={mode} className="select-input-content__label">
                        {leadingIcon != null && leadingIcon}
                        {label != null && <ContentLabel>{label}: </ContentLabel>}
                        <ContentLabelText isPlaceholder={searchTerm === ''}>
                            {searchTerm || placeholder}
                        </ContentLabelText>
                        <ContentLabelIcon>
                            <BiChevronDown />
                        </ContentLabelIcon>
                    </ContentLabelWrapper>
                )}
            </Content>

            {isOpened && (
                <ListWrapper
                    maxHeight={maxHeight}
                    listDisplayedAtBottom={listDisplayedAtBottom}
                    className="select-input-content__list-wrapper"
                >
                    <List>
                        {getDisplayedOptions().map((option) => (
                            <ListItem key={generateId()} onClick={() => onSelectOptionHandler(option)}>
                                {typeof renderOptionLabel === 'function'
                                    ? renderOptionLabel(option.value, option.data)
                                    : option.label}
                            </ListItem>
                        ))}
                    </List>
                </ListWrapper>
            )}
        </Container>
    );
};

export default SelectInput;
