import React from 'react';
import { ButtonProps } from '../../Button';
import PrimaryContainedButton from './PrimaryContainedButton';
import { AiOutlinePlus } from 'react-icons/ai';

const PrimaryContainedAddButton = (props: Omit<ButtonProps, 'mode' | 'appearance' | 'leadingIcon'>) => {
    return <PrimaryContainedButton {...props} leadingIcon={<AiOutlinePlus />} />;
};

export default PrimaryContainedAddButton;
