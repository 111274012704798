import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { useToggle, useDebounce } from '../../../hooks';
import { Container } from './MultiSelectInput-styles';
import { InputMode } from '../Inputs-types';
import { MultiSelectInputList, MultiSelectInputLabel } from './components';
import { useTranslation } from 'react-i18next';

export const OPTION_ALL_VALUE = 'all' as any;

export type MultiSelectInputOption<T, U> = {
    value: T;
    label: string | React.ReactNode;
    data?: U;
    onClickCustom?: (customOption: MultiSelectInputOption<T, U>) => boolean;
};

type Options<T, U> = Array<MultiSelectInputOption<T, U>>;

export type MultiSelectInputProps<T, U> = {
    // required
    value: Array<T>;
    options: Options<T, U>;
    onChange: (selectedOptions: Array<T>) => void;
    // optional
    renderOptionLabel?: (value: T, data?: U) => string | React.ReactNode;
    isLoading?: boolean;
    isSearchable?: boolean;
    mode?: InputMode;
    getSearchValue?: (option: MultiSelectInputOption<T, U>) => string;
    label?: string;
    maxHeight?: number;
    allOption?: boolean | { label: string };
    customOptions?: Options<T, U>;
};

const MultiSelectInput = <T, U>({
    options,
    onChange,
    value,
    renderOptionLabel,
    isLoading = false,
    isSearchable = true,
    allOption = true,
    mode = InputMode.STAND_ALONE,
    maxHeight = 300,
    getSearchValue,
    label,
    customOptions,
}: MultiSelectInputProps<T, U>) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const windowSize = useWindowSize();
    const [isOpened, openList, closeList] = useToggle(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayedOptions, setDisplayedOptions] = useState(options);
    const [enabledCustomOptions, setEnabledCustomOptions] = useState<Array<T>>([]);

    useOnClickOutside(containerRef, closeList);

    const useAllOption = useMemo(() => {
        return (
            (typeof allOption === 'boolean' && allOption === true) ||
            (typeof allOption === 'object' && allOption.label != null)
        );
    }, [allOption]);

    useEffect(() => {
        if (isLoading === false) {
            setDisplayedOptions(getDisplayedOptions(options, value, searchTerm));
        }
    }, [options]);

    useDebounce(() => {
        if (isOpened) {
            setDisplayedOptions(getDisplayedOptions(options, value, searchTerm));
        }
    }, [searchTerm]);

    const onChangeHandler = (currentOptions: Array<T>) => {
        onChange(currentOptions);
        inputRef.current?.focus();
    };

    const getDisplayedOptions = (
        currentOptions: Options<T, U>,
        currentValue: Array<T>,
        currentSearchTerm: string
    ) => {
        const filteredOptions = filterOptionsHandler(currentOptions, currentValue, currentSearchTerm);

        if (customOptions != null) {
            filteredOptions.unshift(...customOptions);
        }

        if (useAllOption === true) {
            filteredOptions.unshift({
                label: t('generic.all'),
                value: OPTION_ALL_VALUE,
            });
        }
        return filteredOptions;
    };

    const filterOptionsHandler = (
        currentOptions: Options<T, U>,
        currentValue: Array<T>,
        currentSearchTerm: string
    ): Options<T, U> => {
        if (isSearchable !== true || getSearchValue == null || searchTerm === '') {
            return currentOptions.map((option) => ({ ...option }));
        }
        const keys = currentSearchTerm.split(' ');

        return currentOptions.filter((option) => {
            let isValid = true;

            if (currentSearchTerm.length > 0) {
                for (const key of keys) {
                    if (isValid === true) {
                        isValid = getSearchValue(option).toLowerCase().includes(key.toLowerCase());
                    }
                }
            }

            if (isValid === true && mode === InputMode.FORM) {
                isValid = currentValue.includes(option.value) === false;
            }

            return isValid;
        });
    };

    const displayListLeftSide = useMemo(() => {
        if (containerRef.current == null || windowSize.width === 0) {
            return false;
        }
        const rect = containerRef.current.getBoundingClientRect();
        return rect.left >= windowSize.width * 0.7;
    }, [containerRef, windowSize.width]);

    /**
     * On click content handler
     */
    const onClickContentHandler = () => {
        if (isOpened === false) {
            openList();
            setTimeout(() => {
                inputRef.current?.focus();
            });
        } else {
            closeList();
        }
    };

    return (
        <Container ref={containerRef} className="multi-select-input__container">
            <MultiSelectInputLabel
                onClickContentHandler={onClickContentHandler}
                mode={mode}
                value={value}
                isLoading={isLoading}
                isOpened={isOpened as boolean}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                inputRef={inputRef}
                isSearchable={isSearchable}
                label={label}
                onChange={onChangeHandler}
                renderOptionLabel={renderOptionLabel}
                options={displayedOptions}
                useAllOption={useAllOption}
            />
            {isOpened && isLoading === false && (
                <MultiSelectInputList
                    options={options}
                    displayedOptions={displayedOptions}
                    onChangeHandler={onChangeHandler}
                    value={value}
                    mode={mode}
                    displayListLeftSide={displayListLeftSide}
                    maxHeight={maxHeight}
                    useAllOption={useAllOption}
                    enabledCustomOptions={enabledCustomOptions}
                    setEnabledCustomOptions={setEnabledCustomOptions}
                />
            )}
        </Container>
    );
};

export default MultiSelectInput;
