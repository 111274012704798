import React, { useMemo } from 'react';
import { CaseQuote } from '../../../../../../types';
import { Table, TableColumnConfig } from '../../../../../../components';
import { CaseQuoteActionsTypes } from '../../CaseQuote';
import { formatCaseFolderDescription, formatPrice } from '../../../../../../tools';
import { useLocale, useLoggedInUser, useTable } from '../../../../../../hooks';
import CaseQuotesTableMenu from './CaseQuotesTableMenu';
import { Description, DescriptionAsText, TableCell } from './CaseQuotesTable-styles';
import { Routes } from '../../../../../../routes';
import { useOutletContext } from 'react-router-dom';
import { CasesPagesContext } from '../../../CasePagesContext';
import { AiFillFolder } from 'react-icons/ai';

type CaseQuotesTableProps = {
    quotes: Array<CaseQuote>;
    openModalHandler: (selectedAction: CaseQuoteActionsTypes, selectedQuote?: CaseQuote) => void;
};

type SanitizedQuote = {
    description: string;
    price: string;
    path?: string;
    original?: CaseQuote;
    isDependent?: boolean;
    isTotal?: boolean;
};

const CaseQuotesTable = ({ quotes, openModalHandler }: CaseQuotesTableProps) => {
    const user = useLoggedInUser();
    const locale = useLocale();
    const { currentCase } = useOutletContext<CasesPagesContext>();

    const getTotalValue = () => {
        let total = 0;

        if (quotes == null) {
            return total;
        }

        for (const quote of quotes) {
            const prices = quote.category.prices;

            total += prices.quote.value;

            if (quote.dependents != null && quote.dependents.length > 0) {
                const dependentPrice = prices.dependent!.value;
                total += dependentPrice * quote.dependents!.length;
            }
        }
        return total;
    };

    const sanitizedQuotes = useMemo(() => {
        if (quotes == null) {
            return [];
        }

        const results = quotes
            .sort((a, b) => a.category.index - b.category.index)
            .map((quote) => {
                const list: Array<SanitizedQuote> = [];
                list.push({
                    original: quote,
                    description: formatCaseFolderDescription(quote, locale),
                    price: formatPrice(quote.category.prices.quote.value),
                    path:
                        quote.id != null
                            ? `/${Routes.CASES}/${currentCase.id}/${Routes.CASE_DOCUMENTS}/${quote.id}`
                            : undefined,
                });

                if (quote.dependents != null) {
                    for (const dependent of quote.dependents) {
                        list.push({
                            original: dependent,
                            description: `Dependent: ${dependent.fullName}`,
                            price: formatPrice(quote.category.prices.dependent!.value),
                            isDependent: true,
                            path:
                                quote.id != null
                                    ? `/${Routes.CASES}/${currentCase.id}/${Routes.CASE_DOCUMENTS}/${quote.id}#${dependent.id}`
                                    : undefined,
                        });
                    }
                }

                return list;
            })
            .flat();
        results.push({
            description: 'Total',
            price: formatPrice(getTotalValue()),
            isTotal: true,
        });

        return results;
    }, [quotes, locale]);

    const columns: Array<TableColumnConfig<SanitizedQuote>> = [
        {
            id: 'description',
            headerTitle: 'generic.description',
            getValue: ({ description }) => description,
            getDisplayedValue: ({ description, path, ...rest }) => (
                <TableCell {...rest}>
                    {path != null ? (
                        <Description to={path}>
                            <AiFillFolder /> {description}
                        </Description>
                    ) : (
                        <DescriptionAsText>
                            {rest.isTotal != true && <AiFillFolder />}
                            {description}
                        </DescriptionAsText>
                    )}
                </TableCell>
            ),
            width: '1fr',
        },
        {
            id: 'price',
            headerTitle: 'generic.price',
            getValue: ({ price }) => price,
            getDisplayedValue: ({ price, ...rest }) => (
                <TableCell {...rest} isPrice>
                    {price}
                </TableCell>
            ),
            width: 'minmax(max-content, 30rem)',
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (quote) => {
                if (quote.original == null) {
                    return '';
                } else {
                    return (
                        <CaseQuotesTableMenu
                            quote={quote.original!}
                            openModalHandler={openModalHandler}
                            isDependent={quote.isDependent}
                        />
                    );
                }
            },
            width: '80px',
            isDisplayed: user.isEmployee(),
        },
    ];
    const { data, displayedColumns, isLoading } = useTable(sanitizedQuotes, columns);

    return <Table data={data} columns={displayedColumns} isLoading={isLoading} />;
};

export default CaseQuotesTable;
