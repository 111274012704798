import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 3rem 1fr 20rem 20rem 3rem 20rem 1fr 5rem;
    grid-column-gap: ${({ theme }) => theme.box.spacing.md};

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const Text = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

export const InputContainer = styled.div``;

export const RemoveCondition = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: ${({ theme }) => theme.colors.danger.main};
        font-size: ${({ theme }) => theme.font.size.lg};
    }
`;
