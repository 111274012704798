import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import config from '../../config/main';
import { useTheme } from 'styled-components';

const CookieConsent = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ReactCookieConsent
            location="bottom"
            cookieName={config.cookies.consent.name}
            style={{
                background: '#2f2f2f',
                fontSize: theme.font.size.md,
                padding: `${theme.box.spacing.xl} ${theme.box.spacing.lg}`,
            }}
            buttonText={t('cookies_consent.accept')}
            buttonStyle={{
                color: '#fff',
                fontSize: theme.font.size.md,
                background: theme.colors.primary.main,
                padding: `${theme.box.spacing.sm} ${theme.box.spacing.xl}`,
                borderRadius: '8px',
                marginLeft: theme.box.spacing.xs,
                boxShadow: theme.box.shadow.main,
            }}
            expires={365}
            enableDeclineButton
            declineButtonStyle={{
                color: theme.colors.text.main,
                fontSize: theme.font.size.md,
                background: theme.colors.onSurface.main,
                padding: `${theme.box.spacing.sm} ${theme.box.spacing.md}`,
                borderRadius: '8px',
                boxShadow: theme.box.shadow.main,
            }}
            declineButtonText={t('cookies_consent.decline')}
        >
            {t('cookies_consent.message')}
        </ReactCookieConsent>
    );
};

export default CookieConsent;
