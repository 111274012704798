import React from 'react';
import { CaseQuoteFormItem, CaseQuoteFormItemQuote } from '../../AddQuotesModal';
import {
    Container,
    Quote,
    QuoteButton,
    QuoteButtonText,
    QuoteButtons,
    QuoteCheckbox,
    QuoteLabel,
    QuotePrice,
} from './AddQuotesModalQuote-styles';
import { useLocale } from '../../../../../../../../hooks';
import { CheckboxInput } from '../../../../../../../../components';
import { CaseClient, CaseQuote, CategoryLinkedQuote } from '../../../../../../../../types';
import { formatPrice } from '../../../../../../../../tools';
import AddQuotesModalItem from '../AddQuotesModalItem';
import { FaMinus, FaPlus } from 'react-icons/fa';

type AddQuotesModalQuoteProps = {
    quote: CaseQuoteFormItem;
    clients: Array<CaseClient>;
    isAllowedToAdd: boolean;
    updateSelection: (categoryQuote: CategoryLinkedQuote, isSelected: boolean) => void;
    addQuote: (categoryQuote: CategoryLinkedQuote) => void;
    updateQuote: (categoryQuoteId: string, index: number, quote: CaseQuoteFormItemQuote) => void;
    removeQuote: (categoryQuoteId: string, index: number) => void;
    caseQuotes: Array<CaseQuote>;
};

const AddQuotesModalQuote = ({
    quote: currentQuote,
    clients,
    updateSelection,
    addQuote,
    updateQuote,
    removeQuote,
    caseQuotes,
    isAllowedToAdd,
}: AddQuotesModalQuoteProps) => {
    const locale = useLocale();

    const shouldDisplayPrice = () => {
        if (currentQuote.isSelected !== true || currentQuote.category.rules.belongsToClient !== true) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Container>
            <Quote>
                <QuoteCheckbox>
                    <CheckboxInput
                        isChecked={currentQuote.isSelected}
                        onChange={(isSelected) => updateSelection(currentQuote.category, isSelected)}
                    />
                </QuoteCheckbox>
                <QuoteLabel>{currentQuote.category.title[locale]}</QuoteLabel>

                <QuotePrice>
                    {shouldDisplayPrice() &&
                        formatPrice(currentQuote.category.prices.quote.value * (currentQuote.count || 1))}
                </QuotePrice>

                <QuoteButtons isActive={currentQuote.isSelected}>
                    {currentQuote.isSelected && (
                        <>
                            <QuoteButton
                                onClick={() => removeQuote(currentQuote.category.id, currentQuote.count - 1)}
                            >
                                <FaMinus />
                            </QuoteButton>
                            <QuoteButtonText>{currentQuote.count}</QuoteButtonText>
                            <QuoteButton
                                onClick={() => addQuote(currentQuote.category)}
                                isDisabled={isAllowedToAdd !== true}
                            >
                                <FaPlus />
                            </QuoteButton>
                        </>
                    )}
                </QuoteButtons>
            </Quote>

            {currentQuote.isSelected === true &&
                currentQuote.category.rules.belongsToClient === true &&
                currentQuote.list!.map((item, index) => (
                    <AddQuotesModalItem
                        key={index}
                        index={index}
                        caseQuotes={caseQuotes}
                        currentQuoteList={currentQuote.list!}
                        category={currentQuote.category}
                        clients={clients}
                        item={item}
                        updateQuote={updateQuote}
                        removeQuote={removeQuote}
                    />
                ))}
        </Container>
    );
};

export default AddQuotesModalQuote;
