import styled from 'styled-components';

export const FilesList = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};

    > * {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }
`;
