import React, { useContext } from 'react';
import CheckBoxInputs, { CheckboxInputsProps } from '../../Inputs/CheckboxInputs';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';

const FormCheckboxInputs = <T,>(props: Omit<CheckboxInputsProps<T>, 'onChange' | 'value'>) => {
    const { formik } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);
    return (
        <CheckBoxInputs
            {...props}
            value={formik.values[name]}
            onChange={(selectedOptions) => formik.setFieldValue(name, selectedOptions)}
            options={props.options.map((option) => ({
                ...option,
                isChecked: formik.values[name].includes(option.value),
            }))}
        />
    );
};

export default FormCheckboxInputs;
