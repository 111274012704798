import React from 'react';
import { Container, RemoveOption } from './FormQuestionSelectOption-styles';
import { FormGroup, FormGroupLabel, FormTextInput } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloseCircle } from 'react-icons/ai';

type FormQuestionSelectOptionProps = {
    label_en: string;
    label_fr: string;
    removeOptionHandler: () => void;
    isRemovable: boolean;
};

const FormQuestionSelectOption = ({
    label_en,
    label_fr,
    removeOptionHandler,
    isRemovable,
}: FormQuestionSelectOptionProps) => {
    const { t } = useTranslation();
    return (
        <Container>
            <FormGroup name={label_en}>
                <FormGroupLabel>{t('generic.label')} EN</FormGroupLabel>
                <FormTextInput type="text" />
            </FormGroup>
            <FormGroup name={label_fr}>
                <FormGroupLabel>{t('generic.label')} FR</FormGroupLabel>
                <FormTextInput type="text" />
            </FormGroup>
            <RemoveOption isDisabled={isRemovable !== true}>
                <AiOutlineCloseCircle onClick={() => removeOptionHandler()} />
            </RemoveOption>
        </Container>
    );
};

export default FormQuestionSelectOption;
