import styled from 'styled-components';

export const Header = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const FileList = styled.ul`
    margin-bottom: ${({ theme }) => theme.box.spacing.xl};
    margin-left: ${({ theme }) => theme.box.spacing.md};
`;

export const FileListItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
`;

export const FileListItemRemove = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.md};
    height: min-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const FileListItemLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;
