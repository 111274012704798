import React from 'react';
import { ServerForm } from '../../../../../../../types';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderTitle,
    DynamicFormSection,
    LocalizedText,
} from '../../../../../../../components';
import { FaChevronLeft } from 'react-icons/fa';
import { Routes } from '../../../../../../../routes';
import { SiGoogleforms } from 'react-icons/si';
import { useTranslation } from 'react-i18next';
import { HiBars3BottomLeft } from 'react-icons/hi2';
import {
    Container,
    GoBack,
    SectionContainer,
    SectionContent,
    SectionHeader,
    SectionHeaderTitle,
    SectionHeaderType,
    HeaderTitle,
} from './FormsListDetailsSections-styles';

type FormListDetailsSectionProps = {
    selectedForm: ServerForm;
};

const FormListDetailsSections = ({ selectedForm }: FormListDetailsSectionProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            <AccordionContainer triggerUpdate={selectedForm}>
                <Accordion level={0} isToggled isDisabled>
                    <AccordionHeader>
                        <AccordionHeaderTitle>
                            <GoBack to={`/${Routes.FORMS}`}>
                                <FaChevronLeft />
                            </GoBack>
                            <HeaderTitle>
                                <SiGoogleforms />
                                <LocalizedText text={selectedForm.title} />
                            </HeaderTitle>
                        </AccordionHeaderTitle>
                    </AccordionHeader>
                    <AccordionBody>
                        {selectedForm.sections.map((section) => (
                            <Accordion key={section.id} level={1}>
                                <AccordionHeader>
                                    <SectionHeader>
                                        <SectionHeaderTitle>
                                            <HiBars3BottomLeft />[{section.name}]&nbsp;
                                            <LocalizedText text={section.title} />
                                        </SectionHeaderTitle>
                                        <SectionHeaderType>
                                            Type: {t(`generic.form_sections_type.${section.sectionType}`)}
                                        </SectionHeaderType>
                                    </SectionHeader>
                                </AccordionHeader>
                                <AccordionBody>
                                    <SectionContainer>
                                        <SectionContent>
                                            <DynamicFormSection section={section as any} />
                                        </SectionContent>
                                    </SectionContainer>
                                </AccordionBody>
                            </Accordion>
                        ))}
                    </AccordionBody>
                </Accordion>
            </AccordionContainer>
        </Container>
    );
};

export default FormListDetailsSections;
