import React, { useEffect, useRef } from 'react';
import { Item } from './MultiSelectInputList-styles';
import { InputMode } from '../../../Inputs-types';
import { MultiSelectInputOption } from '../../MultiSelectInput';
import { generateId } from '../../../../../tools';
import CheckboxInput from '../../../CheckboxInput';

type MultiSelectInputListItemProps<T, U> = {
    mode: InputMode;
    isMovingMouse: boolean;
    hoveredOptionIndex: number;
    option: MultiSelectInputOption<T, U>;
    index: number;
    setHoveredOptionIndex: React.Dispatch<React.SetStateAction<number>>;
    onClickCheckboxInput: (isChecked: boolean, selectedOption: MultiSelectInputOption<T, U>) => void;
    useAllOption: boolean;
    isChecked: boolean;
};

const MultiSelectInputListItem = <T, U>({
    mode,
    isMovingMouse,
    option,
    hoveredOptionIndex,
    index,
    setHoveredOptionIndex,
    onClickCheckboxInput,
    isChecked,
}: MultiSelectInputListItemProps<T, U>) => {
    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hoveredOptionIndex === index && isMovingMouse === false) {
            scrollToItem();
        }
    }, [hoveredOptionIndex]);

    /**
     * When use arrow keys to scroll inside the list, an item can be invisible while being hovered.
     * This function will ensure that the parent will scroll to the hovered item position
     */
    const scrollToItem = () => {
        const container = itemRef.current?.parentNode as HTMLElement;
        const containerRect = container?.getBoundingClientRect();
        const itemRect = itemRef.current?.getBoundingClientRect();

        const containerTop = containerRect?.top ?? 0;
        const containerBottom = containerRect?.bottom ?? 0;
        const itemTop = itemRect?.top ?? 0;
        const itemBottom = itemRect?.bottom ?? 0;

        const itemIsVisible =
            itemRect &&
            containerRect &&
            itemTop >= containerTop + 16 && // Top edge of item is within container bounds + 16px
            itemBottom <= containerBottom - 16; // Bottom edge of item is within container bounds - 16px

        if (!itemIsVisible) {
            const scrollTop = container?.scrollTop + itemTop - containerTop - 16;
            container?.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }
    };

    return (
        <Item
            ref={itemRef}
            mode={mode}
            enableHover={isMovingMouse}
            key={generateId()}
            isHovered={hoveredOptionIndex === index}
            onMouseEnter={() => {
                if (isMovingMouse === true) {
                    setHoveredOptionIndex(index);
                }
            }}
        >
            <CheckboxInput
                label={option.label}
                isChecked={isChecked}
                onChange={(val) => onClickCheckboxInput(val, option)}
            />
        </Item>
    );
};

export default MultiSelectInputListItem;
