import styled from 'styled-components';

export const Container = styled.div``;

export const Quote = styled.div<{ hasDependent: boolean }>`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};

    ${({ hasDependent }) => {
        if (hasDependent === true) {
            return {
                '> div:first-child': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: `0px solid`,
                },
                '> div:last-child': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
            };
        }
    }}
`;

export const QuoteFilesList = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};
`;

export const QuoteFilesListItem = styled.div`
    height: ${({ theme }) => theme.config.input.height.default}px;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};
    padding-left: ${({ theme }) => theme.box.spacing.xxl};

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    }
`;
