import styled from 'styled-components';

export const Container = styled.div<{ grid: string; isLeftSide: boolean }>`
    display: flex;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.box.spacing.md};

    ${({ isLeftSide, grid, theme }) => {
        if (isLeftSide !== true) {
            return {
                flex: 1,
                gap: theme.box.spacing.md,
                display: 'grid',
                gridTemplateColumns: grid,
            };
        }
    }}
`;

export const PageHeaderActionsRightButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;

    > * {
        height: 35px;
    }
`;
