import React, { useState } from 'react';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    Modal,
    ModalSize,
} from '../../../../../components';
import { usePromises } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormsModalSections } from '../components';
import { APIService } from '../../../../../services';
import { INITIAL_FORM_SECTION, SelectedSection } from '../types';
import { Locale, ServerFormPayload } from '../../../../../types';

type Props = {
    onClose: () => void;
    refreshFormsList: () => Promise<void>;
};

const CreateFormModal = ({ onClose, refreshFormsList }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, setError }, [sections]] = usePromises(() =>
        APIService.forms().getSectionsList()
    );
    const [selectedSections, setSelectedSections] = useState<Array<SelectedSection>>([INITIAL_FORM_SECTION]);

    const validationSchema = Yup.object({
        title_en: Yup.string().required(t('generic.error.required_field')),
        title_fr: Yup.string().notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            title_en: '',
            title_fr: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (selectedSections.length === 1 && selectedSections[0].section.id === '') {
                setError(t('forms.main.modals.errors.sections_required'));
                return;
            }

            await executePromise(async () => {
                const payload: ServerFormPayload = {
                    title: {
                        [Locale.EN]: submittedValues.title_en,
                        [Locale.FR]: submittedValues.title_fr ?? '',
                    },
                    sections: selectedSections
                        .filter(({ section }) => section.id !== '')
                        .map(({ section, sectionType }, index) => ({
                            id: section.id,
                            index: index,
                            sectionType: sectionType,
                        })),
                };
                await APIService.forms().addForm(payload);
                await refreshFormsList();
                onClose();
            });
        },
    });

    return (
        <Modal
            title={t('forms.main.modals.add.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            isLoading={sections.isLoading}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema} oneColumn>
                <FormGroup name="title_en">
                    <FormGroupLabel>{t('generic.title')} EN</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="title_fr">
                    <FormGroupLabel>{t('generic.title')} FR</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>

                <FormsModalSections
                    selectedSections={selectedSections}
                    setSelectedSections={setSelectedSections}
                    sections={sections.data ?? []}
                />

                <FormFooter submitLabel={t('forms.main.modals.add.title')} secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default CreateFormModal;
