import BaseRequest from './_baseRequest';
import { BaseRequestConfig } from '../APIService.types';
import { Client, ClientSummary, CreateClientPayload, UpdateClientProfilePayload } from '../../../types';

class ClientsRequest extends BaseRequest {
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    async getList(): Promise<Array<Client>> {
        return this.get('/', true).then((res: any) => res.clients);
    }

    async createClient(client: CreateClientPayload): Promise<{ id: string }> {
        return this.post('/', true, client);
    }

    async getAvailableList(): Promise<Array<ClientSummary>> {
        return this.get('/available', true).then((res: any) => res.clients);
    }

    async updateClientProfile(id: string, employee: UpdateClientProfilePayload): Promise<{}> {
        return this.patch(`/${id}/profile`, true, employee);
    }

    async updateClientOrganization(id: string, internalId: string): Promise<{}> {
        return this.patch(`/${id}/organization`, true, { internalId: internalId });
    }

    async updateClientPassword(id: string, password: string, closeSession = false): Promise<{}> {
        return this.patch(`/${id}/password`, true, { password: password, closeSession: closeSession });
    }

    async archiveClient(id: string) {
        return this.delete(`/${id}`, true);
    }
}

export default ClientsRequest;
