import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    FormTextEditorInput,
    FormTextInput,
    Modal,
    ModalSize,
} from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../hooks';
import { AddQuestion, AddQuestionLabel, Question, QuestionRemove } from './CreateFormsQuestionsModal-styles';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
    Locale,
    ServerFormQuestion,
    ServerFormQuestionInputType,
    ServerFormQuestionPayload,
} from '../../../../../types';
import { APIService } from '../../../../../services';
import { FormQuestionsSelectOptions } from '../components';
import { generateId } from '../../../../../tools';

type Props = {
    onClose: () => void;
    refreshQuestionsList: () => Promise<void>;
    questionsList: Array<ServerFormQuestion>;
};

const getInitialOption = () => {
    return {
        label_en: '',
        label_fr: '',
        value: generateId(),
    };
};

const getInitialQuestion = () => {
    return {
        name: '',
        label_en: '',
        label_fr: '',
        placeholder_en: '',
        placeholder_fr: '',
        tooltip_en: '',
        tooltip_fr: '',
        inputType: ServerFormQuestionInputType.TEXT_INPUT,
        options: [getInitialOption()],
    };
};

const CreateFormsQuestionsModal = ({ onClose, refreshQuestionsList, questionsList }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise, setError }] = usePromises();

    const validationSchema = Yup.object({
        questions: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(t('generic.error.required_field')),
                inputType: Yup.string().required(),
                label_en: Yup.string().required(t('generic.error.required_field')),
                label_fr: Yup.string().notRequired(),
                placeholder_en: Yup.string().notRequired(),
                placeholder_fr: Yup.string().notRequired(),
                tooltip_en: Yup.string().notRequired(),
                tooltip_fr: Yup.string().notRequired(),
                options: Yup.array().of(
                    Yup.object().shape({
                        label_en: Yup.string().when('inputType', {
                            is: ServerFormQuestionInputType.SELECT_INPUT,
                            then: (schema) => schema.required(t('generic.error.required_field')),
                            otherwise: (schema) => schema.notRequired(),
                        }),
                        label_fr: Yup.string().notRequired(),
                        value: Yup.string().notRequired(),
                    })
                ),
            })
        ),
    });

    const formik = useFormik({
        initialValues: {
            questions: [getInitialQuestion()],
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            const duplicateQuestion = submittedValues.questions.find((question) =>
                questionsList.some((questionListItem) => questionListItem.name === question.name)
            );

            if (duplicateQuestion != null) {
                setError(
                    t('forms.questions.modals.errors.question_name_exists', {
                        questionName: duplicateQuestion.name,
                    })
                );
                return;
            }

            await executePromise(async () => {
                const payload: Array<ServerFormQuestionPayload> = [];

                for (const question of submittedValues.questions) {
                    payload.push({
                        name: question.name,
                        label: {
                            [Locale.EN]: question.label_en,
                            [Locale.FR]: question.label_fr ?? '',
                        },
                        placeholder: {
                            [Locale.EN]: question.placeholder_en ?? '',
                            [Locale.FR]: question.placeholder_fr ?? '',
                        },
                        tooltip: {
                            [Locale.EN]: question.tooltip_en ?? '',
                            [Locale.FR]: question.tooltip_fr ?? '',
                        },
                        inputType: question.inputType,
                        options:
                            question.inputType === ServerFormQuestionInputType.SELECT_INPUT
                                ? question.options.map((option) => ({
                                      label: {
                                          [Locale.EN]: option.label_en,
                                          [Locale.FR]: option.label_fr,
                                      },
                                  }))
                                : undefined,
                    });
                }

                await APIService.forms().addQuestions({ questions: payload });
                await refreshQuestionsList();
                onClose();
            });
        },
    });

    const addQuestionHandler = () => {
        formik.setFieldValue('questions', [...formik.values.questions, getInitialQuestion()]);
    };

    const changeOptionsOrderHandler = (
        index: number,
        options: Array<{ label_en: string; label_fr: string; value: string }>
    ) => {
        const updatedQuestions = formik.values.questions.map((question, questionIndex) =>
            questionIndex === index ? { ...question, options: options } : question
        );
        formik.setFieldValue('questions', updatedQuestions);
    };

    const removeQuestionHandler = (index: number) => {
        if (index === 0 && formik.values.questions.length === 1) {
            return;
        }
        formik.setFieldValue(
            'questions',
            formik.values.questions.filter((_, questionIndex) => index !== questionIndex)
        );
    };

    const addOptionsHandler = (index: number) => {
        const updatedQuestions = formik.values.questions.map((question, questionIndex) =>
            questionIndex === index
                ? { ...question, options: [...question.options, getInitialOption()] }
                : question
        );
        formik.setFieldValue('questions', updatedQuestions);
    };

    const removeOptionHandler = (index: number, optionIndex: number) => {
        const updatedQuestions = formik.values.questions.map((question, questionIndex) =>
            questionIndex === index
                ? {
                      ...question,
                      options:
                          question.options.length > 1
                              ? question.options.filter((_, i) => i !== optionIndex)
                              : question.options,
                  }
                : question
        );
        formik.setFieldValue('questions', updatedQuestions);
    };

    const inputTypesList = useMemo(() => {
        return [
            {
                label: t('generic.form_inputs_type.text_input'),
                value: ServerFormQuestionInputType.TEXT_INPUT,
            },
            {
                label: t('generic.form_inputs_type.number_input'),
                value: ServerFormQuestionInputType.NUMBER_INPUT,
            },
            {
                label: t('generic.form_inputs_type.date_input'),
                value: ServerFormQuestionInputType.DATE_INPUT,
            },
            {
                label: t('generic.form_inputs_type.select_input'),
                value: ServerFormQuestionInputType.SELECT_INPUT,
            },
        ];
    }, [locale]);

    return (
        <Modal
            title={t('forms.questions.modals.add.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema} oneColumn>
                {formik.values.questions.map((_, index) => (
                    <Question key={index}>
                        <QuestionRemove isDisabled={index === 0 && formik.values.questions.length === 1}>
                            <AiOutlineCloseCircle onClick={() => removeQuestionHandler(index)} />
                        </QuestionRemove>
                        <FormGroup name={`questions.${index}.name`}>
                            <FormGroupLabel>{t('generic.name')}</FormGroupLabel>
                            <FormTextInput type="text" />
                        </FormGroup>
                        <FormGroup name={`questions.${index}.inputType`}>
                            <FormGroupLabel>{t('generic.type')}</FormGroupLabel>
                            <FormSelectInput options={inputTypesList} />
                        </FormGroup>
                        <FormGroup name={`questions.${index}.label_en`}>
                            <FormGroupLabel>{t('generic.label')} EN</FormGroupLabel>
                            <FormTextInput type="text" />
                        </FormGroup>
                        <FormGroup name={`questions.${index}.label_fr`}>
                            <FormGroupLabel>{t('generic.label')} FR</FormGroupLabel>
                            <FormTextInput type="text" />
                        </FormGroup>
                        <FormGroup name={`questions.${index}.placeholder_en`}>
                            <FormGroupLabel>Placeholder EN</FormGroupLabel>
                            <FormTextInput type="text" />
                        </FormGroup>
                        <FormGroup name={`questions.${index}.placeholder_fr`}>
                            <FormGroupLabel>Placeholder FR</FormGroupLabel>
                            <FormTextInput type="text" />
                        </FormGroup>

                        <FormGroup name={`questions.${index}.tooltip_en`}>
                            <FormGroupLabel>Tooltip EN</FormGroupLabel>
                            <FormTextEditorInput />
                        </FormGroup>
                        <FormGroup name={`questions.${index}.tooltip_fr`}>
                            <FormGroupLabel>Tooltip FR</FormGroupLabel>
                            <FormTextEditorInput />
                        </FormGroup>

                        {formik.values.questions[index].inputType ===
                            ServerFormQuestionInputType.SELECT_INPUT && (
                            <FormQuestionsSelectOptions
                                questionIndex={index}
                                onChangeOptions={(options) => changeOptionsOrderHandler(index, options)}
                                options={(formik.values.questions[index] as any).options}
                                addOptionsHandler={() => addOptionsHandler(index)}
                                removeOptionHandler={(optionIndex) => removeOptionHandler(index, optionIndex)}
                            />
                        )}
                    </Question>
                ))}

                <AddQuestion>
                    <AddQuestionLabel onClick={addQuestionHandler}>
                        {t('forms.questions.modals.add.title')}
                    </AddQuestionLabel>
                </AddQuestion>
                <FormFooter submitLabel={t('generic.save')} secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default CreateFormsQuestionsModal;
