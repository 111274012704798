import React from 'react';
import { useLoggedInUser } from '../../hooks';
import AppTopbar from './AppTopbar';
import PublicTopbar from './PublicTopbar';

const Topbar = () => {
    const { isLoggedIn } = useLoggedInUser();
    return isLoggedIn() ? <AppTopbar /> : <PublicTopbar />;
};

export default Topbar;
