import { configureStore } from '@reduxjs/toolkit';
import { userReducer, caseReducer, settingsReducer, organizationsReducer } from './reducers';

export const store = configureStore({
    reducer: {
        user: userReducer,
        caseData: caseReducer,
        settings: settingsReducer,
        organizations: organizationsReducer,
    },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
