import styled from 'styled-components';

export const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    > button {
        margin-left: ${({ theme }) => theme.box.spacing.sm};
    }
`;
