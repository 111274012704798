import React from 'react';
import { useTranslation } from 'react-i18next';
import { Case } from '../../../../../types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    Modal,
} from '../../../../../components';
import { APIService } from '../../../../../services';
import { usePromises, useStore } from '../../../../../hooks';

type Props = {
    onClose: () => void;
    handleUpdateCaseStatus: (currentCase: Case<{ clients: false; employees: false }>) => void;
    currentCase: Case<{ clients: false; employees: false }>;
};

const UpdateCaseStatusModal = ({ currentCase, onClose, handleUpdateCaseStatus }: Props) => {
    const { t, i18n } = useTranslation();
    const { caseData } = useStore();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        status: Yup.string().required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            status: currentCase.status.id,
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (submittedValues.status === currentCase.status.id) {
                return onClose();
            }
            await executePromise(async () => {
                await APIService.cases().updateCaseStatus(currentCase.id, submittedValues.status);
                const currentStatus = caseData.statusList.find((item) => item.id === submittedValues.status)!;
                handleUpdateCaseStatus({
                    ...currentCase,
                    status: currentStatus,
                });
                onClose();
            });
        },
    });

    return (
        <Modal title={t('generic.update_status')} onClose={onClose} error={error} clearError={clearError}>
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="status" expand>
                    <FormGroupLabel>{t('generic.status')}</FormGroupLabel>
                    <FormSelectInput
                        options={caseData.statusList.map((item) => ({
                            value: item.id,
                            label: item.locale[i18n.language as 'en' | 'fr'],
                        }))}
                    />
                </FormGroup>
                <FormFooter submitLabel={t('generic.save')} secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default UpdateCaseStatusModal;
