import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.box.spacing.md};
    background: ${({ theme }) => theme.colors.background.secondary};
    padding: ${({ theme }) => theme.box.spacing.lg};
    border-radius: 8px;
    position: relative;
`;

export const RemoveOption = styled.div<{ isDisabled: boolean }>`
    position: absolute;
    top: ${({ theme }) => theme.box.spacing.sm};
    right: ${({ theme }) => theme.box.spacing.sm};
    cursor: pointer;
    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        font-size: ${({ theme }) => theme.font.size.md};
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                cursor: 'default',
                svg: {
                    color: theme.colors.text.tertiary,
                },
            };
        }
    }}
`;
