import { BannerAppearance } from '../../components';
import { Routes } from '../../routes';
import { LocalStorageService } from '../../services';
import { STORED_OCR } from '../../services/LocalStorageService/LocalStorageService-types';
import { Case, CaseFile, CaseFolder, LocaleObject, OCR_VALIDATION_SEVERITY } from '../../types';
import { CaseFolderManagerContracts, CaseFolderOCR, CaseFolderTooltip } from './CaseFolderManager-contracts';
import { CaseFolderManager_L1 } from './modules';

class CaseFolderManager implements CaseFolderManagerContracts {
    readonly currentCase: Case;
    readonly id: string;
    readonly category: {
        id: string;
        index: number;
        title: LocaleObject;
    };
    readonly folders: Array<CaseFolderManager_L1>;
    readonly client?: {
        id: string;
        internalId: string;
        firstName: string;
        lastName: string;
    };
    readonly isAnonymous?: boolean;
    readonly isDependent?: boolean;
    readonly dependents?: Array<CaseFolderManager>;
    readonly parentCaseFolder?: CaseFolderManager;

    /**
     * Constructor
     * @param {Case} currentCase
     * @param {CaseFolder} caseFolder
     * @param {CaseFolder} parentCaseFolder
     */
    constructor(currentCase: Case, caseFolder: CaseFolder, parentCaseFolder?: CaseFolderManager) {
        this.currentCase = currentCase;
        this.id = caseFolder.id;
        this.category = caseFolder.category;
        this.folders = caseFolder.folders.map((folder) => new CaseFolderManager_L1(folder));
        this.client = caseFolder.client;
        this.isAnonymous = caseFolder.isAnonymous;
        this.isDependent = caseFolder.isDependent;
        this.parentCaseFolder = parentCaseFolder;

        if (caseFolder.isDependent !== true && caseFolder.dependents != null) {
            this.dependents = caseFolder.dependents.map(
                (dependent) => new CaseFolderManager(currentCase, dependent, this)
            );
        }
    }

    // ************************************************************************************
    // INSTANCE METHODS
    // ************************************************************************************

    public getRoutePath(): string {
        const folderId = this.isDependent === true ? this.parentCaseFolder!.id : this.id;
        return `/${Routes.CASES}/${this.currentCase.id}/${Routes.CASE_DOCUMENTS}/${folderId}`;
    }

    public isValidated(): boolean {
        return this.folders.every((folder) => folder.isValidated() === true);
    }

    public hasErrors(): boolean {
        return this.folders.some((folder) => folder.hasErrors() === true);
    }

    public hasWarnings(): boolean {
        return this.folders.some((folder) => folder.hasWarnings() === true);
    }

    public getTotalFileCount(): number {
        let total = 0;
        for (const childFolder of this.folders) {
            for (const grandChildFolder of childFolder.folders) {
                if (grandChildFolder.isDisplayed === true) {
                    total += 1;
                }
            }
        }
        return total;
    }

    public getUploadedFileCount(): number {
        let uploaded = 0;
        for (const childFolder of this.folders) {
            for (const grandChildFolder of childFolder.folders) {
                if (grandChildFolder.files.length > 0) {
                    uploaded += 1;
                }
            }
        }
        return uploaded;
    }

    public getValidatedFileCount(): number {
        let validated = 0;
        for (const childFolder of this.folders) {
            for (const grandChildFolder of childFolder.folders) {
                if (grandChildFolder.validatedAt != null) {
                    validated += 1;
                }
            }
        }
        return validated;
    }

    public getOCRList(): Array<CaseFolderOCR> {
        const list: Array<CaseFolderOCR> = [];
        const storedOCRList = LocalStorageService.getCaseOCR() ?? [];

        for (const childFolder of this.folders) {
            for (const grandChildFolder of childFolder.folders) {
                for (const file of grandChildFolder.files) {
                    if (file.ocr?.validations != null && file.ocr.validations.length > 0) {
                        for (const validation of file.ocr.validations) {
                            const isLocallyAcknowledged = storedOCRList.some(
                                (ocr) =>
                                    ocr.caseQuoteId === this.id &&
                                    ocr.caseFileId === file.id &&
                                    ocr.ocrKey === validation.key &&
                                    ocr.severity === validation.severity
                            );

                            list.push({
                                caseFolder: this,
                                grandChildFolder: grandChildFolder,
                                caseFile: file,
                                validation: validation,
                                isDisplayed: isLocallyAcknowledged !== true,
                                appearance:
                                    validation.severity === OCR_VALIDATION_SEVERITY.CRITICAL
                                        ? BannerAppearance.ERROR
                                        : BannerAppearance.WARNING,
                            });
                        }
                    }
                }
            }
        }

        if (this.isDependent !== true && this.dependents != null) {
            for (const dependent of this.dependents) {
                list.push(...dependent.getOCRList());
            }
        }

        return list;
    }

    public getTooltips(): Array<CaseFolderTooltip> {
        const tooltips: Array<CaseFolderTooltip> = [];
        for (const childFolder of this.folders) {
            tooltips.push(...childFolder.getTooltips());
        }
        return tooltips;
    }

    public getCaseFileById(caseFileId: string): CaseFile | null {
        for (const childFolder of this.folders) {
            for (const grandChildFolder of childFolder.folders) {
                for (const caseFile of grandChildFolder.files) {
                    if (caseFile.id === caseFileId) {
                        return caseFile;
                    }
                }
            }
        }
        return null;
    }

    // ************************************************************************************
    // STATIC METHODS
    // ************************************************************************************

    static acknowledgeOCR(caseFolderOCR: CaseFolderOCR) {
        const acknowledgedOCRList = LocalStorageService.getCaseOCR() ?? [];
        const storedOCR: STORED_OCR = {
            caseQuoteId: caseFolderOCR.caseFolder.id,
            caseFileId: caseFolderOCR.caseFile.id,
            ocrKey: caseFolderOCR.validation.key,
            severity: caseFolderOCR.validation.severity,
        };
        acknowledgedOCRList.push(storedOCR);
        LocalStorageService.saveCaseOCR(acknowledgedOCRList);
    }
}

export default CaseFolderManager;
