export enum Breakpoints {
    DESKTOP = 1024,
    TABLET = 768,
    MOBILE = 480,
}

export const breakpoints = {
    desktop: `@media (min-width: ${Breakpoints.DESKTOP}px)`,
    tablet_landscape: `@media (max-width: ${Breakpoints.DESKTOP}px)`,
    tablet_portrait: `@media  (max-width: ${Breakpoints.TABLET}px)`,
    mobile: `@media (max-width: ${Breakpoints.MOBILE}px)`,
};
