import React from 'react';
import { Container } from './PageHeader-styles';

type Props = {
    children: React.ReactNode;
    display?: 'row' | 'column';
};

const PageHeader = ({ children, display = 'column' }: Props) => {
    return (
        <Container display={display} className="page-header">
            {children}
        </Container>
    );
};

export default PageHeader;
