import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useLoggedInUser, usePromises, useStore } from '../../../hooks';
import { APIService } from '../../../services';
import { CaseNavigation } from './components';
import {
    Header,
    HeaderLeft,
    HeaderLeftStatus,
    HeaderRight,
    Content,
    LoadingContainer,
    HeaderLeftSelectCase,
} from './CasePages-styles';
import {
    PageContainer,
    PageHeaderTitle,
    SecondaryOutlinedButton,
    SelectInput,
    Spinner,
    SpinnerAppearance,
    SpinnerSize,
} from '../../../components';
import { BiFolder } from 'react-icons/bi';
import { DeleteCaseModal, UpdateCaseStatusModal } from './modals';
import { Routes } from '../../../routes';
import { Link } from 'react-router-dom';
import { OrganizationBusiness } from '../../../business';
import { BsTrash } from 'react-icons/bs';

const CasesPages = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useAppDispatch();
    const store = useStore();
    const params = useParams();
    const user = useLoggedInUser();
    const location = useLocation();

    const navigate = useNavigate();

    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [isDeletingCase, setIsDeletingCase] = useState(false);
    const [promises, [currentCase]] = usePromises(() => APIService.cases().getCase(params.id as string));

    useEffect(() => {
        return () => OrganizationBusiness.resetOrganization(dispatch, store);
    }, [store.organizations.activeOrganizationInternalId]);

    useEffect(() => {
        if (currentCase.isLoading !== true) {
            OrganizationBusiness.updateOrganization(
                currentCase.data.organization.internalId,
                dispatch,
                store
            );
        }
    }, [currentCase.isLoading]);

    useEffect(() => {
        if (params && currentCase.data && params.id !== currentCase.data.id) {
            refreshCase(params.id as string);
        }
    }, [params?.id]);

    const refreshCase = async (caseId: string) => {
        await promises.executePromise(async () => {
            const selectedCase = await APIService.cases().getCase(caseId);
            currentCase.update(selectedCase);
            OrganizationBusiness.updateOrganization(selectedCase.organization.internalId, dispatch, store);
        });
    };

    const getPath = (id: string) => {
        let path = `/${Routes.CASES}/${id}`;

        if (location.pathname.split('/')[3] != null) {
            path += `/${location.pathname.split('/')[3]}`;
        }
        return path;
    };

    return (
        <PageContainer error={promises.error} clearError={promises.clearError}>
            {promises.isInitializing === true && (
                <LoadingContainer>
                    <Spinner appearance={SpinnerAppearance.PRIMARY} size={SpinnerSize.MEDIUM} />
                </LoadingContainer>
            )}

            {promises.isInitializing === false && !promises.error && (
                <>
                    {isUpdatingStatus === true && (
                        <UpdateCaseStatusModal
                            currentCase={currentCase.data}
                            onClose={() => setIsUpdatingStatus(false)}
                            handleUpdateCaseStatus={currentCase.update}
                        />
                    )}
                    {isDeletingCase === true && (
                        <DeleteCaseModal
                            currentCase={currentCase.data}
                            onClose={() => setIsDeletingCase(false)}
                        />
                    )}

                    <Header>
                        <HeaderLeft>
                            {user.isEmployee() || store.caseData.clientCasesList.length <= 1 ? (
                                <PageHeaderTitle>
                                    <BiFolder />
                                    {currentCase.data.internalId}
                                </PageHeaderTitle>
                            ) : (
                                <HeaderLeftSelectCase>
                                    <SelectInput
                                        label={
                                            <>
                                                <BiFolder />
                                                &nbsp; {t('generic.case')}
                                            </>
                                        }
                                        value={currentCase.data.id}
                                        options={store.caseData.clientCasesList.map((caseDocument) => ({
                                            label: caseDocument.internalId,
                                            value: caseDocument.id,
                                            data: caseDocument,
                                        }))}
                                        renderOptionLabel={(id, caseDocument) => (
                                            <Link to={getPath(id)}>{caseDocument!.internalId}</Link>
                                        )}
                                        onChange={(id) => navigate(getPath(id))}
                                    />
                                </HeaderLeftSelectCase>
                            )}

                            <HeaderLeftStatus
                                onClick={() => (user.isEmployee() ? setIsUpdatingStatus(true) : {})}
                                isClickable={user.isEmployee()}
                            >
                                {t('generic.status')}:&nbsp;
                                {currentCase.data.status.locale[i18n.language as 'en' | 'fr']}
                            </HeaderLeftStatus>
                        </HeaderLeft>
                        {user.isEmployee() && (
                            <HeaderRight>
                                <SecondaryOutlinedButton onClick={() => setIsUpdatingStatus(true)}>
                                    {t('generic.update_status')}
                                </SecondaryOutlinedButton>
                                {user.hasPermissions('cases.delete') && (
                                    <SecondaryOutlinedButton
                                        onClick={() => setIsDeletingCase(true)}
                                        leadingIcon={<BsTrash />}
                                    />
                                )}
                            </HeaderRight>
                        )}
                    </Header>

                    <CaseNavigation />

                    {promises.isExecutingPromise !== true && (
                        <Content>
                            <Outlet
                                context={{
                                    currentCase: currentCase.data,
                                    refreshCurrentCase: currentCase.refresh,
                                }}
                            />
                        </Content>
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default CasesPages;
