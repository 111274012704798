import React, { useRef } from 'react';
import {
    TableBodyRowActionContainer,
    TableBodyRowActionContent,
    TableBodyRowActionList,
    TableBodyRowActionListItem,
} from './Table-styles';
import { BsThreeDots } from 'react-icons/bs';
import { generateId } from '../../tools';
import { useToggle } from '../../hooks';
import { useOnClickOutside } from 'usehooks-ts';

export type TableBodyRowActionProps<T> = {
    actions: Array<{
        label: string;
        onClick: (item: T) => void;
        isDisplayed?: boolean;
    }>;
    positionListAtTop?: boolean;
};

const TableBodyRowAction = ({ actions, positionListAtTop }: TableBodyRowActionProps<any>) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [isOpened, _, close, toggle] = useToggle(false);

    useOnClickOutside(containerRef, close);

    return (
        <TableBodyRowActionContainer ref={containerRef}>
            <TableBodyRowActionContent
                onClick={(e) => {
                    e.stopPropagation();
                    toggle(e);
                }}
            >
                <BsThreeDots />
            </TableBodyRowActionContent>
            {isOpened && (
                <TableBodyRowActionList positionListAtTop={positionListAtTop}>
                    {actions
                        .filter((action) => action.isDisplayed !== false)
                        .map((action) => (
                            <TableBodyRowActionListItem
                                key={generateId()}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    action.onClick(e);
                                }}
                            >
                                {action.label}
                            </TableBodyRowActionListItem>
                        ))}
                </TableBodyRowActionList>
            )}
        </TableBodyRowActionContainer>
    );
};

export default TableBodyRowAction;
