import { useRef, useState } from 'react';

type SetValue<T> = (newValue: T | ((oldValue: T) => T)) => void;
type UseStateTuple<T> = [T, SetValue<T>, T];

export const useRefState = <T>(initialValue: T): UseStateTuple<T> => {
    const [value, setValue] = useState(initialValue);
    const ref = useRef(initialValue);

    const updateValue: SetValue<T> = (newValue) => {
        let updatedValue: T;
        if (newValue instanceof Function) {
            updatedValue = (newValue as (oldValue: T) => T)(value);
        } else {
            updatedValue = newValue as T;
        }
        setValue(updatedValue);
        ref.current = updatedValue;
    };

    return [value, updateValue, ref.current];
};
