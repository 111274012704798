import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.background.secondary};
    padding: 0 ${({ theme }) => theme.box.spacing.xxl};

    ${({ theme }) => theme.breakpoints.tablet_portrait} {
        padding: 0;
    }
`;

export const Content = styled.div`
    padding: ${({ theme }) => theme.box.spacing.lg};
    flex: 1;
    background: ${({ theme }) => theme.colors.surface.main};
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;
