/**
 * Format price
 * @param {number} price
 * @return {string}
 */
export const formatPrice = (price: number) => {
    return price.toLocaleString('en-CA', {
        style: 'currency',
        currency: 'CAD',
    });
};
