import React from 'react';
import { Container } from './PageHeaderTitle-styles';

type Props = {
    children: React.ReactNode;
};

const PageHeaderTitle = ({ children }: Props) => {
    return <Container className="page-title">{children}</Container>;
};

export default PageHeaderTitle;
