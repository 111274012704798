import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TableCell = styled.div<{ isDependent?: boolean; isTotal?: boolean; isPrice?: boolean }>`
    ${({ isDependent, isPrice }) => {
        if (isDependent === true && isPrice !== true) {
            return {
                paddingLeft: '5rem',
            };
        }
    }}

    ${({ isTotal, theme }) => {
        if (isTotal === true) {
            return {
                fontWeight: theme.font.weight.bold,
            };
        }
    }}
`;

export const Description = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main} !important;
    text-decoration: underline;

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        fill: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }
`;

export const DescriptionAsText = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main} !important;

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        fill: ${({ theme }) => theme.colors.text.secondary} !important;
    }
`;
