import styled from 'styled-components';

export const Left = styled.div`
    display: flex;
    align-items: center;
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
`;
