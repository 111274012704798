import styled from 'styled-components';

export const CategoryDescription = styled.div`
    padding: 0 ${({ theme }) => theme.box.spacing.lg};
    line-height: 1.4;
    background: ${({ theme }) => theme.colors.surface.main};
    width: 100%;
    max-width: 60rem;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
`;
