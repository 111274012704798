import { APIService, LocalStorageService } from '../services';
import {
    updateThemeAndAssets,
    setActiveOrganizationInternalId,
    setOrganizationsList,
    resetThemeAndAssets,
    REDUX_Organization,
} from '../services/ReduxService';
import { AppDispatch, AppState } from '../services/ReduxService/store';
import theme from '../theme';

export class OrganizationBusiness {
    /**
     * Initialize organization
     * @param {AppDispatch} dispatch
     * @return {Promise<Array<OrganizationPublic>>}
     */
    static async initializeOrganizations(dispatch: AppDispatch): Promise<Array<REDUX_Organization>> {
        const organizationsList = await this.refreshOrganizations(dispatch);

        const storedOrganizationId = LocalStorageService.getOrganization();
        const initialOrganization = organizationsList.find(
            (organization) => organization.internalId === storedOrganizationId
        );

        if (initialOrganization != null) {
            this.applyOrganization(initialOrganization, dispatch);
        }

        return organizationsList;
    }

    static async refreshOrganizations(dispatch: AppDispatch): Promise<Array<REDUX_Organization>> {
        const organizationsList = await APIService.organizations().getPublicList();
        dispatch(setOrganizationsList(organizationsList));
        return organizationsList;
    }

    /**
     * Change organization
     * @param organizationInternalId
     */
    static updateOrganization(organizationInternalId: string, dispatch: AppDispatch, state: AppState) {
        if (organizationInternalId === state.organizations.activeOrganizationInternalId) {
            return;
        }

        const organization = state.organizations.list.find(
            (item) => item.internalId === organizationInternalId
        );
        if (organization == null) {
            return;
        }

        this.applyOrganization(organization, dispatch);
    }

    /**
     * Reset organization
     * @param dispatch
     * @param state
     */
    static resetOrganization(dispatch: AppDispatch, state: AppState) {
        if (state.user?.email.length > 0 !== true) {
            dispatch(resetThemeAndAssets());
            dispatch(setActiveOrganizationInternalId('default'));
            return;
        }

        if (state.organizations.activeOrganizationInternalId === state.user.organization.internalId) {
            return;
        }

        const userOrganization = state.organizations.list.find(
            (organization) => organization.internalId === state.user.organization.internalId
        );
        if (userOrganization != null) {
            this.applyOrganization(userOrganization, dispatch);
        }
    }

    /**
     * Apply organization logo, favicon and theme then store internal id
     * @param {REDUX_Organization} organization
     * @param {AppDispatch} dispatch
     */
    static applyOrganization(organization: REDUX_Organization, dispatch: AppDispatch) {
        const updatedTheme =
            organization.internalId === 'default'
                ? theme
                : {
                      ...theme,
                      colors: {
                          ...theme.colors,
                          primary: {
                              main: organization.theme.primary,
                              extended_50: this.lightenColor(organization.theme.primary, 0.5),
                              extended_10: this.lightenColor(organization.theme.primary, 0.9),
                          },
                      },
                  };
        dispatch(
            updateThemeAndAssets({
                theme: updatedTheme,
                logUrl: organization.assets.logo.url,
                faviconUrl: organization.assets.favicon.url,
            })
        );
        dispatch(setActiveOrganizationInternalId(organization.internalId));
        this.updateFavicon(organization.assets.favicon.url);
        this.updateDocumentTitle(organization.name);
        LocalStorageService.saveOrganization(organization.internalId);
    }

    // *****************************************************************************************************************************************
    // Helpers
    // *****************************************************************************************************************************************

    /**
     * Update favicon
     * @param {string} url
     */
    private static updateFavicon(url: string) {
        const faviconUrl = url.length > 0 ? url : `${process.env.PUBLIC_URL + '/naia_favicon.png'}`;
        const link = document.querySelector("link[rel*='icon']") || (document.createElement('link') as any);
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = faviconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    /**
     * Update document title
     * @param organizationName
     */
    private static updateDocumentTitle(organizationName: string) {
        document.title = organizationName;
    }

    /**
     * Lighten color
     * @param {string} color
     * @param {number} percentage
     * @return {string}
     */
    private static lightenColor(color: string, percentage: number): string {
        // Convert hex color to RGB values

        const [r, g, b] = this.hexToRgb(color);

        // Calculate the new RGB values for a lighter shade
        const newR = Math.min(Math.round(r + (255 - r) * percentage), 255);
        const newG = Math.min(Math.round(g + (255 - g) * percentage), 255);
        const newB = Math.min(Math.round(b + (255 - b) * percentage), 255);

        // Convert the updated RGB values back to hex format
        const newColor = `#${((newR << 16) | (newG << 8) | newB).toString(16).padStart(6, '0')}`;

        return newColor;
    }

    /**
     * Convert hexadecimal color to RGB
     * @param {string} hex
     * @return {Array<number>}
     */
    private static hexToRgb(hex: string): Array<number> {
        return hex.match(/[A-Za-z0-9]{2}/g)?.map((v) => parseInt(v, 16)) ?? [];
    }
}
