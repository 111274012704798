import React from 'react';
import { Container } from './ModalFooter-styles';
import {
    ButtonSize,
    DangerContainedButton,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
} from '../../Button';
import { useTranslation } from 'react-i18next';

type Props = {
    confirmationLabel: string;
    onClick: () => void;
    expand?: boolean;
    secondary?: {
        label?: string;
        onClick: () => void;
    };
    children?: React.ReactNode;
    isLoading?: boolean;
    isDestructive?: boolean;
    isDisabled?: boolean;
};

const ModalFooter = ({
    confirmationLabel,
    onClick,
    secondary,
    children,
    expand = false,
    isLoading = false,
    isDestructive = false,
    isDisabled = false,
}: Props) => {
    const { t } = useTranslation();

    const MainButton = isDestructive ? DangerContainedButton : PrimaryContainedButton;

    return (
        <Container expand={expand}>
            {children != null && children}
            {secondary && (
                <SecondaryOutlinedButton
                    size={ButtonSize.LARGE}
                    expand={expand}
                    onClick={secondary.onClick}
                    type="button"
                    disabled={isLoading === true}
                >
                    {secondary.label ?? t('generic.cancel')}
                </SecondaryOutlinedButton>
            )}

            <MainButton
                size={ButtonSize.LARGE}
                expand={expand}
                type="button"
                isLoading={isLoading}
                disabled={isDisabled}
                onClick={() => {
                    if (isLoading === false) {
                        onClick();
                    }
                }}
            >
                {confirmationLabel}
            </MainButton>
        </Container>
    );
};

export default ModalFooter;
