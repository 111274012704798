import styled from 'styled-components';

export const Container = styled.div`
    height: ${({ theme }) => theme.config.topbar.height.default}px;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    padding-right: ${({ theme }) => theme.box.spacing.xl};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.primary.main};
    box-shadow: ${({ theme }) => theme.box.shadow.light};
    z-index: 9;

    ${({ theme }) => {
        return {
            [theme.breakpoints.tablet_landscape]: {
                paddingRight: theme.box.spacing.md,
            },
        };
    }}
`;
