import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateId } from '../../tools';
import { BodyCell, BodyCellContent, BodyCellContentTruncated } from './Table-styles';
import { TableProps, TableStyles } from './Table-types';

type Props = TableProps<any> & {
    tableRef: React.RefObject<HTMLDivElement>;
    tableStyles: TableStyles;
};

type BodyCellNode = ChildNode & {
    dataset: {
        hovered: boolean;
        row: string;
    };
};

const TableBody = (props: Props) => {
    const { t } = useTranslation();

    const onMouseEnterCell = (row: number) => {
        props.tableRef.current?.childNodes.forEach((node) => {
            const currentNode = node as BodyCellNode;
            currentNode.dataset.hovered =
                currentNode.dataset.row != null && parseInt(currentNode.dataset.row) === row;
        });
    };

    const onMouseLeaveCell = () => {
        props.tableRef.current?.childNodes.forEach((node) => {
            const currentNode = node as BodyCellNode;
            currentNode.dataset.hovered = false;
        });
    };

    const onClickCell = (item: any) => {
        if (typeof props.handleClickRow === 'function') {
            props.handleClickRow(item);
        }
    };

    return (
        <React.Fragment>
            {props.data.map((item, index) => {
                return props.columns.map((column) => {
                    const options = {
                        t: t,
                        index: index,
                        total: props.data.length,
                    };

                    const content =
                        typeof column.getDisplayedValue === 'function'
                            ? column.getDisplayedValue(item, options)
                            : column.getValue(item, options);

                    return (
                        <BodyCell
                            key={generateId()}
                            column={column}
                            data-row={index}
                            onMouseEnter={() => onMouseEnterCell(index)}
                            onMouseLeave={onMouseLeaveCell}
                            onClick={() => onClickCell(item)}
                            isClickable={typeof props.handleClickRow === 'function'}
                            tableStyles={props.tableStyles}
                            className={index === props.data.length - 1 ? 'table-last-row' : ''}
                        >
                            <BodyCellContent column={column} tableStyles={props.tableStyles}>
                                {column.isTruncated === true ? (
                                    <BodyCellContentTruncated column={column}>
                                        {content}
                                    </BodyCellContentTruncated>
                                ) : (
                                    content
                                )}
                            </BodyCellContent>
                        </BodyCell>
                    );
                });
            })}
        </React.Fragment>
    );
};

export default TableBody;
