import styled, { css } from 'styled-components';

const infoStyles = css`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.xs};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    color: ${({ theme }) => theme.colors.text.main} !important;

    cursor: pointer;

    :hover {
        color: ${({ theme }) => theme.colors.primary.main};
        text-decoration: underline;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-right: ${({ theme }) => theme.box.spacing.xl};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    position: relative;
`;

export const IconContainer = styled.div<{ isGeneratingUrl: boolean }>`
    margin-right: ${({ theme }) => theme.box.spacing.md};
    position: relative;

    ${({ isGeneratingUrl, theme }) => {
        if (isGeneratingUrl === true) {
            return {
                svg: {
                    fill: theme.colors.onSurface.secondary,
                },
            };
        }
    }}
`;

export const IconSpinner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const InfoNameDiv = styled.div`
    ${infoStyles};
`;

export const InfoNameLink = styled.a`
    ${infoStyles};
`;

export const InfoSize = styled.div`
    font-size: ${({ theme }) => theme.font.size.xs};
    color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Cta = styled.div<{ isEditable: boolean }>`
    display: flex;
    width: 50%;
    max-width: 40rem;
    color: ${({ theme }) => theme.colors.text.main} !important;
    position: relative;
    margin-right: ${({ theme }) => theme.box.spacing.sm};

    ${({ isEditable }) => {
        if (isEditable !== true) {
            return {
                cursor: 'not-allowed',
                '> div:first-child': {
                    borderColor: 'transparent',
                    '> div:last-child': {
                        opacity: 0,
                    },
                },
            };
        }
    }}
`;

export const CtaLabel = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.config.input.height.default}px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-radius: 0.3rem;
    flex: 1;
    padding-left: ${({ theme }) => theme.box.spacing.xs};

    :hover {
        background-color: ${({ theme }) => theme.colors.onSurface.main};
    }
`;

export const CtaLabelText = styled.div<{ isPlaceholder?: boolean }>`
    flex: 1;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.primary.main} !important;
    text-decoration: underline;
    max-width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${({ isPlaceholder, theme }) => {
        if (isPlaceholder === true) {
            return {
                textDecoration: 'none',
                color: theme.colors.text.secondary,
                fontStyle: 'italic',
            };
        }
    }}
`;

export const CtaLabelLeadingIcon = styled.div`
    display: flex;
    align-items: center;

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        fill: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }
`;

export const CtaLabelTrailingIcon = styled.div`
    width: 3rem;
    display: flex;
    justify-content: center;
    svg {
        font-size: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const RemoveIcon = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.box.spacing.sm};
    right: ${({ theme }) => theme.box.spacing.sm};
    z-index: 2;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.danger.main};

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
    }
`;
