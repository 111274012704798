import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    ButtonSize,
    DraggableList,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormGroupTitle,
    FormTextEditorInput,
    FormTextInput,
    Modal,
    ModalSize,
    SecondaryContainedButton,
} from '../../../../../components';
import { CategoryPayload, CategoryQuoteType } from '../../../../../types';

// config
import { Quote } from '../config/types';
import { CategoryModalGroups } from '../config/styles';
import { CategoryModalGroup } from '../config/components';

type Props = {
    onClose: () => void;
    refreshCategoriesList: () => Promise<void>;
};

const CreateCategoryModal = ({ onClose, refreshCategoriesList }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }, [quotesList]] = usePromises(() =>
        APIService.categories().getQuotesList()
    );

    const [selectedQuotes, setSelectedQuotes] = useState([
        {
            id: '',
            type: CategoryQuoteType.UNIQUE,
            maxCount: 1 as string | number,
            price: 0 as string | number,
            dependentPrice: 0 as string | number,
        },
    ]);

    const validationSchema = Yup.object({
        internalId: Yup.string().required(t('generic.error.required_field')),
        title_en: Yup.string().required(t('generic.error.required_field')),
        title_fr: Yup.string().notRequired(),
        description_en: Yup.string().notRequired(),
        description_fr: Yup.string().notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            internalId: '',
            title_en: '',
            title_fr: '',
            description_en: '',
            description_fr: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload: CategoryPayload = {
                    title: { en: submittedValues.title_en, fr: submittedValues.title_fr },
                    description: { en: submittedValues.description_en, fr: submittedValues.description_fr },
                    internalId: submittedValues.internalId,
                    quotes: selectedQuotes
                        .filter((quote) => quote.id.length > 0)
                        .map((quote, quoteIndex) => {
                            const currentQuote = quotesList.data.find((item) => item.id === quote.id);
                            return {
                                id: quote.id,
                                index: quoteIndex,
                                type: quote.type,
                                maxCount: quote.maxCount === '' ? undefined : (quote.maxCount as number),
                                price: quote.price === '' ? 0 : parseFloat(quote.price as string),
                                dependentPrice:
                                    currentQuote!.dependent == null
                                        ? undefined
                                        : quote.dependentPrice === ''
                                        ? 0
                                        : parseFloat(quote.dependentPrice as string),
                            };
                        }),
                };

                await APIService.categories().createCategory(payload);
                await refreshCategoriesList();
                onClose();
            });
        },
    });

    const isAllowedToAdd = useMemo(() => {
        return selectedQuotes.every((selectedQuote) => selectedQuote.id.length > 0);
    }, [selectedQuotes]);

    const handleChangeQuote = (index: number, quote: Quote) => {
        setSelectedQuotes((prev) =>
            prev.map((prevQuote, prevIndex) => (prevIndex === index ? quote : prevQuote))
        );
    };

    const handleRemoveQuote = (index: number) => {
        setSelectedQuotes((prev) => prev.filter((_, prevIndex) => index !== prevIndex));
    };

    const handleAddQuote = () => {
        if (selectedQuotes.length < quotesList.data.length) {
            setSelectedQuotes((prev) => [
                ...prev,
                {
                    id: '',
                    type: CategoryQuoteType.UNIQUE,
                    maxCount: 1,
                    price: 0,
                    dependentPrice: 0,
                },
            ]);
        }
    };

    return (
        <Modal
            title="categories.main.modals.add.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroupTitle>{t('generic.general_info')}</FormGroupTitle>
                <FormGroup name="internalId">
                    <FormGroupLabel>{t('generic.internal_id')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroupTitle>{t('generic.title')}</FormGroupTitle>
                <FormGroup name="title_en">
                    <FormGroupLabel>{t('generic.locale.en')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="title_fr">
                    <FormGroupLabel>{t('generic.locale.fr')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroupTitle>{t('generic.description')}</FormGroupTitle>
                <FormGroup name="description_en">
                    <FormGroupLabel>{t('generic.locale.en')}</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormGroup name="description_fr">
                    <FormGroupLabel>{t('generic.locale.fr')}</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormGroupTitle>
                    {t('generic.quotes')}
                    <SecondaryContainedButton
                        size={ButtonSize.SMALL}
                        onClick={handleAddQuote}
                        disabled={
                            selectedQuotes.length === quotesList.data?.length || isAllowedToAdd === false
                        }
                    >
                        {t('generic.add')}
                    </SecondaryContainedButton>
                </FormGroupTitle>
                <CategoryModalGroups>
                    <DraggableList
                        list={selectedQuotes}
                        getItemId={(quote) => quote.id}
                        onChange={(value) => setSelectedQuotes(value)}
                        renderListItem={(quote, index) => (
                            <CategoryModalGroup
                                key={index}
                                quote={quote}
                                quotesList={quotesList.data ?? []}
                                index={index}
                                total={selectedQuotes.length}
                                handleChangeQuote={(value) => handleChangeQuote(index, value)}
                                handleRemoveQuote={() => handleRemoveQuote(index)}
                                selectedQuotes={selectedQuotes}
                            />
                        )}
                    />
                </CategoryModalGroups>

                <FormFooter
                    submitLabel={t('categories.main.modals.add.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default CreateCategoryModal;
