import React, { useContext } from 'react';
import {
    CheckBoxInputContainer,
    Container,
    GoBack,
    SecondaryIconCheck,
} from './CaseDocumentsFolderHeader-styles';
import { FaCheck, FaChevronLeft } from 'react-icons/fa';
import { Routes } from '../../../../../../../../routes';
import { useOutletContext } from 'react-router-dom';
import { AccordionContext } from '../../../../../../../../components/Accordion/Accordion';
import { CaseDocumentsContextType } from '../../../../CaseDocuments';
import { CaseFolderIcon, CheckboxInput } from '../../../../../../../../components';
import { CaseDocumentsFolderContext, MultipleSelectMode } from '../../CaseDocumentsFolder';
import { CaseFolderTooltip } from '../../../../../../../../managers';

type CaseDocumentsFolderHeaderProps = {
    children: React.ReactNode;
    level: number;
    isSelected?: boolean;
    isValidated?: boolean;
    hasErrors?: boolean;
    hasWarnings?: boolean;
    onSelectFolder?: (isChecked: boolean) => void;
    tooltips?: Array<CaseFolderTooltip> | null;
};

const CaseDocumentsFolderHeader = ({
    children,
    level,
    isSelected = false,
    isValidated = false,
    hasErrors = false,
    hasWarnings = false,
    onSelectFolder,
    tooltips,
}: CaseDocumentsFolderHeaderProps) => {
    const { isToggled } = useContext(AccordionContext);
    const { currentCase } = useOutletContext<CaseDocumentsContextType>();
    const { multipleSelectMode, caseFolder } = useContext(CaseDocumentsFolderContext);

    return (
        <Container>
            {level === 0 && caseFolder.isDependent !== true && (
                <GoBack to={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_DOCUMENTS}`}>
                    <FaChevronLeft />
                </GoBack>
            )}
            {level !== 0 && multipleSelectMode === MultipleSelectMode.BY_FOLDERS ? (
                <CheckBoxInputContainer>
                    <CheckboxInput isChecked={isSelected!} onChange={(value) => onSelectFolder?.(value)} />
                </CheckBoxInputContainer>
            ) : (
                <CaseFolderIcon
                    level={level}
                    isToggled={isToggled}
                    isValidated={isValidated && hasErrors !== true && hasWarnings !== true}
                    hasErrors={hasErrors && level !== 1}
                    hasWarnings={hasWarnings && level !== 1}
                    tooltips={tooltips}
                />
            )}
            {children}
            {multipleSelectMode === MultipleSelectMode.BY_FOLDERS && isValidated && (
                <SecondaryIconCheck>
                    <FaCheck />
                </SecondaryIconCheck>
            )}
        </Container>
    );
};

export default CaseDocumentsFolderHeader;
