import React, { useMemo, useRef } from 'react';
import {
    Container,
    IconContainer,
    Tooltip,
    TooltipContent,
    TooltipContentIcon,
    TooltipContentText,
    TooltipTitle,
    Tooltips,
} from './CaseFolderIcon-styles';
import { AiFillFolder, AiFillFolderOpen } from 'react-icons/ai';
import { MdOutlineError } from 'react-icons/md';
import { PiWarningFill } from 'react-icons/pi';
import { FaCircleCheck } from 'react-icons/fa6';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import LocalizedText from '../LocalizedText';
import { generateId } from '../../tools';
import { CaseFolderTooltip } from '../../managers/CaseFolderManager/CaseFolderManager-contracts';
import { OCR_VALIDATION_SEVERITY } from '../../types';
import { useScrollPosition } from '../../hooks';

type CaseFolderIconProps = {
    level: number;
    isToggled?: boolean;
    isValidated?: boolean;
    hasErrors?: boolean;
    hasWarnings?: boolean;
    tooltips?: Array<CaseFolderTooltip> | null;
};

const CaseFolderIcon = ({
    level,
    isToggled,
    isValidated,
    hasErrors,
    hasWarnings,
    tooltips,
}: CaseFolderIconProps) => {
    const scrollPosition = useScrollPosition();
    const tooltipId = useRef(generateId());
    const tooltipRef = useRef<HTMLDivElement>(null);

    const showIconState = useMemo(() => {
        return isValidated || hasErrors || hasWarnings;
    }, [isValidated, hasErrors, hasWarnings]);

    const showTooltip = useMemo(() => {
        return tooltips != null && tooltips.some((tooltip) => tooltip.messages.length > 0);
    }, [tooltips]);

    const renderIcon = () => {
        if (hasErrors === true) {
            return (
                <IconContainer className="error">
                    <MdOutlineError />
                </IconContainer>
            );
        }
        if (hasWarnings === true) {
            return (
                <IconContainer className="warning">
                    <PiWarningFill />
                </IconContainer>
            );
        }

        if (isValidated === true) {
            return (
                <IconContainer className="validated">
                    <FaCircleCheck />
                </IconContainer>
            );
        }

        return <></>;
    };

    return (
        <Container data-tooltip-id={tooltipId.current} ref={tooltipRef} scrollPosition={scrollPosition.y}>
            {isToggled ? <AiFillFolderOpen /> : <AiFillFolder />}
            {showIconState === true && renderIcon()}
            {showTooltip === true && (
                <ReactTooltip id={tooltipId.current} place="top-end">
                    <Tooltips>
                        {tooltips!.map((tooltip, index) => (
                            <Tooltip key={index}>
                                {level === 0 && (
                                    <TooltipTitle>
                                        <LocalizedText text={tooltip.title} />
                                    </TooltipTitle>
                                )}
                                {tooltip.messages.map(({ text, severity }) => {
                                    const isCritical = severity === OCR_VALIDATION_SEVERITY.CRITICAL;
                                    return (
                                        <TooltipContent>
                                            <TooltipContentIcon className={isCritical ? 'error' : 'warning'}>
                                                {isCritical ? <MdOutlineError /> : <PiWarningFill />}
                                            </TooltipContentIcon>
                                            <TooltipContentText>
                                                <LocalizedText text={text} />
                                            </TooltipContentText>
                                        </TooltipContent>
                                    );
                                })}
                            </Tooltip>
                        ))}
                    </Tooltips>
                </ReactTooltip>
            )}
        </Container>
    );
};

export default CaseFolderIcon;
