import React, { useState, useEffect, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Container } from './TextEditorInput-styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

type TextEditorInputProps = {
    initialValue: string;
    onChange?: (value: string) => void;
    isDisabled?: boolean;
};

const TextEditorInput = ({ initialValue, onChange, isDisabled = false }: TextEditorInputProps) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isInitialized, setIsInitialized] = useState(false);
    const editorRef = useRef<Editor>(null);

    useEffect(() => {
        if (initialValue != null && (isInitialized === false || isDisabled === true)) {
            const blocksFromHTML = htmlToDraft(initialValue);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
            setIsInitialized(true);
        }
    }, [initialValue, isInitialized]);

    // Function to handle changes in the editor
    const handleEditorChange = (newEditorState: EditorState) => {
        if (typeof onChange === 'function' && isDisabled !== true) {
            onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
            setEditorState(newEditorState);
        }
    };

    const handleEditorFocus = () => {
        // You can optionally do something when the editor gains focus
    };

    const customStyleMap = {
        HIGHLIGHT: {
            background: null, // Set to null for default color
        },
    };

    return (
        <Container isDisabled={isDisabled}>
            <Editor
                ref={editorRef}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                onFocus={handleEditorFocus} // Add this onFocus callback
                editorStyle={{
                    lineHeight: 1.4,
                }}
                customStyleMap={customStyleMap}
                toolbar={{
                    options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'list',
                        'textAlign',
                        'colorPicker',
                        'link',
                        'embedded',
                        'emoji',
                    ],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
                    },
                    fontFamily: {
                        options: [
                            'Roboto',
                            'Arial',
                            'Georgia',
                            'Impact',
                            'Tahoma',
                            'Times New Roman',
                            'Verdana',
                        ],
                        default: 'Roboto',
                    },
                }}
            />
        </Container>
    );
};

export default TextEditorInput;
