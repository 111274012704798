import React, { useState } from 'react';
import {
    Container,
    Section,
    SectionTitle,
    Item,
    ItemLabel,
    ItemValue,
    ItemGroup,
    ItemCta,
    ItemCtaText,
    ItemGroupRemove,
    ItemArray,
    ItemArrayHeader,
    ItemArrayLabel,
    ItemArrayValue,
    ItemText,
} from './OCRFileModalD160-styles';
import { DateInput, InputMode, SelectInput, TextInput } from '../../../../../../../../components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FileOCRResult } from '../../OCRFileModal';
import { getInitialValues, getPayload } from './helpers';

type BusinessOwner = {
    ownerName: string;
    ownerNationality: string;
    ownerImmigrationStatus: string;
    ownerOwnership: number;
};

type UsPersonnel = {
    usPersonnelName: string;
    usPersonnelNationality: string;
    usPersonnelType: string;
    usPersonnelDate: Date | undefined;
    usPersonnelPlaceOfIssuance: string;
};

type ForeignInvestment = {
    foreignInvestmentCash: number;
    foreignInvestmentInventory: number;
    foreignInvestmentEquipment: number;
    foreignInvestmentPremises: number;
    foreignInvestmentOther: number;
    foreignInvestmentTotal: number;
};

type OCRFileModalDS160Props = {
    fileOCRResults: Array<FileOCRResult>;
    updateOCRHandler: (
        results: Array<{
            key: string;
            value: number | string;
            index?: number;
        }>
    ) => Promise<void>;
    renderFooter: (onClick?: ((payload?: any) => void) | undefined) => React.JSX.Element;
};

const OCRFileModalDS160 = ({ fileOCRResults, updateOCRHandler, renderFooter }: OCRFileModalDS160Props) => {
    const [form, setForm] = useState(getInitialValues(fileOCRResults));

    const updateForm = (key: keyof typeof form, value: string | Date | null) => {
        setForm((prev) => ({ ...prev, [key]: value }));
    };

    const updateBusinessOwner = <K extends keyof BusinessOwner>(
        key: K,
        value: BusinessOwner[K],
        index: number
    ) => {
        setForm((prev) => {
            const updatedBusinessOwners = structuredClone(prev.businessOwners);
            updatedBusinessOwners[index][key] = value;
            return {
                ...prev,
                businessOwners: updatedBusinessOwners,
            };
        });
    };

    const addOwner = () => {
        setForm((prev) => ({
            ...prev,
            businessOwners: [
                ...prev.businessOwners,
                {
                    ownerName: '',
                    ownerNationality: '',
                    ownerImmigrationStatus: '',
                    ownerOwnership: 0,
                },
            ],
        }));
    };

    const removeOwner = (index: number) => {
        if (index === 0) {
            return;
        }

        setForm((prev) => ({
            ...prev,
            businessOwners: prev.businessOwners.filter((_, prevIndex) => prevIndex !== index),
        }));
    };

    const updateUsPersonnel = <K extends keyof UsPersonnel>(key: K, value: UsPersonnel[K], index: number) => {
        setForm((prev) => {
            const updatedUsPersonnel = structuredClone(prev.usPersonnel);
            updatedUsPersonnel[index][key] = value;
            return {
                ...prev,
                usPersonnel: updatedUsPersonnel,
            };
        });
    };

    const addUsPersonnel = () => {
        setForm((prev) => ({
            ...prev,
            usPersonnel: [
                ...prev.usPersonnel,
                {
                    usPersonnelName: '',
                    usPersonnelNationality: '',
                    usPersonnelType: '',
                    usPersonnelDate: undefined,
                    usPersonnelPlaceOfIssuance: '',
                },
            ],
        }));
    };

    const removeUsPersonnel = (index: number) => {
        if (index === 0) {
            return;
        }

        setForm((prev) => ({
            ...prev,
            usPersonnel: prev.usPersonnel.filter((_, prevIndex) => prevIndex !== index),
        }));
    };

    const updateStaff = (
        key: 'staffNationals' | 'staffUsCitizens' | 'staffOther' | 'staffTotal',
        value: number,
        index: number
    ) => {
        setForm((prev) => {
            const updatedStaff = structuredClone(prev[key]);
            updatedStaff[index] = value;
            return {
                ...prev,
                [key]: updatedStaff,
            };
        });
    };

    const updateTrade = (
        key:
            | 'tradeImport'
            | 'tradeExport'
            | 'tradeImportThird'
            | 'tradeExportThird'
            | 'tradeDomestic'
            | 'tradeTotal',
        value: number,
        index: number
    ) => {
        setForm((prev) => {
            const updatedTrade = structuredClone(prev[key]);
            updatedTrade[index] = value;
            return {
                ...prev,
                [key]: updatedTrade,
            };
        });
    };

    const updateForeignInvestment = <K extends keyof ForeignInvestment>(
        key: K,
        value: ForeignInvestment[K],
        index: number
    ) => {
        setForm((prev) => {
            const updatedForeignInvestments = structuredClone(prev.foreignInvestmentDetail);
            updatedForeignInvestments[index][key] = value as any;
            return {
                ...prev,
                foreignInvestmentDetail: updatedForeignInvestments,
            };
        });
    };

    const submit = () => {
        const payload = getPayload(form);
        updateOCRHandler(payload);
    };

    return (
        <>
            <Container>
                <Section>
                    <SectionTitle>PART 1 - BUSINESS PROFILE</SectionTitle>
                    <Item>
                        <ItemLabel>1. Name of U.S. Enterprise, Business, or Company</ItemLabel>
                        <ItemValue>
                            <TextInput
                                value={form.companyName}
                                onChange={(e) => updateForm('companyName', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>2. Type of Business Enterprise</ItemLabel>
                        <ItemValue>
                            <SelectInput
                                value={form.businessType}
                                options={[
                                    { label: 'Corporation', value: 'Corporation' },
                                    { label: 'Privately owned', value: 'Privately owned' },
                                    { label: 'Branch/Liaison Office', value: 'Branch/Liaison Office' },
                                    { label: 'Joint venture', value: 'Joint venture' },
                                    { label: 'Partnership', value: 'Partnership' },
                                    { label: 'Subsidiary', value: 'Subsidiary' },
                                    { label: 'Other', value: 'Other' },
                                ]}
                                onChange={(value) => updateForm('businessType', value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        {form.businessType === 'Other' && (
                            <ItemValue>
                                <TextInput
                                    label="Details"
                                    value={form.businessTypeOther}
                                    onChange={(e) => updateForm('businessTypeOther', e.target.value)}
                                    mode={InputMode.FORM}
                                />
                            </ItemValue>
                        )}
                    </Item>
                    <Item>
                        <ItemLabel>
                            3. Headquarters, Subsidiaries and Branch Offices of U.S. Enterprise
                        </ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Address"
                                value={form.headQuarterAddress}
                                onChange={(e) => updateForm('headQuarterAddress', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Phone number"
                                value={form.headQuarterPhoneNumber}
                                onChange={(e) => updateForm('headQuarterPhoneNumber', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="FAX number"
                                value={form.headQuarterFaxNumber}
                                onChange={(e) => updateForm('headQuarterFaxNumber', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>
                            4. Date and Place Business was established or incorporated in the United States
                        </ItemLabel>
                        <ItemValue>
                            <DateInput
                                label="Date"
                                value={form.dateOfEstablishment}
                                onChange={(value) => updateForm('dateOfEstablishment', value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Place"
                                value={form.placeOfEstablishment}
                                onChange={(e) => updateForm('placeOfEstablishment', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>5. What is the nature of business?</ItemLabel>
                        <ItemValue>
                            <SelectInput
                                value={form.businessNature}
                                options={[
                                    { label: 'General Trade', value: 'General Trade' },
                                    { label: 'Imports to U.S.', value: 'Imports to U.S.' },
                                    { label: 'Exports from U.S', value: 'Exports from U.S' },
                                    { label: 'Manufacturing', value: 'Manufacturing' },
                                    { label: 'Retails Sales', value: 'Retails Sales' },
                                    { label: 'Services/Technology', value: 'Services/Technology' },
                                    { label: 'Other', value: 'Other' },
                                ]}
                                onChange={(value) => updateForm('businessNature', value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        {form.businessNature === 'Other' && (
                            <ItemValue>
                                <TextInput
                                    label="Describe"
                                    value={form.businessNatureOther}
                                    onChange={(e) => updateForm('businessNatureOther', e.target.value)}
                                    mode={InputMode.FORM}
                                />
                            </ItemValue>
                        )}
                    </Item>
                    <Item>
                        <ItemLabel>
                            6. Describe fully the services, production, or other activity in No.5 above.
                        </ItemLabel>
                        <ItemValue>
                            <TextInput
                                value={form.servicesDescription}
                                onChange={(e) => updateForm('servicesDescription', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>{`7. Foreign Parent Business (if any)`}</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Name and address"
                                value={form.foreignParentBusinessNameAddress}
                                onChange={(e) =>
                                    updateForm('foreignParentBusinessNameAddress', e.target.value)
                                }
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Phone number"
                                value={form.foreignParentBusinessPhoneNumber}
                                onChange={(e) =>
                                    updateForm('foreignParentBusinessPhoneNumber', e.target.value)
                                }
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="FAX number"
                                value={form.foreignParentBusinessFaxNumber}
                                onChange={(e) => updateForm('foreignParentBusinessFaxNumber', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>
                            {`8. Nationality of Foreign Entity (Corporation, Partnership, ect.) or Foreign Individual Owner of U.S. Business`}
                        </ItemLabel>
                        {form.businessOwners.map((businessOwner, index) => (
                            <ItemGroup key={`business-owners-${index}`}>
                                <ItemGroupRemove isDisabled={index === 0} onClick={() => removeOwner(index)}>
                                    <AiOutlineCloseCircle />
                                </ItemGroupRemove>
                                <ItemValue>
                                    <TextInput
                                        label="Fullname"
                                        value={businessOwner.ownerName}
                                        onChange={(e) =>
                                            updateBusinessOwner('ownerName', e.target.value, index)
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                                <ItemValue>
                                    <TextInput
                                        label="Nationality"
                                        value={businessOwner.ownerNationality}
                                        onChange={(e) =>
                                            updateBusinessOwner('ownerNationality', e.target.value, index)
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                                <ItemValue>
                                    <TextInput
                                        label="Immigration status"
                                        value={businessOwner.ownerImmigrationStatus}
                                        onChange={(e) =>
                                            updateBusinessOwner(
                                                'ownerImmigrationStatus',
                                                e.target.value,
                                                index
                                            )
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                                <ItemValue>
                                    <TextInput
                                        label="Ownership"
                                        value={businessOwner.ownerOwnership}
                                        type="number"
                                        onChange={(e) =>
                                            updateBusinessOwner(
                                                'ownerOwnership',
                                                parseInt(e.target.value),
                                                index
                                            )
                                        }
                                        min={0}
                                        max={100}
                                        trailingIcon="%"
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                            </ItemGroup>
                        ))}
                        <ItemCta>
                            <ItemCtaText onClick={addOwner}>Add owner</ItemCtaText>
                        </ItemCta>
                    </Item>
                    <Item>
                        <ItemLabel>{`9. Financial Statement`}</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Financial statement for year"
                                value={form.financialStatementYear}
                                onChange={(e) => updateForm('financialStatementYear', e.target.value)}
                                mode={InputMode.FORM}
                                type="number"
                            />
                        </ItemValue>
                        <ItemValue>
                            <SelectInput
                                label="Type"
                                value={form.financialStatementYearType}
                                options={[
                                    { label: 'Calendar year', value: 'calendar' },
                                    { label: 'Fiscal year', value: 'fiscal' },
                                ]}
                                onChange={(val) => updateForm('financialStatementYearType', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Total Assets of U.S. Business"
                                type="number"
                                value={form.financialStatementTotalAssets}
                                onChange={(e) => updateForm('financialStatementTotalAssets', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <SelectInput
                                label="Type"
                                options={[
                                    { label: 'Current Cash', value: 'current cash' },
                                    { label: 'Historical cost', value: 'historical cost' },
                                ]}
                                value={form.financialStatementTotalAssetsType}
                                onChange={(val) => updateForm('financialStatementTotalAssetsType', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Total liabilities"
                                value={form.financialStatementTotalLiabilities}
                                type="number"
                                onChange={(e) =>
                                    updateForm('financialStatementTotalLiabilities', e.target.value)
                                }
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Owner's equity"
                                value={form.financialStatementOwnerEquity}
                                type="number"
                                onChange={(e) => updateForm('financialStatementOwnerEquity', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Total Annual Operating Income (Before taxes)"
                                value={form.financialStatementAnnualOperatingIncomeBeforeTaxes}
                                type="number"
                                onChange={(e) =>
                                    updateForm(
                                        'financialStatementAnnualOperatingIncomeBeforeTaxes',
                                        e.target.value
                                    )
                                }
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Total Annual Operating Income (After taxes)"
                                value={form.financialStatementAnnualOperatingIncomeAfterTaxes}
                                type="number"
                                onChange={(e) =>
                                    updateForm(
                                        'financialStatementAnnualOperatingIncomeAfterTaxes',
                                        e.target.value
                                    )
                                }
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>
                            {`10. To measure the amount of International trade with the United States, please complete the following. (For trade in merchandise, exports and imports, refer to shipment and sale of goods across international boundaries. For trade in services and technology, exports and imports, refer to the sale of services by treaty-country nationals to nationals of the United States and other countries.)`}
                        </ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Gross International Trade of the U.S. Enterprise in"
                                value={form.tradeYear}
                                mode={InputMode.FORM}
                                onChange={(e) => updateForm('tradeYear', e.target.value)}
                                type="number"
                            />
                        </ItemValue>
                        <ItemValue>
                            <SelectInput
                                label="Type"
                                value={form.tradeYearType}
                                options={[
                                    { label: 'Calendar year', value: 'calendar' },
                                    { label: 'Fiscal year', value: 'fiscal' },
                                ]}
                                onChange={(val) => updateForm('tradeYearType', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Ending"
                                value={form.tradeYearEnding}
                                mode={InputMode.FORM}
                                onChange={(e) => updateForm('tradeYearEnding', e.target.value)}
                            />
                        </ItemValue>

                        <ItemArray nbColumns={3}>
                            <ItemArrayHeader />
                            <ItemArrayHeader>Dollar value</ItemArrayHeader>
                            <ItemArrayHeader>NO. OF Transactions (optional)</ItemArrayHeader>
                            <ItemArrayHeader>Percent of total trade</ItemArrayHeader>

                            <ItemArrayLabel>Imports from treaty country to U.S. business</ItemArrayLabel>
                            {form.tradeImport.map((item, index) => (
                                <ItemArrayValue key={`tradeImport-${index}`}>
                                    <TextInput
                                        value={item}
                                        onChange={(e) =>
                                            updateTrade('tradeImport', parseInt(e.target.value), index)
                                        }
                                        trailingIcon={index === 0 ? '$' : index === 1 ? '' : '%'}
                                        type="number"
                                    />
                                </ItemArrayValue>
                            ))}

                            <ItemArrayLabel>Exports from U.S. business to treaty country</ItemArrayLabel>
                            {form.tradeExport.map((item, index) => (
                                <ItemArrayValue key={`tradeExport-${index}`}>
                                    <TextInput
                                        value={item}
                                        onChange={(e) =>
                                            updateTrade('tradeExport', parseInt(e.target.value), index)
                                        }
                                        trailingIcon={index === 0 ? '$' : index === 1 ? '' : '%'}
                                        type="number"
                                    />
                                </ItemArrayValue>
                            ))}

                            <ItemArrayLabel>Imports from third countries to U.S. business</ItemArrayLabel>
                            {form.tradeImportThird.map((item, index) => (
                                <ItemArrayValue key={`tradeImportThird-${index}`}>
                                    <TextInput
                                        value={item}
                                        onChange={(e) =>
                                            updateTrade('tradeImportThird', parseInt(e.target.value), index)
                                        }
                                        trailingIcon={index === 0 ? '$' : index === 1 ? '' : '%'}
                                        type="number"
                                    />
                                </ItemArrayValue>
                            ))}

                            <ItemArrayLabel>Exports from U.S. to third countries</ItemArrayLabel>
                            {form.tradeExportThird.map((item, index) => (
                                <ItemArrayValue key={`tradeExportThird-${index}`}>
                                    <TextInput
                                        value={item}
                                        onChange={(e) =>
                                            updateTrade('tradeExportThird', parseInt(e.target.value), index)
                                        }
                                        trailingIcon={index === 0 ? '$' : index === 1 ? '' : '%'}
                                        type="number"
                                    />
                                </ItemArrayValue>
                            ))}

                            <ItemArrayLabel>Domestic U.S. production/manufacturing</ItemArrayLabel>
                            {form.tradeDomestic.map((item, index) => (
                                <ItemArrayValue key={`tradeDomestic-${index}`}>
                                    <TextInput
                                        value={item}
                                        onChange={(e) =>
                                            updateTrade('tradeDomestic', parseInt(e.target.value), index)
                                        }
                                        trailingIcon={index === 0 ? '$' : index === 1 ? '' : '%'}
                                        type="number"
                                    />
                                </ItemArrayValue>
                            ))}

                            <ItemArrayLabel>Total</ItemArrayLabel>
                            {form.tradeTotal.map((item, index) => (
                                <ItemArrayValue key={`tradeTotal-${index}`}>
                                    <TextInput
                                        value={item}
                                        onChange={(e) =>
                                            updateTrade('tradeTotal', parseInt(e.target.value), index)
                                        }
                                        trailingIcon={index === 0 ? '$' : index === 1 ? '' : '%'}
                                        type="number"
                                    />
                                </ItemArrayValue>
                            ))}
                        </ItemArray>
                    </Item>
                    <Item>
                        <ItemLabel>11. Type of Investment</ItemLabel>
                        <ItemValue>
                            <SelectInput
                                value={form.typeOfInvestment}
                                options={[
                                    {
                                        label: 'Creation of a new business',
                                        value: 'Creation of a new business',
                                    },
                                    {
                                        label: 'Purchase of an existing business',
                                        value: 'Purchase of an existing business',
                                    },
                                    {
                                        label: 'Continuation of an existing business',
                                        value: 'Continuation of an existing business',
                                    },
                                ]}
                                onChange={(value) => updateForm('typeOfInvestment', value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>12. Total Investment from Abroad Made in the United States</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="For year"
                                value={form.foreignInvestmentYear}
                                onChange={(e) => updateForm('foreignInvestmentYear', e.target.value)}
                                type="number"
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <SelectInput
                                label="Type"
                                value={form.foreignInvestmentYearType}
                                options={[
                                    { label: 'Calendar year', value: 'calendar' },
                                    { label: 'Fiscal year', value: 'fiscal' },
                                ]}
                                onChange={(val) => updateForm('foreignInvestmentYearType', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>

                        <ItemArray nbColumns={2}>
                            <ItemArrayHeader />
                            <ItemArrayHeader>Initial investment</ItemArrayHeader>
                            <ItemArrayHeader>Total cumulative investment</ItemArrayHeader>

                            <ItemArrayLabel>Cash</ItemArrayLabel>
                            {form.foreignInvestmentDetail.map((detail, index) => (
                                <>
                                    <ItemArrayValue>
                                        <TextInput
                                            type="number"
                                            value={detail.foreignInvestmentCash || 0}
                                            onChange={(e) =>
                                                updateForeignInvestment(
                                                    'foreignInvestmentCash',
                                                    parseInt(e.target.value),
                                                    index
                                                )
                                            }
                                            trailingIcon="$"
                                        />
                                    </ItemArrayValue>
                                </>
                            ))}
                            <ItemArrayLabel>Inventory</ItemArrayLabel>
                            {form.foreignInvestmentDetail.map((detail, index) => (
                                <>
                                    <ItemArrayValue>
                                        <TextInput
                                            type="number"
                                            value={detail.foreignInvestmentInventory || 0}
                                            onChange={(e) =>
                                                updateForeignInvestment(
                                                    'foreignInvestmentInventory',
                                                    parseInt(e.target.value),
                                                    index
                                                )
                                            }
                                            trailingIcon="$"
                                        />
                                    </ItemArrayValue>
                                </>
                            ))}
                            <ItemArrayLabel>Equipment</ItemArrayLabel>
                            {form.foreignInvestmentDetail.map((detail, index) => (
                                <>
                                    <ItemArrayValue>
                                        <TextInput
                                            type="number"
                                            value={detail.foreignInvestmentEquipment || 0}
                                            onChange={(e) =>
                                                updateForeignInvestment(
                                                    'foreignInvestmentEquipment',
                                                    parseInt(e.target.value),
                                                    index
                                                )
                                            }
                                            trailingIcon="$"
                                        />
                                    </ItemArrayValue>
                                </>
                            ))}
                            <ItemArrayLabel>Premises</ItemArrayLabel>
                            {form.foreignInvestmentDetail.map((detail, index) => (
                                <>
                                    <ItemArrayValue>
                                        <TextInput
                                            type="number"
                                            value={detail.foreignInvestmentPremises || 0}
                                            onChange={(e) =>
                                                updateForeignInvestment(
                                                    'foreignInvestmentPremises',
                                                    parseInt(e.target.value),
                                                    index
                                                )
                                            }
                                            trailingIcon="$"
                                        />
                                    </ItemArrayValue>
                                </>
                            ))}
                            <ItemArrayLabel>Other</ItemArrayLabel>
                            {form.foreignInvestmentDetail.map((detail, index) => (
                                <>
                                    <ItemArrayValue>
                                        <TextInput
                                            type="number"
                                            value={detail.foreignInvestmentOther || 0}
                                            onChange={(e) =>
                                                updateForeignInvestment(
                                                    'foreignInvestmentOther',
                                                    parseInt(e.target.value),
                                                    index
                                                )
                                            }
                                            trailingIcon="$"
                                        />
                                    </ItemArrayValue>
                                </>
                            ))}
                            <ItemArrayLabel>Total</ItemArrayLabel>
                            {form.foreignInvestmentDetail.map((detail, index) => (
                                <>
                                    <ItemArrayValue>
                                        <TextInput
                                            type="number"
                                            value={detail.foreignInvestmentTotal || 0}
                                            onChange={(e) =>
                                                updateForeignInvestment(
                                                    'foreignInvestmentTotal',
                                                    parseInt(e.target.value),
                                                    index
                                                )
                                            }
                                            trailingIcon="$"
                                        />
                                    </ItemArrayValue>
                                </>
                            ))}
                        </ItemArray>
                    </Item>
                    <Item>
                        <ItemLabel>
                            13. Source of Investment Capital (personal funds, corporate funds, loans, stocks,
                            debentures, bonds, etc.); Evidence of Possession and Control of Funds in United
                            States
                        </ItemLabel>
                    </Item>
                </Section>

                <Section>
                    <SectionTitle>Part 2 - Staff</SectionTitle>

                    <Item>
                        <ItemLabel>14. Type of Personnel in the United States</ItemLabel>
                        <ItemValue>
                            <SelectInput
                                value={form.staffYearType}
                                options={[
                                    { label: 'Calendar year', value: 'calendar' },
                                    { label: 'Fiscal year', value: 'fiscal' },
                                ]}
                                onChange={(val) => updateForm('staffYearType', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemArray nbColumns={6}>
                            <ItemArrayHeader />
                            <ItemArrayHeader nbColumns={2}>Managerial Executive</ItemArrayHeader>
                            <ItemArrayHeader nbColumns={2}>Specialized Essential</ItemArrayHeader>
                            <ItemArrayHeader nbColumns={2}>All other employees</ItemArrayHeader>
                            <ItemArrayHeader />
                            <ItemArrayHeader>This year</ItemArrayHeader>
                            <ItemArrayHeader>Next year</ItemArrayHeader>
                            <ItemArrayHeader>This year</ItemArrayHeader>
                            <ItemArrayHeader>Next year</ItemArrayHeader>
                            <ItemArrayHeader>This year</ItemArrayHeader>
                            <ItemArrayHeader>Next year</ItemArrayHeader>
                            <>
                                <ItemArrayLabel>
                                    Nationals of Treaty Country on E, H & L Visas:
                                </ItemArrayLabel>
                                {form.staffNationals.map((value, index) => (
                                    <ItemArrayValue key={`staffNations-${index}`}>
                                        <TextInput
                                            value={value}
                                            onChange={(e) =>
                                                updateStaff('staffNationals', parseInt(e.target.value), index)
                                            }
                                            type="number"
                                        />
                                    </ItemArrayValue>
                                ))}
                            </>
                            <>
                                <ItemArrayLabel>U.S. Citizens and legal permanent residents:</ItemArrayLabel>
                                {form.staffUsCitizens.map((value, index) => (
                                    <ItemArrayValue key={`staffUs-${index}`}>
                                        <TextInput
                                            value={value}
                                            onChange={(e) =>
                                                updateStaff(
                                                    'staffUsCitizens',
                                                    parseInt(e.target.value),
                                                    index
                                                )
                                            }
                                            type="number"
                                        />
                                    </ItemArrayValue>
                                ))}
                            </>
                            <>
                                <ItemArrayLabel>Other (Third-Country Nationals):</ItemArrayLabel>
                                {form.staffOther.map((value, index) => (
                                    <ItemArrayValue key={`staffThird-${index}`}>
                                        <TextInput
                                            value={value}
                                            onChange={(e) =>
                                                updateStaff('staffOther', parseInt(e.target.value), index)
                                            }
                                            type="number"
                                        />
                                    </ItemArrayValue>
                                ))}
                            </>
                            <>
                                <ItemArrayLabel>TOTAL:</ItemArrayLabel>
                                {form.staffTotal.map((value, index) => (
                                    <ItemArrayValue key={`staffTotal-${index}`}>
                                        <TextInput
                                            value={value}
                                            onChange={(e) =>
                                                updateStaff('staffTotal', parseInt(e.target.value), index)
                                            }
                                            type="number"
                                        />
                                    </ItemArrayValue>
                                ))}
                            </>
                        </ItemArray>
                    </Item>

                    <Item>
                        <ItemLabel>
                            15. List all personnel of U.S. business holding executive, managerial and/or
                            specialist positions by subsidiary/branch office. If aliens, indicate
                            nonimmmigrant visa status or lawful permanent resident (LPR) status,
                        </ItemLabel>
                        {form.usPersonnel.map((personnel, index) => (
                            <ItemGroup key={`peronnel-${index}`}>
                                <ItemGroupRemove
                                    isDisabled={index === 0}
                                    onClick={() => removeUsPersonnel(index)}
                                >
                                    <AiOutlineCloseCircle />
                                </ItemGroupRemove>
                                <ItemValue>
                                    <TextInput
                                        label="Fullname"
                                        value={personnel.usPersonnelName}
                                        onChange={(e) =>
                                            updateUsPersonnel('usPersonnelName', e.target.value, index)
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                                <ItemValue>
                                    <TextInput
                                        label="Nationality"
                                        value={personnel.usPersonnelNationality}
                                        onChange={(e) =>
                                            updateUsPersonnel('usPersonnelNationality', e.target.value, index)
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                                <ItemValue>
                                    <TextInput
                                        label="Type"
                                        value={personnel.usPersonnelType}
                                        onChange={(e) =>
                                            updateUsPersonnel('usPersonnelType', e.target.value, index)
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                                <ItemValue>
                                    <DateInput
                                        label="Date"
                                        value={personnel.usPersonnelDate}
                                        onChange={(value) =>
                                            updateUsPersonnel('usPersonnelDate', value as Date, index)
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                                <ItemValue>
                                    <TextInput
                                        label="Place of issuance"
                                        value={personnel.usPersonnelPlaceOfIssuance}
                                        onChange={(e) =>
                                            updateUsPersonnel(
                                                'usPersonnelPlaceOfIssuance',
                                                e.target.value,
                                                index
                                            )
                                        }
                                        mode={InputMode.FORM}
                                    />
                                </ItemValue>
                            </ItemGroup>
                        ))}
                        <ItemCta>
                            <ItemCtaText onClick={addUsPersonnel}>Add personnel</ItemCtaText>
                        </ItemCta>
                    </Item>
                </Section>

                <Section>
                    <SectionTitle>Part 3 - Applicant</SectionTitle>
                    <Item>
                        <ItemLabel>16. Name of Applicant</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Family Name"
                                value={form.applicantLastName}
                                onChange={(e) => updateForm('applicantLastName', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="First Name"
                                value={form.applicantFirstName}
                                onChange={(e) => updateForm('applicantFirstName', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Middle Name"
                                value={form.applicantMiddleName}
                                onChange={(e) => updateForm('applicantMiddleName', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>17. Type of Applicant</ItemLabel>
                        <ItemValue>
                            <SelectInput
                                label="Type"
                                value={form.applicantType}
                                options={[
                                    { label: 'Principal owner/investor/trader', value: 'Principal owner' },
                                    { label: 'Manager', value: 'Manager' },
                                    { label: 'Supervisor', value: 'Supervisor' },
                                    { label: 'Executive', value: 'Executive' },
                                    { label: 'Specialist', value: 'Specialist' },
                                    { label: 'Other', value: 'Other' },
                                ]}
                                onChange={(val) => updateForm('applicantType', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        {form.applicantType === 'Other' && (
                            <ItemValue>
                                <TextInput
                                    label="Other"
                                    value={form.applicantTypeOther}
                                    onChange={(e) => updateForm('applicantTypeOther', e.target.value)}
                                    mode={InputMode.FORM}
                                />
                            </ItemValue>
                        )}
                    </Item>
                    <Item>
                        <ItemLabel>18. Present position and duties</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Describe in detail"
                                value={form.applicantPositionAndDuties}
                                onChange={(e) => updateForm('applicantPositionAndDuties', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>19. Name and Address of Employer</ItemLabel>
                        <ItemValue>
                            <TextInput
                                value={form.applicantEmployerNameAnAddress}
                                onChange={(e) => updateForm('applicantEmployerNameAnAddress', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>20. Years with present employer</ItemLabel>
                        <ItemValue>
                            <TextInput
                                value={form.applicantYearWithEmployer}
                                onChange={(e) => updateForm('applicantYearWithEmployer', e.target.value)}
                                mode={InputMode.FORM}
                                type="number"
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>21. Highest level of education</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="School"
                                value={form.applicantEducationSchool}
                                onChange={(e) => updateForm('applicantEducationSchool', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Major/Subject"
                                value={form.applicantEducationMajor}
                                onChange={(e) => updateForm('applicantEducationMajor', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Degree"
                                value={form.applicantDegreeType}
                                onChange={(e) => updateForm('applicantDegreeType', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Year"
                                value={form.applicantDegreeYear}
                                onChange={(e) => updateForm('applicantDegreeYear', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>22. Other relevant experience and education</ItemLabel>
                        <ItemValue>
                            <TextInput
                                value={form.applicantOtherExperience}
                                onChange={(e) => updateForm('applicantOtherExperience', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>23. Position in the United States</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Title"
                                value={form.applicantUsPosition}
                                onChange={(e) => updateForm('applicantUsPosition', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Description of duties"
                                value={form.applicantUsDuties}
                                onChange={(e) => updateForm('applicantUsDuties', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>24. Annual U.S. salary and benefit package</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Salary"
                                value={form.applicantSalary}
                                onChange={(e) => updateForm('applicantSalary', e.target.value)}
                                mode={InputMode.FORM}
                                type="number"
                                trailingIcon="$"
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Allowances/Benefits"
                                value={form.applicantAllowance}
                                onChange={(e) => updateForm('applicantAllowance', e.target.value)}
                                mode={InputMode.FORM}
                                type="number"
                                trailingIcon="$"
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Total"
                                value={form.applicantSalaryTotal}
                                onChange={(e) => updateForm('applicantSalaryTotal', e.target.value)}
                                mode={InputMode.FORM}
                                type="number"
                                trailingIcon="$"
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>25. Name of person in the United States being replaced</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Full name"
                                value={form.replacedPersonName}
                                onChange={(e) => updateForm('replacedPersonName', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Type of visa"
                                value={form.replacedVisa}
                                onChange={(e) => updateForm('replacedVisa', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <DateInput
                                label="Date issued (mm-dd-yyyy)"
                                value={form.replacedDate}
                                onChange={(val) => updateForm('replacedDate', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Place issued"
                                value={form.replacedPlaceIssued}
                                onChange={(e) => updateForm('replacedPlaceIssued', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemText>If NOT a replacement, is this:</ItemText>
                        <ItemValue>
                            <SelectInput
                                label="a. An increase in staff?"
                                value={form.replacedIncrease}
                                options={[
                                    { label: 'Yes', value: '1' },
                                    { label: 'No', value: '0' },
                                ]}
                                onChange={(val) => updateForm('replacedIncrease', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <SelectInput
                                label="b. Continuation of existing employment in the United states?"
                                value={form.replacedContinuation}
                                options={[
                                    { label: 'Yes', value: '1' },
                                    { label: 'No', value: '0' },
                                ]}
                                onChange={(val) => updateForm('replacedContinuation', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>26. Signature</ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Signed by"
                                value={form.signatureName}
                                onChange={(e) => updateForm('signatureName', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <DateInput
                                label="Date"
                                value={form.signatureDate}
                                onChange={(val) => updateForm('signatureDate', val)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                    <Item>
                        <ItemLabel>
                            27. Name and address of person who may me contacted out about this application
                        </ItemLabel>
                        <ItemValue>
                            <TextInput
                                label="Name and address"
                                value={form.contactNameAddress}
                                onChange={(e) => updateForm('contactNameAddress', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                        <ItemValue>
                            <TextInput
                                label="Phone number"
                                value={form.contactPhoneNumber}
                                onChange={(e) => updateForm('contactPhoneNumber', e.target.value)}
                                mode={InputMode.FORM}
                            />
                        </ItemValue>
                    </Item>
                </Section>
            </Container>
            {renderFooter(submit)}
        </>
    );
};

export default OCRFileModalDS160;
