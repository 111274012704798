import React, { useMemo } from 'react';
import {
    Container,
    Remove,
    Info,
    InfoItem,
    AddFile,
    AddFileText,
    FilesList,
    FilesListItem,
    FilesListItemInput,
    FilesListItemRemove,
    FilesListTitle,
} from './CategoryQuoteModalGroup-styles';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { DraggableList, InputMode, SelectInput, TextInput } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { CategoryQuoteGroupAuthorization } from '../../../../../../types';

type Props = {
    title: string;
    authorization: CategoryQuoteGroupAuthorization;
    files: Array<{ label: string; value: string }>;
    index: number;
    total: number;
    handleChangeGroupTitle: (title: string) => void;
    handleChangeGroupAuthorization: (authorization: CategoryQuoteGroupAuthorization) => void;
    handleChangeGroupFile: (file: { label: string; value: string }, fileIndex: number) => void;
    handleRemoveGroup: () => void;
    handleAddFile: () => void;
    handleRemoveFile: (fileIndex: number) => void;
    handleChangeGroupFilesOrder: (files: Array<{ label: string; value: string }>) => void;
    availableFiles: Array<{ label: string; value: string }>;
};

const CategoryQuoteModalGroup = ({
    title,
    authorization,
    files,
    index,
    availableFiles,
    total,
    handleChangeGroupTitle,
    handleChangeGroupAuthorization,
    handleChangeGroupFile,
    handleRemoveGroup,
    handleAddFile,
    handleRemoveFile,
    handleChangeGroupFilesOrder,
}: Props) => {
    const { t } = useTranslation();

    /**
     * Change file handler
     * @param {string} fileId
     * @param {number} fileIndex
     */
    const changeFileHandler = (fileId: string, fileIndex: number) => {
        const file = availableFiles.find((item) => item.value === fileId);
        handleChangeGroupFile(file!, fileIndex);
    };

    const isDisabled = useMemo(() => {
        return index === 0 && total < 2;
    }, [index, total]);

    const renderFile = (file: { value: string; label: string }, fileIndex: number): React.ReactNode => {
        const currentAvailableFiles = [...availableFiles];
        if (file.value.length > 0) {
            currentAvailableFiles.push(file);
        }
        const isFileDisabled = fileIndex === 0 && files.length < 2;

        return (
            <FilesListItem key={file.value}>
                <FilesListItemInput>
                    <SelectInput
                        leadingIcon={
                            <FilesListItemRemove isDisabled={isFileDisabled}>
                                <AiOutlineCloseCircle
                                    onClick={() => {
                                        if (isFileDisabled !== true) {
                                            handleRemoveFile(fileIndex);
                                        }
                                    }}
                                />
                            </FilesListItemRemove>
                        }
                        isSearchable
                        value={file.value}
                        mode={InputMode.FORM}
                        options={currentAvailableFiles.sort((a, b) => a.label.localeCompare(b.label))}
                        onChange={(value) => changeFileHandler(value, fileIndex)}
                    />
                </FilesListItemInput>
            </FilesListItem>
        );
    };

    return (
        <Container>
            <Remove isDisabled={isDisabled}>
                <AiOutlineCloseCircle
                    onClick={() => {
                        if (isDisabled !== true) {
                            handleRemoveGroup();
                        }
                    }}
                />
            </Remove>
            <Info>
                <InfoItem>
                    <TextInput
                        label={t('generic.title')}
                        value={title}
                        mode={InputMode.FORM}
                        onChange={(e) => handleChangeGroupTitle(e.target.value)}
                    />
                </InfoItem>
                <InfoItem>
                    <SelectInput
                        label={t('generic.authorization')}
                        value={authorization}
                        mode={InputMode.FORM}
                        options={Object.values(CategoryQuoteGroupAuthorization).map((authorizationType) => ({
                            label: t(`categories.quotes.authorization.${authorizationType}`),
                            value: authorizationType,
                        }))}
                        onChange={(value) => handleChangeGroupAuthorization(value)}
                    />
                </InfoItem>
            </Info>
            <FilesList>
                <FilesListTitle>{t('generic.files')}</FilesListTitle>
                <DraggableList
                    list={files}
                    renderListItem={renderFile}
                    getItemId={(item) => item.value}
                    onChange={handleChangeGroupFilesOrder}
                />
                {files[files.length - 1].value.length > 0 && (
                    <AddFile>
                        <AddFileText onClick={handleAddFile}>{t('generic.add_files')}</AddFileText>
                    </AddFile>
                )}
            </FilesList>
        </Container>
    );
};

export default CategoryQuoteModalGroup;
