import React, { useContext } from 'react';
import { Container } from './FormFooter-styles';
import { ButtonSize, PrimaryContainedButton, SecondaryOutlinedButton } from '../../Button';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../Form/Form';

type Props = {
    submitLabel: string;
    expand?: boolean;
    secondary?: {
        label?: string;
        onClick: () => void;
    };
    children?: React.ReactNode;
};

const FormFooter = ({ submitLabel, secondary, children, expand = false }: Props) => {
    const { t } = useTranslation();

    const {
        formik: { isSubmitting },
    } = useContext(FormContext);

    return (
        <Container expand={expand}>
            {children != null && children}
            {secondary && isSubmitting === false && (
                <SecondaryOutlinedButton
                    size={ButtonSize.LARGE}
                    expand={expand}
                    disabled={isSubmitting}
                    onClick={secondary.onClick}
                    type="button"
                >
                    {secondary.label ?? t('generic.cancel')}
                </SecondaryOutlinedButton>
            )}

            <PrimaryContainedButton
                size={ButtonSize.LARGE}
                expand={expand}
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
            >
                {submitLabel}
            </PrimaryContainedButton>
        </Container>
    );
};

export default FormFooter;
