/**
 * Format bytes
 * @param bytes
 * @return {string | null }
 */
export const formatBytes = (bytes: number | undefined): string | null => {
    if (bytes == null) {
        return null;
    }

    if (bytes < 1024) {
        return bytes + ' Bytes';
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
};
