import styled from 'styled-components';

export const InputContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const TooltipIcon = styled.div`
    margin-left: ${({ theme }) => theme.box.spacing.sm};
    cursor: pointer;

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
        color: ${({ theme }) => theme.colors.text.tertiary};
    }

    :hover {
        svg {
            color: ${({ theme }) => theme.colors.primary.main};
        }
    }
`;
