import React, { useMemo } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle, PrimaryContainedButton } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useLocale, useLoggedInUser, useToggle } from '../../../hooks';
import { FaRegUser } from 'react-icons/fa6';
import { BsPencil } from 'react-icons/bs';
import { SettingsList } from '../components';
import { SettingsUpdateProfileModal } from '../modals';

const SettingsProfile = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const { user } = useLoggedInUser();
    const [isOpened, openModal, closeModal] = useToggle(false);

    const list = useMemo(() => {
        return [
            {
                title: t('generic.firstName'),
                value: user.firstName,
            },
            {
                title: t('generic.lastName'),
                value: user.lastName,
            },
            {
                title: `${t('generic.phone_number')} 1`,
                value: user.contacts[0]?.phoneNumber ?? '',
            },
            {
                title: `${t('generic.phone_number')} 2`,
                value: user.contacts[1]?.phoneNumber ?? '',
            },
        ];
    }, [locale, user]);

    return (
        <>
            {isOpened && <SettingsUpdateProfileModal onClose={closeModal} />}
            <PageContainer>
                <PageHeader display="row">
                    <PageHeaderTitle>
                        <FaRegUser /> {t('settings.my_profile.title')}
                    </PageHeaderTitle>
                    <PrimaryContainedButton leadingIcon={<BsPencil />} onClick={openModal}>
                        {t('settings.modals.update_profile.title')}
                    </PrimaryContainedButton>
                </PageHeader>
                <SettingsList list={list} />
            </PageContainer>
        </>
    );
};

export default SettingsProfile;
