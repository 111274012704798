import React, { useCallback, useState } from 'react';
import { Dropzone, DropzoneInput, DropzoneMessage } from '../config/styles';
import { Case, CaseFolder } from '../../../../../../types';
import { Modal, ModalFooter, ModalSize } from '../../../../../../components';
import { usePromises } from '../../../../../../hooks';
import { getSupportedFilesByType, supportedFiles } from '../../../../../../config/supported-files';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { BatchUploadFileItem } from '../config/types';
import { FilesList } from './BatchUploadFilesModal-styles';
import { UploadFileItem } from '../config/components';
import { generateId } from '../../../../../../tools';
import { APIService } from '../../../../../../services';

type BatchUploadFilesModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    caseFolders: Array<CaseFolder>;
};

const BatchUploadFilesModal = ({
    onClose,
    refresh,
    currentCase,
    caseFolders,
}: BatchUploadFilesModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, setError, executePromise }] = usePromises();
    const [isUploading, setIsUploading] = useState(false);
    const [fileItems, setFileItems] = useState<Array<BatchUploadFileItem>>([]);
    const [uploadedFileIds, setUploadedFileIds] = useState<Array<string>>([]);
    const [assesUploadResults, setAssessUploadResults] = useState(false);

    const onSubmitHandler = async () => {
        if (fileItems.length === 0) {
            onClose();
            return;
        }

        if (fileItems.some((fileItem) => fileItem.categoryFileId === '')) {
            setError(t('case.documents.modals.upload.error.missing_folder'));
            return;
        }

        setIsUploading(true);
        const currentUploadedFileIds = [...uploadedFileIds];

        const payloads: Array<{
            quoteId: string;
            categoryFileId: string;
            files: Array<{ file: File; id: string }>;
        }> = [];

        for (const { file, id, categoryFileId, quoteId } of fileItems) {
            if (uploadedFileIds.includes(id)) {
                continue;
            }

            const index = payloads.findIndex(
                (payload) => payload.quoteId === quoteId && payload.categoryFileId === categoryFileId
            );
            if (index > -1) {
                payloads[index].files.push({ file: file, id: id });
            } else {
                payloads.push({
                    quoteId: quoteId,
                    categoryFileId: categoryFileId,
                    files: [{ file: file, id: id }],
                });
            }
        }

        for (const payload of payloads) {
            await executePromise(async () => {
                await APIService.cases().uploadFiles(currentCase.id, payload.quoteId, {
                    categoryFileId: payload.categoryFileId,
                    files: payload.files.map(({ file }) => file),
                });
                const fileIds = payload.files.map((file) => file.id);
                currentUploadedFileIds.push(...fileIds);
                setUploadedFileIds((prev) => [...prev, ...fileIds]);
            });
        }

        await executePromise(async () => {
            await refresh();
        });

        if (fileItems.length === currentUploadedFileIds.length) {
            onClose();
        } else {
            setAssessUploadResults(true);
            setIsUploading(false);
        }
    };

    const onDropHandler = useCallback((acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
        setFileItems((prev) => [
            ...prev,
            ...acceptedFiles.map((acceptedFile) => ({
                id: generateId(),
                file: acceptedFile,
                quoteId: '',
                categoryFileId: '',
            })),
        ]);
        if (fileRejections.length > 0) {
            setError(
                t('case.documents.modals.upload.error.unsupported_file_type', {
                    acceptedFileTypes: Object.values(supportedFiles)
                        .map(({ extension }) => extension)
                        .join(', '),
                })
            );
        }
    }, []);

    const onRemoveHandler = (fileId: string) => {
        setFileItems((prev) => prev.filter((prevItem) => prevItem.id !== fileId));
    };

    const onSelectFolderHandler = (fileId: string, quoteId: string, categoryFileId: string) => {
        setFileItems((prev) =>
            prev.map((prevItem) => {
                if (prevItem.id === fileId) {
                    return {
                        ...prevItem,
                        quoteId: quoteId,
                        categoryFileId: categoryFileId,
                    };
                } else {
                    return prevItem;
                }
            })
        );
    };

    const supported = getSupportedFilesByType();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDropHandler,
        accept: supported,
    });

    return (
        <Modal
            onClose={onClose}
            title="generic.upload"
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <FilesList>
                {fileItems.map((fileItem) => (
                    <UploadFileItem
                        key={fileItem.id}
                        fileItem={fileItem}
                        caseFolders={caseFolders}
                        onClickRemove={() => onRemoveHandler(fileItem.id)}
                        onSelectFolder={onSelectFolderHandler}
                        isUploading={isUploading}
                        isUploaded={uploadedFileIds.includes(fileItem.id)}
                        assesUploadResults={assesUploadResults}
                    />
                ))}
            </FilesList>
            {assesUploadResults === false && isUploading === false && (
                <Dropzone {...getRootProps()}>
                    <DropzoneInput {...getInputProps()} />
                    {isDragActive ? (
                        <DropzoneMessage>
                            {t('case.documents.modals.upload.message_drag_active')}
                        </DropzoneMessage>
                    ) : (
                        <DropzoneMessage>
                            {t('case.documents.modals.upload.message_drag_inactive')}
                        </DropzoneMessage>
                    )}
                </Dropzone>
            )}
            <ModalFooter
                confirmationLabel={
                    assesUploadResults === true && uploadedFileIds.length !== fileItems.length
                        ? t('generic.try_again')
                        : t('generic.upload')
                }
                onClick={onSubmitHandler}
                isLoading={isUploading}
                secondary={{
                    onClick: onClose,
                }}
            />
        </Modal>
    );
};

export default BatchUploadFilesModal;
