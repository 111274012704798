import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeStyle = css`
    color: ${({ theme }) => theme.colors.primary.main} !important;
    background: ${({ theme }) => theme.colors.primary.extended_10};
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        margin: auto;
        z-index: 1;
        height: 55%;
        width: 3px;
        background: ${({ theme }) => theme.colors.primary.main};
    }
`;

const subnavActiveStyle = css`
    color: ${({ theme }) => theme.colors.primary.main} !important;
    background: ${({ theme }) => theme.colors.primary.extended_10};

    &:after {
        background: ${({ theme }) => theme.colors.primary.main};
    }

    :hover {
        background: ${({ theme }) => theme.colors.primary.extended_10};
    }
`;

export const Placeholder = styled.div<{ width: number; isDisplayed: boolean; isResizing: boolean }>`
    position: relative;
    width: ${({ width, isDisplayed, theme }) =>
        isDisplayed ? width : theme.config.sidebar.width.collapsed}px;
    height: 100%;

    ${({ isResizing }) => {
        if (isResizing !== true) {
            return {
                transition: `all .3s ease-in`,
            };
        }
    }}

    ${({ theme }) => theme.breakpoints.tablet_landscape} {
        width: ${({ theme }) => theme.config.sidebar.width.collapsed}px;
    }

    ${({ theme }) => theme.breakpoints.mobile} {
        display: none;
    }
`;

export const Backdrop = styled.div<{ isDisplayed: boolean }>`
    position: fixed;
    top: ${({ theme }) => theme.config.topbar.height.default}px;
    height: calc(100vh - ${({ theme }) => theme.config.topbar.height.default}px);
    left: 0;
    width: 100vw;
    z-index: 2;
    display: none;
    background: rgba(0, 0, 0, 0.5);

    ${({ theme }) => theme.breakpoints.tablet_landscape} {
        display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
    }
`;

export const Wrapper = styled.div<{ width: number; isDisplayed: boolean; isResizing: boolean }>`
    position: fixed;
    top: ${({ theme }) => theme.config.topbar.height.default}px;
    left: 0;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1;
    height: calc(100vh - ${({ theme }) => theme.config.topbar.height.default}px);
    width: ${({ width, isDisplayed, theme }) =>
        isDisplayed ? width : theme.config.sidebar.width.collapsed}px;

    ${({ isResizing }) => {
        if (isResizing !== true) {
            return {
                transition: `all .3s ease-in`,
            };
        }
    }}

    ${({ theme }) => theme.breakpoints.tablet_landscape} {
        z-index: 3;
    }

    ${({ theme, isDisplayed }) => {
        return {
            [theme.breakpoints.mobile]: {
                width: '100%',
                height: isDisplayed ? `calc(100vh - ${theme.config.topbar.height.default}px)` : 0,
                overflow: 'hidden',
            },
        };
    }}
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.background.main};
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    min-width: ${({ theme }) => `${theme.config.sidebar.width.collapsed}px`};
`;

export const Toggler = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.colors.surface.main};
    position: absolute;
    right: -1.25rem;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.tertiary};

    &:hover {
        color: ${({ theme }) => theme.colors.surface.main};
        background-color: ${({ theme }) => theme.colors.primary.main};
    }

    ${({ theme }) => theme.breakpoints.mobile} {
        display: none;
    }
`;

export const NavigationList = styled.ul`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
`;

export const NavigationListItemContainer = styled.li<{ isOpened: boolean }>`
    position: relative;
    & > a.active {
        ${({ isOpened, theme }) => {
            if (isOpened === false) {
                return activeStyle;
            } else {
                return {
                    background: theme.colors.background.secondary,
                };
            }
        }}
    }
`;

export const NavigationListItem = styled(NavLink)`
    width: 100%;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.main} !important;
    display: flex;
    align-items: center;
    border-radius: 3px;
    position: relative;

    :hover {
        background: ${({ theme }) => theme.colors.background.secondary};
    }
`;

export const NavigationListItemSubList = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 2.3rem;
    margin: ${({ theme }) => theme.box.spacing.xs} 0;
`;

export const NavigationListItemSubListItem = styled(NavLink)<{ isActive: boolean }>`
    padding: ${({ theme }) => `${theme.box.spacing.sm} ${theme.box.spacing.md}`};
    padding-left: 2.6rem;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.main} !important;
    position: relative;
    min-width: max-content;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        margin: auto;
        z-index: 1;
        height: 100%;
        width: 2px;
        background: ${({ theme }) => theme.colors.onSurface.secondary};
    }

    :hover {
        background: ${({ theme }) => theme.colors.background.secondary};
    }

    &.active {
        ${subnavActiveStyle}
    }

    ${({ isActive }) => {
        if (isActive) {
            return subnavActiveStyle;
        }
    }}
`;

export const NavigationListItemIcon = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    svg {
        font-size: 16px;
    }
`;

export const NavigationListItemLabel = styled.div`
    flex: 1;
    min-width: max-content;
`;

export const NavigationListItemToggle = styled.div`
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${({ theme }) => theme.box.spacing.md};
`;

export const ResizeHandler = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 8px;
    height: 100%;
    cursor: col-resize;
    resize: horizontal;
    opacity: 0;
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        right: -1px;
        margin: auto;
        top: 0;
        width: 3px;
        height: 100%;
        background: ${({ theme }) => theme.colors.primary.main};
    }

    &:hover {
        opacity: 1;
    }
`;
