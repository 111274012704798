import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 ${({ theme }) => theme.box.spacing.md};
`;

export const Counter = styled.div`
    padding: ${({ theme }) => `0 ${theme.box.spacing.md}`};
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 35px;
`;

export const CounterLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
`;

export const CounterValue = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;
