import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CasesPagesContext } from '../CasePagesContext';
import {
    Table,
    TableColumnConfig,
    TableBodyRowAction,
    PrimaryContainedButton,
    PageContainer,
    PageHeader,
} from '../../../../components';
import { useLoggedInUser, usePromises, useTable, useToggle } from '../../../../hooks';
import { CaseChildPageTitle } from '../CasePages-styles';
import { FiUsers } from 'react-icons/fi';
import { AddClientModal, UpdateClientModal, RemoveClientModal } from './modals';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../services';
import { CaseClient } from '../../../../types';

type OpenedModal = null | 'add' | 'update' | 'remove';

const CaseClients = () => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const { currentCase } = useOutletContext<CasesPagesContext>();
    const [openedModal, openModal, closeModal] = useToggle(null as OpenedModal);
    const [selectedClient, setSelectedClient] = useState<null | CaseClient>(null);
    const [promises, [currentClients]] = usePromises(() => APIService.cases().getClientsList(currentCase.id));

    const openModalHandler = (selectedModal: OpenedModal, client?: CaseClient) => {
        openModal(selectedModal);
        if (client != null) {
            setSelectedClient(client);
        }
    };

    const handleRemoveClient = () => {
        currentClients.update(currentClients.data.filter((client) => client.id !== selectedClient!.id));
    };

    const columns: Array<TableColumnConfig<CaseClient>> = [
        {
            id: 'internalId',
            headerTitle: 'clients.internal_id',
            getValue: ({ internalId }) => internalId,
            width: '200px',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'fullName',
            headerTitle: 'generic.fullName',
            getValue: ({ firstName, lastName }) => `${firstName} ${lastName}`,
            width: '200px',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'email',
            headerTitle: 'generic.email',
            getValue: ({ email }) => email,
            width: '1fr',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'contact',
            headerTitle: 'generic.contact',
            getValue: ({ contacts }) =>
                contacts ? contacts.map((contact) => contact.phoneNumber).join(', ') : '',
            width: '1fr',
            isSearchable: true,
        },
        {
            id: 'role',
            headerTitle: 'generic.role',
            getValue: ({ role }) => t(`generic.client_role.${role}`),
            width: '200px',
            isSearchable: true,
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (client, { index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index > 0 && index >= total - 2}
                    actions={[
                        {
                            label: t('case.clients.modals.update.title'),
                            onClick: () => openModalHandler('update', client),
                        },
                        {
                            label: t('case.clients.modals.remove.title'),
                            onClick: () => openModalHandler('remove', client),
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.hasPermissions('employees.update'),
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(
        currentClients.data ?? [],
        columns
    );

    return (
        <>
            {openedModal === 'add' && (
                <AddClientModal
                    onClose={closeModal}
                    currentClients={currentClients.data}
                    currentCase={currentCase}
                    refreshClients={currentClients.refresh}
                />
            )}
            {openedModal === 'update' && (
                <UpdateClientModal
                    onClose={closeModal}
                    selectedClient={selectedClient!}
                    openRemoveClientModal={() => openModalHandler('remove')}
                    currentCase={currentCase}
                    refreshClients={currentClients.refresh}
                />
            )}
            {openedModal === 'remove' && (
                <RemoveClientModal
                    onClose={closeModal}
                    selectedClient={selectedClient!}
                    currentCase={currentCase}
                    handleRemoveClient={handleRemoveClient}
                />
            )}
            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <CaseChildPageTitle>
                        <FiUsers />
                        {t('case.clients.page_title')}
                    </CaseChildPageTitle>
                    <PrimaryContainedButton onClick={() => openModalHandler('add')}>
                        {t('case.clients.modals.add.title')}
                    </PrimaryContainedButton>
                </PageHeader>
                <Table<CaseClient>
                    columns={displayedColumns}
                    data={data}
                    handleSort={handleSort}
                    sortedColumn={sortedColumn}
                    isSortingDown={isSortingDown}
                    handleClickRow={(client) => openModalHandler('update', client)}
                    isLoading={currentClients.isLoading}
                    styles={{
                        table: {
                            minWidth: '800px',
                        },
                    }}
                />
            </PageContainer>
        </>
    );
};

export default CaseClients;
