import React from 'react';
import { TablePlaceHolder, TableEmptyLabel } from './Table-styles';
import { TableStyles } from './Table-types';
import { useTranslation } from 'react-i18next';

type Props = {
    tableStyles: TableStyles;
    width?: number;
};

const TableEmpty = ({ tableStyles, width }: Props) => {
    const { t } = useTranslation();
    return (
        <TablePlaceHolder tableStyles={tableStyles} width={width}>
            <TableEmptyLabel>{t('generic.no_results')}</TableEmptyLabel>
        </TablePlaceHolder>
    );
};

export default TableEmpty;
