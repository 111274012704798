import React from 'react';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../../components';
import { Case, Employee } from '../../../../../../types';
import { APIService } from '../../../../../../services';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../../hooks';

type RemoveEmployeeModalProps = {
    currentCase: Case;
    selectedEmployee: Employee;
    onClose: () => void;
    handleRemoveEmployee: () => void;
};

const RemoveEmployeeModal = ({
    currentCase,
    selectedEmployee,
    onClose,
    handleRemoveEmployee,
}: RemoveEmployeeModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    const removeEmployee = async () => {
        await executePromise(async () => {
            await APIService.cases().removeEmployee(currentCase.id, selectedEmployee.id);
            handleRemoveEmployee();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.access_management.modals.remove.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('case.access_management.modals.remove.message', {
                    employee: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
                    internalId: currentCase.internalId,
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('generic.remove')}
                onClick={removeEmployee}
                secondary={{
                    onClick: onClose,
                }}
                isLoading={isExecutingPromise}
                isDestructive
            />
        </Modal>
    );
};

export default RemoveEmployeeModal;
