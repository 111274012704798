import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: ${({ theme }) => `calc(${theme.config.topbar.height.default} + ${theme.box.spacing.xl})`};
    right: ${({ theme }) => theme.box.spacing.xl};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;
`;

export const Message = styled.div`
    background-color: ${({ theme }) => theme.colors.green.extended_10};
    color: ${({ theme }) => theme.colors.green.main};
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-right: ${({ theme }) => theme.box.spacing.xl};
    margin: ${({ theme }) => theme.box.spacing.md} 0;
    border-radius: 5px;
    max-width: 300px;
    transition: opacity 0.3s ease-in-out;
    font-size: ${({ theme }) => theme.font.size.sm};
    border: 1px solid ${({ theme }) => theme.colors.green.main};
    position: relative;
`;

export const MessageRemove = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: ${({ theme }) => theme.font.size.sm};
    cursor: pointer;
`;
