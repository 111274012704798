import styled from 'styled-components';

export const Container = styled.div<{
    expand?: boolean;
}>`
    width: 100%;
    height: max-content;
    position: relative;

    ${({ expand }) => {
        if (expand === true) {
            return {
                gridColumn: '1/-1',
            };
        }
    }}
`;

export const Error = styled.div`
    position: absolute;
    top: calc(100% + 0.7rem);
    left: 0;
    color: ${({ theme }) => theme.colors.danger.main};
    font-size: ${({ theme }) => theme.font.size.sm};
`;
