import {
    AxiosError,
    AxiosRequestConfig,
    AxiosRequestHeaders,
    AxiosResponseHeaders,
    InternalAxiosRequestConfig,
} from 'axios';
import { Locale } from '../../types';

export interface BaseRequestConfigHeadersOptions {
    useMultiPartFormData?: boolean;
}

export interface BaseRequestConfig {
    BASE_URL: string;
    getAPI_URL: () => string;
    getRequestHeaders: (isAuthenticated: boolean, options?: BaseRequestConfigHeadersOptions) => APIHeader;
    processRequest: <T>(request: object, isAuthenticated: boolean) => Promise<T>;
}

export enum Methods {
    'POST' = 'POST',
    'GET' = 'GET',
    'PUT' = 'PUT',
    'PATCH' = 'PATCH',
    'DELETE' = 'DELETE',
}

export interface APIHeader extends AxiosRequestHeaders {
    'content-type': string;
    authorization: string;
    'NAIA-LOCALE': Locale;
}

export interface APIQueuedRequest {
    request: AxiosRequestConfig;
    [x: string]: any;
}

export interface APIError extends AxiosError {
    response: {
        status: number;
        statusText: string;
        headers: AxiosResponseHeaders;
        config: InternalAxiosRequestConfig<any>;
        data: {
            error: {
                code: number;
            };
        };
    };
}
