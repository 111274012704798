import React, { useMemo, useState } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle, SelectInput } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../hooks';
import { SettingsList } from '../components';
import { FaRegSquareCheck } from 'react-icons/fa6';
import { UserBusiness } from '../../../business';
import { Locale } from '../../../types';
import { useCookies } from 'react-cookie';
import config from '../../../config/main';

const COOKIE_IDENTIFIER = config.cookies.consent.name;

const SettingsPreferences = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [cookies, setCookies] = useCookies([COOKIE_IDENTIFIER]);
    const [hasAcceptedCookies, setHasAcceptedCookies] = useState(cookies[COOKIE_IDENTIFIER] ?? false);

    const updateCookies = (hasAccepted: boolean) => {
        setCookies(COOKIE_IDENTIFIER, hasAccepted, { path: '/' });
        setHasAcceptedCookies(hasAccepted);
    };

    const list = useMemo(() => {
        return [
            {
                title: t('settings.preferences.language.title'),
                value: (
                    <SelectInput
                        value={locale}
                        options={[
                            { label: t('generic.locale.en'), value: Locale.EN },
                            { label: t('generic.locale.fr'), value: Locale.FR },
                        ]}
                        onChange={(value) => UserBusiness.updateLocale(value, true)}
                    />
                ),
            },
            {
                title: t('settings.preferences.cookies.title'),
                subTitle: t('settings.preferences.cookies.subTitle'),
                value: (
                    <SelectInput
                        value={hasAcceptedCookies}
                        options={[
                            { label: t('cookies_consent.accept'), value: true },
                            { label: t('cookies_consent.decline'), value: false },
                        ]}
                        onChange={(value) => updateCookies(value)}
                    />
                ),
            },
        ];
    }, [locale, hasAcceptedCookies]);

    return (
        <PageContainer>
            <PageHeader display="row">
                <PageHeaderTitle>
                    <FaRegSquareCheck /> {t('settings.preferences.title')}
                </PageHeaderTitle>
            </PageHeader>
            <SettingsList list={list} />
        </PageContainer>
    );
};

export default SettingsPreferences;
