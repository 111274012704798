import React from 'react';
import { ServerFormSection, ServerFormSectionType } from '../../../../../../types';
import {
    Container,
    Title,
    Section,
    List,
    AddSection,
    AddSectionLabel,
    RemoveSection,
} from './FormsModalSections-styles';
import { useTranslation } from 'react-i18next';
import { DraggableList, InputMode, SelectInput } from '../../../../../../components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { INITIAL_FORM_SECTION, SelectedSection } from '../../types';
import { useLocale } from '../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../tools';

type FormModalSectionsProps = {
    sections: Array<ServerFormSection>;
    selectedSections: Array<SelectedSection>;
    setSelectedSections: React.Dispatch<React.SetStateAction<SelectedSection[]>>;
};

const FormModalSections = ({ sections, selectedSections, setSelectedSections }: FormModalSectionsProps) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const handleChange = (data: SelectedSection, index: number) => {
        setSelectedSections((prev) =>
            prev.map((prevItem, prevIndex) => (index === prevIndex ? data : prevItem))
        );
    };

    const renderSection = (renderedSection: SelectedSection, index: number) => {
        const groupsList = sections
            .filter((section) => {
                if (
                    section.id !== renderedSection.section.id &&
                    selectedSections.some((selected) => selected.section.id === section.id)
                ) {
                    return false;
                } else {
                    return true;
                }
            })
            .map((section) => ({
                label: `[${section.name}] ${formatLocalizedText(section.title, locale)}`,
                value: section.id,
                data: section,
            }));

        return (
            <Section>
                <SelectInput
                    options={groupsList}
                    value={renderedSection.section.id}
                    onChange={(_, data) => handleChange({ ...renderedSection, section: data! }, index)}
                    mode={InputMode.FORM}
                    leadingIcon={
                        <RemoveSection
                            isDisabled={index === 0 && selectedSections.length === 1}
                            onClick={() => removeSection(index)}
                        >
                            <AiOutlineCloseCircle />
                        </RemoveSection>
                    }
                />
                <SelectInput
                    options={[
                        {
                            label: t('generic.form_sections_type.unique'),
                            value: ServerFormSectionType.UNIQUE,
                        },
                        {
                            label: t('generic.form_sections_type.multiple'),
                            value: ServerFormSectionType.MULTIPLE,
                        },
                    ]}
                    value={renderedSection.sectionType}
                    mode={InputMode.FORM}
                    onChange={(value) => handleChange({ ...renderedSection, sectionType: value }, index)}
                />
            </Section>
        );
    };

    const addSection = () => {
        setSelectedSections((prev) => [...prev, { ...INITIAL_FORM_SECTION }]);
    };

    const removeSection = (index: number) => {
        if (index === 0 && selectedSections.length === 1) {
            return;
        }

        setSelectedSections((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
    };

    return (
        <Container>
            <Title>{t('generic.form_sections_list')}</Title>
            <List>
                <DraggableList
                    list={selectedSections}
                    renderListItem={renderSection}
                    getItemId={(item) => item.section.id}
                    onChange={(orderedGroups) => setSelectedSections(orderedGroups)}
                />

                <AddSection>
                    <AddSectionLabel
                        isDisabled={
                            selectedSections[selectedSections.length - 1].section.id.length === 0 ||
                            selectedSections.length === sections.length
                        }
                        onClick={addSection}
                    >
                        {t('forms.sections.modals.add.title')}
                    </AddSectionLabel>
                </AddSection>
            </List>
        </Container>
    );
};

export default FormModalSections;
