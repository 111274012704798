import React, { ButtonHTMLAttributes, useRef, useState } from 'react';
import Spinner, { SpinnerSize } from '../Spinner';
import {
    ContainerDefault,
    ContainerLink,
    LeadingIcon,
    List,
    ListItem,
    ListToggleIcon,
    ListTogglePlaceholder,
    SpinnerContainer,
} from './Button-styles';
import { useOnClickOutside } from 'usehooks-ts';
import { BiChevronDown } from 'react-icons/bi';

export enum ButtonMode {
    CONTAINED,
    OUTLINED,
}

export enum ButtonSize {
    LARGE,
    MEDIUM,
    SMALL,
}

export enum ButtonAppearance {
    PRIMARY,
    SECONDARY,
    DANGER,
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
    children?: React.ReactNode;
    mode: ButtonMode;
    appearance: ButtonAppearance;
    size?: ButtonSize;
    expand?: boolean;
    isLoading?: boolean;
    leadingIcon?: React.ReactNode;
    path?: string;
    list?: Array<{
        label: string;
        onClick: () => void;
    }>;
    listRightSide?: boolean;
    listHideToggleIcon?: boolean;
};

const Button = ({
    children = '',
    size = ButtonSize.MEDIUM,
    type = 'button',
    isLoading,
    leadingIcon,
    path,
    list,
    listRightSide,
    listHideToggleIcon,
    ...rest
}: ButtonProps) => {
    const containerRef = useRef<HTMLButtonElement>(null);
    const [isListOpened, setIsListOpened] = useState(false);

    const currentProps = {
        ...rest,
        size: size,
        type: type,
        listHideToggleIcon: listHideToggleIcon,
    };

    const className = children === '' ? 'empty' : '';

    const renderContent = () => {
        const isIconOnly = leadingIcon != null && children === '';
        return (
            <>
                {leadingIcon != null && (
                    <LeadingIcon isIconOnly={isIconOnly} className="leading-icon">
                        {leadingIcon}
                    </LeadingIcon>
                )}
                {isLoading === true && (
                    <SpinnerContainer>
                        <Spinner size={SpinnerSize.SMALL} />
                    </SpinnerContainer>
                )}
                {children}
            </>
        );
    };

    useOnClickOutside(containerRef, () => {
        if (list != null && isListOpened === true) {
            setIsListOpened(false);
        }
    });

    if (path != null) {
        return (
            <ContainerLink {...currentProps} to={path} className={className}>
                {renderContent()}
            </ContainerLink>
        );
    }

    const onClickHandler = (callback: () => void) => {
        callback();
        setIsListOpened(false);
    };

    return (
        <ContainerDefault ref={containerRef} className={className} list={list} {...currentProps}>
            {renderContent()}
            {list != null && (
                <>
                    {listHideToggleIcon !== true && (
                        <ListToggleIcon>
                            <BiChevronDown />
                        </ListToggleIcon>
                    )}

                    <ListTogglePlaceholder
                        className="button__placeholder"
                        onClick={() => setIsListOpened((prev) => !prev)}
                    />
                    {isListOpened === true && (
                        <List rightSide={listRightSide}>
                            {list.map((item, index) => (
                                <ListItem key={index} onClick={() => onClickHandler(item.onClick)}>
                                    {item.label}
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            )}
        </ContainerDefault>
    );
};

export default Button;
