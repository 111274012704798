import React, { useMemo } from 'react';
import { Case, CaseFile, CaseFolder } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../../hooks';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    Modal,
} from '../../../../../../components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { APIService } from '../../../../../../services';
import { Extension } from './UpdateFileModal-styles';

type UpdateFileModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFile: CaseFile;
};

const UpdateFileModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
    selectedFile,
}: UpdateFileModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        originalName: Yup.string().required(t('generic.error.required_field')),
    });

    const initialValues = useMemo(() => {
        const text = selectedFile.originalName.split('.');
        const extension = text[text.length - 1];
        return {
            originalName: text.slice(0, -1).join('.'),
            extension: extension,
        };
    }, [selectedFile]);

    const formik = useFormik({
        initialValues: { originalName: initialValues.originalName },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                if (submittedValues.originalName !== initialValues.originalName) {
                    await APIService.cases().updateFile(
                        currentCase.id,
                        currentCaseFolder.id,
                        selectedFile.id,
                        {
                            originalName: `${submittedValues.originalName}.${initialValues.extension}`,
                        }
                    );
                    await refresh();
                }
                onClose();
            });
        },
    });

    return (
        <Modal
            title={t('case.documents.modals.update.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="originalName" expand>
                    <FormGroupLabel>{t('generic.filename')}</FormGroupLabel>
                    <FormTextInput
                        type="text"
                        placeholder={t('generic.filename')}
                        trailingIcon={<Extension>{`.${initialValues.extension}`}</Extension>}
                    />
                </FormGroup>
                <FormFooter
                    submitLabel={t('case.documents.modals.update.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateFileModal;
