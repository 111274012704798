import { initialValues } from './getInitialValues';

/**
 * Get payload
 * @param form
 * @return {Array}
 */
export const getPayload = (
    form: typeof initialValues
): Array<{ key: string; value: number | string; index?: number }> => {
    const payload: Array<{ key: string; value: number | string; index?: number }> = [];

    for (const [key, entry] of Object.entries(form)) {
        if (entry == null) {
            continue;
        }

        if (typeof entry === 'string' || typeof entry === 'number') {
            payload.push({ key: key, value: entry });
            continue;
        }

        if (entry instanceof Date) {
            payload.push({ key: key, value: entry.toISOString() });
        }

        if (Array.isArray(entry)) {
            const firstItem = entry[0];

            if (typeof firstItem === 'number') {
                entry.forEach((item, index) => {
                    payload.push({
                        key: key,
                        value: item as number,
                        index: index,
                    });
                });
            } else {
                entry.forEach((item, index) => {
                    for (let [objectKey, objectEntry] of Object.entries(item)) {
                        if (objectEntry == null) {
                            continue;
                        }

                        if (objectEntry instanceof Date) {
                            objectEntry = objectEntry.toISOString();
                        }

                        payload.push({
                            key: objectKey,
                            value: objectEntry,
                            index: index,
                        });
                    }
                });
            }
        }
    }

    return payload;
};
