import React, { useState } from 'react';
import { useLoggedInUser, usePromises, useToggle } from '../../../hooks';
import { APIService } from '../../../services';

// styles
import { PageContainer, PageHeader, PageHeaderTitle } from '../../../components';
import { RiUserSettingsLine } from 'react-icons/ri';

// custom components
import { EmployeesListActions, EmployeesListTable } from './components';
import { Actions, Employee } from '../../../types';
import {
    CreateEmployeeModal,
    ReadEmployeeModal,
    UpdateEmployeeModal,
    UpdateEmployeePasswordModal,
    UpdateEmployeeOrganizationModal,
    DeleteEmployeeModal,
} from '../modals';
import { useTranslation } from 'react-i18next';

export enum EmployeesListActionsTypes {
    UPDATE_PASSWORD = 'update-password',
    UPDATE_ORGANIZATION = 'update-organization',
}

const EmployeesList = () => {
    const { t } = useTranslation();
    const { hasPermissions } = useLoggedInUser();
    const [searchTerm, setSearchTerm] = useState('');
    const [action, openModal, closeModal] = useToggle<null | Actions | EmployeesListActionsTypes>(null);
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
    const [promises, [employees]] = usePromises(() => APIService.employees().getList());

    return (
        <>
            {action === Actions.READ && (
                <ReadEmployeeModal
                    onClose={closeModal}
                    selectedEmployee={selectedEmployee!}
                    openModal={openModal}
                />
            )}
            {action === Actions.CREATE && (
                <CreateEmployeeModal onClose={closeModal} refreshEmployeesList={employees.refresh} />
            )}
            {action === Actions.UPDATE && (
                <UpdateEmployeeModal
                    onClose={closeModal}
                    refreshEmployeesList={employees.refresh}
                    selectedEmployee={selectedEmployee!}
                />
            )}
            {action === Actions.DELETE && (
                <DeleteEmployeeModal
                    onClose={closeModal}
                    refreshEmployeesList={employees.refresh}
                    selectedEmployee={selectedEmployee!}
                />
            )}
            {action === EmployeesListActionsTypes.UPDATE_ORGANIZATION && (
                <UpdateEmployeeOrganizationModal
                    onClose={closeModal}
                    selectedEmployee={selectedEmployee!}
                    refreshEmployeesList={employees.refresh}
                />
            )}
            {action === EmployeesListActionsTypes.UPDATE_PASSWORD && (
                <UpdateEmployeePasswordModal onClose={closeModal} selectedEmployee={selectedEmployee!} />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader>
                    <PageHeaderTitle>
                        <RiUserSettingsLine />
                        {t(
                            `employees.${
                                hasPermissions(['employees.create', 'employees.update'])
                                    ? 'management'
                                    : 'list'
                            }`
                        )}
                    </PageHeaderTitle>
                    <EmployeesListActions
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        onClickAddEmployee={() => openModal(Actions.CREATE)}
                    />
                </PageHeader>
                <EmployeesListTable
                    employees={employees.data || []}
                    isLoading={employees.isLoading}
                    searchTerm={searchTerm}
                    onClickEmployee={(employee) => {
                        setSelectedEmployee(employee);
                        openModal(Actions.READ);
                    }}
                    onUpdateEmployee={(employee, isUpdatingPassword) => {
                        setSelectedEmployee(employee);
                        openModal(
                            isUpdatingPassword ? EmployeesListActionsTypes.UPDATE_PASSWORD : Actions.UPDATE
                        );
                    }}
                    onUpdateOrganization={(employee) => {
                        setSelectedEmployee(employee);
                        openModal(EmployeesListActionsTypes.UPDATE_ORGANIZATION);
                    }}
                    onClickDeleteEmployee={(employee) => {
                        setSelectedEmployee(employee);
                        openModal(Actions.DELETE);
                    }}
                />
            </PageContainer>
        </>
    );
};

export default EmployeesList;
