import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BackToLogin, Header, Success, SuccessMessage, SuccessEmail } from './ResetPasswordRequest-styles';
import {
    Banner,
    BannerAppearance,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    PrimaryContainedButton,
    PublicPage,
} from '../../components';
import { APIService } from '../../services';
import { FaArrowLeft } from 'react-icons/fa';
import { Routes } from '../../routes';

const ResetPasswordRequest = () => {
    const { t } = useTranslation();
    const [{ error, executePromise, clearError }] = usePromises();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(t('generic.error.invalid_email') as string)
            .required(t('generic.error.required_field') as string),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                await APIService.auth().resetPasswordRequest(submittedValues.email);
                setShowSuccess(true);
            });
        },
    });

    useEffect(() => {
        emailInputRef.current?.focus();
    }, []);

    return (
        <PublicPage>
            <Header>{t('reset_password.request.title')}</Header>
            {error && (
                <Banner appearance={BannerAppearance.ERROR} closeBanner={clearError}>
                    {t('generic.error.unknown')}
                </Banner>
            )}

            {showSuccess !== true ? (
                <Form formik={formik} validationSchema={validationSchema} oneColumn>
                    <FormGroup name="email">
                        <FormGroupLabel>{t('generic.email_address')}</FormGroupLabel>
                        <FormTextInput type="email" placeholder={t('generic.email')} ref={emailInputRef} />
                        <BackToLogin to="/login">{t('login.signin')}</BackToLogin>
                    </FormGroup>
                    <FormFooter submitLabel={t('reset_password.request.title')} expand />
                </Form>
            ) : (
                <Success>
                    <SuccessMessage>{t('reset_password.request.success_message')}</SuccessMessage>
                    <SuccessEmail>{formik.values.email}</SuccessEmail>
                    <PrimaryContainedButton expand leadingIcon={<FaArrowLeft />} path={`/${Routes.LOGIN}`}>
                        {t('reset_password.request.back_to_login')}
                    </PrimaryContainedButton>
                </Success>
            )}
        </PublicPage>
    );
};

export default ResetPasswordRequest;
