import styled, { DefaultTheme } from 'styled-components';
import { EmployeeRole } from '../../types';

const getColors = (role: EmployeeRole, theme: DefaultTheme) => {
    switch (role) {
        case EmployeeRole.ADMIN:
            return {
                backgroundColor: theme.colors.primary.extended_10,
                color: theme.colors.primary.main,
            };
        case EmployeeRole.LAWYER:
            return {
                backgroundColor: theme.colors.green.extended_10,
                color: theme.colors.green.main,
            };
        case EmployeeRole.EMPLOYEE:
            return {
                backgroundColor: theme.colors.yellow.extended_10,
                color: theme.colors.yellow.main,
            };
    }
};

export const Container = styled.div<{ role: EmployeeRole }>`
    width: max-content;
    padding: ${({ theme }) => `${theme.box.spacing.xxs} ${theme.box.spacing.xs}`};
    font-size: ${({ theme }) => theme.font.size.md} !important;
    font-weight: ${({ theme }) => theme.font.weight.normal} !important;
    border-radius: 0.3rem;
    ${({ role, theme }) => getColors(role, theme)}
`;
