import styled from 'styled-components';

export const Container = styled.div`
    margin: ${({ theme }) => theme.box.spacing.sm} 0;
    margin-left: 4rem;
    margin-bottom: 0;
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-right: 12rem;
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    border-radius: 4px;
    display: grid;
    grid-template-columns: 20rem 1fr 10rem;
    grid-gap: ${({ theme }) => theme.box.spacing.md};
`;

export const ClientLabel = styled.div`
    display: flex;
    align-items: center;
`;

export const ClientLabelRemove = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.sm};

    svg {
        font-size: ${({ theme }) => theme.font.size.md};
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
    }
`;

export const ClientLabelText = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const InputContainer = styled.div``;

export const Price = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    padding-right: ${({ theme }) => theme.box.spacing.md};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const AddDependentCta = styled.div`
    grid-column: 2 / 3;
`;

export const AddDependentCtaLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.main};
    text-decoration: underline;
    text-align: end;
    cursor: pointer;
`;

export const DependentLabel = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${({ theme }) => theme.box.spacing.lg};
`;

export const DependentLabelRemove = styled.div`
    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
        margin-right: ${({ theme }) => theme.box.spacing.md};
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const DependentLabelText = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-right: ${({ theme }) => theme.box.spacing.md};
`;

export const DependentPrice = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    text-align: end;
`;
