import styled from 'styled-components';

export const Container = styled.div<{ level: number; headerHeight: number; isDisabled: boolean }>`
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: ${({ headerHeight }) => `${headerHeight}px`};

    ${({ level, theme }) => {
        if (level !== 0) {
            return {
                backgroundColor: theme.colors.surface.main,
                '> .accordion__header__title': {
                    fontWeight: theme.font.weight.normal,
                    fontSize: theme.font.size.md,
                },
                paddingLeft: `calc(${theme.box.spacing.lg} * ${level})`,
            };
        } else {
            return {
                '> .accordion__header__title': {
                    fontWeight: theme.font.weight.bold,
                    fontSize: theme.font.size.lg,
                },
            };
        }
    }}

    ${({ isDisabled }) => {
        if (isDisabled === true) {
            return {
                cursor: 'default',
            };
        }
    }}
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button {
        margin-left: ${({ theme }) => theme.box.spacing.sm};
    }
`;

export const Toggle = styled.div<{ isDisabled: boolean }>`
    font-size: ${({ theme }) => theme.font.size.md};
    transition: all 0.3s ease-in;

    svg {
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    ${({ isDisabled }) => {
        if (isDisabled === true) {
            return {
                opacity: 0,
            };
        }
    }}
`;
