import { ServerForm } from '../../../../../../types';
import { ConditionItem } from '../UpdateFormConditionModal';
import { getEmptyCondition } from './getEmptyCondition';

export const getInitialConditions = (serverForm: ServerForm): Array<ConditionItem> => {
    if (serverForm.conditions.length === 0) {
        return [getEmptyCondition()];
    }

    return serverForm.conditions.map((condition) => {
        let target = condition.target.section.id;
        if (condition.target.question != null) {
            target += `_${condition.target.question.id}`;
        }

        return {
            source: `${condition.source.section.id}_${condition.source.question.id}`,
            requirementType: condition.requirement.requirementType,
            requirementValue: condition.requirement.value,
            actionType: condition.action.actionType,
            target: target,
        };
    });
};
