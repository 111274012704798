import { ResourceKey } from 'i18next';
import { useEffect, useState } from 'react';

export const useNotifications = () => {
    const [visibleNotifications, setVisibleNotifications] = useState<Array<ResourceKey>>([]);

    const addNotification = (message: ResourceKey) => {
        setVisibleNotifications([message, ...visibleNotifications]);
    };

    const removeNotification = (index: number) => {
        const updatedNotifications = [...visibleNotifications];
        updatedNotifications.splice(index, 1);
        setVisibleNotifications(updatedNotifications);
    };

    useEffect(() => {
        if (visibleNotifications.length > 0) {
            const timeout = setTimeout(() => {
                const updatedNotifications = [...visibleNotifications];
                updatedNotifications.pop();
                setVisibleNotifications(updatedNotifications);
            }, 3000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [visibleNotifications]);

    return {
        notifications: visibleNotifications,
        addNotification: addNotification,
        removeNotification: removeNotification,
    };
};
