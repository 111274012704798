import React, { useContext, useMemo } from 'react';
import { useLocale } from '../../../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { formatLocalizedText } from '../../../../../../../../tools';
import { Files, FilesDescription, FilesList, FilesListPlaceholder } from './CaseDocumentsFolderFiles-styles';
import { CaseDocumentsFolderContext, ModalActions } from '../../CaseDocumentsFolder';
import CaseDocumentsFolderFile from '../CaseDocumentsFolderFile/CaseDocumentsFolderFile';
import { CaseFolderManager_L2 } from '../../../../../../../../managers/CaseFolderManager/modules';

type CaseDocumentsFolderFilesProps = {
    grandChildFolder: CaseFolderManager_L2;
    isAllowedToUpload: boolean;
};

const CaseDocumentsFolderFiles = ({ grandChildFolder, isAllowedToUpload }: CaseDocumentsFolderFilesProps) => {
    const locale = useLocale();
    const { t } = useTranslation();
    const { openModalHandler } = useContext(CaseDocumentsFolderContext);

    const description = useMemo(
        () => formatLocalizedText(grandChildFolder.description, locale),
        [grandChildFolder.description]
    );

    return (
        <Files>
            {description !== '' && description !== '<p></p>' && (
                <FilesDescription dangerouslySetInnerHTML={{ __html: description }} />
            )}

            {grandChildFolder.files.length === 0 ? (
                <FilesListPlaceholder
                    isAllowedToUpload={isAllowedToUpload}
                    onClick={(e: any) => {
                        if (isAllowedToUpload !== true) {
                            return;
                        }
                        e.stopPropagation();
                        openModalHandler(ModalActions.UPLOAD_FILE, { folders: [grandChildFolder] });
                    }}
                >
                    {t(
                        isAllowedToUpload
                            ? 'case.documents.file_empty_placeholder'
                            : 'case.documents.file_empty_placeholder_not_allowed'
                    )}
                </FilesListPlaceholder>
            ) : (
                <FilesList>
                    {grandChildFolder.files.map((file) => (
                        <CaseDocumentsFolderFile
                            key={file.id}
                            file={file}
                            grandChildFolder={grandChildFolder}
                        />
                    ))}
                </FilesList>
            )}
        </Files>
    );
};

export default CaseDocumentsFolderFiles;
