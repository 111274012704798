import React, { useContext } from 'react';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';
import ColorInput, { ColorInputProps } from '../../Inputs/ColorInput/ColorInput';

const FormColorInput = (props: Omit<ColorInputProps, 'value' | 'onChange'>) => {
    const { formik } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);

    const onChangeHandler = (value: string) => {
        formik.setFieldValue(name, value);
    };

    return <ColorInput {...props} value={formik.values[name]} onChange={onChangeHandler} />;
};

export default FormColorInput;
