import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    UserAvatar,
    UserAvatarSize,
    FormMultiSelectInput,
} from '../../../../../../components';
import { APIService } from '../../../../../../services';
import { usePromises } from '../../../../../../hooks';
import { Case, Employee } from '../../../../../../types';

type AddEmployeeModalProps = {
    currentCase: Case;
    currentEmployees: Array<Employee>;
    onClose: () => void;
    handleAddEmployees: () => Promise<void>;
};
const AddEmployeeModal = ({
    onClose,
    currentCase,
    currentEmployees,
    handleAddEmployees,
}: AddEmployeeModalProps) => {
    const { t } = useTranslation();
    const [promises, [employees]] = usePromises(() => APIService.employees().getList());

    // validation
    const validationSchema = Yup.object({
        employees: Yup.array().of(Yup.string()),
    });
    const formik = useFormik({
        initialValues: {
            employees: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (submittedValues.employees.length === 0) {
                return onClose();
            }
            await promises.executePromise(async () => {
                await APIService.cases().addEmployees(currentCase.id, submittedValues.employees);
                await handleAddEmployees();
                onClose();
            });
        },
    });

    const employeesList = useMemo(() => {
        if (promises.isInitializing) {
            return [];
        }
        return employees.data
            .filter(
                (employee) =>
                    currentEmployees.some((currentEmployee) => currentEmployee.id === employee.id) === false
            )
            .map((employee) => ({
                value: employee.id,
                label: <UserAvatar {...employee} />,
                data: employee,
            }));
    }, [promises.isInitializing, employees.data]);

    return (
        <Modal
            title={t('case.access_management.modals.add.title')}
            onClose={onClose}
            error={promises.error}
            clearError={promises.clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="employees" expand>
                    <FormGroupLabel>{t('generic.assigned_employees')}</FormGroupLabel>
                    <FormMultiSelectInput<string, Employee>
                        isLoading={promises.isInitializing || employees.isLoading}
                        options={employeesList}
                        renderOptionLabel={(_, employee) => (
                            <UserAvatar {...employee!} size={UserAvatarSize.SMALL} />
                        )}
                        getSearchValue={(employee) =>
                            `${employee.data?.firstName} ${employee.data?.lastName}`
                        }
                        allOption={false}
                    />
                </FormGroup>
                <FormFooter submitLabel={t('case.access_management.modals.add.title')} />
            </Form>
        </Modal>
    );
};

export default AddEmployeeModal;
