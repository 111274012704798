import React from 'react';
import { Client } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../hooks';
import { APIService } from '../../../../services/APIService';
import { Modal, ModalFooter, ModalParagraph } from '../../../../components';

type Props = {
    onClose: () => void;
    refreshClientsList: () => Promise<void>;
    selectedClient: Client;
};

const DeleteClientModal = ({ onClose, refreshClientsList, selectedClient }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.clients().archiveClient(selectedClient.id);
            await refreshClientsList();
            onClose();
        });
    };

    return (
        <Modal title="clients.modal.delete.title" onClose={onClose} error={error} clearError={clearError}>
            <ModalParagraph>
                {t('clients.modal.delete.message', {
                    fullName: `${selectedClient.firstName} ${selectedClient.lastName}`,
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('clients.modal.delete.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteClientModal;
