import styled from 'styled-components';

export const Text = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    line-height: 1.4;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const FilesList = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const FilesListItem = styled.div`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-right: ${({ theme }) => theme.box.spacing.xl};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    position: relative;

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const FilesListItemLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    color: ${({ theme }) => theme.colors.text.main} !important;
    margin-left: ${({ theme }) => theme.box.spacing.md};
`;
