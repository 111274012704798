import React, { useCallback } from 'react';
import {
    Container,
    Dropzone,
    DropzoneContainer,
    DropzoneInput,
    DropzoneMessage,
    UploadedFilesList,
    UploadedFilesListItem,
    UploadedFilesListItemContent,
    UploadedFilesListItemRemove,
} from './FileInput-styles';
import { FileRejection, useDropzone } from 'react-dropzone';
import { getSupportedFilesByType } from '../../../config/supported-files';
import { InputMode } from '../Inputs-types';
import { useTranslation } from 'react-i18next';

import { BiUpload } from 'react-icons/bi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GCSFile } from '../../../types';

export type FileInputProps = {
    value: Array<File | GCSFile> | null;
    onChange: (files: Array<File | GCSFile> | null) => void;
    onError: (message: string) => void;
    supported?: { [key: string]: Array<string> };
    maxFiles?: number;
    mode?: InputMode;
};

const FileInput = ({
    value,
    onChange,
    onError,
    supported = getSupportedFilesByType(),
    mode = InputMode.STAND_ALONE,
    maxFiles,
}: FileInputProps) => {
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
        if (acceptedFiles.length > 0) {
            onChange(acceptedFiles);
        }

        if (fileRejections.length > 0) {
            onError(
                t('generic.error.invalid_file', {
                    acceptedFileTypes: Object.values(supported)
                        .flat()
                        .map((mimetype) => {
                            return `.${mimetype.split('/')[1]}`;
                        })
                        .join(' , '),
                })
            );
        }
    }, []);

    const onRemove = (index: number) => {
        if (maxFiles === 1 && index === 1) {
            onChange(null);
        } else {
            const updatedValue =
                value != null && value.length > 0
                    ? value.filter((_, fileIndex) => fileIndex !== index)
                    : value;
            onChange(updatedValue);
        }
    };

    const onCLick = (file: File | GCSFile) => {
        window.open(file instanceof File ? URL.createObjectURL(file) : file.url, '_blank');
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: supported,
        maxFiles: maxFiles,
    });

    return (
        <>
            {value != null && value.length > 0 && (
                <UploadedFilesList>
                    {value.map((file, index) => (
                        <UploadedFilesListItem key={index} onClick={() => onCLick(file)}>
                            <UploadedFilesListItemRemove
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onRemove(index);
                                }}
                            >
                                <AiOutlineCloseCircle />
                            </UploadedFilesListItemRemove>
                            <UploadedFilesListItemContent>
                                {file instanceof File ? file.name : file.originalName}
                            </UploadedFilesListItemContent>
                        </UploadedFilesListItem>
                    ))}
                </UploadedFilesList>
            )}
            <Container mode={mode}>
                <DropzoneContainer>
                    <Dropzone {...getRootProps()}>
                        <DropzoneInput {...getInputProps()} />
                        <DropzoneMessage>
                            <BiUpload /> Click or Drag and drop here
                        </DropzoneMessage>
                    </Dropzone>
                </DropzoneContainer>
            </Container>
        </>
    );
};

export default FileInput;
