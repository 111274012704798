import React, { useMemo } from 'react';
import { Employee, EmployeeRole } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../hooks';
import { APIService } from '../../../../services/APIService';
import { Modal, ModalFooter, ModalParagraph } from '../../../../components';

type Props = {
    onClose: () => void;
    refreshEmployeesList: () => Promise<void>;
    selectedEmployee: Employee;
};

const DeleteClientModal = ({ onClose, refreshEmployeesList, selectedEmployee }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.employees().archiveEmployee(selectedEmployee.id);
            await refreshEmployeesList();
            onClose();
        });
    };

    const messageKey = useMemo(() => {
        return selectedEmployee.roles.includes(EmployeeRole.LAWYER)
            ? 'employees.modal.delete.message_lawyer'
            : 'employees.modal.delete.message';
    }, [selectedEmployee]);

    return (
        <Modal title="employees.modal.delete.title" onClose={onClose} error={error} clearError={clearError}>
            <ModalParagraph>
                {t(messageKey, {
                    fullName: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('employees.modal.delete.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteClientModal;
