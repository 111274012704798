import styled from 'styled-components';

export const List = styled.div`
    background: ${({ theme }) => theme.colors.onSurface.main};
    padding: ${({ theme }) => `${theme.box.spacing.xl} ${theme.box.spacing.md}`};
    border-radius: 8px;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const AddCondition = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.box.spacing.md};
    padding-right: ${({ theme }) => `calc(5rem + ${theme.box.spacing.md})`};
`;

export const AddConditionLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
    text-decoration: underline;
`;
