import axios, { AxiosRequestConfig } from 'axios';
import { i18n } from '../../translations';
import LocalStorageService from '../LocalStorageService';

// modules
import {
    AuthRequest,
    EmployeeRequest,
    UserRequest,
    CaseRequest,
    ClientRequest,
    OrganizationRequest,
    CategoriesRequest,
    FormsRequest,
} from './modules';
// types
import {
    APIHeader,
    APIQueuedRequest,
    BaseRequestConfigHeadersOptions,
    BaseRequestConfig,
    APIError,
    Methods,
} from './APIService.types';
import { ErrorsCode } from './errors-code';
import { Routes } from '../../routes';

export class APIService {
    private static instance: APIService;
    private API_URL!: string;
    private accessToken: string | null = null;
    private refreshToken: string | null = null;
    private queuedRequests: Array<APIQueuedRequest> = [];
    private isRefreshing = false;
    private hasFailedToRefresh = false;

    // modules
    private auth!: AuthRequest;
    private users!: UserRequest;
    private employees!: EmployeeRequest;
    private cases!: CaseRequest;
    private clients!: ClientRequest;
    private organizations!: OrganizationRequest;
    private categories!: CategoriesRequest;
    private forms!: FormsRequest;

    private static getInstance() {
        return this.instance || (this.instance = new APIService());
    }

    // ********************************************************************************
    // Constructor
    // ********************************************************************************

    private constructor() {
        this.auth = new AuthRequest(this.getRequestConfig('/auth'));
        this.users = new UserRequest(this.getRequestConfig('/users'));
        this.employees = new EmployeeRequest(this.getRequestConfig('/employees'));
        this.cases = new CaseRequest(this.getRequestConfig('/cases'));
        this.clients = new ClientRequest(this.getRequestConfig('/clients'));
        this.organizations = new OrganizationRequest(this.getRequestConfig('/organizations'));
        this.categories = new CategoriesRequest(this.getRequestConfig('/categories'));
        this.forms = new FormsRequest(this.getRequestConfig('/forms'));
    }

    // ********************************************************************************
    // Initialization
    // ********************************************************************************
    /**
     * Initialize API Service
     * @param {string} API_URL
     */
    public static initialize(API_URL: string) {
        const instance = this.getInstance();
        const credentials = LocalStorageService.getCredentials();
        instance.accessToken = credentials.accessToken;
        instance.refreshToken = credentials.refreshToken;
        instance.API_URL = API_URL;
    }

    // ********************************************************************************
    // Auth
    // ********************************************************************************

    public static auth() {
        return this.getInstance().auth;
    }

    public static isAuthenticated() {
        const instance = this.getInstance();
        return instance.accessToken != null && instance.refreshToken != null;
    }

    public static setCredentials(accessToken: string, refreshToken: string) {
        this.getInstance().setCredentials(accessToken, refreshToken);
    }

    public static removeCredentials() {
        this.getInstance().removeCredentials();
    }

    // ********************************************************************************
    // Modules
    // ********************************************************************************

    public static users() {
        return this.getInstance().users;
    }

    public static employees() {
        return this.getInstance().employees;
    }

    public static cases() {
        return this.getInstance().cases;
    }

    public static clients() {
        return this.getInstance().clients;
    }

    public static organizations() {
        return this.getInstance().organizations;
    }

    public static categories() {
        return this.getInstance().categories;
    }

    public static forms() {
        return this.getInstance().forms;
    }

    // ********************************************************************************
    // Credentials helpers
    // ********************************************************************************
    /**
     * Set credentials on the API Service instance
     * @param {string} accessToken
     * @param {string} refreshToken
     */
    private async setCredentials(accessToken: string, refreshToken: string) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        LocalStorageService.saveCredentials(accessToken, refreshToken);
    }

    /**
     * Remove credentials on the API Service instance
     */
    private async removeCredentials() {
        this.accessToken = null;
        this.refreshToken = null;
        LocalStorageService.removeCredentials();
    }

    // ********************************************************************************
    // Helpers
    // ********************************************************************************
    /**
     * Get API URL
     * @returns {string}
     */
    private getAPI_URL(): string {
        return this.API_URL;
    }

    /**
     * Get request headers
     * @param {boolean} [isAuthenticated = false]
     * @param {BaseRequestConfigHeadersOptions} [headerOptions]
     * @returns {APIHeader}
     */
    private getRequestHeaders(
        isAuthenticated: boolean = false,
        headerOptions?: BaseRequestConfigHeadersOptions
    ): APIHeader {
        // Initialize basic headers
        const headers = {
            'content-type':
                headerOptions?.useMultiPartFormData === true ? 'multipart/form-data' : 'application/json',
            'NAIA-LOCALE': i18n.language,
        } as Partial<APIHeader>;

        // Add Authorization when required
        if (isAuthenticated === true) {
            if (this.accessToken == null) {
                throw new Error('Credentials required.');
            }
            headers.authorization = this.accessToken as string;
        }
        return headers as APIHeader;
    }

    /**
     * Get request config
     * @param {string} BASE_URL
     * @returns {BaseRequestConfig}
     */
    private getRequestConfig(BASE_URL: string): BaseRequestConfig {
        return {
            getAPI_URL: this.getAPI_URL.bind(this),
            BASE_URL: BASE_URL,
            getRequestHeaders: this.getRequestHeaders.bind(this),
            processRequest: this.processRequest.bind(this),
        };
    }

    /**
     * Process request
     * @param {AxiosRequestConfig} request
     * @param {boolean} isAuthenticated
     * @returns {Promise<T>}
     */
    private async processRequest<T>(request: AxiosRequestConfig, isAuthenticated: boolean): Promise<T> {
        if (this.API_URL == null) {
            throw new Error('API not initialized');
        }
        if (isAuthenticated === true) {
            if (this.accessToken == null) {
                this.removeCredentials();
                throw new Error('Credentials required');
            }
        }

        // If we are currently refreshing the access token, queue the request
        if (this.isRefreshing === true) {
            return this.queueRequest(request);
        } else {
            // Otherwise, execute the request
            return axios(request)
                .then((response) => response.data)
                .catch((err) => this.handleError(err));
        }
    }

    /**
     * When API Service is refreshing access token, queue request
     * @param {AxiosRequestConfig} request
     * @returns {Promise<T>}
     */
    private async queueRequest<T>(request: AxiosRequestConfig): Promise<T> {
        return new Promise((resolve, reject) => {
            this.queuedRequests.push({
                request: request,
                resolve: resolve,
                reject: reject,
            });
        });
    }

    /**
     * When API Service is done refreshing access token, dequeue request if there are any
     */
    private async dequeueRequest() {
        await Promise.all(
            this.queuedRequests.map(async (item) => {
                const updatedHeaders = this.getRequestHeaders(true, {
                    useMultiPartFormData: item.request.data instanceof FormData,
                });
                item.request.headers = updatedHeaders;
                try {
                    const response = await axios(item.request);
                    item.resolve(response.data);
                } catch (err) {
                    item.reject(err);
                }
            })
        );
        this.queuedRequests = [];
    }

    /**
     * Handle error
     * @param {APIError} error
     * @returns {Promise<T> | null}
     */
    private handleError<T>(error: APIError): Promise<T> | null {
        const errorStatus = error.response.status;
        const errorCode = error.response.data.error.code;

        switch (errorStatus) {
            case 400:
                throw error;
            case 401:
                if (errorCode === ErrorsCode.INVALID_ACCES_TOKEN) {
                    if (this.refreshToken == null || this.hasFailedToRefresh) {
                        // We do not have refresh token or we already failed to refresh
                        this.removeCredentials();
                        throw error;
                    }

                    // We can try to refresh or/and queue the request
                    if (this.isRefreshing === false) {
                        this.isRefreshing = true;
                        this.refreshTokenHandler();
                    }

                    const { method, url, params, data } = error.config!;

                    const request = {
                        method: method,
                        url: url,
                        headers: this.getRequestHeaders(true),
                        params: params,
                        data: data,
                    } as AxiosRequestConfig;

                    return this.queueRequest(request);
                } else {
                    throw error;
                }
            case 404:
                throw error;
            case 500:
                throw error;
            default:
                throw error;
        }
    }

    /**
     * Refresh token handler
     */
    private async refreshTokenHandler() {
        try {
            const response = await axios({
                method: Methods.POST,
                url: `${this.API_URL}/auth/token`,
                headers: this.getRequestHeaders(),
                data: {
                    refreshToken: this.refreshToken,
                },
            });
            this.hasFailedToRefresh = false;
            await this.setCredentials(response.data.tokens.accessToken, this.refreshToken!);
            this.dequeueRequest();
        } catch (err) {
            this.hasFailedToRefresh = true;
            this.removeCredentials();
            await Promise.all(
                this.queuedRequests.map((item) => {
                    item.reject();
                })
            );
            this.queuedRequests = [];
            window.location.replace(`/${Routes.LOGIN}`);
        } finally {
            this.isRefreshing = false;
        }
    }
}
