import React, { useEffect, useMemo, useState } from 'react';
import {
    CategoryQuote,
    CategoryQuoteGroupAuthorization,
    CategoryQuotePayload,
    Locale,
} from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import { FileGroup } from '../components/types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Modal,
    Form,
    FormGroupTitle,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    FormSelectInput,
    SecondaryContainedButton,
    ButtonSize,
    FormFooter,
} from '../../../../../components';
import { CategoryQuoteModalGroups } from '../components';
import { getInitialDependentFileGroups, getInitialFileGroups } from './helpers';

type Props = {
    onClose: () => void;
    refreshQuotesList: () => Promise<void>;
    selectedCategoryQuote: CategoryQuote;
};

const UpdateCategoryQuoteModal = ({ onClose, refreshQuotesList, selectedCategoryQuote }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }, [categoryFiles]] = usePromises(() =>
        APIService.categories().getFilesList()
    );

    const [fileGroups, setFileGroups] = useState<Array<FileGroup>>(
        getInitialFileGroups(selectedCategoryQuote)
    );
    const [dependentFileGroups, setDependentFileGroups] = useState<Array<FileGroup>>(
        getInitialDependentFileGroups(selectedCategoryQuote)
    );

    const validationSchema = Yup.object({
        title_en: Yup.string().required(t('generic.error.required_field')),
        title_fr: Yup.string().notRequired(),
        belongsToClient: Yup.boolean(),
        hasDependents: Yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            title_en: selectedCategoryQuote.title[Locale.EN],
            title_fr: selectedCategoryQuote.title[Locale.FR] ?? '',
            belongsToClient: selectedCategoryQuote.belongsToClient,
            hasDependents: selectedCategoryQuote.dependent != null,
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                {
                    const payload: CategoryQuotePayload = {
                        title: {
                            en: submittedValues.title_en,
                            fr: '',
                        },
                        belongsToClient: submittedValues.belongsToClient,
                        fileGroups: fileGroups.map((fileGroup, index) => ({
                            title: {
                                en: fileGroup.title || `Group #${index + 1}`,
                                fr: '',
                            },
                            authorization: fileGroup.authorization,
                            files: fileGroup.files
                                .filter((file) => file.value.length > 0)
                                .map((file, fileIndex) => ({
                                    id: file.value,
                                    index: fileIndex,
                                })),
                        })),
                    };

                    if (submittedValues.hasDependents === true) {
                        payload.dependentFileGroups = dependentFileGroups.map((fileGroup, index) => ({
                            title: {
                                en: fileGroup.title || `Group #${index + 1}`,
                                fr: '',
                            },
                            authorization: fileGroup.authorization,
                            files: fileGroup.files
                                .filter((file) => file.value.length > 0)
                                .map((file, fileIndex) => ({
                                    id: file.value,
                                    index: fileIndex,
                                })),
                        }));
                    }

                    await APIService.categories().updateQuote(selectedCategoryQuote.id, payload);
                    await refreshQuotesList();
                    onClose();
                }
            });
        },
    });

    useEffect(() => {
        if (formik.values.hasDependents === true && formik.values.belongsToClient === false) {
            formik.setFieldValue('belongsToClient', true);
        }
    }, [formik.values.hasDependents]);

    /**
     * Add group
     */
    const handleAddGroup = () => {
        setFileGroups((prev) => [
            ...prev,
            {
                title: `Group #${prev.length + 1}`,
                authorization: CategoryQuoteGroupAuthorization.NONE,
                files: [{ label: '', value: '' }],
            },
        ]);
    };

    /**
     * Add dependent groups
     */
    const handleAddDependentGroup = () => {
        setDependentFileGroups((prev) => [
            ...prev,
            {
                title: `Group #${prev.length + 1}`,
                authorization: CategoryQuoteGroupAuthorization.NONE,
                files: [{ label: '', value: '' }],
            },
        ]);
    };

    const availableFiles = useMemo(() => {
        if (categoryFiles.data == null) {
            return [];
        }
        return categoryFiles.data
            .filter((categoryFile) => {
                const isSelected = fileGroups.some((fileGroup) =>
                    fileGroup.files.some((file) => file.value === categoryFile.id)
                );
                return isSelected === false;
            })
            .map((categoryFile) => ({
                label: categoryFile.title.en,
                value: categoryFile.id,
            }));
    }, [categoryFiles.data, fileGroups]);

    const availableDependentFiles = useMemo(() => {
        if (categoryFiles.data == null) {
            return [];
        }
        return categoryFiles.data
            .filter((categoryFile) => {
                const isSelected = dependentFileGroups.some((fileGroup) =>
                    fileGroup.files.some((file) => file.value === categoryFile.id)
                );
                return isSelected === false;
            })
            .map((categoryFile) => ({
                label: categoryFile.title.en,
                value: categoryFile.id,
            }));
    }, [categoryFiles.data, dependentFileGroups]);

    return (
        <Modal
            title="categories.quotes.modals.update.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroupTitle>{t('generic.general_info')}</FormGroupTitle>
                <FormGroup name="title_en" expand>
                    <FormGroupLabel>{t('generic.title')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="belongsToClient">
                    <FormGroupLabel>{t('categories.quotes.belongs_to_client')}</FormGroupLabel>
                    <FormSelectInput
                        isDisabled={formik.values.hasDependents === true}
                        options={[
                            { label: t('generic.yes'), value: true },
                            { label: t('generic.no'), value: false },
                        ]}
                    />
                </FormGroup>
                <FormGroup name="hasDependents">
                    <FormGroupLabel>{t('categories.quotes.has_dependents')}</FormGroupLabel>
                    <FormSelectInput
                        options={[
                            { label: t('generic.yes'), value: true },
                            { label: t('generic.no'), value: false },
                        ]}
                    />
                </FormGroup>

                <FormGroupTitle>
                    {t('generic.files_groups')}
                    <SecondaryContainedButton size={ButtonSize.SMALL} onClick={handleAddGroup}>
                        {t('generic.add')}
                    </SecondaryContainedButton>
                </FormGroupTitle>

                <CategoryQuoteModalGroups
                    availableFiles={availableFiles}
                    fileGroups={fileGroups}
                    setFileGroups={setFileGroups}
                />

                {formik.values.hasDependents === true && (
                    <>
                        <FormGroupTitle>
                            {t('generic.files_groups_dependent')}
                            <SecondaryContainedButton
                                size={ButtonSize.SMALL}
                                onClick={handleAddDependentGroup}
                            >
                                {t('generic.add')}
                            </SecondaryContainedButton>
                        </FormGroupTitle>

                        <CategoryQuoteModalGroups
                            availableFiles={availableDependentFiles}
                            fileGroups={dependentFileGroups}
                            setFileGroups={setDependentFileGroups}
                        />
                    </>
                )}

                <FormFooter
                    submitLabel={t('categories.quotes.modals.update.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateCategoryQuoteModal;
