import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, usePromises, useStore } from '../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

// facade
import { AuthenticationBusiness, OrganizationBusiness } from '../../business';

// styles
import {
    Banner,
    BannerAppearance,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormPasswordInput,
    FormTextInput,
    PublicPage,
} from '../../components';
import { ForgotPassword, Header } from './Login-styles';
import { Routes } from '../../routes';

const Login = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const store = useStore();
    const params = useParams();
    const location = useLocation();
    const query = queryString.parse(location.search);

    const emailInputRef = useRef<HTMLInputElement>(null);
    const [{ error, setError, clearError, executePromise }] = usePromises();
    const [showPasswordChangedMessage, setShowPasswordChangedMessage] = useState(false);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(t('generic.error.invalid_email') as string)
            .required(t('generic.error.required_field') as string),
        password: Yup.string().required(t('generic.error.required_field') as string),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const user = await AuthenticationBusiness.login(
                    submittedValues.email,
                    submittedValues.password,
                    dispatch,
                    store
                );
                if (user == null) {
                    setError(true);
                }
            });
        },
    });

    const checkLoginUrl = () => {
        const loginUrl = params?.['*'];
        if (loginUrl != null) {
            const organization = store.organizations.list.find(
                (item) => item.config.loginUrl.toLowerCase() === loginUrl.toLowerCase()
            );
            if (organization != null) {
                OrganizationBusiness.updateOrganization(organization.internalId, dispatch, store);
                return;
            }
        }
    };

    useEffect(() => {
        emailInputRef.current?.focus();
        checkLoginUrl();
        if (query.action != null && query.action === 'passwordChange') {
            setShowPasswordChangedMessage(true);
        }
    }, []);

    return (
        <PublicPage>
            <Header>{t('login.signin')}</Header>
            {!error && showPasswordChangedMessage && (
                <Banner
                    appearance={BannerAppearance.SUCCESS}
                    closeBanner={() => setShowPasswordChangedMessage(false)}
                >
                    {t('login.password_changed')}
                </Banner>
            )}
            {error && (
                <Banner appearance={BannerAppearance.ERROR} closeBanner={clearError}>
                    {t('generic.error.invalid_credentials')}
                </Banner>
            )}
            <Form formik={formik} validationSchema={validationSchema} oneColumn>
                <FormGroup name="email">
                    <FormGroupLabel>{t('generic.email_address')}</FormGroupLabel>
                    <FormTextInput type="email" placeholder={t('generic.email')} ref={emailInputRef} />
                </FormGroup>
                <FormGroup name="password">
                    <FormGroupLabel>{t('generic.password')}</FormGroupLabel>
                    <FormPasswordInput placeholder={t('generic.password')} />

                    <ForgotPassword to={`/${Routes.RESET_PASSWORD_REQUEST}`}>
                        {t('login.forgot_password')}
                    </ForgotPassword>
                </FormGroup>
                <FormFooter submitLabel={t('login.signin')} expand />
            </Form>
        </PublicPage>
    );
};

export default Login;
