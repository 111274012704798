import styled from 'styled-components';

export const LoadingContainer = styled.div`
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ExecuteButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const PreviewContainer = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2e2c2c !important;
    padding: ${({ theme }) => theme.box.spacing.xxl};
    border-radius: 5px;
    cursor: pointer;

    :hover {
        background-color: #383838 !important;
    }
`;

export const Preview = styled.img`
    height: 35rem;
    object-fit: contain;
`;
