import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { FormsListContext } from '../../FormsList';
import { Spinner, SpinnerAppearance, SpinnerSize } from '../../../../../components';
import { Loader, Container } from './FormsListDetails-styles';

import { FormsListDetailsConditions, FormsListDetailsGroups } from './components';

const FormsListDetails = () => {
    const { selectedForm, isLoading, openModalHandler } = useOutletContext<FormsListContext>();

    if (isLoading === true || selectedForm == null) {
        return (
            <Loader>
                <Spinner size={SpinnerSize.MEDIUM} appearance={SpinnerAppearance.PRIMARY} />
            </Loader>
        );
    }

    return (
        <Container>
            <FormsListDetailsGroups selectedForm={selectedForm} />
            <FormsListDetailsConditions selectedForm={selectedForm} openModalHandler={openModalHandler} />
        </Container>
    );
};

export default FormsListDetails;
