import React from 'react';
import {
    PageHeaderActions,
    PageHeaderActionsLeft,
    PageHeaderActionsRight,
    PrimaryContainedAddButton,
    TextInput,
} from '../../../../../components';
import { useTranslation } from 'react-i18next';

type Props = {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    openCreateClientModal: () => void;
};

const ClientsListActions = ({ searchTerm, setSearchTerm, openCreateClientModal }: Props) => {
    const { t } = useTranslation();
    return (
        <PageHeaderActions>
            <PageHeaderActionsLeft>
                <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    name="searchTerm"
                    placeholder={`${t('clients.search_client')}...`}
                />
            </PageHeaderActionsLeft>
            <PageHeaderActionsRight>
                <PrimaryContainedAddButton onClick={() => openCreateClientModal()}>
                    {t('clients.new_client')}
                </PrimaryContainedAddButton>
            </PageHeaderActionsRight>
        </PageHeaderActions>
    );
};

export default ClientsListActions;
