import styled from 'styled-components';

export const Container = styled.div<{
    level: number;
}>`
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    overflow: hidden;

    &.accordion--0:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    }
`;
