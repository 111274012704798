import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 5vh;
`;

export const Modal = styled.div`
    width: 100%;
    max-width: 65rem;
    background-color: ${({ theme }) => theme.colors.surface.main};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    padding: ${({ theme }) => theme.box.spacing.md};
    height: max-content;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;
