import React from 'react';
import { Container, Content, Footer } from './PublicPage-styles';

type PublicPageProps = {
    children: React.ReactNode;
};

const PublicPage = ({ children }: PublicPageProps) => {
    return (
        <Container>
            <Content>{children}</Content>
            <Footer>© 2024 IMMIGRATIO APP - POWERED BY NAIA</Footer>
        </Container>
    );
};

export default PublicPage;
