import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Backdrop from '../Backdrop';
import { CloseIcon, Container, Header, HeaderTitle } from './Modal-styles';
import { MdClose } from 'react-icons/md';
import { ResourceKey } from 'i18next';
import Banner, { BannerAppearance } from '../Banner/Banner';
import Spinner from '../Spinner';

export enum ModalSize {
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
}

type Props = {
    children: React.ReactNode;
    title: ResourceKey | React.ReactNode;
    onClose: () => void;
    size?: ModalSize;
    error?: string | boolean | null;
    errorProps?: Object;
    clearError?: () => void;
    isLoading?: boolean;
};

const Modal = ({
    children,
    title,
    onClose,
    size = ModalSize.MEDIUM,
    error,
    errorProps = {},
    clearError,
    isLoading = false,
}: Props) => {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <Backdrop>
            <Container size={size} isMounted={isMounted}>
                <Header>
                    <HeaderTitle>{typeof title === 'string' ? t(title as ResourceKey) : title}</HeaderTitle>
                    <CloseIcon onClick={onClose}>
                        <MdClose />
                    </CloseIcon>
                </Header>

                {isLoading === true && <Spinner />}

                {error != null && error !== false && (
                    <Banner appearance={BannerAppearance.ERROR} closeBanner={clearError!}>
                        {error === true ? t('generic.error.unknown') : t(error as ResourceKey, errorProps)}
                    </Banner>
                )}
                {isLoading === false && children}
            </Container>
        </Backdrop>
    );
};

export default Modal;
