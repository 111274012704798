import React from 'react';
import { useTranslation } from 'react-i18next';
import { Actions, Client } from '../../../../types';
import { Modal, SecondaryOutlinedButton } from '../../../../components';
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import { MdOutlineMailOutline, MdOutlineContactPage } from 'react-icons/md';
import { FiPhone } from 'react-icons/fi';
import { useLoggedInUser } from '../../../../hooks';
import {
    Buttons,
    CasesList,
    CasesListItem,
    InfoList,
    InfoListItem,
    InfoListItemIcon,
    InfoListItemText,
} from './ReadClientModal-styles';
import { ClientsListActionsTypes } from '../../ClientsList/ClientsList';
import { BiFolder } from 'react-icons/bi';
import { Routes } from '../../../../routes';
import { generateId } from '../../../../tools';

type Props = {
    onClose: () => void;
    selectedClient: Client;
    openModal: (value: Actions | ClientsListActionsTypes) => void;
};

const ReadClientModal = ({ onClose, selectedClient, openModal }: Props) => {
    const { t } = useTranslation();
    const user = useLoggedInUser();

    const infoList: Array<{ icon: React.ReactNode; text: React.ReactNode | string }> = [
        {
            icon: <HiOutlineBuildingLibrary />,
            text: selectedClient.organization.name,
        },
        {
            icon: <MdOutlineContactPage />,
            text: `${t('clients.internal_id')} : ${selectedClient.internalId}`,
        },
        {
            icon: <MdOutlineMailOutline />,
            text: selectedClient.email,
        },
        {
            icon: <FiPhone />,
            text: selectedClient.contacts[0]?.phoneNumber ?? ' - ',
        },
        {
            icon: <BiFolder />,
            text: (
                <CasesList>
                    {selectedClient.cases.map((caseDocument) => (
                        <CasesListItem key={generateId()} to={`/${Routes.CASES}/${caseDocument.id}`}>
                            {caseDocument.internalId}
                        </CasesListItem>
                    ))}
                </CasesList>
            ),
        },
    ];

    return (
        <Modal title={`${selectedClient.firstName} ${selectedClient.lastName}`} onClose={onClose}>
            <InfoList>
                {infoList.map((item, index) => (
                    <InfoListItem key={index}>
                        <InfoListItemIcon>{item.icon}</InfoListItemIcon>
                        <InfoListItemText>{item.text}</InfoListItemText>
                    </InfoListItem>
                ))}
            </InfoList>
            {user.hasPermissions('clients.update') && (
                <Buttons>
                    <SecondaryOutlinedButton expand onClick={() => openModal(Actions.UPDATE)}>
                        {t('clients.modal.update_client.title')}
                    </SecondaryOutlinedButton>
                    <SecondaryOutlinedButton
                        expand
                        onClick={() => openModal(ClientsListActionsTypes.UPDATE_ORGANIZATION)}
                    >
                        {t('clients.modal.update_organization.title')}
                    </SecondaryOutlinedButton>
                    <SecondaryOutlinedButton
                        expand
                        onClick={() => openModal(ClientsListActionsTypes.UPDATE_PASSWORD)}
                    >
                        {t('clients.modal.change_password.title')}
                    </SecondaryOutlinedButton>
                    <SecondaryOutlinedButton expand onClick={() => openModal(Actions.DELETE)}>
                        {t('clients.modal.delete.title')}
                    </SecondaryOutlinedButton>
                </Buttons>
            )}
        </Modal>
    );
};

export default ReadClientModal;
