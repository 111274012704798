import { ServerFormConditionActionType, ServerFormConditionRequirementType } from '../../../../../../types';
import { ConditionItem } from '../UpdateFormConditionModal';

export const getEmptyCondition = (): ConditionItem => {
    return {
        source: '',
        requirementType: '' as ServerFormConditionRequirementType,
        requirementValue: '',
        actionType: '' as ServerFormConditionActionType,
        target: '',
    };
};
