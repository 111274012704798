import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CasesFormsPagesContext } from '../../CaseFormsContext';
import { APIService } from '../../../../../../services';
import { Routes } from '../../../../../../routes';
import {
    AccordionContainer,
    Accordion,
    AccordionHeader,
    AccordionHeaderTitle,
    AccordionBody,
    AccordionHeaderContent,
    SecondaryOutlinedButton,
} from '../../../../../../components';
import { FaChevronLeft } from 'react-icons/fa';
import { Container, GoBack, Header, Content } from './CaseFormDetails-styles';
import { SiGoogleforms } from 'react-icons/si';
import { CaseFormDetailsForm } from './components';
import { useLocale, useLoggedInUser } from '../../../../../../hooks';
import { formatCaseFormTitle } from '../../../../../../tools';
import { BsPencil } from 'react-icons/bs';
import { Actions } from '../../../../../../types';
import { FaPlus } from 'react-icons/fa6';
import { CustomActions } from '../../CaseForms';

const CaseFormsDetails = () => {
    const context = useOutletContext<CasesFormsPagesContext>();
    const user = useLoggedInUser();
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const locale = useLocale();
    const navigate = useNavigate();

    /**
     * Get form handler
     * @return {Promise<void>}
     */
    const getFormHandler = async (formId: string) => {
        if (context.caseForms.some((caseForm) => caseForm.id === formId) !== true) {
            navigate(`/${Routes.CASES}/${context.currentCase.id}/${Routes.CASE_FORMS}`);
            return;
        }
        await context.executePromise(async () => {
            const form = await APIService.cases().getForm(context.currentCase.id, formId);
            context.setCurrentCaseForm(form);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        return () => {
            context.setCurrentCaseForm(null);
        };
    }, []);

    useEffect(() => {
        if (context.isInitializing !== true) {
            if (params.form_id !== context.currentCaseForm?.id) {
                setIsLoading(true);
                getFormHandler(params.form_id as string);
            }
        }
    }, [context.isInitializing, params.form_id]);

    if (isLoading === true || context.currentCaseForm == null) {
        return <div></div>;
    }

    return (
        <>
            <Container>
                <AccordionContainer triggerUpdate={context.currentCaseForm}>
                    <Accordion level={0} isToggled={true} isDisabled>
                        <AccordionHeader>
                            <AccordionHeaderTitle>
                                <GoBack
                                    to={`/${Routes.CASES}/${context.currentCase.id}/${Routes.CASE_FORMS}`}
                                >
                                    <FaChevronLeft />
                                </GoBack>
                                <Header>
                                    <SiGoogleforms />
                                    {formatCaseFormTitle(context.currentCaseForm, locale)}
                                </Header>
                            </AccordionHeaderTitle>
                            {user.isEmployee() && (
                                <>
                                    <AccordionHeaderContent>
                                        <SecondaryOutlinedButton
                                            leadingIcon={<FaPlus />}
                                            onClick={() => context.openModal(CustomActions.ADD_SECTIONS)}
                                        />
                                        <SecondaryOutlinedButton
                                            leadingIcon={<BsPencil />}
                                            onClick={() => context.openModal(Actions.UPDATE)}
                                        />
                                    </AccordionHeaderContent>
                                </>
                            )}
                        </AccordionHeader>
                        <AccordionBody>
                            <Content>
                                <CaseFormDetailsForm
                                    currentCase={context.currentCase}
                                    currentCaseForm={context.currentCaseForm}
                                    setCurrentCaseForm={context.setCurrentCaseForm}
                                    openModal={context.openModal}
                                />
                            </Content>
                        </AccordionBody>
                    </Accordion>
                </AccordionContainer>
            </Container>

            <Outlet context={context} />
        </>
    );
};

export default CaseFormsDetails;
