import React from 'react';
import { Container, Message, MessageRemove } from './Notification-styles';
import { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

type NotificationProps = {
    notifications: Array<ResourceKey>;
    removeNotification: (index: number) => void;
};

const Notification = ({ notifications, removeNotification }: NotificationProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            {notifications.map((notification, index) => (
                <Message key={index}>
                    <MessageRemove onClick={() => removeNotification(index)}>
                        <AiOutlineClose />
                    </MessageRemove>
                    {t(notification)}
                </Message>
            ))}
        </Container>
    );
};

export default Notification;
