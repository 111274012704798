import React from 'react';
import { OutletContainer, PageContainer, PageHeader, PageHeaderTitle } from '../../components';
import { Outlet } from 'react-router-dom';
import { Container } from './SettingsPages-styles';
import { SettingsSidebar } from './components';
import { useTranslation } from 'react-i18next';
import { HiOutlineCog } from 'react-icons/hi';

const SettingsPages = () => {
    const { t } = useTranslation();

    return (
        <OutletContainer useBackgroundColor>
            <PageContainer>
                <PageHeader>
                    <PageHeaderTitle>
                        <HiOutlineCog />
                        {t('settings.page_title')}
                    </PageHeaderTitle>
                </PageHeader>
                <Container>
                    <SettingsSidebar />
                    <Outlet />
                </Container>
            </PageContainer>
        </OutletContainer>
    );
};

export default SettingsPages;
