import styled, { css } from 'styled-components';
import { ButtonAppearance, ButtonMode, ButtonSize, ButtonProps } from './Button';
import { NavLink } from 'react-router-dom';

const buttonStyles = css<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: ${({ expand }) => (expand ? '100%' : 'max-content')};
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    padding-right: ${({ theme, list, listHideToggleIcon }) =>
        list != null && listHideToggleIcon !== true ? 0 : theme.box.spacing.md};
    background: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.text.onPrimary};
    border-radius: 0.3rem;
    cursor: pointer;
    user-select: none;
    font-size: ${({ theme }) => theme.font.size.sm};
    box-shadow: ${({ theme }) => theme.box.shadow.dark};
    border: 1px solid transparent;

    &.empty {
        .lead-icon {
            margin-right: 0;
        }
    }

    ${({ disabled, theme }) => {
        if (disabled === true) {
            return {
                background: theme.colors.background.secondary,
                cursor: 'not-allowed',
            };
        }
    }}

    ${({ theme, mode, appearance }) => {
        if (appearance === ButtonAppearance.PRIMARY && mode === ButtonMode.CONTAINED) {
            return {
                background: theme.colors.primary.main,
                color: theme.colors.text.onPrimary,
                ':hover': {
                    background: theme.colors.primary.extended_50,
                },
            };
        }

        if (appearance === ButtonAppearance.PRIMARY && mode === ButtonMode.OUTLINED) {
            return {
                background: theme.colors.surface.main,
                color: theme.colors.primary.main,
                borderColor: theme.colors.primary.main,
            };
        }

        if (appearance === ButtonAppearance.SECONDARY && mode === ButtonMode.CONTAINED) {
            return {
                background: theme.colors.background.main,
                color: theme.colors.text.secondary,
            };
        }

        if (appearance === ButtonAppearance.SECONDARY && mode === ButtonMode.OUTLINED) {
            return {
                background: theme.colors.surface.main,
                color: theme.colors.text.secondary,
                borderColor: theme.colors.text.tertiary,
                boxShadow: theme.box.shadow.light,
                ':hover': {
                    background: theme.colors.background.secondary,
                },
                svg: {
                    color: theme.colors.text.secondary + ' !important',
                    fill: theme.colors.text.secondary + ' !important',
                },
            };
        }

        if (appearance === ButtonAppearance.DANGER && mode === ButtonMode.CONTAINED) {
            return {
                background: theme.colors.danger.main,
                color: theme.colors.text.onPrimary,
            };
        }
    }}


    ${({ size, theme }) => {
        if (size === ButtonSize.LARGE) {
            return {
                height: '4rem',
                fontSize: theme.font.size.md,
                borderRadius: '0.8rem',
            };
        }

        if (size === ButtonSize.SMALL) {
            return {
                height: '2.5rem',
                fontSize: theme.font.size.md,
                borderRadius: '0.2rem',

                svg: {
                    transform: 'translateY(-1.5px)',
                },
            };
        }
    }}
`;

export const ContainerDefault = styled.button`
    ${buttonStyles};
    position: relative;
`;

export const ContainerLink = styled(NavLink)`
    ${buttonStyles};
`;

export const LeadingIcon = styled.div<{ isIconOnly: boolean }>`
    width: ${({ theme }) => theme.font.size.sm};
    height: ${({ theme }) => theme.font.size.sm};
    margin-right: ${({ theme, isIconOnly }) => (isIconOnly ? 0 : theme.box.spacing.xs)};

    svg {
        color: ${({ theme }) => theme.colors.text.onPrimary};
        fill: ${({ theme }) => theme.colors.text.onPrimary};
    }
`;

export const SpinnerContainer = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.md};
`;

export const ListTogglePlaceholder = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
`;

export const ListToggleIcon = styled.div`
    width: 3rem;
    display: flex;
    justify-content: center;

    svg {
        font-size: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const List = styled.ul<{ rightSide?: boolean }>`
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    width: max-content;
    min-width: 100%;
    z-index: 1;
    background: ${({ theme }) => theme.colors.surface.main};
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    text-align: left;

    ${({ rightSide }) => {
        if (rightSide === true) {
            return {
                left: 'unset',
                right: 0,
            };
        }
    }}
`;

export const ListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.md};
    padding: ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.text.main};
    cursor: pointer;
    :hover {
        background: ${({ theme }) => theme.colors.surface.secondary};
    }
`;
