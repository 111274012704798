import React from 'react';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../../components';
import { Case, CaseClient } from '../../../../../../types/cases';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../../services';
import { usePromises } from '../../../../../../hooks';

type Props = {
    onClose: () => void;
    currentCase: Case;
    selectedClient: CaseClient;
    handleRemoveClient: () => void;
};

const RemoveClientModal = ({ onClose, selectedClient, currentCase, handleRemoveClient }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    const removeClient = async () => {
        await executePromise(async () => {
            await APIService.cases().removeClient(currentCase.id, selectedClient.id);
            handleRemoveClient();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.clients.modals.remove.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('case.clients.modals.remove.message', {
                    client: `${selectedClient.firstName}`,
                    internalId: currentCase.internalId,
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('generic.remove')}
                onClick={removeClient}
                secondary={{
                    onClick: onClose,
                }}
                isLoading={isExecutingPromise}
                isDestructive
            />
        </Modal>
    );
};

export default RemoveClientModal;
