import styled from 'styled-components';

export const Container = styled.div<{ grid: string; isLeftSide: boolean }>`
    ${({ isLeftSide, grid, theme }) => {
        if (isLeftSide === true) {
            return {
                flex: 1,
                gap: theme.box.spacing.md,
                display: 'grid',
                gridTemplateColumns: grid,
            };
        }
    }}
`;
