import React, { useEffect, useState } from 'react';
import {
    Back,
    BackIcon,
    BackLabel,
    Container,
    FormContainer,
    FormContent,
    FormContentClose,
} from './CaseFormsFillOut-styles';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { CasesFormsPagesContext } from '../../CaseFormsContext';
import { usePromises } from '../../../../../../hooks';
import { DynamicForm, DynamicFormViewMode } from '../../../../../../components';
import { APIService } from '../../../../../../services';
import { Routes } from '../../../../../../routes';
import { CustomActions } from '../../CaseForms';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const CaseFormsFillOut = () => {
    const { t } = useTranslation();
    const [isDirty, setIsDirty] = useState(false);
    const { currentCase, currentCaseForm, openModal, refreshCurrentCaseForm } =
        useOutletContext<CasesFormsPagesContext>();
    const [{ executePromise }] = usePromises();
    const navigate = useNavigate();

    useEffect(() => {
        // Disable scrolling
        // Remove body scrollbar
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';

        // Cleanup to re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
        };
    }, []);

    const onSubmit = async (
        answers: Array<{
            sectionId: string;
            sectionInternalId: string;
            questions: Array<{ questionId: string; value: string }>;
        }>
    ) => {
        await executePromise(async () => {
            await APIService.cases().updateFormAnswers(currentCase.id, currentCaseForm!.id, {
                answers: answers,
            });
            await refreshCurrentCaseForm();
            navigate(`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}/${currentCaseForm!.id}`);
        });
    };

    const onCloseForm = () => {
        if (isDirty === true) {
            openModal(CustomActions.UNSAVED_CHANGES);
        } else {
            navigate(`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}/${currentCaseForm!.id}`);
        }
    };

    return (
        <Container>
            <Back onClick={onCloseForm}>
                <BackIcon>
                    <FaArrowLeft />
                </BackIcon>
                <BackLabel>{t('generic.back')}</BackLabel>
            </Back>
            <FormContainer>
                <FormContent>
                    <FormContentClose onClick={onCloseForm}>
                        <MdClose />
                    </FormContentClose>
                    <DynamicForm
                        viewMode={DynamicFormViewMode.FILL}
                        form={currentCaseForm!.form}
                        onSubmit={onSubmit}
                        onTouchForm={() => setIsDirty(true)}
                        onClose={onCloseForm}
                    />
                </FormContent>
            </FormContainer>
        </Container>
    );
};

export default CaseFormsFillOut;
