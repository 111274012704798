import config from '../config/main';
import { EmployeeRole } from '../types';

export class PermissionsBusiness {
    /**
     * Check one or multiple permissions for a given roles
     * - With one permission as string:
     *      - ex: checkPermissions(role, 'employees.create')
     *      - Returns true if one of the role has the permission employees.create
     * - With multiple permissions as string:
     *      - ex: checkPermissions(role, 'employees.create', 'employees.update')
     *      - Returns true if one of the role has the permission employees.create AND employees.update
     * - With one set of permission as array of string:
     *      - ex: checkPermissions(role, ['employees.create', 'employees.update'])
     *      - Returns true if one of the role has the permission employees.create OR the employees.update
     * @param {Array<EmployeeRole>} roles
     * @param {Array<string | Array<string>>} requiredPermissions
     * @return {Boolean}
     */
    public static checkPermissions(
        roles: Array<EmployeeRole>,
        ...requiredPermissions: Array<string | Array<string>>
    ): boolean {
        let hasPermissions = true;

        for (const permissions of requiredPermissions) {
            if (hasPermissions === false) {
                break;
            }

            if (typeof permissions === 'string') {
                hasPermissions = this.checkPermission(roles, permissions);
            } else if (Array.isArray(permissions)) {
                hasPermissions =
                    permissions.filter((permission) => this.checkPermission(roles, permission)).length > 0;
            } else {
                hasPermissions = false;
            }
        }

        return hasPermissions;
    }

    // ****************************************************************************************************************************
    // Helpers
    // ****************************************************************************************************************************

    /**
     * Validate a single permission for given roles
     * @param {Array<EmployeeRole>} roles
     * @param  {string} requiredPermission
     * @return {Boolean} - Returns true if permission is valid and at least one of the role has the permission
     */
    private static checkPermission(roles: Array<EmployeeRole>, requiredPermission: string): boolean {
        // Get regex
        const permissionRegex = this.getPermissionRegex(requiredPermission);

        // test permission
        let hasPermission = false;
        for (const role of roles) {
            const matchingPermissions = config.permissions[role].filter((permission) =>
                permissionRegex.test(permission)
            );

            if (matchingPermissions.length > 0) {
                hasPermission = true;
                break;
            }
        }

        return hasPermission;
    }

    /**
     * Crete permission regex
     * @param requiredPermission
     * @return {RegExp} - example: ^((employees\\.((create)|\\*))|\\*)$ will match 'employees.create' and 'employees.*'
     */
    private static getPermissionRegex = (requiredPermission: string): RegExp => {
        let permissionRegexString = '';
        const splittedRequiredPermission = requiredPermission.split('.');

        // create a regex string to test permission. Ex: if the permission to check is employee.create, employee.* should match
        splittedRequiredPermission.reverse().forEach((part, index) => {
            if (index === 0) {
                permissionRegexString = '((' + part + ')|\\*';
            } else {
                permissionRegexString = '(' + part + '\\.' + permissionRegexString + ')|\\*)';
            }
        });

        // complete regex string
        permissionRegexString = '^' + permissionRegexString + '$';

        return new RegExp(permissionRegexString, 's');
    };
}
