import React, { useContext, useMemo } from 'react';
import { InputMode } from '../../Inputs';
import SelectInput, { SelectInputProps } from '../../Inputs/SelectInput';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';

const FormSelectInput = <T, U>(props: Omit<SelectInputProps<T, U>, 'value' | 'onChange'>) => {
    const { formik } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);

    const onChangeHandler = (value: T) => {
        formik.setFieldValue(name, value);
    };

    const value = useMemo(() => {
        let result = formik.values;
        const keys = name.split('.');

        for (const key of keys) {
            if (result[key] != null) {
                result = result[key];
            }
        }
        return typeof result === 'string' ? result : '';
    }, [formik.values, name]);

    return (
        <SelectInput<T, U> {...props} value={value as T} onChange={onChangeHandler} mode={InputMode.FORM} />
    );
};

export default FormSelectInput;
