import React, { useContext, useMemo } from 'react';
import { Container, Counter, CounterLabel, CounterValue } from './CaseDocumentsFolderCounters-styles';
import { CaseDocumentsFolderContext } from '../../CaseDocumentsFolder';
import { useTranslation } from 'react-i18next';

const CaseDocumentsFolderCounters = () => {
    const { t } = useTranslation();
    const { caseFolder, multipleSelectMode } = useContext(CaseDocumentsFolderContext);

    const { uploadedCount, validatedCount, totalCount } = useMemo(() => {
        return {
            uploadedCount: caseFolder.getUploadedFileCount(),
            validatedCount: caseFolder.getValidatedFileCount(),
            totalCount: caseFolder.getTotalFileCount(),
        };
    }, [caseFolder]);

    if (multipleSelectMode != null) {
        return <></>;
    }

    return (
        <Container>
            <Counter>
                <CounterLabel>{t('generic.uploaded')}:&nbsp;</CounterLabel>
                <CounterValue>{`${uploadedCount}/${totalCount}`}</CounterValue>
            </Counter>
            <Counter>
                <CounterLabel>{t('generic.validated')}:&nbsp;</CounterLabel>
                <CounterValue>{`${validatedCount}/${totalCount}`}</CounterValue>
            </Counter>
        </Container>
    );
};

export default CaseDocumentsFolderCounters;
