import React, { useMemo } from 'react';
import { Client } from '../../../../types';
import { Form, FormGroup, FormSelectInput, FormGroupLabel, Modal, FormFooter } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { usePromises, useStore } from '../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { APIService } from '../../../../services';

type Props = {
    onClose: () => void;
    refreshClientsList: () => Promise<void>;
    selectedClient: Client;
};

const UpdateClientOrganizationModal = ({ onClose, refreshClientsList, selectedClient }: Props) => {
    const { t } = useTranslation();
    const { list } = useStore().organizations;
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        internalId: Yup.string().required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            internalId: selectedClient.organization.internalId,
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (selectedClient.organization.internalId === submittedValues.internalId) {
                return onClose();
            }
            await executePromise(async () => {
                await APIService.clients().updateClientOrganization(
                    selectedClient.id,
                    submittedValues.internalId
                );
                await refreshClientsList();
                onClose();
            });
        },
    });

    const organizationsList = useMemo(() => {
        return list.map((organization) => ({
            label: organization.name,
            value: organization.internalId,
        }));
    }, [list]);

    return (
        <Modal
            title="clients.modal.update_organization.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="internalId" expand>
                    <FormGroupLabel>{t('generic.organization')}</FormGroupLabel>
                    <FormSelectInput options={organizationsList} />
                </FormGroup>
                <FormFooter
                    submitLabel={t('clients.modal.update_organization.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateClientOrganizationModal;
