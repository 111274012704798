import React from 'react';
import { FileGroup } from '../types';
import CategoryQuoteModalGroup from '../CategoryQuoteModalGroup';
import { CategoryQuoteGroupAuthorization } from '../../../../../../types';

type Props = {
    availableFiles: Array<{ label: string; value: string }>;
    fileGroups: Array<FileGroup>;
    setFileGroups: React.Dispatch<React.SetStateAction<FileGroup[]>>;
};

const CategoryQuoteModalGroups = ({ availableFiles, fileGroups, setFileGroups }: Props) => {
    /**
     * Change group title
     * @param {string} title
     * @param {number} groupIndex
     */
    const handleChangeGroupTitle = (title: string, groupIndex: number) => {
        setFileGroups((prev) =>
            prev.map((prevGroup, prevIndex) => {
                if (prevIndex === groupIndex) {
                    return {
                        ...prevGroup,
                        title: title,
                    };
                } else {
                    return prevGroup;
                }
            })
        );
    };

    const handleChangeGroupAuthorization = (
        authorization: CategoryQuoteGroupAuthorization,
        groupIndex: number
    ) => {
        setFileGroups((prev) =>
            prev.map((prevGroup, prevIndex) => {
                if (prevIndex === groupIndex) {
                    return {
                        ...prevGroup,
                        authorization: authorization,
                    };
                } else {
                    return prevGroup;
                }
            })
        );
    };

    /**
     * Change group file
     * @param {Object} file
     * @param {number} groupIndex
     * @param {number} fileIndex
     */
    const handleChangeGroupFile = (
        file: { label: string; value: string },
        groupIndex: number,
        fileIndex: number
    ) => {
        setFileGroups((prev) =>
            prev.map((prevGroup, prevIndex) => {
                if (prevIndex === groupIndex) {
                    return {
                        ...prevGroup,
                        files: prevGroup.files.map((prevFile, prevFileIndex) => {
                            if (prevFileIndex === fileIndex) {
                                return file;
                            } else {
                                return prevFile;
                            }
                        }),
                    };
                } else {
                    return prevGroup;
                }
            })
        );
    };

    const handleChangeGroupFilesOrder = (
        groupIndex: number,
        files: Array<{ label: string; value: string }>
    ) => {
        setFileGroups((prev) =>
            prev.map((prevGroup, prevIndex) => {
                if (prevIndex === groupIndex) {
                    return {
                        ...prevGroup,
                        files: files,
                    };
                } else {
                    return prevGroup;
                }
            })
        );
    };

    /**
     * Handle remove group
     * @param {number} groupIndex
     */
    const handleRemoveGroup = (groupIndex: number) => {
        setFileGroups((prev) => prev.filter((_, prevIndex) => prevIndex !== groupIndex));
    };

    /**
     * Handle add file
     * @param {number} groupIndex
     */
    const handleAddFile = (groupIndex: number) => {
        setFileGroups((prev) =>
            prev.map((prevGroup, prevIndex) => {
                if (prevIndex === groupIndex) {
                    return {
                        ...prevGroup,
                        files: [...prevGroup.files, { label: '', value: '' }],
                    };
                } else {
                    return prevGroup;
                }
            })
        );
    };

    /**
     * Handle remove file
     * @param {number} groupIndex
     * @param {Number} fileIndex
     */
    const handleRemoveFile = (groupIndex: number, fileIndex: number) => {
        setFileGroups((prev) =>
            prev.map((prevGroup, prevIndex) => {
                if (prevIndex === groupIndex) {
                    return {
                        ...prevGroup,
                        files: prevGroup.files.filter((_, index) => index !== fileIndex),
                    };
                } else {
                    return prevGroup;
                }
            })
        );
    };

    return (
        <>
            {fileGroups.map((fileGroup, index) => (
                <CategoryQuoteModalGroup
                    total={fileGroups.length}
                    key={index}
                    title={fileGroup.title}
                    authorization={fileGroup.authorization}
                    files={fileGroup.files}
                    index={index}
                    availableFiles={availableFiles}
                    handleChangeGroupTitle={(title) => handleChangeGroupTitle(title, index)}
                    handleChangeGroupAuthorization={(authorization) =>
                        handleChangeGroupAuthorization(authorization, index)
                    }
                    handleChangeGroupFile={(file: { label: string; value: string }, fileIndex: number) =>
                        handleChangeGroupFile(file, index, fileIndex)
                    }
                    handleRemoveGroup={() => handleRemoveGroup(index)}
                    handleAddFile={() => handleAddFile(index)}
                    handleRemoveFile={(fileIndex) => handleRemoveFile(index, fileIndex)}
                    handleChangeGroupFilesOrder={(files) => handleChangeGroupFilesOrder(index, files)}
                />
            ))}
        </>
    );
};

export default CategoryQuoteModalGroups;
