import React, { useMemo } from 'react';
import { Routes } from '../../../../../routes';
import { useLoggedInUser } from '../../../../../hooks';
import { Navbar } from '../../../../../elements';

const CaseNavigation = () => {
    const { user, isEmployee } = useLoggedInUser();

    const displayedList = useMemo(() => {
        const overview = { id: 'overview', path: Routes.CASE_OVERVIEW };
        const quote = { id: 'quote', path: Routes.CASE_QUOTE };
        const documents = { id: 'documents', path: Routes.CASE_DOCUMENTS };
        const clients = { id: 'clients', path: Routes.CASE_CLIENTS };
        const access = { id: 'access_management', path: Routes.CASE_ACCESS_MANAGEMENT };
        const notes = { id: 'notes', path: Routes.CASE_NOTES };
        const events = { id: 'events', path: Routes.CASE_EVENTS };
        const forms = { id: 'forms', path: Routes.CASE_FORMS };

        const list =
            isEmployee() === true
                ? [overview, quote, documents, clients, access, notes, forms, events]
                : [documents, overview, notes, forms, events];

        return list.map((item) => ({
            ...item,
            translation: `case.navigation.${item.id}`,
        }));
    }, [user]);

    return <Navbar list={displayedList} mobileBreakPoint={1082} />;
};

export default CaseNavigation;
