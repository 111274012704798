import { EmployeeRole } from '../types';

export const permissions: Record<EmployeeRole, Array<string>> = {
    admin: [
        'employees.*',
        'cases.*',
        'cases-employees.*',
        'clients.*',
        'organizations.*',
        'categories.*',
        'forms.*',
    ],
    lawyer: [
        'employees.read',
        'cases.create',
        'cases-employees.*',
        'clients.add',
        'clients.update',
        'clients.read',
        'organizations.read',
        'categories.read',
        'forms.read',
    ],
    employee: [
        'employees.read',
        'cases.create',
        'cases-employees.*',
        'clients.add',
        'clients.update',
        'clients.read',
        'organizations.read',
        'categories.read',
        'forms.read',
    ],
};
