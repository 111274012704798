import styled from 'styled-components';

export const ResultsItemLabel = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    &.error {
        svg {
            fill: ${({ theme }) => theme.colors.danger.main};
        }
    }

    &.warning {
        svg {
            fill: ${({ theme }) => theme.colors.yellow.main};
        }
    }

    &.ok {
        svg {
            fill: ${({ theme }) => theme.colors.green.main};
        }
    }
`;

export const ResultsItemValue = styled.div<{ isEmpty: boolean }>`
    font-weight: ${({ theme }) => theme.font.weight.bold};
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ${({ isEmpty, theme }) => {
        if (isEmpty === true) {
            return {
                color: theme.colors.text.tertiary,
                fontStyle: 'italic',
                fontWeight: 'normal',
            };
        }
    }}
`;

export const ResultsItemDetails = styled.div`
    line-height: 2;
    margin: ${({ theme }) => theme.box.spacing.sm} 0;
`;
