import React from 'react';
import { EmployeeRole } from '../../types';
import {
    AvatarContainer,
    Container,
    ContainerLink,
    Email,
    Fullname,
    InfoContainer,
} from './UserAvatar-styles';

type UserAvatarProps = {
    firstName: string;
    lastName: string;
    email?: string;
    roles?: Array<EmployeeRole>;
    size?: UserAvatarSize;
    path?: string;
    showEmail?: boolean;
};

export enum UserAvatarSize {
    MEDIUM,
    SMALL,
    LARGE,
}

export const UserAvatarIcon = ({ firstName, lastName, roles }: UserAvatarProps) => {
    return (
        <AvatarContainer roles={roles} className="user-avatar__icon">
            {firstName.charAt(0)}
            {lastName.charAt(0)}
        </AvatarContainer>
    );
};

export const UserAvatarWrapper = ({ children, path }: { children: React.ReactNode; path?: string }) => {
    if (path != null && path.length > 0) {
        return <ContainerLink to={path}>{children}</ContainerLink>;
    } else {
        return <React.Fragment>{children}</React.Fragment>;
    }
};

const UserAvatar = ({
    firstName,
    lastName,
    roles,
    email,
    size = UserAvatarSize.MEDIUM,
    path,
    showEmail = false,
}: UserAvatarProps) => {
    return (
        <UserAvatarWrapper path={path}>
            <Container className="user-avatar" size={size}>
                <UserAvatarIcon firstName={firstName} lastName={lastName} roles={roles} />
                <InfoContainer className="user-avatar__info">
                    <Fullname className="user-avatar__info__fullname">
                        {firstName} {lastName}
                    </Fullname>
                    {showEmail === true && email != null && (
                        <Email className="user-avatar__info__email">{email}</Email>
                    )}
                </InfoContainer>
            </Container>
        </UserAvatarWrapper>
    );
};

export default UserAvatar;
