import BaseRequest from './_baseRequest';
import { BaseRequestConfig } from '../APIService.types';
import { Locale, User } from '../../../types';

class UserRequest extends BaseRequest {
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    async getProfile(): Promise<User> {
        return this.get('/me', true);
    }

    async updateProfile(payload: {
        firstName: string;
        lastName: string;
        contacts: Array<{ identifier: string; phoneNumber: string }>;
    }): Promise<void> {
        return this.patch('/me/profile', true, payload);
    }

    async updatePassword(payload: { password: string; logoutAll: boolean }): Promise<void> {
        return this.patch('/me/password', true, payload);
    }

    async updatePreferences(preferences: { locale: Locale }): Promise<void> {
        return this.patch('/me/preferences', true, preferences);
    }
}

export default UserRequest;
