import styled from 'styled-components';

export const Files = styled.div`
    padding: ${({ theme }) => theme.box.spacing.lg};
    padding-left: 7rem;
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.background.main};
    gap: ${({ theme }) => theme.box.spacing.md};
`;

export const FilesDescription = styled.div`
    line-height: 1.4;
    padding: ${({ theme }) => theme.box.spacing.md};
    background-color: ${({ theme }) => theme.colors.surface.main};
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
`;

export const FilesList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
    grid-auto-rows: max-content;
    row-gap: ${({ theme }) => theme.box.spacing.md};
`;

export const FilesListPlaceholder = styled.div<{ isAllowedToUpload: boolean }>`
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.background.main};
    cursor: ${({ isAllowedToUpload }) => (isAllowedToUpload === true ? 'pointer' : 'default')};
    font-size: ${({ theme }) => theme.font.size.sm};

    :hover {
        background-color: ${({ theme, isAllowedToUpload }) =>
            isAllowedToUpload ? theme.colors.background.secondary : theme.colors.background.main};
    }
`;
