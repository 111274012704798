import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div<{ isDragging: boolean }>`
    padding: ${({ theme }) => `${theme.box.spacing.sm} ${theme.box.spacing.md}`};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    background-color: ${({ theme, isDragging }) =>
        isDragging ? theme.colors.onSurface.secondary : theme.colors.surface.main};
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const HeaderImage = styled.img`
    width: 3rem;
    object-fit: contain;
    margin-right: ${({ theme }) => theme.box.spacing.md};
`;

export const HeaderLabel = styled(NavLink)`
    font-weight: bold;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.primary.main} !important;
    :hover {
        text-decoration: underline;
    }
`;

export const ClientsList = styled.ul``;

export const ClientsListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.sm};
    line-height: 1.4;
`;

export const ClientsPlaceholder = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    font-style: italic;
`;
