import React, { useEffect } from 'react';
import {
    Banner,
    BannerAppearance,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormPasswordInput,
    PublicPage,
} from '../../components';
import { Header } from './ResetPasswordConfirm-styles';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../hooks';
import { passwordRegex } from '../../tools';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../routes';
import { APIService } from '../../services';
import queryString from 'query-string';

const ResetPasswordConfirm = () => {
    const { t } = useTranslation();
    const [{ error, executePromise, clearError }] = usePromises();
    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search.replace(/\+/g, '%2B'));

    useEffect(() => {
        if (query.email == null || query.code == null) {
            navigate(`/${Routes.LOGIN}`);
        }
    }, []);

    const validationSchema = Yup.object({
        password: Yup.string().required(t('generic.error.required_field')).matches(passwordRegex, ' '),
        confirm_password: Yup.string()
            .required(t('generic.error.required_field'))
            .test('passwords-match', t('reset_password.confirm.errors.not_matching'), function (value) {
                return value === '' || value === this.parent.password;
            }),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload = {
                    email: query.email as string,
                    code: query.code as string,
                    password: submittedValues.password,
                };
                await APIService.auth().resetPasswordConfirm(payload);
                navigate(`/${Routes.LOGIN}?action=passwordChange`);
            });
        },
    });

    return (
        <PublicPage>
            <Header>{t('reset_password.confirm.title')}</Header>
            {error && (
                <Banner appearance={BannerAppearance.ERROR} closeBanner={() => clearError()}>
                    {t('generic.error.unknown')}
                </Banner>
            )}
            <Form formik={formik} validationSchema={validationSchema} oneColumn>
                <FormGroup name="password">
                    <FormGroupLabel>{t('reset_password.confirm.new_password')}</FormGroupLabel>
                    <FormPasswordInput validate autoComplete="new-password" />
                </FormGroup>
                <FormGroup name="confirm_password">
                    <FormGroupLabel>{t('reset_password.confirm.confirm_password')}</FormGroupLabel>
                    <FormPasswordInput autoComplete="new-password" />
                </FormGroup>
                <FormFooter expand submitLabel={t('reset_password.confirm.title')} />
            </Form>
        </PublicPage>
    );
};

export default ResetPasswordConfirm;
