import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.h1`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.xl};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.font.size.xxl};
`;

export const ForgotPassword = styled(Link)`
    position: absolute;
    top: calc(100% + 0.7rem);
    right: 0;
    color: ${({ theme }) => theme.colors.primary.main};
    font-size: ${({ theme }) => theme.font.size.sm};
    user-select: none;
    font-weight: ${({ theme }) => theme.font.weight.semiBold};

    :hover {
        text-decoration: underline;
    }
`;
