import styled from 'styled-components';

export const Container = styled.div`
    grid-column: 1/-1;
`;

export const Title = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const List = styled.div`
    background: ${({ theme }) => theme.colors.onSurface.main};
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 8px;
`;

export const Section = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 70% 30%;
    grid-column-gap: ${({ theme }) => theme.box.spacing.md};
`;

export const RemoveSection = styled.div<{ isDisabled: boolean }>`
    margin-right: ${({ theme }) => theme.box.spacing.md};

    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        font-size: ${({ theme }) => theme.font.size.md};
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                svg: {
                    color: theme.colors.text.disabled,
                },
            };
        }
    }}
`;

export const AddSection = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.box.spacing.md};
    margin-right: ${({ theme }) => theme.box.spacing.sm};
`;

export const AddSectionLabel = styled.div<{ isDisabled: boolean }>`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                cursor: 'not-allowed',
                color: theme.colors.text.disabled,
            };
        }
    }}
`;
