import styled from 'styled-components';
import { InputMode } from '../Inputs-types';

export const Container = styled.div<{
    mode?: InputMode;
}>`
    width: 100%;
    height: ${({ theme }) => theme.config.input.height.default}px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    z-index: 2;
    background: ${({ theme }) => theme.colors.surface.main};
    font-size: ${({ theme }) => theme.font.size.lg};
    border-radius: 0.8rem;

    ${({ mode, theme }) => {
        if (mode === InputMode.STAND_ALONE) {
            return {
                borderRadius: '0.3rem',
                height: `${theme.config.input.height.small}px`,
            };
        }
    }}
`;

export const DropzoneContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const Dropzone = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        transform: translateY(1px);
    }
`;

export const DropzoneInput = styled.input``;

export const DropzoneMessage = styled.p`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const TrailingIconContainer = styled.div`
    width: 3rem;
    min-width: 3rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const UploadedFilesList = styled.ul`
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const UploadedFilesListItem = styled.li`
    display: flex;
    padding: ${({ theme }) => theme.box.spacing.md};
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.onSurface.secondary};
    }
`;

export const UploadedFilesListItemRemove = styled.div`
    color: ${({ theme }) => theme.colors.danger.main};
    font-size: ${({ theme }) => theme.font.size.md};
    margin-right: ${({ theme }) => theme.box.spacing.sm};
`;

export const UploadedFilesListItemContent = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;
