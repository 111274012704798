import React, { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CasesPagesContext } from '../CasePagesContext';
import { usePromises } from '../../../../hooks';
import { APIService } from '../../../../services';
import { CaseEventsActions, CaseEventsTable } from './components';
import { PageContainer, PageHeader } from '../../../../components';
import { useWindowSize } from 'usehooks-ts';
import { BiHistory } from 'react-icons/bi';
import { CaseChildPageTitle } from '../CasePages-styles';
import { useTranslation } from 'react-i18next';

const CaseEvents = () => {
    const { t } = useTranslation();
    const windowSize = useWindowSize();
    const { currentCase } = useOutletContext<CasesPagesContext>();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEventCategory, setSelectedEventCategory] = useState<string>('');
    const [selectedAuthor, setSelectedAuthor] = useState<string>('');
    const [promises, [events]] = usePromises(() => APIService.cases().getEvents(currentCase.id));

    const authorsList = useMemo(() => {
        const list = new Set<string>();
        if (events.data != null) {
            for (const item of events.data) {
                list.add(item.author);
            }
        }
        return [...list];
    }, [events.data]);

    return (
        <PageContainer error={promises.error} clearError={promises.clearError}>
            <PageHeader display={windowSize.width > 1100 ? 'row' : 'column'}>
                <CaseChildPageTitle>
                    <BiHistory />
                    {t('case.events.page_title')}
                </CaseChildPageTitle>
                <CaseEventsActions
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    selectedEventCategory={selectedEventCategory}
                    selectedAuthor={selectedAuthor}
                    authorsList={authorsList}
                    setSelectedEventCategory={setSelectedEventCategory}
                    setSelectedAuthor={setSelectedAuthor}
                />
            </PageHeader>
            <CaseEventsTable
                isLoading={events.isLoading}
                events={events.data ?? []}
                searchTerm={searchTerm}
                selectedAuthor={selectedAuthor}
                selectedEventCategory={selectedEventCategory}
            />
        </PageContainer>
    );
};

export default CaseEvents;
