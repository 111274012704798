import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 7rem;
    background: ${({ theme }) => theme.colors.background.main};
`;

export const Content = styled.div`
    width: 45rem;
    background: ${({ theme }) => theme.colors.surface.main};
    padding: ${({ theme }) => `${theme.box.spacing.xl} ${theme.box.spacing.xxl}`};
    padding-bottom: ${({ theme }) => `${theme.box.spacing.xxl}`};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.box.shadow.light};
`;

export const Footer = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;
