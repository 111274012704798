import React from 'react';
import { ResourceKey } from 'i18next';
import Banner, { BannerAppearance } from '../../Banner';
import { Container } from './PageContainer-styles';
import { useTranslation } from 'react-i18next';

type PageContainerProps = {
    error?: boolean | string | null;
    errorProps?: Object;
    clearError?: () => void;
    children: React.ReactNode;
};

const PageContainer = ({ error, errorProps = {}, clearError, children }: PageContainerProps) => {
    const { t } = useTranslation();
    return (
        <Container className="page-container">
            {error != null && error !== false && (
                <Banner appearance={BannerAppearance.ERROR} closeBanner={clearError!}>
                    {error === true ? t('generic.error.unknown') : t(error as ResourceKey, errorProps)}
                </Banner>
            )}
            {children}
        </Container>
    );
};

export default PageContainer;
