import React from 'react';
import { Container } from './Logo-styles';
import logo from '../../../../assets/images/Naia_white-notext.png';
import { useStore } from '../../../../hooks';

const Logo = () => {
    const { settings } = useStore();

    return (
        <Container
            src={settings.assets.logo.url.length > 0 ? settings.assets.logo.url : logo}
            alt="NAIA logo"
        />
    );
};

export default Logo;
