import React from 'react';
import { EmployeeRole } from '../../types';
import { Container } from './EmployeeBadges-styles';
import EmployeeBadge from '../EmployeeBadge';
import { generateId } from '../../tools';

type Props = {
    roles: Array<EmployeeRole>;
};

const EmployeeBadges = ({ roles }: Props) => {
    return (
        <Container>
            {roles.map((role) => (
                <EmployeeBadge key={generateId()} role={role} />
            ))}
        </Container>
    );
};

export default EmployeeBadges;
