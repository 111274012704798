import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const focusedStyle = css`
    background: ${({ theme }) => theme.colors.surface.main};
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    .user-avatar__info__fullname {
        color: ${({ theme }) => theme.colors.text.main};
    }

    svg {
        color: ${({ theme }) => theme.colors.text.main};
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.text.onPrimary};
    position: relative;
    margin-right: ${({ theme }) => theme.box.spacing.lg};
`;

export const Content = styled.div<{ isOpened: boolean }>`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};
    border-radius: 20px;
    padding: ${({ theme }) => theme.box.spacing.xxs};
    padding-right: ${({ theme }) => theme.box.spacing.xs};
    cursor: pointer;

    > div:first-child {
        margin-right: ${({ theme }) => theme.box.spacing.xs};
    }

    :hover {
        ${focusedStyle}
    }
`;

export const MenuContainer = styled.div`
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    width: max-content;
    min-width: 24rem;
    height: max-content;
    background: ${({ theme }) => theme.colors.surface.main};
    box-shadow: ${({ theme }) => theme.box.shadow.dark};
    z-index: 2;
    color: ${({ theme }) => theme.colors.text.main};
    border-radius: 1rem;
    overflow: hidden;
`;

export const MenuContent = styled.div`
    padding: ${({ theme }) => theme.box.spacing.sm};
`;

export const MenuHeader = styled.div`
    .user-avatar {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
        &__icon {
            width: 6rem;
            height: 6rem;
            font-size: ${({ theme }) => theme.font.size.xl};
        }
        &__info {
            &__fullname {
                font-size: ${({ theme }) => theme.font.size.md};
            }
        }
    }
`;

export const MenuList = styled.ul``;

export const MenuListItem = styled.li<{ isLink?: boolean }>`
    height: 4rem;
    font-size: ${({ theme }) => theme.font.size.md};
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: ${({ theme }) => theme.box.spacing.md};
    :hover {
        background: ${({ theme }) => theme.colors.surface.secondary};
    }

    ${({ isLink }) => {
        if (isLink === true) {
            return {
                padding: 0,
            };
        }
    }}
`;

export const MenuListItemLink = styled(Link)`
    height: 4rem;
    padding-left: ${({ theme }) => theme.box.spacing.md};
    font-size: ${({ theme }) => theme.font.size.md};
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text.main};
    :hover {
        background: ${({ theme }) => theme.colors.surface.secondary};
    }
`;

export const MenuFooter = styled.div`
    background: ${({ theme }) => theme.colors.background.main};
    padding: ${({ theme }) => theme.box.spacing.sm};
    border-top: 1px solid ${({ theme }) => theme.colors.background.secondary};
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .employee-badge {
        font-size: ${({ theme }) => theme.font.size.sm};
        margin-left: 4px;
    }
`;
