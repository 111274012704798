import React from 'react';
import { Container, Div } from './Spinner-styles';
import { range } from '../../tools';

export enum SpinnerSize {
    MEDIUM = '40',
    SMALL = '20',
}

export enum SpinnerAppearance {
    PRIMARY,
    WHITE,
    BLACK,
}

type Props = {
    size?: SpinnerSize;
    appearance?: SpinnerAppearance;
};

const Spinner = ({ size = SpinnerSize.SMALL, appearance = SpinnerAppearance.WHITE }: Props) => {
    return (
        <Container className="spinner" size={parseInt(size)} appearance={appearance}>
            {range(12).map((i) => (
                <Div key={i} size={parseInt(size)} appearance={appearance} />
            ))}
        </Container>
    );
};

export default Spinner;
