import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Case, StatusListItem } from '../../../types';

type InitialState = {
    statusList: Array<StatusListItem>;
    clientCasesList: Array<Case>;
};

export const caseSlice = createSlice({
    name: 'case',
    initialState: {
        statusList: [],
        clientCasesList: [],
    } as InitialState,
    reducers: {
        setStatusList: (state, { payload }: PayloadAction<Array<StatusListItem>>) => {
            state.statusList = payload;
        },
        setClientCasesList: (state, { payload }: PayloadAction<Array<Case>>) => {
            state.clientCasesList = payload;
        },
    },
});

export const { setStatusList, setClientCasesList } = caseSlice.actions;

export default caseSlice.reducer;
