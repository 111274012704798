import React from 'react';
import {
    PageHeaderActions,
    PageHeaderActionsLeft,
    PageHeaderActionsRight,
    SelectInput,
    TextInput,
} from '../../../../../../components';
import { CaseEventCategory } from '../../../../../../types';
import { useTranslation } from 'react-i18next';

type Props = {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    selectedEventCategory: string;
    setSelectedEventCategory: React.Dispatch<React.SetStateAction<string>>;
    selectedAuthor: string;
    setSelectedAuthor: React.Dispatch<React.SetStateAction<string>>;
    authorsList: Array<string>;
};

const CaseEventsActions = ({
    searchTerm,
    setSearchTerm,
    selectedEventCategory,
    selectedAuthor,
    authorsList,
    setSelectedAuthor,
    setSelectedEventCategory,
}: Props) => {
    const { t } = useTranslation();
    return (
        <PageHeaderActions
            breakpoint={1100}
            grid={{
                default: '300px 200px minmax(200px, max-content)',
                mobile: [300, 300, 300],
                isLeftSide: false,
            }}
        >
            <PageHeaderActionsLeft>
                <React.Fragment />
            </PageHeaderActionsLeft>
            <PageHeaderActionsRight>
                <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    name="searchTerm"
                    placeholder={`${t('generic.search')}...`}
                />
                <SelectInput
                    value={selectedAuthor}
                    onChange={(value) => setSelectedAuthor(value)}
                    options={[
                        {
                            label: t('generic.all'),
                            value: '',
                        },
                        ...authorsList.map((author) => ({
                            label: author,
                            value: author,
                        })),
                    ]}
                    isSearchable={false}
                    label={t('generic.author')}
                />
                <SelectInput
                    value={selectedEventCategory}
                    onChange={(value) => setSelectedEventCategory(value)}
                    options={[
                        {
                            label: t('generic.all'),
                            value: '',
                        },
                        {
                            label: t('generic.clients'),
                            value: CaseEventCategory.CLIENTS,
                        },
                        {
                            label: t('generic.employees'),
                            value: CaseEventCategory.EMPLOYEES,
                        },
                        {
                            label: t('generic.status'),
                            value: CaseEventCategory.STATUS,
                        },
                        {
                            label: t('generic.files'),
                            value: CaseEventCategory.FILES,
                        },
                        {
                            label: t('generic.quotes'),
                            value: CaseEventCategory.QUOTES,
                        },
                        {
                            label: t('generic.notes'),
                            value: CaseEventCategory.NOTES,
                        },
                    ]}
                    isSearchable={false}
                    label={t('generic.event_type')}
                />
            </PageHeaderActionsRight>
        </PageHeaderActions>
    );
};

export default CaseEventsActions;
