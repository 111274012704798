import React from 'react';
import { useLoggedInUser, useTable } from '../../../../../hooks';
import { Table, TableBodyRowAction, TableColumnConfig } from '../../../../../components';
import { LogoContainer, Logo, Favicon } from './OrganizationsListTable-styles';
import { PAGE_ORGANIZATIONS_Organization } from '../../../OrganizationsPages';

type Props = {
    isLoading?: boolean;
    organizations: null | Array<PAGE_ORGANIZATIONS_Organization>;
    searchTerm: string;
    onClickUpdateOrganization: (organization: PAGE_ORGANIZATIONS_Organization) => void;
};

const OrganizationsListTable = ({
    isLoading,
    organizations,
    searchTerm,
    onClickUpdateOrganization,
}: Props) => {
    const user = useLoggedInUser();

    const columns: Array<TableColumnConfig<PAGE_ORGANIZATIONS_Organization>> = [
        {
            id: 'name',
            headerTitle: 'organizations.name',
            getValue: ({ name }) => name,
            getDisplayedValue: ({ name, assets, theme }) => (
                <>
                    <LogoContainer color={theme.primary}>
                        <Logo src={assets.logo.url} />
                    </LogoContainer>
                    {name}
                </>
            ),
            width: 'minmax(max-content, 1fr)',
            isSortable: true,
            isSearchable: true,
            isDefaultSort: true,
        },
        {
            id: 'internalId',
            headerTitle: 'generic.internal_id',
            getValue: ({ internalId }) => internalId,
            width: 'minmax(max-content, 300px)',
            isSortable: true,
            isSearchable: true,
            isDefaultSort: true,
            isCentered: true,
        },
        {
            id: 'loginUrl',
            headerTitle: 'organizations.login_url',
            getValue: ({ config }) => config.loginUrl,
            width: 'minmax(max-content, 500px)',
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'primaryColor',
            headerTitle: 'organizations.primary_color',
            getValue: ({ theme }) => theme.primary,
            width: 'minmax(max-content, 500px)',
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'favicon',
            headerTitle: 'Favicon',
            getValue: () => '',
            getDisplayedValue: ({ assets }) => <Favicon src={assets.favicon.url} />,
            width: 'minmax(max-content, 250px)',
            isCentered: true,
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (organization, { t, index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index >= total - 2}
                    actions={[
                        {
                            label: t('organizations.modals.update_organization.title'),
                            onClick: () => onClickUpdateOrganization(organization),
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.hasPermissions('organizations.update'),
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(
        organizations ?? [],
        columns,
        {
            searchTerm: searchTerm,
        }
    );

    return (
        <Table
            columns={displayedColumns}
            data={data}
            handleSort={handleSort}
            handleClickRow={(organization) => onClickUpdateOrganization(organization)}
            sortedColumn={sortedColumn}
            isSortingDown={isSortingDown}
            isLoading={isLoading}
            styles={{
                table: {
                    minWidth: '800px',
                },
            }}
        />
    );
};

export default OrganizationsListTable;
