import styled, { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-top: ${({ theme }) => `${theme.config.topbar.height.default}px`};
    background: ${({ theme }) => theme.colors.background.secondary};
    z-index: 1;
    overflow-y: scroll;
`;

export const Back = styled.div`
    margin: ${({ theme }) => theme.box.spacing.lg};
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;

    :hover {
        color: ${({ theme }) => theme.colors.primary.main};
        > div:last-child {
            text-decoration: underline;
        }
    }
`;

export const BackIcon = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
    }
`;

export const BackLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.lg};
`;

export const FormContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const FormContent = styled.div`
    width: 90vw;
    max-width: 80rem;
    background-color: ${({ theme }) => theme.colors.surface.main};
    margin: ${({ theme }) => theme.box.spacing.xl};
    height: max-content;
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    position: relative;
    animation: ${fadeInUp} 0.5s ease-out;
`;

export const FormContentClose = styled.div`
    position: absolute;
    right: ${({ theme }) => theme.box.spacing.md};
    top: ${({ theme }) => theme.box.spacing.md};
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    align-self: flex-start;

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    :hover {
        background: ${({ theme }) => theme.colors.onSurface.main};
    }
`;
