import styled from 'styled-components';

export const Container = styled.label`
    padding-left: 3rem;
    position: relative;
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const Text = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 2px;
`;

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
        background-color: ${({ theme }) => theme.colors.primary.main};
        border-color: transparent;

        &:after {
            display: block;
        }
    }
`;

export const Checkmark = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-radius: 2px;

    svg {
        fill: ${({ theme }) => theme.colors.surface.main};
    }
`;
