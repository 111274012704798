import React, { useEffect, useMemo } from 'react';
import { CategoryQuote, CategoryQuoteType } from '../../../../../../../types';
import { Container, Remove, Content, ContentItem } from './CategoryModalGroup-styles';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { InputMode, SelectInput, TextInput } from '../../../../../../../components';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../../tools';
import { Quote } from '../../../config/types';

type Props = {
    index: number;
    total: number;
    quote: Quote;
    selectedQuotes: Array<{ id: string }>;
    handleChangeQuote: (quote: Quote) => void;
    handleRemoveQuote: () => void;
    quotesList: Array<CategoryQuote>;
};

const CategoryModalGroup = ({
    quote,
    index,
    total,
    handleChangeQuote,
    handleRemoveQuote,
    quotesList,
    selectedQuotes,
}: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const isDisabled = useMemo(() => {
        return index === 0 && total < 2;
    }, [index, total]);

    const currentQuote = useMemo(() => {
        return quotesList.find((item) => item.id === quote.id);
    }, [quotesList, quote.id]);

    const availableQuotes = useMemo(() => {
        const list = quotesList
            .filter((item) => {
                const isSelected = selectedQuotes.some((selected) => selected.id === item.id);
                return isSelected === false;
            })
            .map((item) => ({
                label: formatLocalizedText(item.title, locale),
                value: item.id,
            }));

        if (currentQuote != null) {
            list.push({
                label: formatLocalizedText(currentQuote.title, locale),
                value: currentQuote.id,
            });
        }

        return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [quotesList, selectedQuotes, currentQuote, locale]);

    useEffect(() => {
        if (quote.type === CategoryQuoteType.UNIQUE && quote.maxCount !== 1) {
            handleChangeQuote({
                ...quote,
                maxCount: 1,
            });
        }
    }, [quote.type]);

    return (
        <Container>
            <Remove isDisabled={isDisabled}>
                <AiOutlineCloseCircle
                    onClick={() => {
                        if (isDisabled !== true) {
                            handleRemoveQuote();
                        }
                    }}
                />
            </Remove>
            <Content>
                <ContentItem column="1/-1">
                    <SelectInput
                        label={t('generic.quote')}
                        value={quote.id}
                        mode={InputMode.FORM}
                        options={availableQuotes}
                        onChange={(id) =>
                            handleChangeQuote({
                                ...quote,
                                id: id,
                            })
                        }
                    />
                </ContentItem>
                <ContentItem>
                    <TextInput
                        label={t('generic.price')}
                        type="number"
                        value={quote.price}
                        mode={InputMode.FORM}
                        trailingIcon="$"
                        onChange={(e) =>
                            handleChangeQuote({
                                ...quote,
                                price: e.target.value as unknown as number,
                            })
                        }
                    />
                </ContentItem>
                <ContentItem>
                    <SelectInput
                        label={t('generic.type')}
                        value={quote.type}
                        mode={InputMode.FORM}
                        options={[
                            { label: 'Unique', value: CategoryQuoteType.UNIQUE },
                            { label: 'Multiple', value: CategoryQuoteType.MULTIPLE },
                        ]}
                        onChange={(type) =>
                            handleChangeQuote({
                                ...quote,
                                type: type,
                            })
                        }
                    />
                </ContentItem>
                <ContentItem>
                    <TextInput
                        label={t('generic.dependent_price')}
                        type="number"
                        value={quote.dependentPrice}
                        mode={InputMode.FORM}
                        trailingIcon="$"
                        disabled={currentQuote == null || currentQuote.dependent == null}
                        onChange={(e) => {
                            if (currentQuote?.dependent != null) {
                                handleChangeQuote({
                                    ...quote,
                                    dependentPrice: e.target.value as unknown as number,
                                });
                            }
                        }}
                    />
                </ContentItem>
                <ContentItem>
                    <TextInput
                        label={t('generic.max_count')}
                        disabled={quote.type === CategoryQuoteType.UNIQUE}
                        value={quote.maxCount}
                        type="number"
                        mode={InputMode.FORM}
                        onChange={(e) =>
                            handleChangeQuote({
                                ...quote,
                                maxCount: e.target.value as unknown as number,
                            })
                        }
                    />
                </ContentItem>
            </Content>
        </Container>
    );
};

export default CategoryModalGroup;
