import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 25rem;
    border-right: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    padding: ${({ theme }) => theme.box.spacing.md};
`;

export const Item = styled(NavLink)`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.text.main};
    border-radius: 3px;
    position: relative;

    &.active {
        background-color: ${({ theme }) => theme.colors.primary.extended_10};
        color: ${({ theme }) => theme.colors.primary.main};

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 55%;
            width: 3px;
            background-color: ${({ theme }) => theme.colors.primary.main};
        }
    }
`;

export const ItemIcon = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${({ theme }) => theme.box.spacing.sm};

    svg {
        font-size: 16px;
    }
`;

export const ItemLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;
