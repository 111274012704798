import React, { useEffect, useMemo } from 'react';
import { OutletContainer } from '../../components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../routes';
import { useLoggedInUser, useStore } from '../../hooks';

const CasesPages = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const user = useLoggedInUser();
    const { clientCasesList } = useStore().caseData;
    const caseRoute = `/${Routes.CASES}`;

    useEffect(() => {
        if (pathname === caseRoute && user.isClient() === true && clientCasesList.length > 0) {
            navigate(`${caseRoute}/${clientCasesList[0].id}/${Routes.CASE_DOCUMENTS}`);
        }
    }, [pathname, clientCasesList, user]);

    const useBackgroundColor = useMemo(() => {
        return pathname !== caseRoute;
    }, [pathname]);

    return (
        <OutletContainer useBackgroundColor={useBackgroundColor}>
            <Outlet />
        </OutletContainer>
    );
};

export default CasesPages;
