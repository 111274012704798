import React, { useMemo } from 'react';
import { CaseDocumentsContextType } from '../CaseDocuments';
import { formatCaseFolderDescription } from '../../../../../tools';
import { useLocale, useTable } from '../../../../../hooks';
import { CaseFolderIcon, Table, TableColumnConfig } from '../../../../../components';
import { Description, TableCell } from './CaseDocumentsList-styles';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { CaseFolderManager, CaseFolderTooltip } from '../../../../../managers';

type FormattedCaseFolder = {
    description: string;
    path?: string;
    isTotal?: boolean;
    isDependent?: boolean;
    isValidated?: boolean;
    hasErrors?: boolean;
    hasWarnings?: boolean;
    counters: {
        uploaded: number;
        validated: number;
        total: number;
    };
    tooltips?: Array<CaseFolderTooltip> | null;
};

const CaseDocumentsList = () => {
    const { caseFolders, currentCase } = useOutletContext<CaseDocumentsContextType>();
    const { pathname } = useLocation();
    const locale = useLocale();
    const navigate = useNavigate();

    const formattedCaseFolders = useMemo(() => {
        if (caseFolders.data == null) {
            return [];
        }
        const results: Array<FormattedCaseFolder> = caseFolders.data
            .sort((a, b) => a.category.index - b.category.index)
            .map((caseFolder) => {
                const list: Array<FormattedCaseFolder> = [];

                const managedCaseFolder = new CaseFolderManager(currentCase, caseFolder);

                list.push({
                    path: caseFolder.id,
                    description: formatCaseFolderDescription(caseFolder, locale),
                    isValidated: managedCaseFolder.isValidated(),
                    hasErrors: managedCaseFolder.hasErrors(),
                    hasWarnings: managedCaseFolder.hasWarnings(),
                    counters: {
                        uploaded: managedCaseFolder.getUploadedFileCount(),
                        validated: managedCaseFolder.getValidatedFileCount(),
                        total: managedCaseFolder.getTotalFileCount(),
                    },
                    tooltips: managedCaseFolder.getTooltips(),
                });

                if (caseFolder.dependents != null) {
                    for (const dependent of caseFolder.dependents) {
                        const managedDependent = new CaseFolderManager(
                            currentCase,
                            dependent,
                            managedCaseFolder
                        );

                        list.push({
                            path: `${caseFolder.id}#${dependent.id}`,
                            description: formatCaseFolderDescription(dependent, locale),
                            isDependent: true,
                            isValidated: managedDependent.isValidated(),
                            hasErrors: managedDependent.hasErrors(),
                            hasWarnings: managedDependent.hasWarnings(),
                            counters: {
                                uploaded: managedDependent.getUploadedFileCount(),
                                validated: managedDependent.getValidatedFileCount(),
                                total: managedDependent.getTotalFileCount(),
                            },
                            tooltips: managedDependent.getTooltips(),
                        });
                    }
                }
                return list;
            })
            .flat();

        results.push({
            description: 'Total',
            isTotal: true,
            counters: {
                uploaded: results.reduce((acc, { counters }) => acc + counters.uploaded, 0),
                validated: results.reduce((acc, { counters }) => acc + counters.validated, 0),
                total: results.reduce((acc, { counters }) => acc + counters.total, 0),
            },
        });
        return results;
    }, [caseFolders.data, locale]);

    const renderDescription = (caseFolder: FormattedCaseFolder) => {
        if (caseFolder.isTotal === true) {
            return <TableCell {...caseFolder}>{caseFolder.description}</TableCell>;
        }

        return (
            <TableCell isDependent={caseFolder.isDependent}>
                <Description to={`${pathname}/${caseFolder.path}`}>
                    <CaseFolderIcon
                        level={0}
                        isValidated={caseFolder.isValidated}
                        hasErrors={caseFolder.hasErrors}
                        hasWarnings={caseFolder.hasWarnings}
                        tooltips={caseFolder.tooltips}
                    />
                    {caseFolder.description}
                </Description>
            </TableCell>
        );
    };

    const columns: Array<TableColumnConfig<FormattedCaseFolder>> = [
        {
            id: 'description',
            headerTitle: 'generic.description',
            getValue: ({ description }) => description,
            getDisplayedValue: (caseFolder) => renderDescription(caseFolder),
            width: '1fr',
        },
        {
            id: 'counters_uploaded',
            headerTitle: 'generic.uploaded',
            getValue: ({ counters }) => counters.uploaded,
            getDisplayedValue: ({ counters, isTotal }) => (
                <TableCell isTotal={isTotal}>{`${counters.uploaded}/${counters.total} (${Math.ceil(
                    (counters.uploaded / counters.total) * 100
                )}%)`}</TableCell>
            ),
            width: 'minmax(max-content, 20rem)',
            isCentered: true,
        },
        {
            id: 'counters_validated',
            headerTitle: 'generic.validated',
            getValue: ({ counters }) => counters.validated,
            getDisplayedValue: ({ counters, isTotal }) => (
                <TableCell isTotal={isTotal}>{`${counters.validated}/${counters.total} (${Math.ceil(
                    (counters.validated / counters.total) * 100
                )}%)`}</TableCell>
            ),
            width: 'minmax(max-content, 20rem)',
            isCentered: true,
        },
    ];

    const { data, displayedColumns, isLoading } = useTable(formattedCaseFolders, columns);

    if (data.length < 2) {
        return <React.Fragment />;
    }

    return (
        <Table
            data={data}
            columns={displayedColumns}
            isLoading={isLoading}
            handleClickRow={(formattedCaseFolder) => {
                if (formattedCaseFolder.isTotal !== true) {
                    navigate(`${pathname}/${formattedCaseFolder.path!}`);
                }
            }}
        />
    );
};

export default CaseDocumentsList;
