import styled from 'styled-components';

export const Container = styled.div`
    grid-column: 1/-1;
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.main};
    padding-bottom: ${({ theme }) => theme.box.spacing.sm};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
