import React from 'react';
import { Container, Content } from './OutletContainer-styes';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

type Props = {
    children: React.ReactNode;
    mapping?: { [key: string]: (item: BreadcrumbData) => string };
    useBackgroundColor?: boolean;
    expand?: boolean;
};

const OutletContainer = ({ children, useBackgroundColor, expand = false }: Props) => {
    return (
        <Container className="outlet-container" expand={expand} useBackgroundColor={useBackgroundColor}>
            <Content className="outlet-content" expand={expand}>
                {children}
            </Content>
        </Container>
    );
};

export default OutletContainer;
