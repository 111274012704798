import {
    Category,
    CategoryFile,
    CategoryPayload,
    CategoryQuote,
    CategoryQuotePayload,
    LocaleObject,
} from '../../../types';
import { BaseRequestConfig } from '../APIService.types';
import BaseRequest from './_baseRequest';

class CategoriesRequest extends BaseRequest {
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    // **************************************************************************
    // Main categories
    // **************************************************************************

    async getList(): Promise<Array<Category>> {
        return this.get('/main', true).then((res: any) => res.categories);
    }

    async getCategory(categoryId: string): Promise<Category> {
        return this.get(`/main/${categoryId}`, true);
    }

    async createCategory(payload: CategoryPayload) {
        return this.post('/main', true, payload);
    }

    async updateCategory(categoryId: string, payload: CategoryPayload) {
        return this.patch(`/main/${categoryId}`, true, payload);
    }

    async archiveCategory(categoryId: string) {
        return this.delete(`/main/${categoryId}`, true);
    }

    // **************************************************************************
    // Quotes
    // **************************************************************************

    async getQuotesList(): Promise<Array<CategoryQuote>> {
        return this.get('/quotes', true).then((res: any) => res.quotes);
    }

    async createQuote(payload: CategoryQuotePayload) {
        return this.post('/quotes', true, payload);
    }

    async updateQuote(quoteId: string, payload: CategoryQuotePayload) {
        return this.patch(`/quotes/${quoteId}`, true, payload);
    }

    async archiveQuote(quoteId: string) {
        return this.delete(`/quotes/${quoteId}`, true);
    }

    // **************************************************************************
    // Files
    // **************************************************************************

    async getFilesList(): Promise<Array<CategoryFile>> {
        return this.get('/files', true).then((res: any) => res.files);
    }

    async createFile(payload: { title: LocaleObject; description: LocaleObject; OCRType: string }) {
        return this.post('/files', true, payload);
    }

    async updateFile(
        fileId: string,
        payload: { title: LocaleObject; description: LocaleObject; OCRType: string }
    ) {
        return this.patch(`/files/${fileId}`, true, payload);
    }

    async archiveFile(fileId: string) {
        return this.delete(`/files/${fileId}`, true);
    }
}

export default CategoriesRequest;
