import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageHeader, PageHeaderTitle } from '../../../components';
import { usePromises, useToggle } from '../../../hooks';
import { Actions, ServerFormQuestion } from '../../../types';
import { APIService } from '../../../services';
import { FormsQuestionsListActions, FormsQuestionsListTable } from './components';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { CreateFormsQuestionsModal, DeleteFormQuestionModal, UpdateFormQuestionModal } from './modals';

const FormQuestionsList = () => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [action, openModal, closeModal] = useToggle<null | Actions>(null);
    const [promises, [questions]] = usePromises(() => APIService.forms().getQuestionsList());
    const [selectedQuestion, setSelectedQuestion] = useState<null | ServerFormQuestion>(null);

    const openModalHandler = (modalAction: Actions, question: ServerFormQuestion) => {
        openModal(modalAction);
        setSelectedQuestion(question);
    };

    return (
        <>
            {action === Actions.CREATE && (
                <CreateFormsQuestionsModal
                    onClose={closeModal}
                    refreshQuestionsList={questions.refresh}
                    questionsList={questions.data ?? []}
                />
            )}

            {action === Actions.UPDATE && (
                <UpdateFormQuestionModal
                    onClose={closeModal}
                    refreshQuestionsList={questions.refresh}
                    selectedQuestion={selectedQuestion!}
                    questionsList={questions.data ?? []}
                />
            )}

            {action === Actions.DELETE && (
                <DeleteFormQuestionModal
                    onClose={closeModal}
                    refreshQuestionsList={questions.refresh}
                    selectedQuestion={selectedQuestion!}
                />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader>
                    <PageHeaderTitle>
                        <FaRegQuestionCircle />
                        {t('forms.questions.page_title')}
                    </PageHeaderTitle>
                    <FormsQuestionsListActions
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        onClickAddQuestion={() => openModal(Actions.CREATE)}
                    />
                </PageHeader>
                <FormsQuestionsListTable
                    isLoading={questions.isLoading}
                    questions={questions.data}
                    searchTerm={searchTerm}
                    openModalHandler={openModalHandler}
                />
            </PageContainer>
        </>
    );
};

export default FormQuestionsList;
