import React from 'react';
import { Container } from './OCRFileModalText-styles';
import { useTranslation } from 'react-i18next';

type OCRFileModalTextProps = {
    text: string;
};

const OCRFileModalText = ({ text }: OCRFileModalTextProps) => {
    const { t } = useTranslation();

    if (text.length > 0 !== true) {
        return <Container isEmpty>{t('case.documents.modals.ocr.no_text_detected')}</Container>;
    }

    return <Container dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br>') }} />;
};

export default OCRFileModalText;
