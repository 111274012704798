import React, { useState } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle, PrimaryContainedAddButton } from '../../../components';
import { useTranslation } from 'react-i18next';
import { TbFileInvoice } from 'react-icons/tb';
import { useLoggedInUser, usePromises, useToggle } from '../../../hooks';
import { Actions, CategoryQuote } from '../../../types';
import { CreateCategoryQuoteModal, DeleteCategoryQuoteModal, UpdateCategoryQuoteModal } from './modals';
import { CategoriesQuotesListTable } from './components';
import { APIService } from '../../../services';

const CategoriesQuotesList = () => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const [selectedCategoryQuote, setSelectedCategoryQuote] = useState<null | CategoryQuote>(null);
    const [action, openModal, closeModal] = useToggle<null | Actions>(null);
    const [promises, [quotes]] = usePromises(() => APIService.categories().getQuotesList());

    return (
        <>
            {action === Actions.CREATE && (
                <CreateCategoryQuoteModal onClose={closeModal} refreshQuotesList={quotes.refresh} />
            )}

            {action === Actions.UPDATE && (
                <UpdateCategoryQuoteModal
                    onClose={closeModal}
                    refreshQuotesList={quotes.refresh}
                    selectedCategoryQuote={selectedCategoryQuote!}
                />
            )}

            {action === Actions.DELETE && (
                <DeleteCategoryQuoteModal
                    onClose={closeModal}
                    refreshQuotesList={quotes.refresh}
                    selectedCategoryQuote={selectedCategoryQuote!}
                />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <PageHeaderTitle>
                        <TbFileInvoice />
                        {t('categories.quotes.page_title')}
                    </PageHeaderTitle>
                    {user.hasPermissions('categories.create') && (
                        <PrimaryContainedAddButton onClick={() => openModal(Actions.CREATE)}>
                            {t('categories.quotes.modals.add.title')}
                        </PrimaryContainedAddButton>
                    )}
                </PageHeader>
                <CategoriesQuotesListTable
                    quotes={quotes.data ?? []}
                    isLoading={quotes.isLoading}
                    openUpdateQuoteModal={(quote: CategoryQuote) => {
                        setSelectedCategoryQuote(quote);
                        openModal(Actions.UPDATE);
                    }}
                    openDeleteQuoteModal={(quote: CategoryQuote) => {
                        setSelectedCategoryQuote(quote);
                        openModal(Actions.DELETE);
                    }}
                />
            </PageContainer>
        </>
    );
};

export default CategoriesQuotesList;
