import { useSelector } from 'react-redux';
import type { AppState } from '../services/ReduxService';

export const useStore = () => {
    const store = useSelector<AppState, AppState>((state) => state);
    return {
        user: store.user,
        caseData: store.caseData,
        settings: store.settings,
        organizations: store.organizations,
    };
};
