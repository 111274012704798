import React from 'react';
import { Actions, ServerFormSection } from '../../../../../types';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    DynamicFormSection,
    LocalizedText,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
} from '../../../../../components';
import { BiPencil } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { Container, Section, SectionContainer, SectionContent } from './FormsSectionsListTable-styles';

type FormSectionsListTableProps = {
    sections: Array<ServerFormSection>;
    openModalHandler: (selectedAction: Actions, section: ServerFormSection) => void;
};

const FormSectionsListTable = ({ sections, openModalHandler }: FormSectionsListTableProps) => {
    const renderSection = (section: ServerFormSection) => {
        return (
            <AccordionContainer key={section.id} triggerUpdate={section}>
                <Accordion level={0}>
                    <AccordionHeader>
                        <AccordionHeaderTitle>
                            [{section.name}] <LocalizedText text={section.title} />
                        </AccordionHeaderTitle>
                        <AccordionHeaderContent>
                            <SecondaryOutlinedButton
                                leadingIcon={<BsTrash />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openModalHandler(Actions.DELETE, section);
                                }}
                            />
                            <PrimaryContainedButton
                                leadingIcon={<BiPencil />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openModalHandler(Actions.UPDATE, section);
                                }}
                            />
                        </AccordionHeaderContent>
                    </AccordionHeader>
                    <AccordionBody>
                        <SectionContainer>
                            <SectionContent>
                                <DynamicFormSection section={section as any} />
                            </SectionContent>
                        </SectionContainer>
                    </AccordionBody>
                </Accordion>
            </AccordionContainer>
        );
    };

    return (
        <Container>
            {sections.map((section) => (
                <Section key={section.id}>{renderSection(section)}</Section>
            ))}
        </Container>
    );
};

export default FormSectionsListTable;
