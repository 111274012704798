import React from 'react';
import { useLocale } from '../../../hooks';
import Modal, { ModalFooter } from '../../Modal';
import { formatLocalizedText } from '../../../tools';
import { TextEditorInput } from '../../Inputs';
import { CaseFormSectionQuestion } from '../../../types';

type DynamicFormQuestionTooltipModalProps = {
    onClose: () => void;
    question: CaseFormSectionQuestion;
};

const DynamicFormQuestionTooltipModal = ({ onClose, question }: DynamicFormQuestionTooltipModalProps) => {
    const locale = useLocale();

    return (
        <Modal title={formatLocalizedText(question.label, locale)} onClose={onClose}>
            <TextEditorInput initialValue={formatLocalizedText(question.tooltip, locale)} isDisabled />
            <ModalFooter confirmationLabel="OK" onClick={onClose} />
        </Modal>
    );
};

export default DynamicFormQuestionTooltipModal;
