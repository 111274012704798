import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.text.tertiary};
`;

export const List = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const ListItem = styled(NavLink)`
    padding: ${({ theme }) => `${theme.box.spacing.md} ${theme.box.spacing.xl} `};
    color: ${({ theme }) => theme.colors.text.main} !important;
    font-size: ${({ theme }) => theme.font.size.md};
    white-space: nowrap;

    &.active {
        font-weight: ${({ theme }) => theme.font.weight.semiBold};
    }

    &:first-child {
        margin-left: 0;
    }

    ${({ theme }) => theme.breakpoints.mobile} {
        width: 100%;
        text-align: center;
    }
`;

export const ActiveIndicator = styled.div<{
    width: number;
    offset: number;
}>`
    position: absolute;
    bottom: 0;
    left: ${({ width }) => `${width * 0.25}px`};
    width: ${({ width }) => `${width * 0.5}px`};
    height: 3px;
    background: ${({ theme }) => theme.colors.primary.extended_50};
    transform: translateX(${({ offset }) => `${offset}px`});
    transition: all 0.3s ease;
`;

export const SelectContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};

    ul > li {
        padding: 0;
        height: 5rem;

        > a {
            height: 5rem;
            display: flex;
            align-items: center;
            padding: ${({ theme }) => `${0} ${theme.box.spacing.md}`};
            color: ${({ theme }) => theme.colors.text.main} !important;
        }
    }
`;
