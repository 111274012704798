import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateId } from '../../tools';
import { HeaderCell, HeaderCellIcon, HeaderCellLabel, HeaderCellLabelText } from './Table-styles';
import { TableColumnConfig, TableProps } from './Table-types';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

const TableHeader = (props: TableProps<any>) => {
    const { t } = useTranslation();

    /**
     * Render icon
     * @param column
     * @return {null | JSX}
     */
    const renderIcon = (column: TableColumnConfig<any>) => {
        if (column.isSortable !== true) return null;
        if (column.id !== props.sortedColumn?.id) return <TiArrowUnsorted />;
        return props.isSortingDown ? <TiArrowSortedDown /> : <TiArrowSortedUp />;
    };

    const onClickCellHandler = (column: TableColumnConfig<any>) => {
        if (column.isSortable === true && typeof props.handleSort === 'function') {
            props.handleSort(column);
        }
    };

    return (
        <React.Fragment>
            {props.columns.map((column) => {
                return (
                    <HeaderCell key={generateId()} column={column} onClick={() => onClickCellHandler(column)}>
                        <HeaderCellLabel>
                            <HeaderCellLabelText>{t(column.headerTitle)}</HeaderCellLabelText>
                            {column.isSortable && <HeaderCellIcon>{renderIcon(column)}</HeaderCellIcon>}
                        </HeaderCellLabel>
                    </HeaderCell>
                );
            })}
        </React.Fragment>
    );
};

export default TableHeader;
