import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { usePromises, useStore } from '../../../../hooks';
import * as Yup from 'yup';

// components
import {
    Form,
    FormCheckboxInputs,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormPasswordInput,
    FormSelectInput,
    FormTextInput,
    Modal,
} from '../../../../components';

// types
import { EmployeeRole } from '../../../../types';
import { APIService } from '../../../../services';
import { passwordRegex, emailRegex, validatePhoneNumber, formatPhoneNumber } from '../../../../tools';
import { CreateEmployeePayload } from '../../../../types';

type Props = {
    onClose: () => void;
    refreshEmployeesList: () => Promise<void>;
};

const CreateEmployeeModal = ({ onClose, refreshEmployeesList }: Props) => {
    const { t } = useTranslation();
    const { list } = useStore().organizations;
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('generic.error.required_field')),
        lastName: Yup.string().required(t('generic.error.required_field')),
        email: Yup.string()
            .required(t('generic.error.required_field'))
            .matches(emailRegex, t('generic.error.invalid_email')),
        organizationInternalId: Yup.string().required(t('generic.error.required_field')),
        password: Yup.string().required(t('generic.error.required_field')).matches(passwordRegex, ' '),
        roles: Yup.array().of(Yup.string()).min(1, t('generic.error.required_field')),
        phoneNumber: Yup.string().test('phoneNumber', t('generic.error.invalid_phone_number'), (value) => {
            if (value) {
                return validatePhoneNumber(value);
            } else {
                return true;
            }
        }),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            organizationInternalId: 'default',
            password: '',
            roles: [],
            phoneNumber: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload: CreateEmployeePayload = {
                    firstName: submittedValues.firstName,
                    lastName: submittedValues.lastName,
                    email: submittedValues.email,
                    password: submittedValues.password,
                    roles: submittedValues.roles,
                    organizationInternalId: submittedValues.organizationInternalId,
                };

                if (submittedValues.phoneNumber.length > 0) {
                    payload.contacts = [
                        {
                            phoneNumber: formatPhoneNumber(submittedValues.phoneNumber),
                        },
                    ];
                }
                await APIService.employees().createEmployee(payload);
                await refreshEmployeesList();
                onClose();
            });
        },
    });

    const organizationsList = useMemo(() => {
        return list.map((organization) => ({
            label: organization.name,
            value: organization.internalId,
        }));
    }, [list]);

    return (
        <Modal
            title="employees.modal.add_employee.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="firstName">
                    <FormGroupLabel>{t('generic.firstName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.firstName')} />
                </FormGroup>
                <FormGroup name="lastName">
                    <FormGroupLabel>{t('generic.lastName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.lastName')} />
                </FormGroup>
                <FormGroup name="email">
                    <FormGroupLabel>{t('generic.email_address')}</FormGroupLabel>
                    <FormTextInput
                        type="email"
                        placeholder={t('generic.email')}
                        autoComplete="new-password"
                    />
                </FormGroup>
                <FormGroup name="organizationInternalId">
                    <FormGroupLabel>{t('generic.organization')}</FormGroupLabel>
                    <FormSelectInput options={organizationsList} />
                </FormGroup>
                <FormGroup name="password" expand>
                    <FormGroupLabel>{t('generic.password')}</FormGroupLabel>
                    <FormPasswordInput
                        placeholder={t('generic.password')}
                        validate
                        autoComplete="new-password"
                    />
                </FormGroup>
                <FormGroup name="phoneNumber" expand>
                    <FormGroupLabel>{t('generic.contact')}</FormGroupLabel>
                    <FormTextInput placeholder={t('generic.contact')} />
                </FormGroup>
                <FormGroup name="roles" expand>
                    <FormGroupLabel>{t('generic.role')}</FormGroupLabel>
                    <FormCheckboxInputs
                        options={[
                            { label: 'Admin', value: EmployeeRole.ADMIN },
                            { label: 'Lawyer', value: EmployeeRole.LAWYER },
                            { label: 'Default', value: EmployeeRole.EMPLOYEE },
                        ]}
                    />
                </FormGroup>
                <FormFooter
                    submitLabel={t('employees.modal.add_employee.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default CreateEmployeeModal;
