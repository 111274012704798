import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TableCell = styled.div<{ isDependent?: boolean; isTotal?: boolean; isPrice?: boolean }>`
    ${({ isDependent, isPrice }) => {
        if (isDependent === true && isPrice !== true) {
            return {
                paddingLeft: '5rem',
            };
        }
    }}

    ${({ isTotal, theme }) => {
        if (isTotal === true) {
            return {
                fontWeight: theme.font.weight.bold,
                cursor: 'default',
            };
        }
    }}
`;

export const Description = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main} !important;
    text-decoration: underline;
`;
