import React, { useCallback, useState } from 'react';
import { LocalizedText, Modal, ModalFooter } from '../../../../../../components';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Dropzone, DropzoneInput, DropzoneMessage } from '../config/styles';
import {
    FileList,
    FileListItem,
    FileListItemLabel,
    FileListItemRemove,
    Header,
} from './UploadFileModal-styles';
import { Case, CaseFolder, CaseFolder_L2 } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../../services';
import { getSupportedFilesByType, supportedFiles } from '../../../../../../config/supported-files';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useLocale, usePromises } from '../../../../../../hooks';
import { formatCaseFolderDescription } from '../../../../../../tools';

type UploadFileModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFolder: CaseFolder_L2;
};

const UploadFileModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
    selectedFolder,
}: UploadFileModalProps) => {
    const locale = useLocale();
    const { t } = useTranslation();
    const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
    const [{ error, clearError, setError, executePromise, isExecutingPromise }] = usePromises();

    const onSubmitHandler = async () => {
        if (uploadedFiles.length === 0) {
            onClose();
            return;
        }

        await executePromise(async () => {
            await APIService.cases().uploadFiles(currentCase.id, currentCaseFolder.id, {
                categoryFileId: selectedFolder.id,
                files: uploadedFiles,
            });
            await refresh();
            onClose();
        });
    };

    const onDrop = useCallback((acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
        setUploadedFiles((prev) => [...prev, ...acceptedFiles]);
        if (fileRejections.length > 0) {
            setError(
                t('case.documents.modals.upload.error.unsupported_file_type', {
                    acceptedFileTypes: Object.values(supportedFiles)
                        .map(({ extension }) => extension)
                        .join(', '),
                })
            );
        }
    }, []);

    const removeFileHandler = (index: number) => {
        setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const supported = getSupportedFilesByType();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: supported,
    });

    return (
        <Modal
            onClose={onClose}
            title={formatCaseFolderDescription(currentCaseFolder, locale)}
            error={error}
            clearError={clearError}
        >
            <Header>
                {t('case.documents.modals.upload.title')} "
                <LocalizedText text={selectedFolder.title} />
                ":
            </Header>
            {uploadedFiles.length > 0 && (
                <FileList>
                    {uploadedFiles.map((uploadedFile, index) => (
                        <FileListItem key={index}>
                            <FileListItemRemove onClick={() => removeFileHandler(index)}>
                                <AiOutlineCloseCircle />
                            </FileListItemRemove>
                            <FileListItemLabel>{uploadedFile.name}</FileListItemLabel>
                        </FileListItem>
                    ))}
                </FileList>
            )}
            <Dropzone {...getRootProps()}>
                <DropzoneInput {...getInputProps()} />
                {isDragActive ? (
                    <DropzoneMessage>{t('case.documents.modals.upload.message_drag_active')}</DropzoneMessage>
                ) : (
                    <DropzoneMessage>
                        {t('case.documents.modals.upload.message_drag_inactive')}
                    </DropzoneMessage>
                )}
            </Dropzone>
            <ModalFooter
                confirmationLabel={t('generic.upload')}
                onClick={onSubmitHandler}
                isLoading={isExecutingPromise}
                isDisabled={uploadedFiles.length === 0}
                secondary={{
                    onClick: onClose,
                }}
            />
        </Modal>
    );
};

export default UploadFileModal;
