import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../types';

export const userSlice = createSlice({
    name: 'user',
    initialState: {} as User,
    reducers: {
        setUser: (state, { payload }: PayloadAction<User>) => {
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.email = payload.email;
            state.preferences = payload.preferences;
            state.isEmployee = payload.isEmployee;
            state.roles = payload.roles;
            state.contacts = payload.contacts;
            state.organization = payload.organization;
        },
        clearUser() {
            return {} as User;
        },
    },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
