import React, { useMemo, useState } from 'react';
import { Modal, ModalFooter } from '../../../../../../components';
import { Case, ExistingClientInformation, NewClientInformation } from '../../../../../../types/cases';
import { useTranslation } from 'react-i18next';
import { usePromises, useToggle } from '../../../../../../hooks';
import { APIService } from '../../../../../../services';
import { ClientInformation, CaseClient } from '../../../../../../types';

import {
    DEFAULT_EXISTING_CLIENT,
    DEFAULT_NEW_CLIENT,
    NewClientForm,
    NewClientFormList,
} from '../../../../../../elements';

type Props = {
    onClose: () => void;
    currentClients: Array<CaseClient>;
    currentCase: Case;
    refreshClients: () => Promise<void>;
};

type EditedClient = NewClientInformation & { index: number };

const AddClientModal = ({ onClose, currentCase, currentClients, refreshClients }: Props) => {
    const { t } = useTranslation();
    const [isAddingNewClient, openAddClientModal, closeAddClientModal] = useToggle(false);
    const [newClients, setNewClients] = useState<Array<NewClientInformation>>([DEFAULT_NEW_CLIENT]);
    const [editedNewClient, setEditedNewClient] = useState<null | EditedClient>(null);
    const [selectedClients, setSelectedClients] = useState<Array<ExistingClientInformation>>([
        DEFAULT_EXISTING_CLIENT,
    ]);
    const [promises, [clients, employees]] = usePromises(
        () => APIService.clients().getAvailableList(),
        () => APIService.employees().getList()
    );

    const updateClient = (client: ClientInformation, isExistingClient: boolean, index: number) => {
        if (isExistingClient === true) {
            setSelectedClients((prev) => {
                const updated = [...prev];
                updated[index] = client;
                return updated;
            });
        } else {
            setNewClients((prev) => {
                const updated = [...prev];
                updated[index] = client as NewClientInformation;
                return updated;
            });
        }
    };

    const addClients = async () => {
        await promises.executePromise(async () => {
            // validate clients
            if (hasInvalidClients() === true) {
                promises.setError('cases.new_case.error.invalid_existing_client');
                return;
            }

            const payload = {
                clients: selectedClients
                    .filter((client) => client.email !== '')
                    .map((client) => {
                        const existingClient = clients.data.find((item) => item.email === client.email)!;
                        return {
                            email: existingClient.email,
                            role: client.role,
                        };
                    }),
                newClients: newClients
                    .filter((newClient) => newClient.email !== '')
                    .map((client) => {
                        const newClient = client as NewClientInformation;
                        return {
                            firstName: newClient.firstName,
                            lastName: newClient.lastName,
                            email: newClient.email,
                            password: newClient.password,
                            contacts:
                                newClient.phoneNumber !== ''
                                    ? [
                                          {
                                              identifier: '',
                                              phoneNumber: newClient.phoneNumber,
                                          },
                                      ]
                                    : [],
                            role: newClient.role,
                        };
                    }),
            };

            if (Object.values(payload).some((payloadItem) => payloadItem.length > 0)) {
                await APIService.cases().addClients(currentCase.id, payload);
                await refreshClients();
            }

            onClose();
        });
    };

    const hasInvalidClients = () => {
        return selectedClients.some(
            (selectedClient) =>
                selectedClient.email.length > 0 &&
                clients.data.some((currentClient) => currentClient.email === selectedClient.email) !== true
        );
    };

    const openAddClientModalHandler = (selectedClient: EditedClient) => {
        setEditedNewClient(selectedClient);
        openAddClientModal();
    };

    const closeAddClientModalHandler = () => {
        setEditedNewClient(null);
        closeAddClientModal();
    };

    const availableClients = useMemo(() => {
        if (clients.data == null) {
            return [];
        }
        return clients.data.filter(
            (client) => currentClients.some((currentClient) => currentClient.email === client.email) === false
        );
    }, [clients.data, currentClients]);

    return (
        <Modal
            title={t('case.clients.modals.add.title')}
            onClose={onClose}
            error={promises.error}
            clearError={promises.clearError}
        >
            {isAddingNewClient === true && (
                <NewClientForm
                    initialValues={editedNewClient!}
                    clients={clients.data}
                    employees={employees.data}
                    handleClient={(client) => updateClient(client, false, newClients.length - 1)}
                    onClose={closeAddClientModalHandler}
                />
            )}
            {isAddingNewClient === false && (
                <>
                    <NewClientFormList
                        isLoading={clients.isLoading}
                        clients={availableClients}
                        selectedClients={selectedClients}
                        setSelectedClients={setSelectedClients}
                        newClients={newClients}
                        setNewClients={setNewClients}
                        openAddClientModal={openAddClientModalHandler}
                    />
                    <ModalFooter
                        confirmationLabel={t('generic.save')}
                        onClick={addClients}
                        secondary={{
                            label: t('generic.cancel'),
                            onClick: onClose,
                        }}
                    />
                </>
            )}
        </Modal>
    );
};

export default AddClientModal;
