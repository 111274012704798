import { Locale } from '../../types';
import { LocalStorageServiceDictionary } from './LocalStorageService-dictionnary';
import { STORED_OCR } from './LocalStorageService-types';
import { isValidStoredOCR } from './LocalStorageService-validators';

class LocalStorageService {
    // ******************************************************
    // CREDENTIALS
    // ******************************************************

    static getCredentials() {
        return {
            accessToken: localStorage.getItem(LocalStorageServiceDictionary.ACCESS_TOKEN),
            refreshToken: localStorage.getItem(LocalStorageServiceDictionary.REFRESH_TOKEN),
        };
    }
    static removeCredentials() {
        localStorage.removeItem(LocalStorageServiceDictionary.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageServiceDictionary.REFRESH_TOKEN);
    }

    static saveCredentials(accessToken: string, refreshToken: string) {
        localStorage.setItem(LocalStorageServiceDictionary.ACCESS_TOKEN, accessToken);
        localStorage.setItem(LocalStorageServiceDictionary.REFRESH_TOKEN, refreshToken);
    }

    // ******************************************************
    // CREDENTIALS
    // ******************************************************
    static getLocale(): Locale | null {
        return localStorage.getItem(LocalStorageServiceDictionary.LOCALE) as Locale;
    }

    static saveLocale(locale: Locale) {
        localStorage.setItem(LocalStorageServiceDictionary.LOCALE, locale);
    }

    // ******************************************************
    // ORGANIZATION
    // ******************************************************
    static getOrganization(): string | null {
        return localStorage.getItem(LocalStorageServiceDictionary.ORGANIZATION);
    }

    static saveOrganization(internalId: string) {
        localStorage.setItem(LocalStorageServiceDictionary.ORGANIZATION, internalId);
    }

    // ******************************************************
    // SIDEBAR WIDTH
    // ******************************************************
    static getSidebarState(): { displayed: boolean; width: number } | null {
        const stored = localStorage.getItem(LocalStorageServiceDictionary.SIDEBAR_WIDTH);
        return stored != null ? JSON.parse(stored) : null;
    }

    static saveSidebarState(payload: { displayed: boolean; width: number }) {
        localStorage.setItem(LocalStorageServiceDictionary.SIDEBAR_WIDTH, JSON.stringify(payload));
    }

    // ******************************************************
    // DASHBOARD CONFIG WIDTH
    // ******************************************************
    static getDashboardConfig(userEmail: string): Object | null {
        const stored = localStorage.getItem(`${LocalStorageServiceDictionary.DASHBOARD_CONFIG}-${userEmail}`);
        return stored != null ? JSON.parse(stored) : null;
    }

    static saveDashboardConfig(userEmail: string, dashboardConfig: Object) {
        localStorage.setItem(
            `${LocalStorageServiceDictionary.DASHBOARD_CONFIG}-${userEmail}`,
            JSON.stringify(dashboardConfig)
        );
    }

    // ******************************************************
    // CASE OCR
    // ******************************************************
    static getCaseOCR(): Array<STORED_OCR> | null {
        const stored = localStorage.getItem(`${LocalStorageServiceDictionary.CASE_OCR}`);
        if (stored != null) {
            const parsed = JSON.parse(stored);
            // Check if each item in the parsed data matches the STORED_OCR type
            if (Array.isArray(parsed) && parsed.every(isValidStoredOCR)) {
                return parsed;
            } else {
                // Handle the case where the stored data doesn't match the type
                localStorage.removeItem(LocalStorageServiceDictionary.CASE_OCR);
                return null;
            }
        } else {
            return null;
        }
    }

    static saveCaseOCR(ocrList: Array<STORED_OCR>) {
        localStorage.setItem(LocalStorageServiceDictionary.CASE_OCR, JSON.stringify(ocrList));
    }
}

export default LocalStorageService;
