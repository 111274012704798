import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    .accordion--0:last-child {
        border-bottom: 0px;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;

    svg {
        fill: ${({ theme }) => theme.colors.primary.extended_50};
        margin-right: ${({ theme }) => theme.box.spacing.md};
        font-size: ${({ theme }) => theme.font.size.xl};
    }
`;

export const GoBack = styled(Link)`
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    > svg {
        font-size: ${({ theme }) => theme.font.size.sm};
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    :hover {
        > svg {
            color: ${({ theme }) => theme.colors.primary.main};
        }
    }
`;

export const Content = styled.div`
    padding: ${({ theme }) => theme.box.spacing.md};
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    min-height: 100%;
`;
