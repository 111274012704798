import React from 'react';
import { Routes } from '../../routes';
import { Container, Content } from './FormsPages-styles';
import { Navbar } from '../../elements';
import { Outlet } from 'react-router-dom';

const FormsPages = () => {
    const list = [
        {
            id: 'forms',
            path: Routes.FORMS_LIST,
            translation: 'forms.navigation.forms_list',
        },
        {
            id: 'groups',
            path: Routes.FORMS_SECTIONS_LIST,
            translation: 'forms.navigation.sections_list',
        },
        {
            id: 'questions',
            path: Routes.FORMS_QUESTIONS_LIST,
            translation: 'forms.navigation.questions_list',
        },
    ];

    return (
        <Container className="container">
            <Navbar list={list} />
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
};

export default FormsPages;
