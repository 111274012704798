import React, { useMemo } from 'react';
import { ClientSummary, ClientInformation, CaseClientRole } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useCaseClientRoles } from '../../../hooks';
import { Client, ClientClickable, Container, Remove } from './NewClientFormItem-styles';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { InputMode, SelectInput, TextInput } from '../../../components';

type NewClientFormItemProps = {
    clients: Array<ClientSummary>;
    selectedClients?: Array<ClientInformation>;
    isLoading: boolean;
    isAllowedToRemoveClient: boolean;
    handleClient: (clientInformation: ClientInformation) => void;
    removeClient: () => void;
    currentClient: ClientInformation;
    openAddClientModal?: () => void;
};

const NewClientFormItem = ({
    clients,
    isLoading,
    isAllowedToRemoveClient,
    handleClient,
    removeClient,
    openAddClientModal,
    currentClient,
    selectedClients = [],
}: NewClientFormItemProps) => {
    const { t } = useTranslation();
    const clientRoles = useCaseClientRoles();

    const onSelectClient = (clientEmail: string) => {
        handleClient({
            role: currentClient.role,
            email: clientEmail,
        });
    };

    const onSelectRole = (clientRole: CaseClientRole) => {
        handleClient({
            ...currentClient,
            role: clientRole,
        });
    };

    const clientsList = useMemo(() => {
        return clients
            .filter((client) => {
                if (client.email === currentClient.email) {
                    return true;
                }
                return (
                    selectedClients.some((selectedClient) => selectedClient.email === client.email) === false
                );
            })
            .map((client) => ({
                label: `${client.firstName} ${client.lastName}`,
                value: client.email,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [clients, selectedClients]);

    return (
        <Container>
            <Remove isDisabled={isAllowedToRemoveClient === false} onClick={() => removeClient()}>
                <AiOutlineCloseCircle />
            </Remove>
            {'firstName' in currentClient ? (
                <Client>
                    <ClientClickable onClick={openAddClientModal} />
                    <TextInput
                        mode={InputMode.FORM}
                        value={
                            currentClient.firstName.length > 0
                                ? `${currentClient.firstName} ${currentClient.lastName}`
                                : ''
                        }
                        disabled
                        trailingIcon={<BsPencil />}
                        placeholder={`${t('cases.new_case.add_new_client_placeholder')}`}
                    />
                </Client>
            ) : (
                <SelectInput
                    mode={InputMode.FORM}
                    value={currentClient.email}
                    options={clientsList}
                    onChange={onSelectClient}
                    onChangeSearchTerm={onSelectClient}
                    isLoading={isLoading}
                    isSearchable={true}
                    isSearchTermRequired={true}
                    maxDisplayedOptions={10}
                    placeholder={t('cases.new_case.add_existing_client_placeholder')}
                />
            )}
            <SelectInput
                value={currentClient.role}
                mode={InputMode.FORM}
                options={clientRoles}
                onChange={onSelectRole}
            />
        </Container>
    );
};

export default NewClientFormItem;
