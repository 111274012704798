import React from 'react';
import {
    PageHeaderActions,
    PageHeaderActionsLeft,
    PageHeaderActionsRight,
    PrimaryContainedAddButton,
    TextInput,
} from '../../../../../components';
import { useLoggedInUser } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';

type Props = {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    onClickAddEmployee: () => void;
};

const EmployeesListActions = ({ searchTerm, setSearchTerm, onClickAddEmployee }: Props) => {
    const { t } = useTranslation();
    const user = useLoggedInUser();

    return (
        <PageHeaderActions>
            <PageHeaderActionsLeft>
                <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    name="searchTerm"
                    placeholder={`${t('employees.search_employee')}...`}
                />
            </PageHeaderActionsLeft>
            <PageHeaderActionsRight>
                {user.hasPermissions('employees.create') && (
                    <PrimaryContainedAddButton onClick={onClickAddEmployee}>
                        {t('employees.new_employee')}
                    </PrimaryContainedAddButton>
                )}
            </PageHeaderActionsRight>
        </PageHeaderActions>
    );
};

export default EmployeesListActions;
