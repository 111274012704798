import styled from 'styled-components';

export const TextGroup = styled.div``;

export const TextGroupKey = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.xs};
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    user-select: none;
`;

export const TextGroupValue = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const RemoveClientButtonContainer = styled.div`
    flex: 1;
    height: 100%;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.danger.main};
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.sm};
    }
`;

export const RemoveClientButton = styled.div`
    display: flex;
    align-items: center;
`;
