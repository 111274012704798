import styled from 'styled-components';

export const Content = styled.div`
    > div {
        margin-bottom: ${({ theme }) => theme.box.spacing.lg};
    }
`;

export const Note = styled.div`
    padding: ${({ theme }) => theme.box.spacing.md};
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};
`;

export const NoteText = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const NoteTimestamp = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    margin-top: ${({ theme }) => theme.box.spacing.md};
`;
