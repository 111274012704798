import React from 'react';
import { Case } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services/APIService';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../components';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../routes';

type Props = {
    onClose: () => void;
    currentCase: Case<{ clients: false; employees: false }>;
};

const DeleteCaseModal = ({ onClose, currentCase }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();
    const navigate = useNavigate();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.cases().archiveCase(currentCase.id);
            navigate(`/${Routes.CASES}`);
            onClose();
        });
    };

    return (
        <Modal title="case.modals.delete.title" onClose={onClose} error={error} clearError={clearError}>
            <ModalParagraph>
                {t('case.modals.delete.message', {
                    internalId: currentCase.internalId,
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('case.modals.delete.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteCaseModal;
