import BaseRequest from './_baseRequest';
import { BaseRequestConfig } from '../APIService.types';
import {
    AddQuotesPayload,
    Case,
    CaseClient,
    CaseEvent,
    CaseFolder,
    CaseForm,
    CaseFormList,
    CaseKeys,
    CaseKeysDefault,
    CaseNote,
    CaseQuote,
    CreateCasePayload,
    Employee,
    LocaleObject,
    OCR_VALIDATION,
    StatusListItem,
} from '../../../types';
import { OrganizationKeys, OrganizationKeysDefault } from '../../../types/organizations';

const defaultQuery: CaseKeysDefault = { clients: true, employees: true };
const defaultOrganizationQuery: OrganizationKeysDefault = {
    id: false,
    config: false,
    theme: false,
    assets: false,
};

class CaseRequest extends BaseRequest {
    /**
     * constructor
     * @param {BaseRequestConfig} config
     */
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    /**
     * Get list of cases
     * @param {Object} [query]
     * @param {Object} [organizationQuery]
     * @return {Promise<Array<CaseListItem>>}
     */
    getList<T extends Partial<CaseKeys>, U extends Partial<OrganizationKeys>>(
        query?: T,
        organizationQuery?: U
    ): Promise<Array<Case<T, U>>> {
        const currentQuery = {
            ...defaultQuery,
            ...(query != null ? query : {}),
            organization: {
                ...defaultOrganizationQuery,
                ...(organizationQuery != null ? organizationQuery : {}),
            },
        };
        return this.get('/', true, currentQuery).then((res: any) => res.cases);
    }

    /**
     * Get list of status
     * @return {Promise<Array>}
     */
    getStatusList(): Promise<Array<StatusListItem>> {
        return this.get('/status', true).then((res: any) => res.status);
    }

    /**
     * Create a new case
     * @param {CreateCasePayload} payload
     * @return {Promise<{ id: string}>}
     */
    createCase(payload: CreateCasePayload): Promise<{ id: string }> {
        return this.post('/', true, payload);
    }

    /**
     * Update case status
     * @param {string} caseId
     * @param {string} status
     * @return {Promise<{}>}
     */
    updateCaseStatus(caseId: string, status: string): Promise<{}> {
        return this.patch(`/${caseId}/status`, true, {
            status: status,
        });
    }

    /**
     * Get one case
     * @param {string} caseId
     * @return {Promise<Case>}
     */
    getCase(caseId: string): Promise<Case<{ clients: false; employees: false }, OrganizationKeysDefault>> {
        return this.get(`/${caseId}`, true);
    }

    /**
     * Archive one case
     * @param {string} caseId
     * @return {Promise<{}>}
     */
    archiveCase(caseId: string): Promise<{}> {
        return this.delete(`/${caseId}`, true);
    }

    /**
     * Get case events
     * @param {string} caseId
     * @return {Promise<Object>}
     */
    getEvents(caseId: string): Promise<Array<CaseEvent>> {
        return this.get(`/${caseId}/events`, true).then((res: any) => res.events);
    }

    // **************************************************************************
    // Employees
    // **************************************************************************

    /**
     * Get list of employees assigned to a case
     * @param {string} caseId
     * @return {Promise<Object>}
     */
    getEmployeesList(caseId: string): Promise<Array<Employee>> {
        return this.get(`/${caseId}/employees`, true).then((res: any) => res.employees);
    }

    /**
     * Add one or multiple employees to a case
     * @param {string} caseId
     * @param {Array<string>} employees
     * @return {Promise<Object>}
     */
    addEmployees(caseId: string, employees: Array<string>): Promise<{}> {
        return this.post(`/${caseId}/employees`, true, { employees: employees });
    }

    /**
     * Remove one employee from case
     * @param {string} caseId
     * @param {string} employeeId
     * @return {Promise<Object>}
     */
    removeEmployee(caseId: string, employeeId: string): Promise<{}> {
        return this.delete(`/${caseId}/employees/${employeeId}`, true);
    }

    // **************************************************************************
    // Clients
    // **************************************************************************

    /**
     * Get list of clients of a case
     * @param {string} caseId
     * @return {Promise<Object>}
     */
    getClientsList(caseId: string): Promise<Array<CaseClient>> {
        return this.get(`/${caseId}/clients`, true).then((res: any) => res.clients);
    }

    /**
     * Add one or multiple clients to a case
     * @param {string} caseId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    addClients(caseId: string, payload: Pick<CreateCasePayload, 'clients' | 'newClients'>): Promise<{}> {
        return this.post(`/${caseId}/clients`, true, payload);
    }

    /**
     * Update a client's role
     * @param {string} caseId
     * @param {string} clientId
     * @param {string} role
     * @return {Promise<{}>}
     */
    updateClient(caseId: string, clientId: string, role: string): Promise<{}> {
        return this.patch(`/${caseId}/clients/${clientId}`, true, {
            role: role,
        });
    }

    /**
     * Remove one client from case
     * @param {string} caseId
     * @param {string} clientId
     * @return {Promise<{}>}
     */
    removeClient(caseId: string, clientId: string): Promise<{}> {
        return this.delete(`/${caseId}/clients/${clientId}`, true);
    }

    // **************************************************************************
    // Quotes
    // **************************************************************************

    /**
     * Get list of quotes of case
     * @param {string} caseId
     * @return {Promise<Object>}
     */
    getQuotesList(caseId: string): Promise<Array<CaseQuote>> {
        return this.get(`/${caseId}/quotes`, true).then((res: any) => res.quotes);
    }

    /**
     * Add initial quotes to case
     * @param {string} caseId
     * @param {Array<AddQuotesPayload>} payload
     * @return {Promise<{ }>}
     */
    addQuotes(caseId: string, payload: { quotes: Array<AddQuotesPayload> }): Promise<{}> {
        return this.post(`/${caseId}/quotes`, true, payload);
    }

    /**
     * Update quote
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    updateQuote(caseId: string, quoteId: string, payload: { clientId: string }) {
        return this.patch(`/${caseId}/quotes/${quoteId}`, true, {
            clientId: payload.clientId,
        });
    }

    /**
     * Remove quote package from case
     * @param {string} caseId
     * @param {string} quoteId
     * @return {Promise<{ void }>}
     */
    removeQuote(caseId: string, quoteId: string): Promise<{}> {
        return this.delete(`/${caseId}/quotes/${quoteId}`, true);
    }

    /**
     * Add quote dependents
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Array<Object>} payload
     * @return {Promise<Object>}
     */
    addQuoteDependents(caseId: string, quoteId: string, payload: Array<{ fullName: string }>): Promise<{}> {
        return this.post(`/${caseId}/quotes/${quoteId}/dependents`, true, payload);
    }

    /**
     * Update quote dependent
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Object} payload
     * @return {Promise<Object>}
     */
    updateQuoteDependent(caseId: string, quoteId: string, payload: { fullName: string }): Promise<{}> {
        return this.patch(`/${caseId}/quotes/${quoteId}/dependents`, true, payload);
    }

    /**
     * Remove quote dependent
     * @param {string} caseId
     * @param {string} quoteId
     */
    removeQuoteDependent(caseId: string, quoteId: string): Promise<{}> {
        return this.delete(`/${caseId}/quotes/${quoteId}/dependents`, true);
    }

    // **************************************************************************
    // Quotes documents
    // **************************************************************************

    /**
     * Get documents list
     * @param {string} caseId
     * @return {Promise}
     */
    getDocumentsList(caseId: string): Promise<Array<CaseFolder>> {
        return this.get(`/${caseId}/quotes-documents`, true).then((res: any) => res.folders);
    }

    /**
     * Upload files
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Object} payload
     * @return {Promise}
     */
    uploadFiles(
        caseId: string,
        quoteId: string,
        payload: { categoryFileId: string; files: Array<File> }
    ): Promise<{}> {
        const formData = new FormData();
        formData.append('data', JSON.stringify({ categoryFileId: payload.categoryFileId }));
        for (const file of payload.files) {
            formData.append('files', file);
        }
        return this.post(`/${caseId}/quotes-documents/${quoteId}`, true, formData, undefined, {
            useMultiPartFormData: true,
        });
    }

    /**
     * Move files
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Object} payload
     * @return {Promise}
     */
    moveFiles(
        caseId: string,
        quoteId: string,
        payload: { files: Array<{ fileId: string; quoteId: string; categoryFileId: string }> }
    ): Promise<{}> {
        return this.patch(`/${caseId}/quotes-documents/${quoteId}`, true, payload);
    }

    /**
     * Remove file
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Object} payload
     * @return {Promise}
     */
    removeFiles(caseId: string, quoteId: string, payload: { fileIds: Array<string> }): Promise<{}> {
        return this.delete(`/${caseId}/quotes-documents/${quoteId}`, true, payload);
    }

    /**
     * Update hidden files
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Object} payload
     * @return {Promise}
     */
    updateHiddenFiles(
        caseId: string,
        quoteId: string,
        payload: { hiddenCategoryFileIds: Array<string> }
    ): Promise<{}> {
        return this.post(`/${caseId}/quotes-documents/${quoteId}/update/hidden`, true, payload);
    }

    /**
     * Update validated files
     * @param {string} caseId
     * @param {string} quoteId
     * @param {Object} payload
     * @return {Promise}
     */
    updateValidatedFiles(
        caseId: string,
        quoteId: string,
        payload: { validatedCategoryFileIds: Array<string>; areValid: boolean }
    ): Promise<{}> {
        return this.post(`/${caseId}/quotes-documents/${quoteId}/update/validated`, true, payload);
    }

    /**
     * Update file
     * @param {string} caseId
     * @param {string} quoteId
     * @param {string} fileId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    updateFile(
        caseId: string,
        quoteId: string,
        fileId: string,
        payload: { originalName: string }
    ): Promise<{}> {
        return this.patch(`/${caseId}/quotes-documents/${quoteId}/files/${fileId}`, true, payload);
    }

    /**
     * Execute OCR on file
     * @param {string} caseId
     * @param {string} quoteId
     * @param {string} fileId
     * @return {Promise<{}>}
     */
    executeOCR(
        caseId: string,
        quoteId: string,
        fileId: string
    ): Promise<{
        text: string;
        results?: Array<{ key: string; label: LocaleObject; value: string }>;
        validations?: Array<OCR_VALIDATION>;
    }> {
        return this.post(`/${caseId}/quotes-documents/${quoteId}/files/${fileId}/ocr/execute`, true);
    }

    /**
     * update OCR on file
     * @param {string} caseId
     * @param {string} quoteId
     * @param {string} fileId
     * @param {Array} results
     * @return {Promise<{}>}
     */
    updateOCR(
        caseId: string,
        quoteId: string,
        fileId: string,
        results: Array<{ key: string; value: string | number; index?: number }>
    ): Promise<{
        results: Array<{ key: string; label: LocaleObject; value: string }>;
    }> {
        return this.patch(`/${caseId}/quotes-documents/${quoteId}/files/${fileId}/ocr/update`, true, {
            results,
        });
    }

    /**
     * Acknowledge OCR on file
     * @param {string} caseId
     * @param {string} quoteId
     * @param {string} fileId
     * @return {Promise<{}>}
     */
    acknowledgeOCR(caseId: string, quoteId: string, fileId: string, key: string): Promise<{}> {
        return this.post(`/${caseId}/quotes-documents/${quoteId}/files/${fileId}/ocr/acknowledge`, true, {
            key: key,
        });
    }

    /**
     * Get file url
     * @param {string} caseId
     * @param {string} quoteId
     * @param {string} fileId
     * @return {Promise<Object>}
     */
    getFileUrl(caseId: string, quoteId: string, fileId: string): Promise<{ path: string; expiredAt: Date }> {
        return this.get(`/${caseId}/quotes-documents/${quoteId}/files/${fileId}/url`, true);
    }

    // **************************************************************************
    // Notes
    // **************************************************************************

    /**
     * Get notes list for a case
     * @param {string} caseId
     * @return {Promise<Object>}
     */
    getNotesList(caseId: string): Promise<Array<CaseNote>> {
        return this.get(`/${caseId}/notes`, true).then((res: any) => res.notes);
    }

    /**
     * Add note
     * @param {String} caseId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    addNote(
        caseId: string,
        payload: { title: string; content: string; isVisibleForClient: boolean }
    ): Promise<void> {
        return this.post(`/${caseId}/notes`, true, payload);
    }

    /**
     * Update note
     * @param {String} caseId
     * @param {String} noteId
     * @param {Object} payload
     * @return {Promise<void>}
     */
    updateNote(
        caseId: string,
        noteId: string,
        payload: { title?: string; content?: string; isVisibleForClient?: boolean }
    ): Promise<void> {
        return this.patch(`/${caseId}/notes/${noteId}`, true, payload);
    }

    /**
     * Add notes
     * @param {String} caseId
     * @param {String} noteId
     * @return {Promise<void>}
     */
    removeNote(caseId: string, noteId: string): Promise<void> {
        return this.delete(`/${caseId}/notes/${noteId}`, true);
    }

    // **************************************************************************
    // Notes
    // **************************************************************************

    /**
     * Get notes list for a case
     * @param {string} caseId
     * @return {Promise<Object>}
     */
    getFormsList(caseId: string): Promise<CaseFormList> {
        return this.get(`/${caseId}/forms`, true).then((res: any) => res.forms);
    }

    /**
     * Add form
     * @param {String} caseId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    addForm(caseId: string, payload: { clientId: string; formId: string }): Promise<void> {
        return this.post(`/${caseId}/forms`, true, payload);
    }

    /**
     * Add form
     * @param {String} caseId
     * @param {String} formId
     * @return {Promise<{}>}
     */
    getForm(caseId: string, formId: string): Promise<CaseForm> {
        return this.get(`/${caseId}/forms/${formId}`, true);
    }

    /**
     * Remove form
     * @param {String} caseId
     * @param {String} formId
     * @return {Promise<{}>}
     */
    removeForm(caseId: string, formId: string): Promise<void> {
        return this.delete(`/${caseId}/forms/${formId}`, true);
    }

    /**
     * Update form display
     * @param caseId
     * @param formId
     * @param payload
     * @return {Promise<void>}
     */
    updateFormDisplay(
        caseId: string,
        formId: string,
        payload: {
            sections: Array<{
                sectionId: string;
                sectionInternalId: string;
                isDisplayed: boolean;
                questions: Array<{ questionId: string; isDisplayed: boolean }>;
            }>;
        }
    ) {
        return this.post(`/${caseId}/forms/${formId}/display`, true, payload);
    }

    /**
     * Update form answers
     * @param {String} caseId
     * @param {String} formId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    updateFormAnswers(
        caseId: string,
        formId: string,
        payload: {
            answers: Array<{
                sectionId: string;
                sectionInternalId: string;
                questions: Array<{
                    questionId: string;
                    value: string;
                }>;
            }>;
        }
    ) {
        return this.post(`/${caseId}/forms/${formId}/answers`, true, payload);
    }

    /**
     * Update form validated answers
     * @param {String} caseId
     * @param {String} formId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    updateFormValidatedAnswers(
        caseId: string,
        formId: string,
        payload: {
            answers: Array<{
                sectionId: string;
                sectionInternalId: string;
                questionId: string;
                isValid: boolean;
            }>;
        }
    ) {
        return this.post(`/${caseId}/forms/${formId}/answers/validated`, true, payload);
    }
}

export default CaseRequest;
