import React from 'react';
import { ServerFormSection } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../components';

type Props = {
    onClose: () => void;
    refreshSectionsList: () => Promise<void>;
    selectedSection: ServerFormSection;
};

const DeleteFormsSectionModal = ({ onClose, refreshSectionsList, selectedSection }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.forms().archiveSection(selectedSection.id);
            await refreshSectionsList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('forms.sections.modals.delete.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('forms.sections.modals.delete.message', { sectionName: selectedSection.name })}
            </ModalParagraph>

            <ModalFooter
                confirmationLabel={t('forms.sections.modals.delete.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteFormsSectionModal;
