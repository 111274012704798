import styled from 'styled-components';

export const Container = styled.label`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.xs};
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    user-select: none;

    &.required:after {
        content: '*';
        color: ${({ theme }) => theme.colors.danger.main};
        display: inline;
    }
`;
