import React from 'react';
import { Container } from './Backdrop-styles';

type Props = {
    children: React.ReactNode;
};

const Backdrop = ({ children }: Props) => {
    return <Container>{children}</Container>;
};

export default Backdrop;
