import React, { useMemo } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle, PrimaryContainedButton } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useLocale, useLoggedInUser, useToggle } from '../../../hooks';
import { BsPencil } from 'react-icons/bs';
import { SettingsList } from '../components';
import { MdOutlineLockPerson } from 'react-icons/md';
import { SettingsUpdatePasswordModal } from '../modals';

const SettingsCredentials = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const { user } = useLoggedInUser();
    const [isOpened, openModal, closeModal] = useToggle(false);

    const list = useMemo(() => {
        return [
            {
                title: t('generic.email'),
                value: user.email,
            },
            {
                title: t('generic.password'),
                value: '*'.repeat(10),
            },
        ];
    }, [locale]);

    return (
        <>
            {isOpened && <SettingsUpdatePasswordModal onClose={closeModal} />}

            <PageContainer>
                <PageHeader display="row">
                    <PageHeaderTitle>
                        <MdOutlineLockPerson /> {t('settings.credentials.title')}
                    </PageHeaderTitle>
                    <PrimaryContainedButton leadingIcon={<BsPencil />} onClick={openModal}>
                        {t('settings.modals.change_password.title')}
                    </PrimaryContainedButton>
                </PageHeader>
                <SettingsList list={list} />
            </PageContainer>
        </>
    );
};

export default SettingsCredentials;
