import styled from 'styled-components';
import { InputMode } from '../../../Inputs-types';

export const Container = styled.div<{ displayListLeftSide: boolean; maxHeight: number }>`
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    width: 100%;
    min-width: max-content;
    z-index: 3;
    background: ${({ theme }) => theme.colors.surface.main};
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    overflow-y: overlay;

    label {
        width: 100%;
        padding-top: ${({ theme }) => theme.box.spacing.sm};
        padding-bottom: ${({ theme }) => theme.box.spacing.sm};
        margin: 0;
        padding-left: 4rem;
        padding-right: 1rem;

        :hover {
            background: ${({ theme }) => theme.colors.onSurface.main};
        }

        .checkmark {
            left: ${({ theme }) => theme.box.spacing.sm};
        }
    }

    ${({ displayListLeftSide }) => {
        if (displayListLeftSide === true) {
            return {
                left: 'unset',
                right: 0,
            };
        }
    }}
`;

export const Item = styled.div<{ isHovered?: boolean; enableHover: boolean; mode: InputMode }>`
    cursor: pointer;

    ${({ enableHover, theme }) => {
        if (enableHover === true) {
            return {
                ':hover': {
                    background: theme.colors.onSurface.main,
                },
            };
        }
    }}

    ${({ isHovered, theme }) => {
        if (isHovered === true) {
            return {
                background: theme.colors.onSurface.main,
            };
        }
    }}
`;

export const Empty = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: ${({ theme }) => theme.box.spacing.md};
`;
