import React, { useMemo, useState } from 'react';
import {
    Banner,
    BannerAppearance,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormMultiSelectInput,
    FormSelectInput,
    PageHeaderTitle,
    UserAvatar,
    UserAvatarSize,
} from '../../../components';
import { Container, Modal, Content } from './NewCase-styles';
import { BiFolder } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewCaseClientsList } from './components';
import { useTranslation } from 'react-i18next';
import {
    ClientInformation,
    CreateCasePayload,
    Employee,
    EmployeeRole,
    ExistingClientInformation,
    NewClientInformation,
} from '../../../types';
import { APIService } from '../../../services';
import { useLocale, useLoggedInUser, usePromises, useToggle } from '../../../hooks';
import { Routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { ResourceKey } from 'i18next';
import { DEFAULT_EXISTING_CLIENT, DEFAULT_NEW_CLIENT, NewClientForm } from '../../../elements';
import { formatLocalizedText } from '../../../tools';

type EditedClient = NewClientInformation & { index: number };

const NewCase = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const navigate = useNavigate();
    const user = useLoggedInUser();
    const belongsToDefaultOrganization = user.belongsToDefaultOrganization();

    const [isAddingNewClient, openAddClientModal, closeAddClientModal] = useToggle(false);
    const [newClients, setNewClients] = useState<Array<NewClientInformation>>([DEFAULT_NEW_CLIENT]);
    const [editedNewClient, setEditedNewClient] = useState<null | EditedClient>(null);
    const [selectedClients, setSelectedClients] = useState<Array<ExistingClientInformation>>([
        DEFAULT_EXISTING_CLIENT,
    ]);
    const [promises, [categories, clients, employees, organizations]] = usePromises(
        () => APIService.categories().getList(),
        () => APIService.clients().getAvailableList(),
        () => APIService.employees().getList(),
        () => (belongsToDefaultOrganization ? APIService.organizations().getList() : Promise.resolve([]))
    );

    const updateClient = (client: ClientInformation, isExistingClient: boolean, index: number) => {
        if (isExistingClient === true) {
            setSelectedClients((prev) => {
                const updated = [...prev];
                updated[index] = client;
                return updated;
            });
        } else {
            setNewClients((prev) => {
                const updated = [...prev];
                updated[index] = client as NewClientInformation;
                return updated;
            });
        }
    };

    const openAddClientModalHandler = (selectedClient: EditedClient) => {
        setEditedNewClient(selectedClient);
        openAddClientModal();
    };

    const closeAddClientModalHandler = () => {
        setEditedNewClient(null);
        closeAddClientModal();
    };

    const validationSchema = Yup.object({
        organizationId: Yup.string().required(t('generic.error.required_field')),
        categoryId: Yup.string().required(t('generic.error.required_field')),
        employees: Yup.array()
            .of(Yup.string())
            .min(1, t('generic.error.required_field'))
            .required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            organizationId: 'default',
            categoryId: '',
            employees: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            // check if at least one lawyer has been assigned
            if (hasSelectedLawyer(submittedValues.employees) === false) {
                promises.setError('cases.new_case.error.lawyer_required');
                return;
            }

            //  check if at least one client has been added
            if (hasAddedClient() === false) {
                promises.setError('cases.new_case.error.client_required');
                return;
            }

            // validate clients
            if (hasInvalidClients() === true) {
                promises.setError('cases.new_case.error.invalid_existing_client');
                return;
            }

            await promises.executePromise(async () => {
                const payload: CreateCasePayload = {
                    categoryId: submittedValues.categoryId,
                    employees: submittedValues.employees,
                    organizationId:
                        submittedValues.organizationId === 'default'
                            ? undefined
                            : submittedValues.organizationId,
                    clients: selectedClients
                        .filter((client) => client.email !== '')
                        .map((client) => {
                            const currentClient = clients.data.find((item) => item.email === client.email)!;
                            return {
                                email: currentClient.email,
                                role: client.role,
                            };
                        }),
                    newClients: newClients
                        .filter((newClient) => newClient.email !== '')
                        .map((newClient) => {
                            return {
                                firstName: newClient.firstName,
                                lastName: newClient.lastName,
                                email: newClient.email,
                                password: newClient.password,
                                contacts:
                                    newClient.phoneNumber !== ''
                                        ? [
                                              {
                                                  identifier: '',
                                                  phoneNumber: newClient.phoneNumber,
                                              },
                                          ]
                                        : [],
                                role: newClient.role,
                            };
                        }),
                };
                const response = await APIService.cases().createCase(payload);
                navigate(`/${Routes.CASES}/${response.id}`);
            });
        },
    });

    const hasInvalidClients = () => {
        return selectedClients.some(
            (selectedClient) =>
                selectedClient.email.length > 0 &&
                clients.data.some((currentClient) => currentClient.email === selectedClient.email) !== true
        );
    };

    const hasSelectedLawyer = (employeeIds: Array<String>): boolean => {
        return employeeIds.some((employeeId) =>
            employees.data.find((employee) => employee.id === employeeId)?.roles.includes(EmployeeRole.LAWYER)
        );
    };

    const hasAddedClient = () => {
        return (
            (selectedClients.length > 0 && selectedClients.some((client) => client.email.length > 0)) ||
            (newClients.length > 0 && newClients.some((client) => client.email.length > 0))
        );
    };

    const categoriesList = useMemo(() => {
        if (categories.data == null) {
            return [];
        }

        return categories.data.map((category) => ({
            label: `${category.internalId} - ${formatLocalizedText(category.title, locale)}`,
            value: category.id,
        }));
    }, [categories.isLoading]);

    const organizationsList = useMemo(() => {
        if (organizations.data == null) {
            return [];
        }

        return [
            {
                label: 'Naia',
                value: 'default',
            },
            ...organizations.data.map((organization) => ({
                label: organization.name,
                value: organization.id!,
            })),
        ];
    }, [organizations.isLoading]);

    return (
        <Container>
            <Modal>
                <PageHeaderTitle>
                    {isAddingNewClient ? <FiUsers /> : <BiFolder />}
                    {t(isAddingNewClient ? 'cases.new_case.add_new_client' : 'cases.new_case.create_case')}
                </PageHeaderTitle>
                <Content>
                    {promises.error && (
                        <Banner closeBanner={promises.clearError} appearance={BannerAppearance.ERROR}>
                            {promises.error === true
                                ? t('generic.error.unknown')
                                : t(promises.error as ResourceKey)}
                        </Banner>
                    )}
                    {isAddingNewClient === true && (
                        <NewClientForm
                            initialValues={editedNewClient!}
                            clients={clients.data}
                            employees={employees.data}
                            handleClient={(client) => updateClient(client, false, newClients.length - 1)}
                            onClose={closeAddClientModalHandler}
                        />
                    )}
                    {isAddingNewClient === false && (
                        <Form
                            formik={formik}
                            validationSchema={validationSchema}
                            isLoading={promises.isInitializing}
                        >
                            <FormGroup
                                name="categoryId"
                                expand={user.belongsToDefaultOrganization() === false}
                            >
                                <FormGroupLabel>{t('generic.category')}</FormGroupLabel>
                                <FormSelectInput options={categoriesList} />
                            </FormGroup>
                            {user.belongsToDefaultOrganization() === true && (
                                <FormGroup name="organizationId">
                                    <FormGroupLabel>{t('generic.organization')}</FormGroupLabel>
                                    <FormSelectInput options={organizationsList} />
                                </FormGroup>
                            )}
                            <FormGroup name="employees" expand>
                                <FormGroupLabel>{t('generic.assigned_employees')}</FormGroupLabel>
                                <FormMultiSelectInput<string, Employee>
                                    isLoading={promises.isInitializing || employees.isLoading}
                                    options={(employees.data ?? []).map((employee) => ({
                                        value: employee.id,
                                        label: <UserAvatar {...employee} />,
                                        data: employee,
                                    }))}
                                    renderOptionLabel={(_, employee) => (
                                        <UserAvatar {...employee!} size={UserAvatarSize.SMALL} />
                                    )}
                                    getSearchValue={(employee) =>
                                        `${employee.data?.firstName} ${employee.data?.lastName}`
                                    }
                                    allOption={false}
                                />
                            </FormGroup>
                            <NewCaseClientsList
                                isLoading={promises.isInitializing || clients.isLoading}
                                clients={clients.data ?? []}
                                selectedClients={selectedClients}
                                setSelectedClients={setSelectedClients}
                                newClients={newClients}
                                setNewClients={setNewClients}
                                openAddClientModal={openAddClientModalHandler}
                            />
                            <FormFooter submitLabel={t('cases.new_case.create_case')} />
                        </Form>
                    )}
                </Content>
            </Modal>
        </Container>
    );
};

export default NewCase;
