import React, { forwardRef, InputHTMLAttributes } from 'react';
import { InputMode } from '../Inputs-types';
import {
    Container,
    TextArea,
    Input,
    InputInnerLabel,
    TrailingIconContainer,
    LeadingIconContainer,
} from './TextInput-styles';

export type TextInputProps = InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    trailingIcon?: React.ReactNode;
    leadingIcon?: React.ReactNode;
    mode?: InputMode;
    label?: string;
    textarea?: boolean;
};

const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(
    (
        {
            trailingIcon,
            leadingIcon,
            mode = InputMode.STAND_ALONE,
            label,
            textarea = false,
            ...rest
        }: TextInputProps,
        ref
    ) => {
        return (
            <Container mode={mode} className="input-container" textarea={textarea}>
                {leadingIcon != null && (
                    <LeadingIconContainer className="input-container__leading-icon">
                        {leadingIcon}
                    </LeadingIconContainer>
                )}
                {label != null && <InputInnerLabel>{label}:</InputInnerLabel>}
                {textarea ? (
                    <TextArea {...rest} ref={ref as React.ForwardedRef<HTMLTextAreaElement>} mode={mode} />
                ) : (
                    <Input {...rest} ref={ref as React.ForwardedRef<HTMLInputElement>} mode={mode} />
                )}
                {trailingIcon && (
                    <TrailingIconContainer className="input-container__trailing-icon">
                        {trailingIcon}
                    </TrailingIconContainer>
                )}
            </Container>
        );
    }
);

export default TextInput;
