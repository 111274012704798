import styled from 'styled-components';

export const Container = styled.div`
    padding: ${({ theme }) => theme.box.spacing.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
`;

export const Quote = styled.div`
    display: flex;
    align-items: center;
`;

export const QuoteCheckbox = styled.div`
    width: 4rem;
`;

export const QuoteLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    flex: 1;
`;

export const QuotePrice = styled.div`
    text-align: end;
    width: 10rem;
    font-size: ${({ theme }) => theme.font.size.md};
    padding-right: ${({ theme }) => theme.box.spacing.md};
`;

export const QuoteButtons = styled.div<{ isActive: boolean }>`
    width: 12rem;
    display: flex;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-radius: 4px;
    overflow: hidden;
    height: 3rem;

    ${({ isActive }) => {
        if (isActive !== true) {
            return {
                borderColor: 'transparent',
            };
        }
    }}
`;

export const QuoteButton = styled.div<{ isDisabled?: boolean }>`
    width: 4rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.onSurface.main};
    cursor: pointer;

    svg {
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.onSurface.secondary};
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                cursor: 'not-allowed',
                ':hover': {
                    background: theme.colors.onSurface.main,
                },
            };
        }
    }}
`;

export const QuoteButtonText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-right: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    width: 4rem;
`;
