import styled from 'styled-components';

export const Container = styled.div`
    grid-column: 1 / -1;
    background-color: ${({ theme }) => theme.colors.background.secondary};
    padding: ${({ theme }) => theme.box.spacing.sm};
    padding-top: ${({ theme }) => theme.box.spacing.xxl};
    border-radius: 4px;
    position: relative;
`;

export const Remove = styled.div<{ isDisabled: boolean }>`
    position: absolute;
    top: ${({ theme }) => theme.box.spacing.md};
    right: ${({ theme }) => theme.box.spacing.md};
    width: min-content;

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
    }
    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                svg: {
                    color: theme.colors.text.disabled,
                    cursor: 'not-allowed',
                },
            };
        }
    }}
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: ${({ theme }) => theme.font.size.md};
    gap: ${({ theme }) => theme.box.spacing.md};
    padding: 0 ${({ theme }) => theme.box.spacing.sm};
`;

export const InfoItem = styled.div`
    flex: 1;
`;

export const FilesList = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.box.spacing.md};
`;

export const FilesListTitle = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-left: ${({ theme }) => theme.box.spacing.sm};
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
`;

export const FilesListItem = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const FilesListItemRemove = styled.div<{ isDisabled: boolean }>`
    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                svg: {
                    color: theme.colors.text.disabled,
                    cursor: 'not-allowed',
                },
            };
        }
    }}
`;

export const FilesListItemInput = styled.div`
    width: 100%;
`;

export const AddFile = styled.div`
    margin: ${({ theme }) => theme.box.spacing.md} 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const AddFileText = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;
`;
