import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeRole } from '../../types';
import { Container } from './EmployeeBadge-styles';

type Props = {
    role: EmployeeRole;
};

const EmployeeBadge = ({ role }: Props) => {
    const { t } = useTranslation();
    return (
        <Container role={role} className="employee-badge">
            {t(`generic.employee_role.${role}`)}
        </Container>
    );
};

export default EmployeeBadge;
