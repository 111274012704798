import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../../../types';

export type REDUX_Organization = Organization<{ config: true; theme: true; assets: true }>;

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState: {
        activeOrganizationInternalId: 'default',
        list: [] as Array<REDUX_Organization>,
    },
    reducers: {
        setOrganizationsList: (state, { payload }: PayloadAction<Array<REDUX_Organization>>) => {
            state.list = payload;
        },
        setActiveOrganizationInternalId: (state, { payload }: PayloadAction<string>) => {
            state.activeOrganizationInternalId = payload;
        },
    },
});

export const { setOrganizationsList, setActiveOrganizationInternalId } = organizationsSlice.actions;
export default organizationsSlice.reducer;
