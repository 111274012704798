import React from 'react';
import { generateId } from '../helpers';

/**
 * Flatten children
 * @param {React.ReactNode} children
 * @return {React.ReactNode}
 */
export const flattenChildren = (children: React.ReactNode) => {
    let flattenedChildren: Array<React.ReactNode> = [];

    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child) && child.props.children) {
            // If the child is a valid element with children, add its children to the flattened array
            // flattenedChildren = flattenedChildren.concat(React.Children.toArray(child.props.children));
            flattenedChildren = flattenedChildren.concat(
                React.Children.toArray(child.props.children).map((flattenedChild) => {
                    // Generate a unique key for each child
                    const uniqueKey = generateId();
                    return React.cloneElement(flattenedChild as any, { key: uniqueKey });
                })
            );
        }
        //  else {
        //     // If the child has no children or is not a valid element, just add it to the flattened array
        //     flattenedChildren.push(child);
        // }
    });

    return flattenedChildren;
};
