import styled from 'styled-components';
import { InputMode } from '../../../Inputs-types';

export const Container = styled.div<{ isLoading: boolean; mode?: InputMode }>`
    width: 100%;
    display: flex;
    border-radius: 0.8rem;
    background: ${({ theme }) => theme.colors.surface.main};
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    overflow: hidden;

    * {
        cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
    }

    ${({ mode, theme }) => {
        if (mode === InputMode.STAND_ALONE) {
            return {
                borderRadius: '0.3rem',
                minHeight: `${theme.config.input.height.small}px`,
                maxHeight: `${theme.config.input.height.small}px`,
            };
        }
    }}
`;

export const Content = styled.div<{ mode: InputMode; isOpened: boolean; hasLabel?: boolean }>`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    z-index: 1;
    font-size: ${({ theme }) => theme.font.size.lg};
    padding: ${({ theme }) => theme.box.spacing.sm};
    flex: 1;
    min-height: ${({ theme }) => theme.config.input.height.default}px;

    ${({ mode, theme }) => {
        if (mode === InputMode.STAND_ALONE) {
            return {
                borderRadius: '0.3rem',
                maxHeight: `${theme.config.input.height.small}px`,
            };
        }
    }}

    > .input-container {
        border: none;
        input {
            padding: 0px;
            border: none;
        }
    }

    ${({ mode, theme, isOpened }) => {
        if (mode === InputMode.STAND_ALONE) {
            return {
                height: `${theme.config.input.height.small}px`,
                minHeight: `${theme.config.input.height.small}px`,
                maxHeight: `${theme.config.input.height.small}px`,
                padding: 'unset',
                paddingLeft: theme.box.spacing.md,
                paddingRight: 0,
                '> .input-container': {
                    order: 1,
                    width: isOpened ? '200px' : 'max-content',
                    height: 'max-content',
                    '> div': {
                        marginRight: '5px',
                    },
                    input: {
                        display: isOpened ? 'initial' : 'none',
                    },
                },
                '> .multi-select-input__content__label_item': {
                    order: 2,
                },
            };
        } else {
            return {
                '> .input-container': {
                    width: 'unset',
                    flex: 1,
                    minWidth: '200px',
                    height: `${theme.config.input.height.small * 0.8}px`,

                    display: isOpened ? 'initial' : 'none',
                    input: {
                        height: `${theme.config.input.height.small * 0.8}px`,
                    },
                },
            };
        }
    }}
`;

export const ContentFormItem = styled.div`
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    border-radius: 1.6rem;
    display: flex;
    align-items: center;
    user-select: none;
    height: ${({ theme }) => theme.config.input.height.small * 0.8}px;
    padding: 4px;
`;

export const ContentFormItemRemove = styled.div`
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.onSurface.secondary};
    margin-left: 5px;

    svg {
        font-size: 10px;
    }
`;

export const ContentToggleIcon = styled.div<{ mode: InputMode }>`
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: ${({ theme, mode }) =>
        mode === InputMode.FORM ? theme.config.input.height.default : theme.config.input.height.small}px;

    svg {
        font-size: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const ContentLabel = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const ContentLabelText = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    line-height: 1.4;
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    display: flex;
    align-items: center;
`;

export const ContentLabelValue = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    flex: 1;
`;
