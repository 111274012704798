import React, { useMemo } from 'react';
import { InputMode } from '../../../Inputs-types';
import {
    Container,
    Content,
    ContentFormItem,
    ContentFormItemRemove,
    ContentLabel,
    ContentLabelText,
    ContentLabelValue,
    ContentToggleIcon,
} from './MultiSelectInputLabel-styles';
import TextInput from '../../../TextInput';
import Spinner, { SpinnerAppearance } from '../../../../Spinner';
import { AiOutlineClose } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { generateId } from '../../../../../tools';
import { MultiSelectInputOption } from '../../MultiSelectInput';
import { useTranslation } from 'react-i18next';

type Options<T, U> = Array<MultiSelectInputOption<T, U>>;

type MultiSelectInputLabelProps<T, U> = {
    onClickContentHandler: () => void;
    mode: InputMode;
    value: Array<T>;
    isLoading: boolean;
    isOpened: boolean;
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    inputRef: React.RefObject<HTMLInputElement>;
    isSearchable: boolean;
    label?: string;
    onChange: (currentOptions: Array<T>) => void;
    renderOptionLabel?: (value: T, data?: U) => string | React.ReactNode;
    options: Options<T, U>;
    useAllOption: boolean;
};

const MultiSelectInputLabel = <T, U>({
    onClickContentHandler,
    mode,
    value,
    isLoading,
    isOpened,
    searchTerm,
    setSearchTerm,
    inputRef,
    isSearchable,
    label,
    onChange,
    renderOptionLabel,
    options,
    useAllOption,
}: MultiSelectInputLabelProps<T, U>) => {
    const { t } = useTranslation();

    const { selectedAll, selectedCount } = useMemo(() => {
        return {
            selectedCount: value.length,
            selectedAll:
                value.length ===
                options.filter((option) => option.onClickCustom == null).length - (useAllOption ? 1 : 0),
        };
    }, [value, options, useAllOption]);

    /**
     * Render label
     * @param itemValue
     * @return {string | React.ReactNode}
     */
    const renderLabelHandler = (itemValue: T): string | React.ReactNode => {
        const currentOption = options.find((option) => option.value === itemValue);
        if (currentOption == null) {
            return '';
        }
        if (typeof renderOptionLabel === 'function') {
            return renderOptionLabel(itemValue, currentOption.data);
        } else {
            return currentOption?.label;
        }
    };

    return (
        <Container
            onClick={onClickContentHandler}
            isLoading={isLoading}
            mode={mode}
            className="multi-select-input__content"
        >
            <Content
                mode={mode}
                isOpened={isOpened as boolean}
                className="multi-select-input__content__label"
                hasLabel={label != null && label?.length > 0}
            >
                {mode === InputMode.FORM &&
                    value.map((itemValue) => (
                        <ContentFormItem
                            key={generateId()}
                            className="multi-select-input__content__label_item"
                        >
                            {renderLabelHandler(itemValue)}
                            <ContentFormItemRemove
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange(value.filter((i) => i !== itemValue));
                                }}
                            >
                                <AiOutlineClose />
                            </ContentFormItemRemove>
                        </ContentFormItem>
                    ))}

                {isSearchable === true ? (
                    <TextInput
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                        mode={mode}
                        ref={inputRef}
                        label={label}
                    />
                ) : (
                    <ContentLabel>
                        <ContentLabelText>{label}: </ContentLabelText>
                        <ContentLabelValue>
                            {selectedAll ? t('generic.all') : t('generic.selected', { count: selectedCount })}
                        </ContentLabelValue>
                    </ContentLabel>
                )}
            </Content>
            <ContentToggleIcon className="multi-select-input__content__icon" mode={mode}>
                {isLoading === true ? <Spinner appearance={SpinnerAppearance.PRIMARY} /> : <BiChevronDown />}
            </ContentToggleIcon>
        </Container>
    );
};

export default MultiSelectInputLabel;
