import { parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string): string => {
    try {
        if (typeof phoneNumber !== 'string') {
            return phoneNumber;
        }

        let parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'CA');
        if (parsedPhoneNumber == null) {
            parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US');
        }

        return parsedPhoneNumber ? parsedPhoneNumber.formatInternational().replace('ext.', 'x') : phoneNumber;
    } catch (err) {
        return phoneNumber;
    }
};
