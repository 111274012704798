export const colors = {
    primary: {
        main: '#1f3966',
        extended_50: '#4e6fa8',
        extended_10: '#e1eaef',
    },
    text: {
        main: 'rgb(23, 43, 77)',
        secondary: '#939393',
        tertiary: '#d5d5d5',
        onPrimary: 'white',
        disabled: '#c5c5c5',
    },
    background: {
        main: '#fafbfc',
        secondary: '#efefef',
        highlighted: 'black',
    },
    surface: {
        main: '#ffffff',
        secondary: '#fafafa',
    },
    onSurface: {
        main: '#f7f7f7',
        secondary: '#e0e0e0',
    },
    danger: {
        main: '#d7403a',
        extended_10: 'rgba(215,64,58, .1)',
    },
    black: {
        extended_80: 'rgba(0,0,0, .8)',
    },
    white: {
        main: '#ffffff',
    },
    yellow: {
        main: '#e9af5d',
        extended_10: '#fef3c7',
    },
    green: {
        main: '#24a195',
        extended_10: '#ccfbf1',
    },
};
