import styled, { css } from 'styled-components';
import { MultipleSelectMode } from '../../CaseDocumentsFolder';

const contentStyles = css<{
    isContextMenuVisible: boolean;
    multipleSelectMode: MultipleSelectMode | null;
    isSelected: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: ${({ theme }) => theme.box.spacing.sm};
    cursor: pointer;
    width: 18rem;
    max-width: 18rem;
    position: relative;

    :hover {
        background-color: ${({ theme }) => theme.colors.surface.main};
        border-radius: 0.5rem;
        box-shadow: ${({ theme }) => theme.box.shadow.main};

        .cta {
            display: flex;
        }
    }

    ${({ isContextMenuVisible, theme }) => {
        if (isContextMenuVisible === true) {
            return {
                background: theme.colors.surface.main,
                borderRadius: '0.5rem',
                boxShadow: theme.box.shadow.main,
            };
        }
    }}

    ${({ multipleSelectMode, theme, isSelected }) => {
        if (multipleSelectMode === MultipleSelectMode.BY_FILES) {
            return {
                background: isSelected ? theme.colors.primary.extended_10 : theme.colors.surface.main,
                borderRadius: '0.5rem',
                boxShadow: theme.box.shadow.main,
                ':hover': {
                    background: isSelected ? theme.colors.primary.extended_10 : theme.colors.surface.main,
                },
            };
        }
    }}
`;

export const Container = styled.div<{ scrollPosition: number }>`
    display: flex;
    justify-content: center;

    .react-tooltip {
        position: fixed !important;
        ${({ scrollPosition }) => {
            return {
                transform: `translateY(-${scrollPosition}px) !important`,
            };
        }}
    }
`;

export const Content = styled.div`
    ${contentStyles}
`;

export const ContentAsLink = styled.a`
    ${contentStyles}
`;

export const ContentIcon = styled.div<{ isGeneratingUrl?: boolean }>`
    width: 7rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    position: relative;

    ${({ isGeneratingUrl, theme }) => {
        if (isGeneratingUrl === true) {
            return {
                svg: {
                    fill: theme.colors.onSurface.secondary,
                },
            };
        }
    }}
`;

export const ContentIconLoading = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentTitle = styled.div`
    display: flex;
    align-items: center;
    max-width: 90%;
`;

export const ContentTitleText = styled.div`
    flex: 1;
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.main} !important;
    text-align: center;
    line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 100%;
`;

export const ContentCta = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.box.spacing.sm};
    right: ${({ theme }) => theme.box.spacing.sm};
    z-index: 1;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.secondary};
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    :hover {
        background-color: ${({ theme }) => theme.colors.background.secondary};
    }
`;

export const ContextMenu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #000000e0;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    position: absolute;
    z-index: 9;
    width: 28.5rem;
    border-radius: 1rem;
    padding: ${({ theme }) => `${theme.box.spacing.md} 0`};
`;

export const ContextMenuItem = styled.li`
    padding: ${({ theme }) => `${theme.box.spacing.sm} ${theme.box.spacing.xl}`};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.background.main};
    font-size: ${({ theme }) => theme.font.size.sm};
    display: flex;
    align-items: center;

    svg {
        color: ${({ theme }) => theme.colors.primary.extended_50};
        margin-right: ${({ theme }) => theme.box.spacing.md};
        font-size: ${({ theme }) => theme.font.size.md};
    }

    &:hover {
        background-color: grey;

        svg {
            color: ${({ theme }) => theme.colors.background.main};
        }
    }
`;

export const ContextMenuDetails = styled.div`
    margin-top: ${({ theme }) => theme.box.spacing.md};
    border-top: 1px solid ${({ theme }) => theme.colors.text.secondary};
    padding-top: ${({ theme }) => theme.box.spacing.md};
`;

export const ContextMenuDetailsItem = styled.div`
    padding: ${({ theme }) => `${theme.box.spacing.xs} ${theme.box.spacing.xl}`};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.font.size.sm};
`;

export const ContextMenuDetailsItemLabel = styled.div`
    color: ${({ theme }) => theme.colors.text.secondary};
    font-weight: bold;
`;

export const ContextMenuDetailsItemValue = styled.div`
    color: ${({ theme }) => theme.colors.background.secondary};
`;

export const CheckBoxContainer = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.box.spacing.sm};
    left: ${({ theme }) => theme.box.spacing.sm};

    :hover {
        > label {
            background-color: ${({ theme }) => theme.colors.onSurface.main};
        }
    }

    > label {
        width: 1.6rem;
        height: 1.6rem;
        padding-left: 0;

        > div.checkmark {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
`;

export const TooltipIcon = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.4rem;
    height: 1.4rem;
    background-color: ${({ theme }) => theme.colors.surface.main};
    margin-right: ${({ theme }) => theme.box.spacing.sm};

    > svg {
        font-size: ${({ theme }) => theme.font.size.md};
    }

    &.error {
        > svg {
            fill: ${({ theme }) => theme.colors.danger.main} !important;
        }
    }

    &.validated {
        > svg {
            fill: ${({ theme }) => theme.colors.green.main} !important;
        }
    }

    &.warning {
        background: none;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: ${({ theme }) => theme.colors.surface.main};
            width: 30%;
            height: 50%;
            z-index: 1;
        }
        > svg {
            fill: ${({ theme }) => theme.colors.yellow.main} !important;
            position: relative;
            z-index: 2;
        }
    }
`;

export const Tooltips = styled.div`
    > div:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.sm};
    }
`;

export const TooltipContent = styled.div`
    display: flex;
    align-items: center;
`;

export const TooltipContentIcon = styled.div`
    position: relative;
    margin-right: ${({ theme }) => theme.box.spacing.xs};

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    &.error {
        > svg {
            fill: ${({ theme }) => theme.colors.danger.main} !important;
        }
    }

    &.warning {
        > svg {
            fill: ${({ theme }) => theme.colors.yellow.main} !important;
        }
    }
`;

export const TooltipContentText = styled.div`
    line-height: 1.4;
    max-width: 40vw;
`;
