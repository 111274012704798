import React, { useEffect } from 'react';
import { Container } from './Uuid-styles';
import { Spinner, SpinnerAppearance, SpinnerSize } from '../../components';
import { useAppDispatch, usePromises, useStore } from '../../hooks';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationBusiness } from '../../business';
import { Routes } from '../../routes';
import bcrypt from 'bcryptjs';

const Uuid = () => {
    const dispatch = useAppDispatch();
    const store = useStore();
    const { search } = useLocation();
    const navigate = useNavigate();
    const [{ error, executePromise }] = usePromises();

    useEffect(() => {
        AuthenticationBusiness.logout(dispatch);
        tryAuth();
    }, []);

    const tryAuth = () => {
        executePromise(async () => {
            const searchParams = new URLSearchParams(search);
            const auth = searchParams.get('auth');
            if (auth != null) {
                const portfolioAuth = JSON.parse(window.atob(decodeURIComponent(auth)));
                if (portfolioAuth?.role != null && portfolioAuth.secret != null) {
                    const hashedPortfolioScret = bcrypt.hashSync(portfolioAuth.secret);
                    await AuthenticationBusiness.uuid(
                        portfolioAuth.role,
                        hashedPortfolioScret,
                        dispatch,
                        store
                    );
                    return navigate('/');
                }
            }
            navigate(`/${Routes.LOGIN}`);
        });
    };

    if (error) {
        return <Navigate to={`/${Routes.LOGIN}`} />;
    }

    return (
        <Container>
            <Spinner appearance={SpinnerAppearance.PRIMARY} size={SpinnerSize.MEDIUM} />
        </Container>
    );
};

export default Uuid;
