import React from 'react';
import { CategoryQuote, CategoryQuoteGroupAuthorization } from '../../../../../types';
import { Container, Quote, QuoteFilesList, QuoteFilesListItem } from './CategoriesQuotesListTable-styles';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    LocalizedText,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
} from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { BiPencil } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { useLocale } from '../../../../../hooks';
import { sortLocalizedText } from '../../../../../tools';

type Props = {
    quotes: Array<CategoryQuote>;
    isLoading: boolean;
    openDeleteQuoteModal: (selectedQuote: CategoryQuote) => void;
    openUpdateQuoteModal: (selectedQuote: CategoryQuote) => void;
};

const CategoriesQuotesListTable = ({ quotes, openUpdateQuoteModal, openDeleteQuoteModal }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const renderQuote = (quote: CategoryQuote, isMain = true) => (
        <AccordionContainer key={quote.id} triggerUpdate={quote}>
            <Accordion level={0}>
                <AccordionHeader>
                    <AccordionHeaderTitle>
                        <LocalizedText text={quote.title} />
                        {quote.belongsToClient && <>&nbsp;- {t('categories.quotes.belongs_to_client')}</>}
                    </AccordionHeaderTitle>
                    {isMain === true && (
                        <AccordionHeaderContent>
                            <SecondaryOutlinedButton
                                leadingIcon={<BsTrash />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openDeleteQuoteModal(quote);
                                }}
                            />
                            <PrimaryContainedButton
                                leadingIcon={<BiPencil />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openUpdateQuoteModal(quote);
                                }}
                            />
                        </AccordionHeaderContent>
                    )}
                </AccordionHeader>
                <AccordionBody>
                    {quote.fileGroups
                        .sort((a, b) => sortLocalizedText(a.title, b.title, locale))
                        .map((fileGroup, fileGroupIndex) => (
                            <Accordion
                                key={fileGroupIndex}
                                level={1}
                                index={fileGroupIndex}
                                parentIndex={0}
                                isToggled={quote.fileGroups.length === 1}
                                isDisabled={quote.fileGroups.length === 1}
                            >
                                <AccordionHeader>
                                    <AccordionHeaderTitle>
                                        <LocalizedText text={fileGroup.title} />

                                        {fileGroup.authorization !== CategoryQuoteGroupAuthorization.NONE && (
                                            <>
                                                &nbsp; (Authorization:&nbsp;
                                                {t(
                                                    `categories.quotes.authorization.${fileGroup.authorization}`
                                                )}
                                                )
                                            </>
                                        )}
                                    </AccordionHeaderTitle>
                                </AccordionHeader>
                                <AccordionBody>
                                    <QuoteFilesList>
                                        {fileGroup.files
                                            .sort((a, b) => a.index - b.index)
                                            .map((file, fileIndex) => (
                                                <QuoteFilesListItem key={fileIndex}>
                                                    <LocalizedText text={file.title} />
                                                </QuoteFilesListItem>
                                            ))}
                                    </QuoteFilesList>
                                </AccordionBody>
                            </Accordion>
                        ))}
                </AccordionBody>
            </Accordion>
        </AccordionContainer>
    );

    return (
        <Container>
            {quotes
                .sort((a, b) => sortLocalizedText(a.title, b.title, locale))
                .map((quote) => (
                    <Quote key={quote.id} hasDependent={quote.dependent != null}>
                        {renderQuote(quote)}
                        {quote.dependent != null &&
                            renderQuote(
                                {
                                    ...quote.dependent,
                                    title: {
                                        en: 'Dependent',
                                        fr: 'Dépendent',
                                    },
                                } as CategoryQuote,
                                false
                            )}
                    </Quote>
                ))}
        </Container>
    );
};

export default CategoriesQuotesListTable;
