import React, { useRef } from 'react';
import { i18n } from '../../../../translations';
import { useLoggedInUser, useToggle } from '../../../../hooks';
import { useOnClickOutside } from 'usehooks-ts';

// styles
import { Container, Content, IconContainer, Label, List, ListItem } from './LocaleSelect-styles';
import { CiGlobe } from 'react-icons/ci';
import { BiChevronDown } from 'react-icons/bi';
import { Locale } from '../../../../types';
import { UserBusiness } from '../../../../business';

const LocaleSelect = () => {
    const [isOpened, _, closeHandler, toggleHandler] = useToggle(false);
    const user = useLoggedInUser();
    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => {
        closeHandler();
    });

    const onChangeLocaleHandler = (locale: Locale) => {
        UserBusiness.updateLocale(locale, user.isLoggedIn());
        closeHandler();
    };

    return (
        <Container ref={containerRef}>
            <Content onClick={() => toggleHandler()}>
                <IconContainer>
                    <CiGlobe />
                </IconContainer>
                <Label>
                    {i18n.language.toUpperCase()}
                    <BiChevronDown />
                </Label>
            </Content>
            {isOpened && (
                <List>
                    <ListItem onClick={() => onChangeLocaleHandler(Locale.EN)}>English</ListItem>
                    <ListItem onClick={() => onChangeLocaleHandler(Locale.FR)}>Français</ListItem>
                </List>
            )}
        </Container>
    );
};

export default LocaleSelect;
