import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormGroupTitle,
    FormSelectInput,
    FormTextEditorInput,
    FormTextInput,
    Modal,
} from '../../../../../components';
import { APIService } from '../../../../../services';
import { CategoryFile, OCR_TYPE } from '../../../../../types';

type Props = {
    onClose: () => void;
    refreshFilesList: () => Promise<void>;
    selectedCategoryFile: CategoryFile;
};

const UpdateCategoryFileModal = ({ onClose, refreshFilesList, selectedCategoryFile }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        title_en: Yup.string().required(t('generic.error.required_field')),
        title_fr: Yup.string().notRequired(),
        description_en: Yup.string().notRequired(),
        description_fr: Yup.string().notRequired(),
        OCRType: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            title_en: selectedCategoryFile.title.en,
            title_fr: selectedCategoryFile.title.fr,
            description_en: selectedCategoryFile.description.en,
            description_fr: selectedCategoryFile.description.fr,
            OCRType: selectedCategoryFile.ocr.type ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload = {
                    title: {
                        en: submittedValues.title_en,
                        fr: submittedValues.title_fr,
                    },
                    description: {
                        en: submittedValues.description_en,
                        fr: submittedValues.description_fr,
                    },
                    OCRType: submittedValues.OCRType ?? '',
                };
                await APIService.categories().updateFile(selectedCategoryFile.id, payload);
                await refreshFilesList();
                onClose();
            });
        },
    });

    const list = useMemo(
        () => [
            {
                label: t('generic.none'),
                value: '',
            },
            {
                label: t('generic.ocr_list.DS160'),
                value: OCR_TYPE.DS160,
            },
            {
                label: t('generic.ocr_list.passport'),
                value: OCR_TYPE.PASSPORT,
            },
        ],
        [locale]
    );

    return (
        <Modal
            title="categories.files.modals.update.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="OCRType" expand>
                    <FormGroupLabel>{t('generic.ocr')}</FormGroupLabel>
                    <FormSelectInput options={list} />
                </FormGroup>
                <FormGroupTitle>{t('generic.title')}</FormGroupTitle>
                <FormGroup name="title_en" expand>
                    <FormGroupLabel>{t('generic.locale.en')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="title_fr" expand>
                    <FormGroupLabel>{t('generic.locale.fr')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroupTitle>{t('generic.description')}</FormGroupTitle>
                <FormGroup name="description_en" expand>
                    <FormGroupLabel>{t('generic.locale.en')}</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormGroup name="description_fr" expand>
                    <FormGroupLabel>{t('generic.locale.fr')}</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormFooter
                    submitLabel={t('categories.files.modals.update.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateCategoryFileModal;
