import styled from 'styled-components';

export const Container = styled.div<{ display: 'row' | 'column' }>`
    display: flex;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};

    ${({ display, theme }) => {
        if (display === 'row') {
            return {
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.box.spacing.xl,
                [' > h2.page-title']: {
                    marginBottom: 0,
                },
            };
        } else {
            return {
                flexDirection: 'column',
                rowGap: theme.box.spacing.md,
            };
        }
    }}
`;
