import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CaseDocumentsContextType } from '../CaseDocuments';
import { Container } from './CaseDocumentsDetails-styles';
import { CaseFile, LocaleObject } from '../../../../../types';
import CaseDocumentsFolder from './CaseDocumentsFolder';
import { CaseFolderManager } from '../../../../../managers';

export type SelectedCaseFileItem = {
    id: string;
    title: LocaleObject;
    fileItem?: CaseFile;
};

const CaseDocumentsDetails = () => {
    const { currentCaseFolder, currentCase } = useOutletContext<CaseDocumentsContextType>();
    const [isLoading, setIsLoading] = useState(false);
    const caseFolderId = useRef(currentCaseFolder?.id);

    useEffect(() => {
        if (currentCaseFolder != null && currentCaseFolder.id !== caseFolderId.current) {
            setIsLoading(true);
            caseFolderId.current = currentCaseFolder.id;
            setTimeout(() => {
                setIsLoading(false);
            });
        }
    }, [currentCaseFolder]);

    const managedCaseFolder = useMemo(
        () => (currentCaseFolder != null ? new CaseFolderManager(currentCase, currentCaseFolder) : null),
        [currentCaseFolder]
    );

    if (managedCaseFolder == null || isLoading) {
        return <div></div>;
    }

    return (
        <Container>
            <CaseDocumentsFolder caseFolder={managedCaseFolder} />
            {managedCaseFolder.dependents != null &&
                managedCaseFolder.dependents.map((dependent, index) => (
                    <CaseDocumentsFolder caseFolder={dependent} key={index} />
                ))}
        </Container>
    );
};

export default CaseDocumentsDetails;
