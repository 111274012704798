import styled from 'styled-components';

export const Container = styled.div<{ isEmpty?: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 5px;
    font-size: ${({ theme }) => theme.font.size.md};
    line-height: 1.4;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};

    ${({ isEmpty, theme }) => {
        if (isEmpty === true) {
            return {
                fontStyle: 'italic',
                color: theme.colors.text.secondary,
            };
        }
    }}
`;
