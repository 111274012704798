import React, { useMemo, useState } from 'react';
import {
    Case,
    CaseForm,
    ServerFormQuestion,
    ServerFormQuestionInputType,
} from '../../../../../../../../types';
import {
    Container,
    Section,
    SectionTitle,
    SectionItem,
    SectionItemQuestion,
    SectionItemAnswer,
    Buttons,
    SectionItemTooltip,
    Footer,
    SectionTitleText,
} from './CaseFormDetailsForm-styles';
import {
    ButtonSize,
    InputMode,
    LocalizedText,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
    TextInput,
} from '../../../../../../../../components';
import { getDisplayedSections } from '../../../../../../../../components/DynamicForm/helpers';
import { useLocale, useLoggedInUser, usePromises } from '../../../../../../../../hooks';
import { FaRegCopy } from 'react-icons/fa6';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaCheckCircle } from 'react-icons/fa';

import { APIService } from '../../../../../../../../services';
import { FaXmark, FaCheck } from 'react-icons/fa6';
import moment from 'moment';
import { formatLocalizedText } from '../../../../../../../../tools';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../../../../routes';

type CaseFormDetailsFormProps = {
    currentCase: Case;
    currentCaseForm: CaseForm;
    setCurrentCaseForm: React.Dispatch<React.SetStateAction<CaseForm | null>>;
    openModal: (value?: any) => void;
};
const CaseFormDetailsForm = ({
    currentCase,
    currentCaseForm,
    setCurrentCaseForm,
}: CaseFormDetailsFormProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const { form } = currentCaseForm;
    const user = useLoggedInUser();
    const [copiedQuestion, setCopiedQuestion] = useState<null | string>(null);
    const [{ executePromise }] = usePromises();

    const displayedSections = useMemo(() => {
        return getDisplayedSections(form);
    }, [form]);

    const copyToClipboard = (sectionIndex: number, questionId: string, value: string) => {
        setCopiedQuestion(`${sectionIndex}_${questionId}`);
        navigator.clipboard.writeText(value);
        setTimeout(() => {
            setCopiedQuestion(null);
        }, 2000);
    };

    const updateValidatedAnswer = async (
        sectionId: string,
        sectionInternalId: string,
        questionId: string,
        isValid: boolean
    ) => {
        await executePromise(async () => {
            await APIService.cases().updateFormValidatedAnswers(currentCase.id, currentCaseForm.id, {
                answers: [
                    {
                        sectionId: sectionId,
                        sectionInternalId: sectionInternalId,
                        questionId: questionId,
                        isValid: isValid,
                    },
                ],
            });

            const updatedForm = await APIService.cases().getForm(currentCase.id, currentCaseForm.id);
            setCurrentCaseForm(updatedForm);
        });
    };

    const getDisplayedValue = (value: string | number, question: ServerFormQuestion) => {
        if (value == null || value === '') {
            return value;
        }

        switch (question.inputType) {
            case ServerFormQuestionInputType.TEXT_INPUT:
            case ServerFormQuestionInputType.NUMBER_INPUT:
                return value;
            case ServerFormQuestionInputType.DATE_INPUT:
                return moment(value).format('YYYY-MM-DD');
            case ServerFormQuestionInputType.SELECT_INPUT:
                const option = question.options.find((i) => i.value === value);
                if (option != null) {
                    return formatLocalizedText(option.label, locale);
                }
                return value;
            default: {
                return '';
            }
        }
    };

    const renderSections = () => {
        let currentSectionNumber = 0;
        let previousSectionId = '';
        let subSectionLetter = 'a';

        return displayedSections
            .filter((section) => section.isDisplayed !== false)
            .map((section, sectionIndex) => {
                const sectionInternalId = (section as any).internalId as string;

                let displayedIndex = '';

                if (section.id !== previousSectionId) {
                    currentSectionNumber++;
                }

                const hasNextSimilar =
                    displayedSections[sectionIndex + 1]?.isDisplayed === true &&
                    displayedSections[sectionIndex + 1]?.id === section.id;
                if (hasNextSimilar === true || section.id === previousSectionId) {
                    displayedIndex = `${currentSectionNumber}${subSectionLetter}`;
                    subSectionLetter = String.fromCharCode(subSectionLetter.charCodeAt(0) + 1); // Increment letter
                } else {
                    displayedIndex = currentSectionNumber.toString();
                    subSectionLetter = 'a';
                }

                // Update previousSectionId
                previousSectionId = section.id;

                return (
                    <Section key={sectionInternalId}>
                        <SectionTitle>
                            <SectionTitleText>
                                {displayedIndex} - <LocalizedText text={section.title} />
                            </SectionTitleText>
                        </SectionTitle>
                        {section.questions
                            .filter((question) => question.isDisplayed !== false)
                            .map((q) => {
                                const question =
                                    q as CaseForm['form']['sections'][number]['questions'][number];
                                const { value, validatedAt } = question.answer;
                                const tooltipId = `${sectionIndex}_${question.id}`;
                                const displayedValue = getDisplayedValue(value, question);

                                return (
                                    <SectionItem key={question.id} isEmployee={user.isEmployee()}>
                                        <SectionItemQuestion>
                                            <LocalizedText text={question.label} />
                                        </SectionItemQuestion>
                                        <SectionItemAnswer
                                            data-tooltip-id={tooltipId}
                                            isValidated={validatedAt != null}
                                        >
                                            <TextInput
                                                value={displayedValue}
                                                mode={InputMode.FORM}
                                                disabled
                                                leadingIcon={
                                                    validatedAt != null ? (
                                                        <FaCheckCircle className="input__check-icon" />
                                                    ) : null
                                                }
                                            />
                                            {user.isEmployee() && (
                                                <Buttons className="buttons">
                                                    {value !== '' && (
                                                        <SecondaryOutlinedButton
                                                            leadingIcon={<FaRegCopy />}
                                                            onClick={() =>
                                                                copyToClipboard(
                                                                    sectionIndex,
                                                                    question.id,
                                                                    displayedValue.toString()
                                                                )
                                                            }
                                                        />
                                                    )}

                                                    {value !== '' && (
                                                        <PrimaryContainedButton
                                                            leadingIcon={
                                                                validatedAt == null ? (
                                                                    <FaCheck />
                                                                ) : (
                                                                    <FaXmark />
                                                                )
                                                            }
                                                            onClick={() =>
                                                                updateValidatedAnswer(
                                                                    section.id,
                                                                    sectionInternalId,
                                                                    question.id,
                                                                    validatedAt == null
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Buttons>
                                            )}
                                        </SectionItemAnswer>
                                        <SectionItemTooltip isDisplayed={tooltipId === copiedQuestion}>
                                            <ReactTooltip id={tooltipId} place="bottom">
                                                Copied
                                            </ReactTooltip>
                                        </SectionItemTooltip>
                                    </SectionItem>
                                );
                            })}
                    </Section>
                );
            });
    };

    return (
        <Container>
            {renderSections()}
            <Footer>
                <PrimaryContainedButton
                    size={ButtonSize.LARGE}
                    path={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}/${currentCaseForm.id}/${Routes.CASE_FORMS_FILL}`}
                >
                    {t('case.forms.modals.complete.title')}
                </PrimaryContainedButton>
            </Footer>
        </Container>
    );
};

export default CaseFormDetailsForm;
