import React, { useContext } from 'react';
import { FileInput, InputMode } from '../../Inputs';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';
import { FileInputProps } from '../../Inputs/FileInput/FileInput';
import { GCSFile } from '../../../types';

const FormFileInput = (props: Omit<FileInputProps, 'value' | 'onChange' | 'onError'>) => {
    const { formik } = useContext(FormContext);
    const { name, setCustomError } = useContext(FormGroupContext);

    const onChangeHandler = (value: Array<File | GCSFile> | null) => {
        formik.setFieldTouched(name, true);
        formik.setFieldValue(name, value);
        setCustomError(null);
    };

    const onErrorHandler = (message: string) => {
        setCustomError(message);
    };

    return (
        <FileInput
            {...props}
            value={formik.values[name]}
            onChange={onChangeHandler}
            mode={InputMode.FORM}
            onError={onErrorHandler}
        />
    );
};

export default FormFileInput;
