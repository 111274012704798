import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../routes';
import { useAppDispatch } from '../../hooks';
import { updateRedirect } from '../../services/ReduxService';

const RedirectToLogin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(updateRedirect({ redirectUrl: `${location.pathname}${location.search}` }));
        navigate(Routes.LOGIN);
    }, []);

    return <React.Fragment />;
};

export default RedirectToLogin;
