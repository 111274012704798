import React from 'react';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../../hooks';
import { APIService } from '../../../../../../services';
import { CaseQuote, Case } from '../../../../../../types';

type RemoveDependentModalProps = {
    onClose: () => void;
    selectedQuote: CaseQuote | null;
    currentCase: Case;
    refreshQuotesList: () => Promise<void>;
};

const RemoveDependentModal = ({
    onClose,
    currentCase,
    selectedQuote,
    refreshQuotesList,
}: RemoveDependentModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        await executePromise(async () => {
            clearError();
            await APIService.cases().removeQuoteDependent(currentCase.id, selectedQuote!.id);
            await refreshQuotesList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.quote.modals.remove_dependent.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('case.quote.modals.remove_dependent.message', {
                    fullName: selectedQuote!.fullName,
                    internalId: currentCase.internalId,
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('case.quote.modals.remove_dependent.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
                isLoading={isExecutingPromise}
                isDestructive
            />
        </Modal>
    );
};

export default RemoveDependentModal;
