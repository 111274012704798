import React from 'react';
import { StatusListItem } from '../../../../../types';
import { PAGE_DASHBOARD_Case } from '../../../DashboardPages';
import { Droppable } from 'react-beautiful-dnd';
import { CasesList, Container, Title } from './DashboardTableColumn-styles';
import { LocalizedText } from '../../../../../components';
import DashboardTableCard from '../DashboardTableCard';

type DashboardTableColumnProps = {
    index: number;
    status: StatusListItem;
    cases: Array<PAGE_DASHBOARD_Case>;
};

const DashboardTableColumn = ({ status, cases }: DashboardTableColumnProps) => {
    return (
        <Container>
            <Title>
                <LocalizedText text={status.locale} />
            </Title>
            <Droppable droppableId={status.id} type="case">
                {(innerProvided, innerSnapshot) => (
                    <CasesList
                        isDraggingHover={innerSnapshot.isDraggingOver}
                        ref={innerProvided.innerRef}
                        {...innerProvided.droppableProps}
                    >
                        {cases.map((caseItem, caseItemIndex) => (
                            <DashboardTableCard caseItem={caseItem} index={caseItemIndex} key={caseItem.id} />
                        ))}
                        {innerProvided.placeholder}
                    </CasesList>
                )}
            </Droppable>
        </Container>
    );
};

export default DashboardTableColumn;
