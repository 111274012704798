import React, { useContext } from 'react';
import { InputMode } from '../../Inputs';
import MultiSelectInput, {
    MultiSelectInputOption,
    MultiSelectInputProps,
} from '../../Inputs/MultiSelectInput';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';

type FormMultiSelectInputProps<T, U> = Omit<MultiSelectInputProps<T, U>, 'value' | 'onChange' | 'options'> & {
    options: Array<Omit<MultiSelectInputOption<T, U>, 'isChecked'>>;
};

const FormMultiSelectInput = <T, U>(props: FormMultiSelectInputProps<T, U>) => {
    const { formik, validationSchema } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);

    const isRequired = () => {
        const schema = validationSchema?.fields[name] as any;

        return (
            schema?.describe().tests.some((test: any) => test.name === 'required') ||
            (schema.spec.nullable !== true && schema.spec.optional !== true)
        );
    };

    const onChangeHandler = (values: Array<T>) => {
        formik.setFieldValue(name, values);
        formik.setFieldTouched(name);

        if (isRequired() && values.length > 0) {
            setTimeout(() => {
                formik.setFieldError(name, undefined);
            });
        }
    };

    return (
        <MultiSelectInput<T, U>
            {...props}
            value={formik.values[name]}
            onChange={onChangeHandler}
            mode={InputMode.FORM}
        />
    );
};

export default FormMultiSelectInput;
