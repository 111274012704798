import React from 'react';
import { CategoryQuoteType, LocaleObject } from '../../../../../types';
import { Table, TableColumnConfig } from '../../../../../components';
import { useLocale, useTable } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { formatLocalizedText } from '../../../../../tools';

type QuoteItem = {
    id: string;
    title: LocaleObject;
    rules: {
        quoteType: CategoryQuoteType;
        maxCount?: number;
    };
    prices: {
        quote: {
            value: number;
        };
        dependent?: {
            value: number;
        };
    };
};

type Props = {
    quotes: Array<QuoteItem>;
};

const CategoriesListTableItem = ({ quotes }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const columns: Array<TableColumnConfig<QuoteItem>> = [
        {
            id: 'title',
            headerTitle: 'generic.subcategories',
            getValue: (quote) => formatLocalizedText(quote.title, locale),
            width: 'minmax(max-content, 2fr)',
        },
        {
            id: 'type',
            headerTitle: 'generic.type',
            getValue: (quote) => t(`generic.quote_type.${quote.rules.quoteType}`),
            width: 'minmax(min-content, 1fr)',
            isCentered: true,
        },
        {
            id: 'maxCount',
            headerTitle: 'generic.max_count',
            getValue: (quote) => quote.rules.maxCount ?? ' - ',
            width: 'minmax(min-content, 1fr)',
            isCentered: true,
        },
        {
            id: 'price',
            headerTitle: 'generic.price',
            getValue: (quote) => quote.prices.quote.value,
            getDisplayedValue: (quote) => `${quote.prices.quote.value}$`,
            width: 'minmax(min-content, 1fr)',
            isCentered: true,
        },
        {
            id: 'dependent_price',
            headerTitle: 'generic.dependent',
            getValue: (quote) => quote.prices.dependent?.value ?? ' - ',
            getDisplayedValue: (quote) =>
                quote.prices.dependent?.value ? `${quote.prices.dependent.value}$` : ' - ',
            width: 'minmax(min-content, 1fr)',
            isCentered: true,
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(quotes, columns);

    return (
        <Table
            columns={displayedColumns}
            data={data}
            handleSort={handleSort}
            sortedColumn={sortedColumn}
            isSortingDown={isSortingDown}
        />
    );
};

export default CategoriesListTableItem;
