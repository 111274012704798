import React, { useRef } from 'react';
import {
    Container,
    Content,
    MenuContainer,
    MenuContent,
    MenuHeader,
    MenuList,
    MenuListItem,
    MenuFooter,
    MenuListItemLink,
} from './UserProfile-styles';
import { useAppDispatch, useLoggedInUser, useToggle } from '../../../../../hooks';
import { AuthenticationBusiness } from '../../../../../business';
import { BiChevronDown } from 'react-icons/bi';
import { EmployeeBadge, UserAvatar } from '../../../../../components';
import { useOnClickOutside } from 'usehooks-ts';
import { generateId } from '../../../../../tools';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../routes';

const UserProfile = () => {
    const { user, isEmployee } = useLoggedInUser();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isOpened, _, close, toggle] = useToggle<boolean>(false);

    const containeRef = useRef(null);
    useOnClickOutside(containeRef, close);

    return (
        <Container ref={containeRef}>
            <Content onClick={() => toggle()} isOpened={isOpened as boolean}>
                <UserAvatar {...user} />
                <BiChevronDown />
            </Content>

            {isOpened && (
                <MenuContainer>
                    <MenuContent>
                        <MenuHeader>
                            <UserAvatar {...user} />
                        </MenuHeader>
                        <MenuList>
                            <MenuListItem isLink onClick={() => close()}>
                                <MenuListItemLink to={`/${Routes.SETTINGS}`}>
                                    {t('settings.page_title')}
                                </MenuListItemLink>
                            </MenuListItem>

                            <MenuListItem onClick={() => AuthenticationBusiness.logout(dispatch)}>
                                {t('generic.logout')}
                            </MenuListItem>
                        </MenuList>
                    </MenuContent>

                    {isEmployee() && (
                        <MenuFooter>
                            {user.roles!.map((role) => (
                                <EmployeeBadge key={generateId()} role={role} />
                            ))}
                        </MenuFooter>
                    )}
                </MenuContainer>
            )}
        </Container>
    );
};

export default UserProfile;
