import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useLoggedInUser } from '../../hooks';

type Props = {
    children?: React.ReactNode;
    permissions?: string | Array<string | Array<string>>;
};

const EmployeeRoute = ({ children, permissions }: Props) => {
    const user = useLoggedInUser();

    // if (user.isEmployee() === false) {
    //     // The user is not an employee and cannot access the route, redirect to "/"
    //     return <Navigate to="/" />;
    // }

    if (permissions != null && permissions.length > 0) {
        const hasPermissions =
            typeof permissions === 'string'
                ? user.hasPermissions([permissions])
                : user.hasPermissions(...permissions);
        if (hasPermissions === false) {
            return <Navigate to="/" />;
        }
    }

    if (typeof children === 'object') {
        // The current route does not have sub paths
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        // The current route has sub path
        return <Outlet />;
    }
};

export default EmployeeRoute;
