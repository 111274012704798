import React from 'react';
import {
    LocalizedText,
    PrimaryContainedButton,
    Table,
    TableColumnConfig,
} from '../../../../../../../../components';
import { formatLocalizedText } from '../../../../../../../../tools';
import { ResultsItemDetails, ResultsItemLabel, ResultsItemValue } from './OCRFileModalTable-styles';
import { FileOCRResult } from '../../OCRFileModal';

import { MdOutlineError } from 'react-icons/md';
import { PiWarningFill } from 'react-icons/pi';
import { FaCircleCheck } from 'react-icons/fa6';
import { OCR_VALIDATION_SEVERITY } from '../../../../../../../../types';
import { useTranslation } from 'react-i18next';
import { useLocale, useTable } from '../../../../../../../../hooks';

const IconMap = {
    error: MdOutlineError,
    warning: PiWarningFill,
    ok: FaCircleCheck,
};

type OCRFileModalTableProps = {
    fileOCRResults: Array<FileOCRResult>;
    acknowledgeOCRHandler: (key: string) => Promise<void>;
};

const OCRFileModalTable = ({ fileOCRResults, acknowledgeOCRHandler }: OCRFileModalTableProps) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const renderAttribute = (result: FileOCRResult) => {
        let type: keyof typeof IconMap = 'ok';

        if (result.value.length === 0) {
            type = 'warning';
        } else if (result.validation != null) {
            type = result.validation.severity === OCR_VALIDATION_SEVERITY.CRITICAL ? 'error' : 'warning';
        }
        const Icon = IconMap[type];

        return (
            <ResultsItemLabel className={`${type}`}>
                <Icon />
                <LocalizedText text={result.label} />
            </ResultsItemLabel>
        );
    };

    const renderDetails = (result: FileOCRResult) => {
        if (result.validation == null || result.validation.isProcessed === true) {
            return '';
        }
        return (
            <ResultsItemDetails>
                <LocalizedText text={result.validation.message} />
            </ResultsItemDetails>
        );
    };

    const columns: Array<TableColumnConfig<FileOCRResult>> = [
        {
            id: 'attribute',
            headerTitle: 'case.documents.modals.ocr.results',
            getValue: ({ label }) => formatLocalizedText(label, locale),
            getDisplayedValue: (result) => renderAttribute(result),
            width: 'max-content',
        },
        {
            id: 'result',
            headerTitle: '',
            getValue: ({ value }) => value || t('generic.unknown'),
            getDisplayedValue: ({ value }) => (
                <ResultsItemValue isEmpty={value.length === 0}>
                    {value || t('generic.unknown')}
                </ResultsItemValue>
            ),
            width: 'minmax(max-content, 1fr)',
        },
        {
            id: 'details',
            headerTitle: 'case.documents.modals.ocr.details',
            getValue: ({ validation }) =>
                validation == null ? '' : formatLocalizedText(validation.message, locale),
            getDisplayedValue: (result) => renderDetails(result),
            width: '3fr',
            isExpanded: true,
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: ({ validation }) =>
                validation == null || validation.isProcessed ? (
                    ''
                ) : (
                    <PrimaryContainedButton onClick={() => acknowledgeOCRHandler(validation.key)}>
                        {t('generic.discard')}
                    </PrimaryContainedButton>
                ),
            width: 'max-content',
        },
    ];

    const { data, displayedColumns } = useTable(fileOCRResults, columns);

    return <Table data={data} columns={displayedColumns} />;
};

export default OCRFileModalTable;
