import React, { useMemo } from 'react';
import {
    Container,
    ClientLabel,
    ClientLabelRemove,
    ClientLabelText,
    InputContainer,
    Price,
    AddDependentCta,
    DependentLabel,
    DependentLabelRemove,
    DependentLabelText,
    AddDependentCtaLabel,
} from './AddQuotesModalItem-styles';
import { CaseClient, CaseQuote, CategoryLinkedQuote } from '../../../../../../../../types';
import { useTranslation } from 'react-i18next';
import { SelectInput, TextInput } from '../../../../../../../../components';
import { formatPrice } from '../../../../../../../../tools';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CaseQuoteFormItemQuote } from '../../AddQuotesModal';
import { useLocale } from '../../../../../../../../hooks';

type AddQuotesModalClientProps = {
    item: CaseQuoteFormItemQuote;
    caseQuotes: Array<CaseQuote>;
    category: CategoryLinkedQuote;
    index: number;
    clients: Array<CaseClient>;
    currentQuoteList: Array<CaseQuoteFormItemQuote>;
    updateQuote: (categoryQuoteId: string, index: number, quote: CaseQuoteFormItemQuote) => void;
    removeQuote: (categoryQuoteId: string, index: number) => void;
};

const AddQuotesModalItem = ({
    category,
    item,
    index,
    currentQuoteList,
    clients,
    caseQuotes,
    updateQuote,
    removeQuote,
}: AddQuotesModalClientProps) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const selectClientHandler = (clientId: string) => {
        updateQuote(category.id, index, { ...item, clientId: clientId });
    };

    const addDependentHandler = () => {
        updateQuote(category.id, index, {
            ...item,
            dependents: item.dependents ? [...item.dependents, ''] : [''],
        });
    };

    const removeDependentHandler = (dependentIndex: number) => {
        updateQuote(category.id, index, {
            ...item,
            dependents: item.dependents!.filter((_, itemIndex) => dependentIndex !== itemIndex),
        });
    };

    const updateDependentHandler = (fullName: string, dependentIndex: number) => {
        const updatedDependents = structuredClone(item.dependents!);
        updatedDependents[dependentIndex] = fullName;
        updateQuote(category.id, index, {
            ...item,
            dependents: updatedDependents,
        });
    };

    const clientsList = useMemo(() => {
        const list: Array<{ label: string; value: string }> = [{ label: t('generic.tbd'), value: '' }];
        for (const client of clients) {
            if (currentQuoteList.some((currentQuote) => currentQuote.clientId === client.id)) {
                continue;
            }
            if (
                caseQuotes.some(
                    (caseQuote) => caseQuote.category.id === category.id && caseQuote.client?.id === client.id
                )
            ) {
                continue;
            }

            list.push({
                label: `${client.firstName} ${client.lastName}`,
                value: client.id,
            });
        }
        return list;
    }, [clients, caseQuotes, currentQuoteList, locale]);

    return (
        <Container>
            <ClientLabel>
                <ClientLabelRemove>
                    <AiOutlineCloseCircle onClick={() => removeQuote(category.id, index)} />
                </ClientLabelRemove>
                <ClientLabelText>{t('generic.client')}:</ClientLabelText>
            </ClientLabel>
            <InputContainer>
                <SelectInput onChange={selectClientHandler} value={item.clientId} options={clientsList} />
            </InputContainer>
            <Price>{formatPrice(category.prices.quote.value)}</Price>

            {category.rules.hasDependent === true && (
                <>
                    {item.dependents != null &&
                        item.dependents.map((dependent, dependentIndex) => (
                            <React.Fragment key={dependentIndex}>
                                <DependentLabel>
                                    <DependentLabelRemove>
                                        <AiOutlineCloseCircle
                                            onClick={() => removeDependentHandler(dependentIndex)}
                                        />
                                    </DependentLabelRemove>
                                    <DependentLabelText>{t('generic.dependent')}:</DependentLabelText>
                                </DependentLabel>
                                <InputContainer>
                                    <TextInput
                                        value={dependent}
                                        onChange={(e) =>
                                            updateDependentHandler(e.target.value, dependentIndex)
                                        }
                                    />
                                </InputContainer>
                                <Price>{formatPrice(category.prices.dependent!.value)}</Price>
                            </React.Fragment>
                        ))}
                    <AddDependentCta>
                        <AddDependentCtaLabel onClick={addDependentHandler}>
                            {t('case.quote.modals.add_quotes.add_dependent')}
                        </AddDependentCtaLabel>
                    </AddDependentCta>
                </>
            )}
        </Container>
    );
};

export default AddQuotesModalItem;
