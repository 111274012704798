import styled from 'styled-components';

export const Message = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const EmptyMessage = styled.div``;

export const List = styled.ul`
    list-style: inside;
    margin-bottom: ${({ theme }) => theme.box.spacing.xl};
`;

export const ListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
`;

export const InputTitle = styled.h3`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const InputList = styled.div``;

export const InputListItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const InputListItemRemove = styled.div<{ isDisabled?: boolean }>`
    margin-right: ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.danger.main};
    cursor: pointer;

    svg {
        font-size: ${({ theme }) => theme.font.size.md};
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                color: theme.colors.text.disabled,
            };
        }
    }}
`;

export const InputListItemContent = styled.div`
    flex: 1;
`;

export const AddDependentCtaContainer = styled.div`
    margin-top: ${({ theme }) => theme.box.spacing.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.xl};
    display: flex;
    justify-content: flex-end;
`;

export const AddDependentCta = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;
`;
