import React from 'react';
import Spinner, { SpinnerAppearance, SpinnerSize } from '../Spinner';
import { TablePlaceHolder, TableLoadingLabel } from './Table-styles';
import { TableStyles } from './Table-types';

type Props = {
    tableStyles: TableStyles;
    width?: number;
};

const TableLoading = ({ tableStyles, width }: Props) => {
    return (
        <TablePlaceHolder tableStyles={tableStyles} width={width}>
            <Spinner size={SpinnerSize.MEDIUM} appearance={SpinnerAppearance.BLACK} />
            <TableLoadingLabel>Loading...</TableLoadingLabel>
        </TablePlaceHolder>
    );
};

export default TableLoading;
