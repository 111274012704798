import styled from 'styled-components';
import { InputMode } from '../Inputs-types';

export const Container = styled.div`
    position: relative;
`;

export const Content = styled.div<{ isLoading: boolean; isDisabled: boolean }>`
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;

    * {
        cursor: ${({ isLoading, isDisabled }) =>
            isDisabled ? 'not-allowed' : isLoading ? 'default' : 'pointer'};
    }

    > .input-container {
        > input {
            padding-left: ${({ theme }) => theme.box.spacing.xs};
            padding-right: 0;
        }
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                '*': {
                    color: theme.colors.text.disabled,
                },
            };
        }
    }}
`;

export const ContentLabelWrapper = styled.div<{ mode?: InputMode }>`
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.config.input.height.default}px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-radius: 0.8rem;
    flex: 1;
    padding-left: ${({ theme }) => theme.box.spacing.xs};
    background-color: ${({ theme }) => theme.colors.surface.main};

    ${({ mode, theme }) => {
        if (mode === InputMode.STAND_ALONE) {
            return {
                borderRadius: '0.3rem',
                height: `${theme.config.input.height.small}px`,
                maxHeight: `${theme.config.input.height.small}px`,
            };
        }
    }}
`;

export const ContentLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    line-height: 1.4;
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    display: flex;
    align-items: center;
`;

export const ContentLabelText = styled.div<{ isPlaceholder: boolean }>`
    font-size: ${({ theme }) => theme.font.size.md};
    flex: 1;

    ${({ isPlaceholder, theme }) => {
        if (isPlaceholder === true) {
            return {
                color: theme.colors.text.tertiary,
                fontWeight: theme.font.weight.normal,
            };
        }
    }}
`;

export const ContentLabelIcon = styled.div`
    width: 3rem;
    display: flex;
    justify-content: center;
    svg {
        font-size: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const ListWrapper = styled.div<{ maxHeight: number; listDisplayedAtBottom: boolean }>`
    position: absolute;
    top: ${({ listDisplayedAtBottom }) => (listDisplayedAtBottom ? 'calc(100% + 0.5rem)' : 'unset')};
    bottom: ${({ listDisplayedAtBottom }) => (listDisplayedAtBottom ? 'unset' : 'calc(100% + 0.5rem)')};
    left: 0;
    width: 100%;
    z-index: 3;
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    overflow: hidden;
    padding-bottom: ${({ theme, listDisplayedAtBottom }) =>
        listDisplayedAtBottom ? theme.box.spacing.xl : 0};

    > ul {
        max-height: ${({ maxHeight, theme }) => `calc(${maxHeight}px - ${theme.box.spacing.xl})`};
    }

    box-shadow: ${({ theme, listDisplayedAtBottom }) =>
        listDisplayedAtBottom ? 'unset' : theme.box.shadow.main};
`;

export const List = styled.ul`
    overflow-y: auto;
    background: ${({ theme }) => theme.colors.surface.main};
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
`;

export const ListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.md};
    padding: ${({ theme }) => theme.box.spacing.md};
    cursor: pointer;
    :hover {
        background: ${({ theme }) => theme.colors.surface.secondary};
    }
`;
