import React, { createContext, useMemo } from 'react';
import { Container } from './PageHeaderActions-styles';
import { useWindowSize } from 'usehooks-ts';
import { flattenChildren } from '../../../tools';

const DEFAULTS_PROPS = {
    breakpoint: 1000,
    grid: {
        default: 'minmax(max-content, 400px)',
        mobile: [200],
        isLeftSide: true,
    },
};

type Props = {
    children: React.ReactNode;
    breakpoint?: number;
    grid?: {
        default?: string;
        mobile?: Array<number>;
        isLeftSide?: boolean;
    };
};

type PageHeaderActionsContextType = {
    breakpoint: number;
    isMobile: boolean;
    grid: {
        default: string;
        mobile: Array<number>;
        isLeftSide: boolean;
    };
};

export const PageHeaderActionsContext = createContext<PageHeaderActionsContextType>(null!);

const PageHeaderActions = ({
    children,
    breakpoint = DEFAULTS_PROPS.breakpoint,
    grid = DEFAULTS_PROPS.grid,
}: Props) => {
    const windowSize = useWindowSize();

    const currentGrid = useMemo(
        () => ({
            default: grid?.default || DEFAULTS_PROPS.grid.default,
            mobile: grid?.mobile || DEFAULTS_PROPS.grid.mobile,
            isLeftSide: grid?.isLeftSide ?? DEFAULTS_PROPS.grid.isLeftSide,
        }),
        [grid]
    );

    const isMobile = useMemo(() => {
        return windowSize.width > 0 && windowSize.width <= breakpoint;
    }, [windowSize]);

    return (
        <PageHeaderActionsContext.Provider
            value={{
                breakpoint: breakpoint,
                isMobile: isMobile,
                grid: currentGrid,
            }}
        >
            <Container isMobile={isMobile} mobileGrid={currentGrid.mobile} className="page-header-actions">
                {isMobile ? (
                    <React.Fragment key="flattened">{flattenChildren(children)}</React.Fragment>
                ) : (
                    <React.Fragment key="default">{children}</React.Fragment>
                )}
            </Container>
        </PageHeaderActionsContext.Provider>
    );
};

export default PageHeaderActions;
