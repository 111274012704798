import React, { useState } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle } from '../../../components';
import { useTranslation } from 'react-i18next';
import { RiFileSettingsLine } from 'react-icons/ri';
import { usePromises, useToggle } from '../../../hooks';
import { APIService } from '../../../services';
import { Actions, CategoryFile } from '../../../types';
import { CreateCategoryFileModal, DeleteCategoryFileModal, UpdateCategoryFileModal } from './modals';
import { CategoriesFilesListActions, CategoriesFilesListTable } from './components';

const CategoriesFilesList = () => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [action, openModal, closeModal] = useToggle<null | Actions>(null);
    const [promises, [categoryFiles]] = usePromises(() => APIService.categories().getFilesList());
    const [selectedCategoryFile, setSelectedCategoryFile] = useState<null | CategoryFile>(null);
    const [showTranslation, setShowTranslation] = useState(false);

    return (
        <>
            {action === Actions.CREATE && (
                <CreateCategoryFileModal onClose={closeModal} refreshFilesList={categoryFiles.refresh} />
            )}

            {action === Actions.UPDATE && (
                <UpdateCategoryFileModal
                    onClose={closeModal}
                    refreshFilesList={categoryFiles.refresh}
                    selectedCategoryFile={selectedCategoryFile!}
                />
            )}

            {action === Actions.DELETE && (
                <DeleteCategoryFileModal
                    onClose={closeModal}
                    refreshFilesList={categoryFiles.refresh}
                    selectedCategoryFile={selectedCategoryFile!}
                />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader>
                    <PageHeaderTitle>
                        <RiFileSettingsLine />
                        {t('categories.files.page_title')}
                    </PageHeaderTitle>
                    <CategoriesFilesListActions
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        onClickAddCategoryFile={() => openModal(Actions.CREATE)}
                        showTranslation={showTranslation}
                        setShowTranslation={setShowTranslation}
                    />
                </PageHeader>
                <CategoriesFilesListTable
                    isLoading={categoryFiles.isLoading}
                    categoryFiles={categoryFiles.data}
                    searchTerm={searchTerm}
                    showTranslation={showTranslation}
                    onUpdateCategoryFile={(categoryFile) => {
                        setSelectedCategoryFile(categoryFile);
                        openModal(Actions.UPDATE);
                    }}
                    onDeleteCategoryFile={(categoryFile) => {
                        setSelectedCategoryFile(categoryFile);
                        openModal(Actions.DELETE);
                    }}
                />
            </PageContainer>
        </>
    );
};

export default CategoriesFilesList;
