import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore, usePromises } from '../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailRegex, formatPhoneNumber, passwordRegex, validatePhoneNumber } from '../../../../tools';
import { CreateClientPayload } from '../../../../types';
import { APIService } from '../../../../services';
import {
    Modal,
    Form,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    FormSelectInput,
    FormPasswordInput,
    FormFooter,
} from '../../../../components';

type Props = {
    onClose: () => void;
    refreshClientsList: () => Promise<void>;
};

const CreateClientModal = ({ onClose, refreshClientsList }: Props) => {
    const { t } = useTranslation();
    const { list } = useStore().organizations;
    const [{ error, clearError, executePromise }] = usePromises();

    const organizationsList = useMemo(() => {
        return list.map((organization) => ({
            label: organization.name,
            value: organization.internalId,
        }));
    }, [list]);

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('generic.error.required_field')),
        lastName: Yup.string().required(t('generic.error.required_field')),
        email: Yup.string()
            .required(t('generic.error.required_field'))
            .matches(emailRegex, t('generic.error.invalid_email')),
        organizationInternalId: Yup.string().required(t('generic.error.required_field')),
        password: Yup.string().required(t('generic.error.required_field')).matches(passwordRegex, ' '),
        phoneNumber: Yup.string().test('phoneNumber', t('generic.error.invalid_phone_number'), (value) => {
            if (value) {
                return validatePhoneNumber(value);
            } else {
                return true;
            }
        }),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            organizationInternalId: 'default',
            password: '',
            roles: [],
            phoneNumber: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload: CreateClientPayload = {
                    firstName: submittedValues.firstName,
                    lastName: submittedValues.lastName,
                    email: submittedValues.email,
                    password: submittedValues.password,
                    organizationInternalId: submittedValues.organizationInternalId,
                };

                if (submittedValues.phoneNumber.length > 0) {
                    payload.contacts = [{ phoneNumber: formatPhoneNumber(submittedValues.phoneNumber) }];
                }

                await APIService.clients().createClient(payload);
                await refreshClientsList();
                onClose();
            });
        },
    });

    return (
        <Modal title="clients.modal.add_client.title" onClose={onClose} error={error} clearError={clearError}>
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="firstName">
                    <FormGroupLabel>{t('generic.firstName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.firstName')} />
                </FormGroup>
                <FormGroup name="lastName">
                    <FormGroupLabel>{t('generic.lastName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.lastName')} />
                </FormGroup>
                <FormGroup name="email">
                    <FormGroupLabel>{t('generic.email_address')}</FormGroupLabel>
                    <FormTextInput
                        type="email"
                        placeholder={t('generic.email')}
                        autoComplete="new-password"
                    />
                </FormGroup>
                <FormGroup name="organizationInternalId">
                    <FormGroupLabel>{t('generic.organization')}</FormGroupLabel>
                    <FormSelectInput options={organizationsList} />
                </FormGroup>
                <FormGroup name="password" expand>
                    <FormGroupLabel>{t('generic.password')}</FormGroupLabel>
                    <FormPasswordInput
                        placeholder={t('generic.password')}
                        validate
                        autoComplete="new-password"
                    />
                </FormGroup>
                <FormGroup name="phoneNumber" expand>
                    <FormGroupLabel>{t('generic.contact')}</FormGroupLabel>
                    <FormTextInput placeholder={t('generic.contact')} />
                </FormGroup>
                <FormFooter
                    submitLabel={t('clients.modal.add_client.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default CreateClientModal;
