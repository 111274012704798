import React from 'react';
import { CategoryFile } from '../../../../../types';
import { useLoggedInUser, useTable } from '../../../../../hooks';
import { Table, TableBodyRowAction, TableColumnConfig } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { Description } from './CategoriesFilesListTable-styles';

type Props = {
    isLoading: boolean;
    categoryFiles: null | Array<CategoryFile>;
    searchTerm: string;
    showTranslation: boolean;
    onUpdateCategoryFile: (categoryFile: CategoryFile) => void;
    onDeleteCategoryFile: (categoryFile: CategoryFile) => void;
};

const CategoriesFilesListTable = ({
    categoryFiles,
    searchTerm,
    onUpdateCategoryFile,
    onDeleteCategoryFile,
    showTranslation,
}: Props) => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const columns: Array<TableColumnConfig<CategoryFile>> = [
        {
            id: 'title_en',
            headerTitle: `${t('generic.title')} ${showTranslation ? 'EN' : ''}`,
            getValue: ({ title }) => title.en,
            width: 'minmax(max-content, 1fr)',
            isSortable: true,
            isDefaultSort: true,
            isSearchable: true,
        },
        {
            id: 'title_fr',
            headerTitle: `${t('generic.title')} FR`,
            getValue: ({ title }) => title.fr,
            width: '1fr',
            isDisplayed: showTranslation,
        },
        {
            id: 'description_en',
            headerTitle: `${t('generic.description')} ${showTranslation ? 'EN' : ''}`,
            getValue: ({ description }) => description.en ?? ' - ',
            getDisplayedValue: ({ description }) => (
                <Description dangerouslySetInnerHTML={{ __html: description.en }} />
            ),
            width: 'minmax(100px, 1fr)',
            isExpanded: true,
        },
        {
            id: 'description_fr',
            headerTitle: `${t('generic.description')} FR`,
            getValue: ({ description }) => description.fr,
            width: 'max-content',
            isDisplayed: showTranslation,
        },
        {
            id: 'ocr',
            headerTitle: t('generic.ocr'),
            getValue: ({ ocr }) => ocr.type ?? '',
            getDisplayedValue: ({ ocr }) =>
                ocr.type != null && ocr.type?.length > 0 ? t(`generic.ocr_list.${ocr.type}`) : '  ',
            width: 'minmax(max-content, 1fr)',
            isSortable: true,
            isDefaultSort: true,
            isSearchable: true,
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (categoryFile, { index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index >= total - 2}
                    actions={[
                        {
                            label: t('categories.files.modals.update.title'),
                            onClick: () => onUpdateCategoryFile(categoryFile),
                        },
                        {
                            label: t('categories.files.modals.remove.title'),
                            onClick: () => onDeleteCategoryFile(categoryFile),
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.hasPermissions('categories.update'),
        },
    ];
    const table = useTable(categoryFiles, columns, { searchTerm: searchTerm });

    return (
        <Table
            {...table}
            columns={table.displayedColumns}
            handleClickRow={(categoryFile) => onUpdateCategoryFile(categoryFile)}
            styles={{
                table: {
                    minWidth: '800px',
                },
            }}
        />
    );
};

export default CategoriesFilesListTable;
