import React from 'react';
import { Container } from '../Topbar-styles';
import { Logo } from '../components';
import LocaleSelect from '../components/LocaleSelect';

const PublicTopbar = () => {
    return (
        <Container>
            <Logo />
            <LocaleSelect />
        </Container>
    );
};

export default PublicTopbar;
