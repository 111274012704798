import { CaseForm } from '../../../types';
import { DynamicFormValues } from '../types';

/**
 * Get initial values
 * @param form
 * @return {Object}
 */
export const getInitialValues = (form: CaseForm['form']): DynamicFormValues => {
    const initialValues = {} as DynamicFormValues;

    form.sections.forEach((section) => {
        const sectionIdentifier = `section_${section.internalId}`;

        initialValues[sectionIdentifier] = {
            sectionId: section.id,
            sectionInternalId: section.internalId,
            questions: {},
        };

        for (const question of section.questions) {
            const answer = question.answer.value as any;
            initialValues[sectionIdentifier].questions[question.id] = answer ?? '';
        }
    });

    return initialValues;
};
