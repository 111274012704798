import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
    display: grid;
    grid-template-columns: max-content 1fr 40%;
    grid-auto-rows: max-content;
    grid-gap: ${({ theme }) => theme.box.spacing.md};

    @media (max-width: 600px) {
        > div:nth-child(2) {
            grid-column: 2 / 4;
        }
        > div:nth-child(3) {
            grid-column: 2 / 4;
        }
    }
`;

export const Remove = styled.div<{ isDisabled: boolean }>`
    width: max-content;
    display: flex;
    align-items: center;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.text.disabled : theme.colors.danger.main)};

    svg {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const Client = styled.div`
    position: relative;

    svg {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const ClientClickable = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
`;

export const AddClient = styled.div`
    margin: ${({ theme }) => theme.box.spacing.sm} 0;
    font-size: ${({ theme }) => theme.font.size.md};
    text-align: end;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    :hover {
        text-decoration: underline;
    }
`;
