import React, { useMemo } from 'react';
import { Container, Item, ItemIcon, ItemLabel } from './SettingsSidebar-styles';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../hooks';
import { FaRegUser } from 'react-icons/fa6';
import { Routes } from '../../../../routes';
import { MdOutlineLockPerson } from 'react-icons/md';
import { FaRegSquareCheck } from 'react-icons/fa6';

const SettingsSidebar = () => {
    const { t } = useTranslation();
    const locale = useLocale();

    const list = useMemo(() => {
        return [
            {
                label: t('settings.my_profile.title'),
                icon: <FaRegUser />,
                path: `/${Routes.SETTINGS}`,
            },
            {
                label: t('settings.credentials.title'),
                icon: <MdOutlineLockPerson />,
                path: `/${Routes.SETTINGS}/${Routes.SETTINGS_CREDENTIALS}`,
            },
            {
                label: t('settings.preferences.title'),
                icon: <FaRegSquareCheck />,
                path: `/${Routes.SETTINGS}/${Routes.SETTINGS_PREFERENCES}`,
            },
        ];
    }, [locale]);

    return (
        <Container>
            {list.map(({ label, icon, path }, index) => (
                <Item key={index} to={path} end>
                    <ItemIcon>{icon}</ItemIcon>
                    <ItemLabel>{label}</ItemLabel>
                </Item>
            ))}
        </Container>
    );
};

export default SettingsSidebar;
