import React, { useState } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle, PrimaryContainedAddButton } from '../../../components';
import { useTranslation } from 'react-i18next';
import { BiCategory } from 'react-icons/bi';
import { useLoggedInUser, usePromises, useToggle } from '../../../hooks';
import { Actions, Category } from '../../../types';
import { CreateCategoryModal, DeleteCategoryModal, UpdateCategoryModal } from './modals';
import { APIService } from '../../../services';
import { CategoriesListTable } from './components';

const CategoriesList = () => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const [action, openModal, closeModal] = useToggle<null | Actions>(null);
    const [selectedCategory, setSelectedCategory] = useState<null | Category>(null);
    const [promises, [categories]] = usePromises(() => APIService.categories().getList());

    return (
        <>
            {action === Actions.CREATE && (
                <CreateCategoryModal onClose={closeModal} refreshCategoriesList={categories.refresh} />
            )}

            {action === Actions.UPDATE && (
                <UpdateCategoryModal
                    onClose={closeModal}
                    selectedCategory={selectedCategory!}
                    refreshCategoriesList={categories.refresh}
                />
            )}

            {action === Actions.DELETE && (
                <DeleteCategoryModal
                    onClose={closeModal}
                    selectedCategory={selectedCategory!}
                    refreshCategoriesList={categories.refresh}
                />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <PageHeaderTitle>
                        <BiCategory />
                        {t('categories.main.page_title')}
                    </PageHeaderTitle>
                    {user.hasPermissions('categories.create') && (
                        <PrimaryContainedAddButton onClick={() => openModal(Actions.CREATE)}>
                            {t('categories.main.modals.add.title')}
                        </PrimaryContainedAddButton>
                    )}
                </PageHeader>
                <CategoriesListTable
                    categories={categories.data ?? []}
                    openUpdateCategoryModal={(category) => {
                        setSelectedCategory(category);
                        openModal(Actions.UPDATE);
                    }}
                    openDeleteCategoryModal={(category) => {
                        setSelectedCategory(category);
                        openModal(Actions.DELETE);
                    }}
                />
            </PageContainer>
        </>
    );
};

export default CategoriesList;
