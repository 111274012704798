import styled from 'styled-components';
import { DynamicFormViewMode } from '../types';

export const Title = styled.div<{ viewMode: DynamicFormViewMode }>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ viewMode, theme }) => {
        if (viewMode !== DynamicFormViewMode.FILL) {
            return {
                marginBottom: theme.box.spacing.lg,
            };
        }
    }}
`;

export const TitleText = styled.h4`
    font-size: ${({ theme }) => theme.font.size.lg};
`;

export const MultipleSection = styled.div`
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.box.spacing.md};
    position: relative;
`;

export const MultipleSectionRemove = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.box.spacing.sm};
    right: ${({ theme }) => theme.box.spacing.sm};
    cursor: pointer;

    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const MultipleSectionTitle = styled.div`
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;
