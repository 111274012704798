import React, { useContext } from 'react';
import { AccordionContext } from '../Accordion';
import { Container } from './AccordionHeader-styles';
import { AccordionContainerContext } from '../../AccordionContainer';

type AccordionHeaderProps = {
    children: React.ReactNode;
};

const AccordionHeader = ({ children }: AccordionHeaderProps) => {
    const { accordionsState, updateAccordionState } = useContext(AccordionContainerContext);
    const { accordionId, isDisabled, headerHeight, level } = useContext(AccordionContext);

    /**
     * On click handler
     */
    const onClickHandler = () => {
        if (isDisabled === true) {
            return;
        }
        const currentAccordionState = accordionsState.find((state) => state.id === accordionId)!;
        updateAccordionState({
            ...currentAccordionState,
            isToggled: !currentAccordionState.isToggled,
        });
    };

    return (
        <Container
            level={level}
            onClick={onClickHandler}
            headerHeight={headerHeight}
            className="accordion__header"
            isDisabled={isDisabled}
        >
            {children}
            {/* <Toggle isDisabled={isDisabled}>{isToggled ? <AiOutlineMinus /> : <AiOutlinePlus />}</Toggle> */}
        </Container>
    );
};

export default AccordionHeader;
