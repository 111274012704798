import React, { createContext, useContext, useMemo, useRef } from 'react';
import { Container } from './Accordion-styles';
import { generateId } from '../../tools';
import { AccordionContainerContext } from '../AccordionContainer';

type AccordionContextType = {
    accordionId: string;
    index: number;
    parentIndex: number;
    level: number;
    isToggled: boolean;
    isDisabled: boolean;
    headerHeight: number;
};

export const AccordionContext = createContext<AccordionContextType>(null!);

type AccordionProps = {
    children: React.ReactNode;
    level: number;
    index?: number;
    parentIndex?: number;
    headerHeight?: number;
    isToggled?: boolean;
    isDisabled?: boolean;
};

const Accordion = ({
    children,
    index = 0,
    parentIndex = 0,
    level = 0,
    headerHeight = 45,
    isToggled = false,
    isDisabled = false,
}: AccordionProps) => {
    const accordionId = useRef(generateId());
    const { accordionsState } = useContext(AccordionContainerContext);

    const currentIsToggled = useMemo(() => {
        return accordionsState.find((state) => state.id === accordionId.current)?.isToggled ?? isToggled;
    }, [isToggled, accordionsState, accordionId]);

    return (
        <AccordionContext.Provider
            value={{
                index: index,
                parentIndex: parentIndex,
                accordionId: accordionId.current,
                isToggled: currentIsToggled,
                isDisabled: isDisabled,
                level: level,
                headerHeight: headerHeight,
            }}
        >
            <Container level={level} className={`accordion accordion--${level}`}>
                {children}
            </Container>
        </AccordionContext.Provider>
    );
};

export default Accordion;
