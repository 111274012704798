import React from 'react';
import { CaseFolder, CaseFolder_L2 } from '../../../../../../../../types';
import {
    List,
    ListFiles,
    ListFilesGroup,
    ListFilesGroupList,
    ListFilesGroupListItem,
    ListFilesGroupListItemLabel,
    ListFilesGroupTitle,
    ListQuotes,
    ListQuotesItem,
    ListQuotesItemLabel,
} from './FileItemQuotesList-styles';
import { useLocale, useLoggedInUser } from '../../../../../../../../hooks';
import { AiFillFolder, AiFillFolderOpen } from 'react-icons/ai';
import { formatCaseFolderDescription } from '../../../../../../../../tools';
import { LocalizedText } from '../../../../../../../../components';

type FileItemQuotesListProps = {
    caseFolders: Array<CaseFolder>;
    selectedFolder: CaseFolder | null;
    selectFolderHandler: (caseFolder: CaseFolder) => void;
    selectedGrandChildFolder: CaseFolder_L2 | null;
    selectGrandChildFolderHandler: (categoryFile: CaseFolder_L2) => void;
};

const FileItemQuotesList = ({
    caseFolders,
    selectedFolder,
    selectedGrandChildFolder,
    selectFolderHandler,
    selectGrandChildFolderHandler,
}: FileItemQuotesListProps) => {
    const user = useLoggedInUser();
    const locale = useLocale();

    const renderCaseFolder = (caseFolder: CaseFolder, isSelected: boolean, isDependent?: boolean) => (
        <ListQuotesItem
            key={caseFolder.id}
            isSelected={isSelected}
            onClick={() => selectFolderHandler(caseFolder)}
            isDependent={isDependent}
        >
            {isSelected ? <AiFillFolderOpen /> : <AiFillFolder />}
            <ListQuotesItemLabel>{formatCaseFolderDescription(caseFolder, locale)}</ListQuotesItemLabel>

            {isSelected === true && (
                <ListFiles>
                    {caseFolder.folders
                        .filter(
                            (childFolder) =>
                                user.isAllowedToUpload(childFolder) &&
                                childFolder.folders.some((grandChildFolder) => grandChildFolder.isDisplayed)
                        )
                        .map((childFolder, fileGroupIndex) => (
                            <ListFilesGroup key={fileGroupIndex}>
                                <ListFilesGroupTitle>
                                    <LocalizedText text={childFolder.title} />
                                </ListFilesGroupTitle>
                                <ListFilesGroupList>
                                    {childFolder.folders
                                        .filter((grandChildFolder) => grandChildFolder.isDisplayed === true)
                                        .map((grandChildFolder, fileIndex) => (
                                            <ListFilesGroupListItem
                                                isSelected={
                                                    selectedGrandChildFolder?.id === grandChildFolder.id
                                                }
                                                key={fileIndex}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    selectGrandChildFolderHandler(grandChildFolder);
                                                }}
                                            >
                                                <AiFillFolder />
                                                <ListFilesGroupListItemLabel>
                                                    <LocalizedText text={grandChildFolder.title} />
                                                </ListFilesGroupListItemLabel>
                                            </ListFilesGroupListItem>
                                        ))}
                                </ListFilesGroupList>
                            </ListFilesGroup>
                        ))}
                </ListFiles>
            )}
        </ListQuotesItem>
    );

    return (
        <List>
            <ListQuotes>
                {caseFolders
                    .filter((caseFolder) =>
                        caseFolder.folders.some((childFolder) => user.isAllowedToUpload(childFolder))
                    )
                    .map((caseFolder) => (
                        <>
                            {renderCaseFolder(caseFolder, selectedFolder?.id === caseFolder.id)}

                            {caseFolder.dependents != null &&
                                caseFolder.dependents
                                    .filter((dependentFolder) =>
                                        dependentFolder.folders.some((dependentChildFolder) =>
                                            user.isAllowedToUpload(dependentChildFolder)
                                        )
                                    )
                                    .map((dependentFolder) =>
                                        renderCaseFolder(
                                            dependentFolder,
                                            selectedFolder?.id === dependentFolder.id,
                                            true
                                        )
                                    )}
                        </>
                    ))}
            </ListQuotes>
        </List>
    );
};

export default FileItemQuotesList;
