import BaseRequest from './_baseRequest';
import { BaseRequestConfig } from '../APIService.types';

class AuthRequest extends BaseRequest {
    constructor(constructor: BaseRequestConfig) {
        super(constructor);
    }

    /**
     * Login
     * @param {string} email
     * @param {string} password
     * @returns {Promise<Object>}
     */
    async login(
        email: string,
        password: string
    ): Promise<{ tokens: { accessToken: string; refreshToken: string } }> {
        return this.post('/login', false, {
            email: email,
            password: password,
        });
    }

    /**
     * Logout
     * @return {Promise<void>}
     */
    async logout(): Promise<void> {
        return this.post('/logout', true, {});
    }

    /**
     * Uuid
     * @param {string} signature
     * @return {Promise<Object>}
     */
    async uuid(
        role: string,
        signature: string
    ): Promise<{ tokens: { accessToken: string; refreshToken: string } }> {
        return this.post('/uuid', false, {
            role: role,
            signature: signature,
        });
    }

    /**
     * Reset password request
     * @param email
     * @return {Promise<void>}
     */
    async resetPasswordRequest(email: string): Promise<{}> {
        return this.post('/reset-password/request', false, { email: email });
    }

    /**
     * Reset password confirm
     * @param email
     * @return {Promise<void>}
     */
    async resetPasswordConfirm(payload: { email: string; password: string; code: string }): Promise<{}> {
        return this.post('/reset-password/confirm', false, {
            email: payload.email,
            code: payload.code,
            password: payload.password,
        });
    }
}

export default AuthRequest;
