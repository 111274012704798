import React, { useRef } from 'react';

import { Checkmark, Container, Input, Text } from './CheckboxInput-styles';

import { IoMdCheckmark } from 'react-icons/io';
import { generateId } from '../../../tools';

export type CheckboxInputProps = {
    label?: string | React.ReactNode;
    isChecked: boolean;
    onChange(value: boolean): void;
};

const CheckboxInput = ({ label, isChecked, onChange }: CheckboxInputProps) => {
    const id = useRef(generateId());

    return (
        <Container htmlFor={id.current}>
            {label != null && <Text>{label}</Text>}
            <Input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => {
                    e.stopPropagation();
                    onChange(e.target.checked);
                }}
                id={id.current}
            />
            <Checkmark className="checkmark">{isChecked === true && <IoMdCheckmark />}</Checkmark>
        </Container>
    );
};

export default CheckboxInput;
