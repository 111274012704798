import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.text.onPrimary};
    cursor: pointer;
`;

export const IconContainer = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
    }
`;

export const Label = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};
    svg {
        margin-left: 0.3rem;
        margin-bottom: 0.3rem;
    }
`;

export const List = styled.ul`
    position: absolute;
    z-index: 1;
    background: ${({ theme }) => theme.colors.surface.main};
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    top: calc(100% + 5px);
    right: 0;
    border-radius: 2px;
`;

export const ListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.md};
    padding: ${({ theme }) => `${theme.box.spacing.sm} ${theme.box.spacing.md}`};
    cursor: pointer;

    :hover {
        background: ${({ theme }) => theme.colors.surface.secondary};
    }
`;
