import styled, { css } from 'styled-components';
import { InputMode } from '../Inputs-types';

const focusedStyle = css`
    outline: none;
    position: relative;
`;

export const Container = styled.div<{
    mode?: InputMode;
}>`
    width: 100%;
    height: ${({ theme }) => theme.config.input.height.default}px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    z-index: 2;
    background: ${({ theme }) => theme.colors.surface.main};
    font-size: ${({ theme }) => theme.font.size.lg};
    border-radius: 0.8rem;

    ${({ mode, theme }) => {
        if (mode === InputMode.STAND_ALONE) {
            return {
                borderRadius: '0.3rem',
                height: `${theme.config.input.height.small}px`,
                '.input-container__trailing-icon': {
                    height: `${theme.config.input.height.small}px`,
                },
            };
        }
    }}

    .react-datepicker-wrapper {
        width: 100%;
        height: 100%;
    }

    .react-datepicker__triangle {
        left: 20% !important;
        transform: unset !important;
    }

    .react-datepicker__input-container {
        width: 100%;
        height: 100%;

        & > input {
            width: 100%;
            height: 100%;
            padding: 0 ${({ theme }) => theme.box.spacing.md};
            overflow: hidden;
            border-radius: 0.8rem;
            background: transparent;

            &:focus {
                ${focusedStyle}
            }

            &::placeholder {
                color: ${({ theme }) => theme.colors.text.tertiary};
                font-size: ${({ theme }) => theme.font.size.md};
            }
        }
    }

    .react-datepicker-popper {
        z-index: 2;

        .react-datepicker {
            font-size: ${({ theme }) => theme.font.size.md};
        }

        .react-datepicker__day-names {
            margin-top: ${({ theme }) => theme.box.spacing.md};
        }
    }

    .react-datepicker__day--selected {
        background-color: ${({ theme }) => theme.colors.primary.main};
        color: ${({ theme }) => theme.colors.text.onPrimary};
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
        width: 32px;
        height: 32px;
        align-content: center;
    }

    .react-datepicker__current-month {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const TrailingIconContainer = styled.div`
    width: 3rem;
    min-width: 3rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;

    svg {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const InputInnerLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding-left: ${({ theme }) => theme.box.spacing.sm};
    min-width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    line-height: 1.4;
`;
