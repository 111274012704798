import { Organization } from '../../../types';
import { REDUX_Organization } from '../../ReduxService';
import { BaseRequestConfig } from '../APIService.types';
import BaseRequest from './_baseRequest';

class OrganizationRequest extends BaseRequest {
    /**
     * Constructor
     * @param config
     */
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    /**
     * Get list of organizations
     * @return {Promise<Array<Organization>>}
     */
    async getList(): Promise<Array<Organization<{ id: true; config: true; theme: true; assets: true }>>> {
        return this.get('/', true).then((res: any) => res.organizations);
    }

    /**
     * Get public list of organizations
     * @return {Promise<Array<Organization>>}
     */
    async getPublicList(): Promise<Array<REDUX_Organization>> {
        return this.get('/public', false).then((res: any) => res.organizations);
    }

    /**
     * Create an organization
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    async createOrganization(payload: {
        name: string;
        primaryColor: string;
        loginUrl: string;
        logo: File;
        favicon: File;
    }): Promise<{}> {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                name: payload.name,
                primaryColor: payload.primaryColor,
                loginUrl: payload.loginUrl,
            })
        );
        formData.append('logo', payload.logo);
        formData.append('favicon', payload.favicon);
        return this.post('/', true, formData, undefined, { useMultiPartFormData: true });
    }

    /**
     * Update an organization
     * @param {string} organizationId
     * @param {Object} payload
     * @return {Promise<{}>}
     */
    async updateOrganization(
        organizationId: string,
        payload: {
            name: string;
            primaryColor: string;
            loginUrl: string;
            logo?: File;
            favicon?: File;
        }
    ): Promise<{}> {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                name: payload.name,
                primaryColor: payload.primaryColor,
                loginUrl: payload.loginUrl,
            })
        );

        if (payload.logo != null) {
            formData.append('logo', payload.logo);
        }

        if (payload.favicon != null) {
            formData.append('favicon', payload.favicon);
        }

        return this.patch(`/${organizationId}`, true, formData, undefined, { useMultiPartFormData: true });
    }
}

export default OrganizationRequest;
