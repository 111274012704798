import React, { useEffect, useMemo, useState } from 'react';
import {
    Case,
    CaseFile,
    CaseFolder,
    LocaleObject,
    OCR_RESULT,
    OCR_TYPE,
    OCR_VALIDATION,
} from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../../hooks';
import {
    Modal,
    ModalFooter,
    ModalSize,
    SecondaryContainedButton,
    Spinner,
    SpinnerAppearance,
    SpinnerSize,
} from '../../../../../../components';
import { APIService } from '../../../../../../services';
import { ExecuteButtonContainer, LoadingContainer, Preview, PreviewContainer } from './OCRFileModal-styles';
import { OCRFileModalDS160, OCRFileModalTable, OCRFileModalText } from './components';
import { getSupportedFileByMimeType } from '../../../../../../config/supported-files';

type OCRFileModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFile: CaseFile;
};

enum ViewMode {
    TEXT = 'text',
    TABLE = 'table',
}

export type FileOCRResult = {
    key: string;
    label: LocaleObject;
    value: string;
    index?: number;
    validation?: OCR_VALIDATION & { isProcessed: boolean };
};

const OCRFileModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
    selectedFile,
}: OCRFileModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();
    const [isInitialized, setIsInitialized] = useState(false);
    const [viewMode, setViewMode] = useState<null | ViewMode>(null);
    const [fileURL, setFileURL] = useState(selectedFile.url?.path);
    const [fileOCR, setFileOCR] = useState<null | { text: string; results?: Array<FileOCRResult> }>(null);

    useEffect(() => {
        initializeHandler();
    }, []);

    const initializeHandler = async () => {
        let shouldReload = false;

        if (selectedFile.url?.path == null) {
            await generateUrlHandler();
            shouldReload = true;
        }

        if (selectedFile.ocr?.text == null) {
            await executeOCRHandler();
            shouldReload = true;
        } else {
            setFileOCR({
                text: selectedFile.ocr.text,
                results:
                    selectedFile.ocr.results != null && selectedFile.ocr.validations != null
                        ? formatOCRResultsHandler(selectedFile.ocr.results, selectedFile.ocr.validations)
                        : selectedFile.ocr.results,
            });
            setViewMode(selectedFile.ocr.results != null ? ViewMode.TABLE : ViewMode.TEXT);
        }

        if (shouldReload === true) {
            await refresh();
        }

        setIsInitialized(true);
    };

    const executeOCRHandler = async () => {
        await executePromise(async () => {
            const { text, results, validations } = await APIService.cases().executeOCR(
                currentCase.id,
                currentCaseFolder.id,
                selectedFile.id
            );
            setFileOCR({
                text: text,
                results:
                    results != null && validations != null
                        ? formatOCRResultsHandler(results, validations)
                        : results,
            });
            setViewMode(results != null ? ViewMode.TABLE : ViewMode.TEXT);
        });
    };

    const reExecuteOCRHandler = async () => {
        setIsInitialized(false);
        await executeOCRHandler();
        await refresh();
        setIsInitialized(true);
    };

    const updateOCRHandler = async (
        results: Array<{ key: string; value: number | string; index?: number }>
    ) => {
        await executePromise(async () => {
            await APIService.cases().updateOCR(
                currentCase.id,
                currentCaseFolder.id,
                selectedFile.id,
                results
            );
            await refresh();
            onClose();
        });
    };

    const acknowledgeOCRHandler = async (key: string) => {
        await executePromise(async () => {
            await APIService.cases().acknowledgeOCR(
                currentCase.id,
                currentCaseFolder.id,
                selectedFile.id,
                key
            );
            await refresh();
            const updatedResults = fileOCR!.results!.map((result) => {
                if (result?.validation?.key === key) {
                    return {
                        ...result,
                        validation: undefined,
                    };
                } else {
                    return result;
                }
            });
            setFileOCR({
                text: fileOCR!.text,
                results: updatedResults,
            });
        });
    };

    const generateUrlHandler = async () => {
        await executePromise(async () => {
            const response = await APIService.cases().getFileUrl(
                currentCase.id,
                currentCaseFolder.id,
                selectedFile.id
            );
            setFileURL(response.path);
        });
    };

    const formatOCRResultsHandler = (
        results: Array<OCR_RESULT>,
        validations: Array<OCR_VALIDATION>
    ): Array<FileOCRResult> => {
        const validationsClone = structuredClone(validations);
        const processedValidationKeys = new Set<string>();

        return results.map((result) => {
            const currentValidation = validationsClone.find((validation) =>
                validation.key.includes(result.key)
            );
            const formatted = {
                key: result.key,
                label: result.label,
                value: result.value,
                index: result.index,
                validation:
                    currentValidation != null
                        ? {
                              ...currentValidation,
                              isProcessed: processedValidationKeys.has(currentValidation.key),
                          }
                        : undefined,
            };

            processedValidationKeys.add(currentValidation?.key ?? '');

            return formatted;
        });
    };

    const showPreview = useMemo(() => {
        const fileConfig = getSupportedFileByMimeType(selectedFile.mimeType);
        return fileConfig.settings.enablePreview === true;
    }, [selectedFile]);

    const renderView = () => {
        if (viewMode === ViewMode.TABLE) {
            return renderTableView();
        }

        return <OCRFileModalText text={fileOCR!.text} />;
    };

    const renderTableView = () => {
        if (selectedFile.ocr?.type === OCR_TYPE.DS160) {
            return (
                <OCRFileModalDS160
                    fileOCRResults={fileOCR!.results!}
                    updateOCRHandler={updateOCRHandler}
                    renderFooter={renderFooter}
                />
            );
        }

        return (
            <OCRFileModalTable
                fileOCRResults={fileOCR!.results!}
                acknowledgeOCRHandler={acknowledgeOCRHandler}
            />
        );
    };

    const renderFooter = (onClick?: (payload?: any) => void) => {
        const hasOnClick = typeof onClick === 'function';

        return (
            <ModalFooter
                confirmationLabel={hasOnClick ? t('generic.save') : 'OK'}
                onClick={hasOnClick ? onClick : onClose}
                secondary={
                    fileOCR?.results != null
                        ? {
                              label:
                                  viewMode === ViewMode.TABLE
                                      ? t('case.documents.modals.ocr.view_text')
                                      : t('case.documents.modals.ocr.view_result'),
                              onClick: () =>
                                  setViewMode(viewMode === ViewMode.TABLE ? ViewMode.TEXT : ViewMode.TABLE),
                          }
                        : undefined
                }
            />
        );
    };

    return (
        <Modal
            title={t('generic.ocr')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            {isInitialized === false && (
                <LoadingContainer>
                    <Spinner appearance={SpinnerAppearance.PRIMARY} size={SpinnerSize.MEDIUM} />
                </LoadingContainer>
            )}
            {isInitialized === true && (
                <>
                    <ExecuteButtonContainer>
                        <SecondaryContainedButton onClick={() => reExecuteOCRHandler()}>
                            {t('case.documents.modals.ocr.launch_ocr')}
                        </SecondaryContainedButton>
                    </ExecuteButtonContainer>
                    {renderView()}
                    {showPreview === true && (
                        <PreviewContainer href={fileURL} target="_blank">
                            <Preview src={fileURL} />
                        </PreviewContainer>
                    )}
                    {(selectedFile.ocr?.type !== OCR_TYPE.DS160 || viewMode === ViewMode.TEXT) &&
                        renderFooter()}
                </>
            )}
        </Modal>
    );
};

export default OCRFileModal;
