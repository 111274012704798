import React, { useMemo, useRef, useState } from 'react';
import { Case, CaseFolder, CaseFolder_L2 } from '../../../../../../../../types';
import { BatchUpdateFileItem } from '../../types';
import { useLocale, usePromises } from '../../../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import { formatBytes, formatCaseFolderDescription, formatLocalizedText } from '../../../../../../../../tools';
import {
    Container,
    Cta,
    CtaLabel,
    CtaLabelLeadingIcon,
    CtaLabelText,
    CtaLabelTrailingIcon,
    IconContainer,
    IconSpinner,
    Info,
    InfoNameDiv,
    InfoNameLink,
    InfoSize,
    RemoveIcon,
} from './UpdateFileItem-styles';
import { AiFillFolder, AiOutlineCloseCircle } from 'react-icons/ai';
import { FileIcon, Spinner, SpinnerAppearance } from '../../../../../../../../components';
import { BiChevronDown } from 'react-icons/bi';
import FileItemQuotesList from '../FileItemQuotesList';
import { APIService } from '../../../../../../../../services';

type UpdateFileItemProps = {
    fileItem: BatchUpdateFileItem;
    caseFolders: Array<CaseFolder>;
    isEditable: boolean;
    isRemovable?: boolean;
    currentCase: Case;
    onClickRemove: () => void;
    onSelectFolder: (fileId: string, quoteId: string, categoryFileId: string) => void;
    updateFileUrl: (id: string, url: { path: string; expiredAt: Date }) => void;
};

const getInitialSelectedFolder = (fileItem: BatchUpdateFileItem, caseFolders: Array<CaseFolder>) => {
    const currentCaseFolder = caseFolders.find((caseFolder) => caseFolder.id === fileItem.quoteId)!;
    if (currentCaseFolder != null) {
        return currentCaseFolder;
    } else {
        for (const { dependents } of caseFolders) {
            if (dependents != null) {
                for (const dependent of dependents) {
                    if (dependent.id === fileItem.quoteId) {
                        return dependent;
                    }
                }
            }
        }
    }
    return null;
};

const getInitialSelectedGrandChildFolder = (
    fileItem: BatchUpdateFileItem,
    caseFolders: Array<CaseFolder>
) => {
    const currentCaseFolder = getInitialSelectedFolder(fileItem, caseFolders)!;
    for (const childFolder of currentCaseFolder.folders) {
        for (const grandChildFolder of childFolder.folders) {
            if (grandChildFolder.id === fileItem.categoryFileId) {
                return grandChildFolder;
            }
        }
    }

    return null;
};

const UpdateFileItem = ({
    fileItem,
    caseFolders,
    onClickRemove,
    onSelectFolder,
    isEditable,
    currentCase,
    updateFileUrl,
    isRemovable = true,
}: UpdateFileItemProps) => {
    const locale = useLocale();
    const { t } = useTranslation();
    const [{ executePromise }] = usePromises();

    const initialSelectedFolder = useMemo(() => getInitialSelectedFolder(fileItem, caseFolders), []);
    const [selectedFolder, setSelectedFolder] = useState<null | CaseFolder>(initialSelectedFolder);
    const [selectedGrandChildFolder, setSelectedGrandChildFolder] = useState<null | CaseFolder_L2>(
        getInitialSelectedGrandChildFolder(fileItem, caseFolders)
    );
    const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => {
        setIsOpened(false);
    });

    const selectFolderHandler = (caseFolder: CaseFolder) => {
        setSelectedFolder(caseFolder);
        setSelectedGrandChildFolder(null);
    };

    const selectGrandChildFolderHandler = (caseGrandChildFolder: CaseFolder_L2) => {
        setSelectedGrandChildFolder(caseGrandChildFolder);
        setIsOpened(false);
        onSelectFolder(fileItem.id, selectedFolder!.id, caseGrandChildFolder.id);
    };

    const renderLabel = () => {
        if (selectedFolder == null || selectedGrandChildFolder == null) {
            return t('case.documents.select_folder');
        } else {
            return `${formatCaseFolderDescription(selectedFolder, locale)} / ${formatLocalizedText(
                selectedGrandChildFolder.title,
                locale
            )}`;
        }
    };

    const generateUrlHandler = async () => {
        if (isGeneratingUrl === true) {
            return;
        }

        await executePromise(async () => {
            setIsGeneratingUrl(true);
            const response = await APIService.cases().getFileUrl(
                currentCase.id,
                initialSelectedFolder!.id,
                fileItem.id
            );
            updateFileUrl(fileItem.id, response);
            setIsGeneratingUrl(false);
            window.open(response.path, '_blank');
        });
    };

    return (
        <Container ref={containerRef}>
            {isRemovable === true && (
                <RemoveIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClickRemove();
                    }}
                >
                    <AiOutlineCloseCircle />
                </RemoveIcon>
            )}

            <IconContainer isGeneratingUrl={isGeneratingUrl}>
                <FileIcon mimeType={fileItem.file.mimeType} />
                {isGeneratingUrl === true && (
                    <IconSpinner>
                        <Spinner appearance={SpinnerAppearance.PRIMARY} />
                    </IconSpinner>
                )}
            </IconContainer>
            <Info>
                {fileItem.file.url?.path != null ? (
                    <InfoNameLink href={fileItem.file.url?.path} target="_blank">
                        {fileItem.file.originalName}
                    </InfoNameLink>
                ) : (
                    <InfoNameDiv onClick={generateUrlHandler}>{fileItem.file.originalName}</InfoNameDiv>
                )}
                <InfoSize>{formatBytes(fileItem.file.size)}</InfoSize>
            </Info>
            <Cta
                isEditable={isEditable}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <CtaLabel
                    onClick={() => {
                        if (isEditable === true) {
                            setIsOpened((prev) => !prev);
                        }
                    }}
                >
                    <CtaLabelLeadingIcon>
                        <AiFillFolder />
                    </CtaLabelLeadingIcon>
                    <CtaLabelText isPlaceholder={selectedGrandChildFolder == null}>
                        {renderLabel()}
                    </CtaLabelText>
                    <CtaLabelTrailingIcon>
                        <BiChevronDown />
                    </CtaLabelTrailingIcon>
                </CtaLabel>
                {isOpened === true && (
                    <FileItemQuotesList
                        caseFolders={caseFolders}
                        selectedFolder={selectedFolder}
                        selectFolderHandler={selectFolderHandler}
                        selectedGrandChildFolder={selectedGrandChildFolder}
                        selectGrandChildFolderHandler={selectGrandChildFolderHandler}
                    />
                )}
            </Cta>
        </Container>
    );
};

export default UpdateFileItem;
