import { FileOCRResult } from '../../../OCRFileModal';

export const initialValues = {
    companyName: '',
    businessType: '',
    businessTypeOther: '',
    headQuarterAddress: '',
    headQuarterPhoneNumber: '',
    headQuarterFaxNumber: '',
    dateOfEstablishment: undefined as Date | undefined,
    placeOfEstablishment: '',
    businessNature: '',
    businessNatureOther: '',
    servicesDescription: '',
    foreignParentBusinessNameAddress: '',
    foreignParentBusinessPhoneNumber: '',
    foreignParentBusinessFaxNumber: '',
    businessOwners: [
        {
            ownerName: '',
            ownerNationality: '',
            ownerImmigrationStatus: '',
            ownerOwnership: 100,
        },
    ],
    financialStatementYear: '',
    financialStatementYearType: '',
    financialStatementTotalAssets: '',
    financialStatementTotalAssetsType: '',
    financialStatementTotalLiabilities: '',
    financialStatementOwnerEquity: '',
    financialStatementAnnualOperatingIncomeBeforeTaxes: '',
    financialStatementAnnualOperatingIncomeAfterTaxes: '',

    tradeYear: '',
    tradeYearType: '',
    tradeYearEnding: '',
    tradeImport: [0, 0, 0],
    tradeExport: [0, 0, 0],
    tradeImportThird: [0, 0, 0],
    tradeExportThird: [0, 0, 0],
    tradeDomestic: [0, 0, 0],
    tradeTotal: [0, 0, 0],

    staffYearType: '',
    staffNationals: [0, 0, 0, 0, 0, 0],
    staffUsCitizens: [0, 0, 0, 0, 0, 0],
    staffOther: [0, 0, 0, 0, 0, 0],
    staffTotal: [0, 0, 0, 0, 0, 0],

    usPersonnel: [
        {
            usPersonnelName: '',
            usPersonnelNationality: '',
            usPersonnelType: '',
            usPersonnelDate: undefined as Date | undefined,
            usPersonnelPlaceOfIssuance: '',
        },
    ],

    typeOfInvestment: '',

    foreignInvestmentYear: '',
    foreignInvestmentYearType: '',
    foreignInvestmentDetail: [
        // Initial
        {
            foreignInvestmentCash: '',
            foreignInvestmentInventory: '',
            foreignInvestmentEquipment: '',
            foreignInvestmentPremises: '',
            foreignInvestmentOther: '',
            foreignInvestmentTotal: '',
        },
        // Cumulative
        {
            foreignInvestmentCash: '',
            foreignInvestmentInventory: '',
            foreignInvestmentEquipment: '',
            foreignInvestmentPremises: '',
            foreignInvestmentOther: '',
            foreignInvestmentTotal: '',
        },
    ],
    applicantLastName: '',
    applicantFirstName: '',
    applicantMiddleName: '',
    applicantType: '',
    applicantTypeOther: '',
    applicantPositionAndDuties: '',
    applicantEmployerNameAnAddress: '',
    applicantYearWithEmployer: '',
    applicantEducationSchool: '',
    applicantEducationMajor: '',
    applicantDegreeType: '',
    applicantDegreeYear: '',
    applicantOtherExperience: '',
    applicantUsPosition: '',
    applicantUsDuties: '',
    applicantSalary: '',
    applicantAllowance: '',
    applicantSalaryTotal: '',

    replacedPersonName: '',
    replacedVisa: '',
    replacedDate: undefined as Date | undefined,
    replacedPlaceIssued: '',
    replacedIncrease: '',
    replacedContinuation: '',

    signatureName: '',
    signatureDate: undefined as Date | undefined,
    contactNameAddress: '',
    contactPhoneNumber: '',
};

function isValidDateString(dateString: string) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
}

/**
 * Get initial values
 * @param fileOCRResults
 * @return {Object}
 */
export const getInitialValues = (fileOCRResults: Array<FileOCRResult>): typeof initialValues => {
    const values = structuredClone(initialValues);

    for (const [k, value] of Object.entries(initialValues)) {
        const key = k as keyof typeof values;

        if (Array.isArray(value)) {
            const firstItem = value[0];

            if (typeof firstItem === 'object') {
                for (const k1 of Object.keys(firstItem)) {
                    const objectKey = k1 as keyof typeof firstItem;
                    const relevantResults = fileOCRResults.filter((result) => result.key === objectKey);

                    for (const ocrResult of relevantResults) {
                        const index = ocrResult.index ?? 0;
                        if (ocrResult.value) {
                            let currentValue = ocrResult.value;
                            if (typeof currentValue === 'string' && isValidDateString(currentValue)) {
                                currentValue = new Date(currentValue) as any;
                            }

                            if ((values[key] as Array<any>)[index] != null) {
                                (values[key] as Array<any>)[index][objectKey] = currentValue as any;
                            } else {
                                (values[key] as Array<any>)[index] = {
                                    [objectKey]: currentValue as any,
                                };
                            }
                        }
                    }
                }
            } else {
                const ocrResult = fileOCRResults.filter((result) => result.key === key);

                ocrResult.forEach((result) => {
                    (values[key] as Array<any>)[result.index ?? 0] = result.value;
                });
            }
        } else {
            const ocrResult = fileOCRResults.find((result) => result.key === key);
            if (ocrResult != null && ocrResult.value) {
                if (typeof values[key] !== 'string' && typeof values[key] !== 'number') {
                    values[key] = new Date(ocrResult.value) as any;
                } else {
                    values[key] = ocrResult.value as any;
                }
            }
        }
    }

    return values;
};
