import React, { createContext } from 'react';
import { FormikProps } from 'formik';
import { Container, SpinnerContainer } from './Form-styles';
import { ObjectSchema } from 'yup';
import Spinner, { SpinnerAppearance, SpinnerSize } from '../../Spinner';

type Props = FormContextType & {
    children: React.ReactNode;
    oneColumn?: boolean;
    isLoading?: boolean;
    autoComplete?: string;
};

type FormContextType = {
    formik: FormikProps<any>;
    validationSchema?: ObjectSchema<{ [key: string]: any }>;
};

export const FormContext = createContext<FormContextType>(null!);

const Form = ({ children, formik, validationSchema, oneColumn, isLoading = false }: Props) => {
    return (
        <FormContext.Provider
            value={{
                formik: formik,
                validationSchema: validationSchema,
            }}
        >
            {isLoading ? (
                <SpinnerContainer>
                    <Spinner appearance={SpinnerAppearance.PRIMARY} size={SpinnerSize.MEDIUM} />
                </SpinnerContainer>
            ) : (
                <Container onSubmit={formik.handleSubmit} oneColumn={oneColumn} noValidate>
                    {children}
                </Container>
            )}
        </FormContext.Provider>
    );
};

export default Form;
