import React from 'react';
import { Modal, ModalParagraph, ModalFooter } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { Case, CaseForm } from '../../../../../../types';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../routes';

type UnsavedChangesModalProps = {
    currentCase: Case;
    currentCaseForm: CaseForm;
    onClose: () => void;
};

const UnsavedChangesModal = ({ currentCase, currentCaseForm, onClose }: UnsavedChangesModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}/${currentCaseForm.id}`);
        onClose();
    };

    return (
        <Modal title={t('case.forms.modals.unsaved_changes.title')} onClose={onClose}>
            <ModalParagraph>{t('case.forms.modals.unsaved_changes.message')}</ModalParagraph>
            <ModalFooter confirmationLabel={'OK'} onClick={onClick} secondary={{ onClick: onClose }} />
        </Modal>
    );
};

export default UnsavedChangesModal;
