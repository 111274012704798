import React, { useRef, useState } from 'react';
import { Container, Content, ContentColor, ContentLabel, SketcherContainer } from './ColorInput-styles';
import { SketchPicker } from 'react-color';
import { useOnClickOutside } from 'usehooks-ts';

export type ColorInputProps = {
    value: string;
    onChange: (value: string) => void;
};

const ColorInput = ({ value, onChange }: ColorInputProps) => {
    const containerRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    useOnClickOutside(containerRef, () => setIsFocused(false));

    return (
        <Container ref={containerRef}>
            <Content onClick={() => setIsFocused((prev) => !prev)}>
                <ContentColor color={value} />
                <ContentLabel>{value}</ContentLabel>
            </Content>
            {isFocused && (
                <SketcherContainer>
                    <SketchPicker color={value} onChangeComplete={(color) => onChange(color.hex)} />
                </SketcherContainer>
            )}
        </Container>
    );
};

export default ColorInput;
