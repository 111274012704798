import React, { useMemo } from 'react';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    Modal,
} from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../../hooks';
import { Case } from '../../../../../../types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { APIService } from '../../../../../../services';
import { formatLocalizedText } from '../../../../../../tools';

type AddCaseFormModalProps = {
    onClose: () => void;
    refreshFormsList: () => Promise<void>;
    currentCase: Case;
};

const AddCaseFormModal = ({ onClose, refreshFormsList, currentCase }: AddCaseFormModalProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise }, [forms, clients]] = usePromises(
        () => APIService.forms().getFormsList(),
        () => APIService.cases().getClientsList(currentCase.id)
    );

    const validationSchema = Yup.object({
        formId: Yup.string().required(t('generic.error.required_field')),
        clientId: Yup.string().notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            formId: '',
            clientId: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                await APIService.cases().addForm(currentCase.id, {
                    clientId: submittedValues.clientId,
                    formId: submittedValues.formId,
                });
                await refreshFormsList();
                onClose();
            });
        },
    });

    const formsList = useMemo(() => {
        if (forms.data == null) {
            return [];
        }
        return forms.data.map((form) => ({
            label: formatLocalizedText(form.title, locale),
            value: form.id,
        }));
    }, [forms.data, locale]);

    const clientsList = useMemo(() => {
        if (clients.data == null) {
            return [];
        }
        return clients.data.map((client) => ({
            label: `${client.firstName} ${client.lastName}`,
            value: client.id,
        }));
    }, [clients.data, locale]);

    return (
        <Modal
            title={t('case.forms.modals.add.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            isLoading={forms.isLoading || clients.isLoading}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="formId" expand>
                    <FormGroupLabel>{t('generic.form')}</FormGroupLabel>
                    <FormSelectInput options={formsList} />
                </FormGroup>
                <FormGroup name="clientId" expand>
                    <FormGroupLabel>{t('generic.client')}</FormGroupLabel>
                    <FormSelectInput options={clientsList} />
                </FormGroup>
                <FormFooter submitLabel={t('case.forms.modals.add.title')} secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default AddCaseFormModal;
