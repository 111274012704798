import styled from 'styled-components';

export const List = styled.ul`
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};
`;

export const ListItem = styled.li`
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-auto-rows: max-content;
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-left: 0;
    column-gap: ${({ theme }) => theme.box.spacing.md};
`;

export const ListItemTitle = styled.div`
    width: 40%;
    max-width: 30rem;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.secondary};
    grid-column: 1 / 2;
    align-self: center;
`;

export const ListItemSubTitle = styled.div`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    line-height: 1.4;
    font-size: ${({ theme }) => theme.font.size.sm};
`;

export const ListItemValue = styled.div<{ isText: boolean }>`
    grid-column: 2 / 3;
    background-color: ${({ theme }) => theme.colors.surface.main};
    flex: 1;

    ${({ isText, theme }) => {
        if (isText === true) {
            return {
                fontSize: theme.font.size.md,
                fontWeight: theme.font.weight.bold,
            };
        }
    }}

    .select-input-container {
        width: 90%;
        max-width: 20rem;
    }
`;
