import React from 'react';
import { EmployeeBadges, Modal, SecondaryOutlinedButton } from '../../../../components';
import { Actions, Employee } from '../../../../types';
import {
    Buttons,
    Header,
    HeaderName,
    InfoList,
    InfoListItem,
    InfoListItemIcon,
    InfoListItemText,
} from './ReadEmployeeModal-styles';
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import { MdOutlineMailOutline, MdOutlineContactPage } from 'react-icons/md';
import { FiPhone } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../../../hooks';
import { EmployeesListActionsTypes } from '../../EmployeesList/EmployeesList';

type Props = {
    onClose: () => void;
    openModal: (value: Actions | EmployeesListActionsTypes) => void;
    selectedEmployee: Employee;
};

const ReadEmployeeModal = ({ onClose, selectedEmployee, openModal }: Props) => {
    const { t } = useTranslation();
    const user = useLoggedInUser();

    const renderTitle = () => {
        return (
            <Header>
                <HeaderName>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</HeaderName>
                <EmployeeBadges roles={selectedEmployee.roles} />
            </Header>
        );
    };

    const infoList = [
        {
            icon: <HiOutlineBuildingLibrary />,
            text: selectedEmployee.organization.name,
        },
        {
            icon: <MdOutlineContactPage />,
            text: `${t('employees.internal_id')} : ${selectedEmployee.internalId}`,
        },
        {
            icon: <MdOutlineMailOutline />,
            text: selectedEmployee.email,
        },
        {
            icon: <FiPhone />,
            text: selectedEmployee.contacts[0]?.phoneNumber ?? ' - ',
        },
    ];

    return (
        <Modal onClose={onClose} title={renderTitle()}>
            <InfoList>
                {infoList.map((item, index) => (
                    <InfoListItem key={index}>
                        <InfoListItemIcon>{item.icon}</InfoListItemIcon>
                        <InfoListItemText>{item.text}</InfoListItemText>
                    </InfoListItem>
                ))}
            </InfoList>
            {user.hasPermissions('employees.update') && (
                <Buttons>
                    <SecondaryOutlinedButton expand onClick={() => openModal(Actions.UPDATE)}>
                        {t('employees.modal.update_employee.title')}
                    </SecondaryOutlinedButton>
                    <SecondaryOutlinedButton
                        expand
                        onClick={() => openModal(EmployeesListActionsTypes.UPDATE_ORGANIZATION)}
                    >
                        {t('employees.modal.update_organization.title')}
                    </SecondaryOutlinedButton>
                    <SecondaryOutlinedButton
                        expand
                        onClick={() => openModal(EmployeesListActionsTypes.UPDATE_PASSWORD)}
                    >
                        {t('employees.modal.change_password.title')}
                    </SecondaryOutlinedButton>
                    <SecondaryOutlinedButton expand onClick={() => openModal(Actions.DELETE)}>
                        {t('employees.modal.delete.title')}
                    </SecondaryOutlinedButton>
                </Buttons>
            )}
        </Modal>
    );
};

export default ReadEmployeeModal;
