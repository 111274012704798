import React from 'react';
import { CaseFolderManager, CaseFolderOCR } from '../../../../../managers';
import { Locale, LocaleObject } from '../../../../../types';
import { formatCaseFolderDescription } from '../../../../../tools';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Banner, LocalizedText, PrimaryContainedButton } from '../../../../../components';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import { CaseDocumentsContextType } from '../CaseDocuments';
import { Content } from './CaseDocumentsOCRBanner-styles';
import { useTranslation } from 'react-i18next';

type CaseDocumentsOCRBannerProps = {
    caseFolderOCR: CaseFolderOCR;
    refresh: () => Promise<void>;
    setSelectedOCRFileId: React.Dispatch<React.SetStateAction<string>>;
    setCaseFolderOCRList: React.Dispatch<React.SetStateAction<CaseFolderOCR[]>>;
};

const CaseDocumentsOCRBanner = ({
    caseFolderOCR,
    refresh,
    setSelectedOCRFileId,
    setCaseFolderOCRList,
}: CaseDocumentsOCRBannerProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [promises] = usePromises();
    const { currentCase } = useOutletContext<CaseDocumentsContextType>();

    /**
     * Click OCR banner handler
     */
    const clickOCRBannerHandler = () => {
        const path = caseFolderOCR.caseFolder.getRoutePath();
        navigate(path);
        setSelectedOCRFileId(caseFolderOCR.caseFile.id);
        setTimeout(() => setSelectedOCRFileId(''), 100); // reset
    };

    /**
     * Close OCR banner handler
     */
    const closeOCRBannerHandler = () => {
        setCaseFolderOCRList((prev) =>
            prev.map((prevItem) => {
                if (
                    prevItem.caseFolder.id === caseFolderOCR.caseFolder.id &&
                    prevItem.caseFile.id === caseFolderOCR.caseFile.id &&
                    prevItem.validation.key === caseFolderOCR.validation.key &&
                    prevItem.validation.severity === caseFolderOCR.validation.severity
                ) {
                    return {
                        ...prevItem,
                        isDisplayed: false,
                    };
                } else {
                    return prevItem;
                }
            })
        );
        CaseFolderManager.acknowledgeOCR(caseFolderOCR);
    };

    const acknowledgeOCRHandler = async () => {
        if (promises.isExecutingPromise === true) {
            return;
        }

        await promises.executePromise(async () => {
            await APIService.cases().acknowledgeOCR(
                currentCase.id,
                caseFolderOCR.caseFolder.id,
                caseFolderOCR.caseFile.id,
                caseFolderOCR.validation.key
            );
            await refresh();
            CaseFolderManager.acknowledgeOCR(caseFolderOCR);
        });
    };

    /**
     * Get ocr title
     * @param {CaseFolderOCR} ocr
     * @return {LocaleObject}
     */
    const getOCRTitle = (ocr: CaseFolderOCR): LocaleObject => {
        return {
            [Locale.EN]: `${formatCaseFolderDescription(ocr.caseFolder, Locale.EN)} - ${
                ocr.validation.title[Locale.EN]
            }`,
            [Locale.FR]: `${formatCaseFolderDescription(ocr.caseFolder, Locale.FR)} - ${
                ocr.validation.title[Locale.FR]
            }`,
        };
    };

    return (
        <Banner
            title={getOCRTitle(caseFolderOCR)}
            closeBanner={() => closeOCRBannerHandler()}
            appearance={caseFolderOCR.appearance}
            onClick={() => clickOCRBannerHandler()}
        >
            <Content>
                <LocalizedText text={caseFolderOCR.validation.message} />
                <PrimaryContainedButton
                    isLoading={promises.isExecutingPromise}
                    onClick={(e) => {
                        e.stopPropagation();
                        acknowledgeOCRHandler();
                    }}
                >
                    {t('generic.discard')}
                </PrimaryContainedButton>
            </Content>
        </Banner>
    );
};

export default CaseDocumentsOCRBanner;
