import React from 'react';
import { Title } from './AccordionHeader-styles';

type AccordionHeaderTitleProps = {
    children: React.ReactNode;
};

const AccordionHeaderTitle = ({ children }: AccordionHeaderTitleProps) => {
    return <Title className="accordion__header__title">{children}</Title>;
};

export default AccordionHeaderTitle;
