import React, { useState } from 'react';
import {
    ServerForm,
    ServerFormConditionActionType,
    ServerFormConditionPayload,
    ServerFormConditionRequirementType,
} from '../../../../../types';
import { Modal, ModalFooter, ModalSize } from '../../../../../components';
import { usePromises } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { UpdateFormConditionModalItem } from './components';
import { getEmptyCondition, getInitialConditions } from './helpers';
import { AddCondition, AddConditionLabel, List } from './UpdateFormConditionModal-styles';
import { APIService } from '../../../../../services';

type Props = {
    onClose: () => void;
    refreshFormsList: () => Promise<void>;
    selectedForm: ServerForm;
};

export type ConditionItem = {
    source: string;
    requirementType: ServerFormConditionRequirementType;
    requirementValue: string | number;
    actionType: ServerFormConditionActionType;
    target: string;
};

const UpdateFormConditionModal = ({ onClose, refreshFormsList, selectedForm }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();
    const [conditions, setConditions] = useState(getInitialConditions(selectedForm));

    const addCondition = () => {
        setConditions((prev) => [...prev, getEmptyCondition()]);
    };

    const removeCondition = (index: number) => {
        if (index === 0 && conditions.length === 1) {
            setConditions([getEmptyCondition()]);
        } else {
            setConditions((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
        }
    };

    const updateCondition = (index: number, condition: ConditionItem) => {
        setConditions((prev) =>
            prev.map((prevItem, prevIndex) => (prevIndex == index ? condition : prevItem))
        );
    };

    const submitConditions = async () => {
        await executePromise(async () => {
            const payload: Array<ServerFormConditionPayload> = conditions
                .filter((condition) => Object.values(condition).every((value) => value.toString().length > 0))
                .map((condition) => {
                    const [sourceSectionId, sourceQuestionId] = condition.source.split('_');
                    const [targetSectionId, targetQuestionId] = condition.target.split('_');

                    return {
                        source: {
                            sectionId: sourceSectionId,
                            questionId: sourceQuestionId,
                        },
                        target: {
                            sectionId: targetSectionId,
                            questionId: targetQuestionId,
                        },
                        action: {
                            actionType: condition.actionType,
                        },
                        requirement: {
                            requirementType: condition.requirementType,
                            value: condition.requirementValue,
                        },
                    };
                });

            await APIService.forms().updateFormConditions(selectedForm.id, { conditions: payload });
            await refreshFormsList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('forms.main.modals.conditions.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.EXTRA_LARGE}
        >
            <List>
                {conditions.map((condition, index) => (
                    <UpdateFormConditionModalItem
                        key={index}
                        selectedForm={selectedForm}
                        conditionItem={condition}
                        updateCondition={updateCondition}
                        removeCondition={removeCondition}
                        index={index}
                    />
                ))}
                <AddCondition>
                    <AddConditionLabel onClick={addCondition}>
                        {t('forms.main.modals.conditions.add_condition')}
                    </AddConditionLabel>
                </AddCondition>
            </List>

            <ModalFooter
                confirmationLabel={t('generic.save')}
                onClick={submitConditions}
                secondary={{ onClick: onClose }}
            />
        </Modal>
    );
};

export default UpdateFormConditionModal;
