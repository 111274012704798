import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useLoggedInUser, useStore } from '../../hooks';
import { resetRedirect } from '../../services/ReduxService';

type Props = {
    children?: React.ReactNode;
};

const AuthenticatedRoute = ({ children }: Props) => {
    const user = useLoggedInUser();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { url } = useStore().settings.redirect;
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (user.isLoggedIn() === true && url.length > 0) {
            navigate(url);
            dispatch(resetRedirect());
        }
        setIsMounted(true);
    }, []);

    if (user.isLoggedIn() === false) {
        // The user is not authenticated and cannot access the route, redirect to login
        return <Navigate to="/login" />;
    }

    if (isMounted === false) {
        // Wait for useEffect to run once before displaying children
        return <React.Fragment />;
    }

    if (typeof children === 'object') {
        // The current route does not have sub paths
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        // The current route has sub path
        return <Outlet />;
    }
};

export default AuthenticatedRoute;
