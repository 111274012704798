import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

// translations
import { I18nextProvider } from 'react-i18next';
import { i18n } from './translations';

// routing
import { BrowserRouter } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import { ReduxService } from './services';

const root = ReactDOM.createRoot(document.getElementById('root')!);

const RenderedApp = () => {
    return (
        // <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Provider store={ReduxService.store}>
                    <App />
                </Provider>
            </BrowserRouter>
        </I18nextProvider>
        // </React.StrictMode>
    );
};

root.render(<RenderedApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
