import React from 'react';
import { OutletContainer } from '../../components';
import { Outlet } from 'react-router-dom';

const ClientsPage = () => {
    return (
        <OutletContainer>
            <Outlet />
        </OutletContainer>
    );
};

export default ClientsPage;
