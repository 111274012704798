import React, { useState, useMemo } from 'react';
import { Modal, ModalFooter, SelectInput } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { Case, CaseQuote } from '../../../../../../types';
import { useLocale, usePromises } from '../../../../../../hooks';
import { APIService } from '../../../../../../services';
import { SelectContainer, SelectLabel } from './UpdateQuoteModal-styles';
import { formatLocalizedText } from '../../../../../../tools';

type UpdateQuoteModalProps = {
    onClose: () => void;
    selectedQuote: CaseQuote | null;
    currentCase: Case;
    caseQuotes: Array<CaseQuote>;
    refreshQuotesList: () => Promise<void>;
};

const UpdateQuoteModal = ({
    onClose,
    selectedQuote,
    currentCase,
    caseQuotes,
    refreshQuotesList,
}: UpdateQuoteModalProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [selectedClient, setSelectedClient] = useState(selectedQuote!.client?.id ?? '');
    const [promises, [clients]] = usePromises(() => APIService.cases().getClientsList(currentCase.id));

    // Get available clients
    const availableClients = useMemo(() => {
        const array: Array<{ label: string; value: string }> = [
            {
                label: t('generic.tbd'),
                value: '',
            },
        ];

        if (clients.data == null) {
            return array;
        }

        for (const client of clients.data) {
            const isIncluded = caseQuotes.some(
                (caseQuotePackage) => caseQuotePackage.client?.id === client.id
            );
            if (isIncluded === false) {
                array.push({
                    label: `${client.firstName} ${client.lastName}`,
                    value: client.id,
                });
            }
        }
        return array;
    }, [clients, caseQuotes]);

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        if (selectedClient === '') {
            return onClose();
        }

        await promises.executePromise(async () => {
            promises.clearError();
            await APIService.cases().updateQuote(currentCase.id, selectedQuote!.id, {
                clientId: selectedClient,
            });
            await refreshQuotesList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.quote.modals.update_quote.title')}
            onClose={onClose}
            error={promises.error}
            clearError={promises.clearError}
        >
            <SelectLabel>
                {t('case.quote.modals.update_quote.message', {
                    identifier: formatLocalizedText(selectedQuote!.category.title, locale),
                })}
                :
            </SelectLabel>
            <SelectContainer>
                <SelectInput options={availableClients} onChange={setSelectedClient} value={selectedClient} />
            </SelectContainer>
            <ModalFooter
                confirmationLabel={t('case.quote.modals.update_quote.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
            />
        </Modal>
    );
};

export default UpdateQuoteModal;
