import React from 'react';
import { Case, CaseFolder, CaseFile } from '../../../../../../types';
import { FileIcon, Modal, ModalFooter } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../../services';
import { usePromises } from '../../../../../../hooks';
import { FilesList, FilesListItem, FilesListItemLabel, Text } from './BatchRemoveFilesModal-styles';

type RemoveFileModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFiles: Array<CaseFile>;
};

const RemoveFileModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
    selectedFiles,
}: RemoveFileModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    const onSubmitHandler = async () => {
        await executePromise(async () => {
            const fileIds = selectedFiles.map((file) => file.id);
            await APIService.cases().removeFiles(currentCase.id, currentCaseFolder.id, { fileIds: fileIds });
            await refresh();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.documents.modals.remove.title', { count: selectedFiles.length })}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Text>{t('case.documents.modals.remove.message', { count: selectedFiles.length })}:</Text>
            <FilesList>
                {selectedFiles.map((file) => (
                    <FilesListItem key={file.id}>
                        <FileIcon mimeType={file.mimeType} />
                        <FilesListItemLabel>{file.originalName}</FilesListItemLabel>
                    </FilesListItem>
                ))}
            </FilesList>
            <ModalFooter
                confirmationLabel={t('generic.remove')}
                onClick={onSubmitHandler}
                isLoading={isExecutingPromise}
                isDestructive
                secondary={{
                    onClick: onClose,
                }}
            />
        </Modal>
    );
};

export default RemoveFileModal;
