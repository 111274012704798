import styled from 'styled-components';

export const Message = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const List = styled.ul`
    list-style: inside;
`;

export const ListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;
