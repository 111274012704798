import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Case, CaseForm } from '../../../../../../types';
import { CheckboxInput, Modal, ModalFooter } from '../../../../../../components';
import { useLocale, usePromises } from '../../../../../../hooks';
import { List, ListItem, ListItemInput, SubList, SubListItem } from './UpdateCaseFormDisplayModal-styles';
import { formatLocalizedText } from '../../../../../../tools';
import { APIService } from '../../../../../../services';

type UpdateCaseFormDisplayModalProps = {
    onClose: () => void;
    refreshForm: () => Promise<void>;
    currentCase: Case;
    currentCaseForm: CaseForm;
};

const UpdateCaseFormDisplayModal = ({
    onClose,
    refreshForm,
    currentCase,
    currentCaseForm,
}: UpdateCaseFormDisplayModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();
    const [sections, setSections] = useState(structuredClone(currentCaseForm.form.sections));
    const locale = useLocale();

    const onSelectSection = (value: boolean, sectionInternalId: string) => {
        setSections((prev) =>
            prev.map((prevItem) =>
                prevItem.internalId === sectionInternalId ? { ...prevItem, isDisplayed: value } : prevItem
            )
        );
    };

    const onSelectQuestion = (value: boolean, sectionInternalId: string, questionId: string) => {
        setSections((prev) =>
            prev.map((prevItem) =>
                prevItem.internalId === sectionInternalId
                    ? {
                          ...prevItem,
                          questions: prevItem.questions.map((prevQuestion) =>
                              prevQuestion.id === questionId
                                  ? { ...prevQuestion, isDisplayed: value }
                                  : prevQuestion
                          ),
                      }
                    : prevItem
            )
        );
    };

    const onSubmitHandler = async () => {
        await executePromise(async () => {
            const payload = sections.map((section) => ({
                sectionId: section.id,
                sectionInternalId: section.internalId,
                isDisplayed:
                    section.isDisplayed && section.questions.filter((i) => i.isDisplayed === true).length > 0,
                questions: section.questions.map((question) => ({
                    questionId: question.id,
                    isDisplayed: question.isDisplayed,
                })),
            }));
            await APIService.cases().updateFormDisplay(currentCase.id, currentCaseForm.id, {
                sections: payload,
            });
            await refreshForm();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.forms.modals.update.title')}
            error={error}
            clearError={clearError}
            onClose={onClose}
        >
            <List>
                {sections.map((section) => (
                    <ListItem key={section.internalId}>
                        <ListItemInput>
                            <CheckboxInput
                                isChecked={section.isDisplayed}
                                onChange={(value) => onSelectSection(value, section.internalId)}
                                label={formatLocalizedText(section.title, locale)}
                            />
                        </ListItemInput>
                        <SubList>
                            {section.questions.map((question) => (
                                <SubListItem key={question.id}>
                                    <CheckboxInput
                                        isChecked={section.isDisplayed && question.isDisplayed}
                                        label={formatLocalizedText(question.label, locale)}
                                        onChange={(value) =>
                                            onSelectQuestion(value, section.internalId, question.id)
                                        }
                                    />
                                </SubListItem>
                            ))}
                        </SubList>
                    </ListItem>
                ))}
            </List>
            <ModalFooter
                confirmationLabel={t('generic.update')}
                onClick={onSubmitHandler}
                isLoading={isExecutingPromise}
                secondary={{
                    onClick: onClose,
                }}
            />
        </Modal>
    );
};

export default UpdateCaseFormDisplayModal;
