import styled from 'styled-components';

export const Header = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const List = styled.ul`
    background: ${({ theme }) => theme.colors.onSurface.main};
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 5px;
`;

export const ListItem = styled.li``;

export const ListItemInput = styled.div`
    :hover {
        background-color: ${({ theme }) => theme.colors.onSurface.secondary};
    }
    > label {
        width: 100%;
        padding-top: ${({ theme }) => theme.box.spacing.sm};
        padding-bottom: ${({ theme }) => theme.box.spacing.sm};
        margin-left: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const SubList = styled.ul`
    margin-left: ${({ theme }) => theme.box.spacing.xl};
`;

export const SubListItem = styled.li`
    :hover {
        background-color: ${({ theme }) => theme.colors.onSurface.secondary};
    }

    label {
        padding-top: ${({ theme }) => theme.box.spacing.sm};
        padding-bottom: ${({ theme }) => theme.box.spacing.sm};
        width: 100%;
        cursor: pointer;
        margin-left: ${({ theme }) => theme.box.spacing.md};
    }
`;
