import React from 'react';
import { Case, CaseNote } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../../services';
import { Modal, ModalParagraph, ModalFooter } from '../../../../../../components';
import { usePromises } from '../../../../../../hooks';

type RemoveCaseNoteModalProps = {
    onClose: () => void;
    selectedNote: CaseNote;
    currentCase: Case;
    refreshNotesList: () => Promise<void>;
};

const RemoveCaseNoteModal = ({
    onClose,
    currentCase,
    selectedNote,
    refreshNotesList,
}: RemoveCaseNoteModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        await executePromise(async () => {
            await APIService.cases().removeNote(currentCase.id, selectedNote.id);
            await refreshNotesList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.notes.modals.remove_note.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('case.notes.modals.remove_note.message', { title: selectedNote.title })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('case.notes.modals.remove_note.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
                isLoading={isExecutingPromise}
                isDestructive
            />
        </Modal>
    );
};

export default RemoveCaseNoteModal;
