import React from 'react';
import { CategoryQuote } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import { Modal, ModalParagraph, ModalFooter } from '../../../../../components';

type Props = {
    onClose: () => void;
    refreshQuotesList: () => Promise<void>;
    selectedCategoryQuote: CategoryQuote;
};

const DeleteCategoryQuoteModal = ({ onClose, refreshQuotesList, selectedCategoryQuote }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.categories().archiveQuote(selectedCategoryQuote.id);
            await refreshQuotesList();
            onClose();
        });
    };

    return (
        <Modal
            title="categories.quotes.modals.remove.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('categories.quotes.modals.remove.message', { title: selectedCategoryQuote.title.en })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('categories.quotes.modals.remove.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteCategoryQuoteModal;
