import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    grid-column: 1 / -1;
    background-color: ${({ theme }) => theme.colors.background.secondary};
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-top: ${({ theme }) => `calc(${theme.box.spacing.xl} + ${theme.box.spacing.sm})`};
    border-radius: 2px;
    position: relative;
`;

export const Remove = styled.div<{ isDisabled: boolean }>`
    position: absolute;
    right: ${({ theme }) => theme.box.spacing.sm};
    top: ${({ theme }) => theme.box.spacing.sm};
    font-size: ${({ theme }) => theme.font.size.md};

    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                svg: {
                    color: theme.colors.text.disabled,
                    cursor: 'not-allowed',
                },
            };
        }
    }}
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: max-content;
    width: 100%;
    gap: ${({ theme }) => theme.box.spacing.md};
`;

export const ContentItem = styled.div<{ column?: string }>`
    grid-column: ${({ column }) => column ?? 'auto'};
`;
