import React from 'react';
import { Category } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import { Modal, ModalParagraph, ModalFooter } from '../../../../../components';
import { formatLocalizedText } from '../../../../../tools';

type Props = {
    onClose: () => void;
    refreshCategoriesList: () => Promise<void>;
    selectedCategory: Category;
};

const DeleteCategoryModal = ({ onClose, refreshCategoriesList, selectedCategory }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise }] = usePromises();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.categories().archiveCategory(selectedCategory.id);
            await refreshCategoriesList();
            onClose();
        });
    };

    return (
        <Modal
            title="categories.main.modals.remove.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('categories.main.modals.remove.message', {
                    title: formatLocalizedText(selectedCategory.title, locale),
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('categories.main.modals.remove.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteCategoryModal;
