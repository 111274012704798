import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: overlay;
    background: rgba(0, 0, 0, 0.5);
    flex-wrap: wrap;
    padding: 5rem 0;
`;
