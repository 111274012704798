import React, { useState } from 'react';
import { PageContainer, PageHeader, PageHeaderTitle } from '../../../components';
import { useAppDispatch, usePromises, useToggle } from '../../../hooks';
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../../types';
import { OrganizationsListActions, OrganizationsListTable } from './components';
import { CreateOrganizationModal, UpdateOrganizationModal } from '../modals';
import { APIService } from '../../../services';
import { OrganizationBusiness } from '../../../business';
import { PAGE_ORGANIZATIONS_Organization } from '../OrganizationsPages';

const OrganizationsList = () => {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [action, openModal, closeModal] = useToggle<null | Actions>(null);
    const [updatedOrganization, setUpdatedOrganization] = useState<null | PAGE_ORGANIZATIONS_Organization>(
        null
    );
    const { t } = useTranslation();
    const [promises, [organizations]] = usePromises(() => APIService.organizations().getList());

    const handleRefreshOrganization = async () => {
        await organizations.refresh();
        await OrganizationBusiness.refreshOrganizations(dispatch);
    };

    return (
        <>
            {action === Actions.CREATE && (
                <CreateOrganizationModal
                    onClose={closeModal}
                    refreshOrganizationsList={handleRefreshOrganization}
                />
            )}
            {action === Actions.UPDATE && (
                <UpdateOrganizationModal
                    onClose={closeModal}
                    refreshOrganizationsList={handleRefreshOrganization}
                    updatedOrganization={updatedOrganization!}
                />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader>
                    <PageHeaderTitle>
                        <HiOutlineBuildingLibrary />
                        {t(`organizations.list`)}
                    </PageHeaderTitle>
                    <OrganizationsListActions
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        onClickAddOrganization={() => openModal(Actions.CREATE)}
                    />
                </PageHeader>
                <OrganizationsListTable
                    organizations={organizations.data}
                    isLoading={organizations.isLoading}
                    searchTerm={searchTerm}
                    onClickUpdateOrganization={(organization) => {
                        setUpdatedOrganization(organization);
                        openModal(Actions.UPDATE);
                    }}
                />
            </PageContainer>
        </>
    );
};

export default OrganizationsList;
