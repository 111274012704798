import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CasesList = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: ${({ theme }) => theme.box.spacing.md};
`;

export const CasesListItem = styled(Link)`
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    margin-right: ${({ theme }) => theme.box.spacing.md};
    min-height: 45px;
    display: flex;
    align-items: center;

    &:first-child {
        min-height: 55px;
    }
`;
