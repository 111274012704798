import React, { useState } from 'react';
import { CasesPagesContext } from '../CasePagesContext';
import { generateId } from '../../../../tools';

// services
import { APIService } from '../../../../services';

// hooks
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser, usePromises, useTable, useToggle } from '../../../../hooks';

// components
import {
    EmployeeBadge,
    PageContainer,
    PageHeader,
    PrimaryContainedButton,
    Table,
    TableBodyRowAction,
    TableColumnConfig,
    UserAvatar,
} from '../../../../components';
import { AddEmployeeModal, RemoveEmployeeModal, UnauthorizedToRemoveEmployeeModal } from './modals';

// types
import { Employee, EmployeeRole } from '../../../../types';

// styles
import { EmployeeRolesContainer } from './CaseAccessManagement-styles';
import { CaseChildPageTitle } from '../CasePages-styles';
import { BiCog } from 'react-icons/bi';

const CaseAccessManagement = () => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const { currentCase } = useOutletContext<CasesPagesContext>();
    const [promises, [employees]] = usePromises(() => APIService.cases().getEmployeesList(currentCase.id));
    const [openedModal, openModal, closeModal] = useToggle(
        null as null | 'add' | 'remove' | 'unauthorized-to-remove'
    );
    const [selectedEmployee, setSelectedEmployee] = useState<null | Employee>(null);

    const handleRemoveEmployee = () => {
        employees.update(employees.data.filter((employee) => employee.id !== selectedEmployee!.id));
    };

    const onClickRemoveEmployee = (employeeToRemove: Employee) => {
        if (employeeToRemove.roles.includes(EmployeeRole.LAWYER)) {
            const lawyerCount = employees.data.filter((employee) =>
                employee.roles.includes(EmployeeRole.LAWYER)
            ).length;
            if (lawyerCount < 2) {
                return openModal('unauthorized-to-remove');
            }
        }
        setSelectedEmployee(employeeToRemove);
        openModal('remove');
    };

    const columns: Array<TableColumnConfig<Employee>> = [
        {
            id: 'fullName',
            headerTitle: 'generic.fullName',
            getValue: ({ firstName, lastName }) => `${firstName} ${lastName}`,
            getDisplayedValue: (employee) => <UserAvatar {...employee} />,
            width: 'minmax(250px, max-content)',
            isSortable: true,
            isSearchable: true,
            isDefaultSort: true,
        },
        {
            id: 'internalId',
            headerTitle: 'employees.internal_id',
            getValue: ({ internalId }) => internalId,
            width: '200px',
            isSortable: true,
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'emailAddress',
            headerTitle: 'generic.email_address',
            getValue: ({ email }) => email,
            width: '1fr',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'roles',
            headerTitle: 'generic.role',
            getValue: ({ roles }) => roles.map((role) => t(`generic.employee_role.${role}`)).join(' '),
            getDisplayedValue: ({ roles }) => (
                <EmployeeRolesContainer>
                    {roles.map((role) => (
                        <EmployeeBadge key={generateId()} role={role} />
                    ))}
                </EmployeeRolesContainer>
            ),
            width: employees.data?.some((employee) => employee.roles.length === 3) ? '240px' : '200px',
            isSortable: true,
        },

        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (employee, { index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index >= total - 2}
                    actions={[
                        {
                            label: 'Remove employee',
                            onClick: () => onClickRemoveEmployee(employee),
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.hasPermissions('cases-employees.update'),
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(
        employees.data ?? [],
        columns
    );

    return (
        <PageContainer error={promises.error} clearError={promises.clearError}>
            {openedModal === 'add' && (
                <AddEmployeeModal
                    onClose={closeModal}
                    currentEmployees={employees.data ?? []}
                    handleAddEmployees={employees.refresh}
                    currentCase={currentCase}
                />
            )}
            {openedModal === 'remove' && (
                <RemoveEmployeeModal
                    onClose={() => {
                        setSelectedEmployee(null);
                        closeModal();
                    }}
                    selectedEmployee={selectedEmployee!}
                    handleRemoveEmployee={handleRemoveEmployee}
                    currentCase={currentCase}
                />
            )}
            {openedModal === 'unauthorized-to-remove' && (
                <UnauthorizedToRemoveEmployeeModal onClose={closeModal} />
            )}
            <PageHeader display="row">
                <CaseChildPageTitle>
                    <BiCog />
                    {t('case.access_management.page_title')}
                </CaseChildPageTitle>
                <PrimaryContainedButton onClick={() => openModal('add')}>
                    {t('case.access_management.modals.add.title')}
                </PrimaryContainedButton>
            </PageHeader>

            <Table
                columns={displayedColumns}
                data={data}
                handleSort={handleSort}
                sortedColumn={sortedColumn}
                isSortingDown={isSortingDown}
                isLoading={employees.isLoading}
                styles={{
                    table: {
                        minWidth: '800px',
                    },
                }}
            />
        </PageContainer>
    );
};

export default CaseAccessManagement;
