import styled from 'styled-components';

export const Container = styled.div<{ scrollPosition: number }>`
    position: relative;
    margin-right: ${({ theme }) => theme.box.spacing.md};

    > svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        fill: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }

    > .react-tooltip {
        position: fixed !important;
        ${({ scrollPosition }) => {
            return {
                transform: `translateY(-${scrollPosition}px) !important`,
            };
        }}
    }
`;

export const IconContainer = styled.div`
    position: absolute;
    bottom: 2px;
    right: -4px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.4rem;
    height: 1.4rem;
    background-color: ${({ theme }) => theme.colors.surface.main};

    > svg {
        font-size: ${({ theme }) => theme.font.size.md};
    }

    &.error {
        > svg {
            fill: ${({ theme }) => theme.colors.danger.main} !important;
        }
    }

    &.validated {
        > svg {
            fill: ${({ theme }) => theme.colors.green.main} !important;
        }
    }

    &.warning {
        background: none;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: ${({ theme }) => theme.colors.surface.main};
            width: 30%;
            height: 50%;
            z-index: 1;
        }
        > svg {
            fill: ${({ theme }) => theme.colors.yellow.main} !important;
            position: relative;
            z-index: 2;
        }
    }
`;

export const Tooltips = styled.div`
    > div:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.sm};
    }
`;

export const Tooltip = styled.div`
    padding-bottom: ${({ theme }) => theme.box.spacing.xs};
    padding-top: ${({ theme }) => theme.box.spacing.xs};

    > div:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.sm};
    }
`;

export const TooltipTitle = styled.div`
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.text.secondary};
    margin-bottom: ${({ theme }) => theme.box.spacing.xs};
`;

export const TooltipContent = styled.div`
    display: flex;
    align-items: center;
`;

export const TooltipContentIcon = styled.div`
    position: relative;
    margin-right: ${({ theme }) => theme.box.spacing.xs};

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    &.error {
        > svg {
            fill: ${({ theme }) => theme.colors.danger.main} !important;
        }
    }

    &.warning {
        > svg {
            fill: ${({ theme }) => theme.colors.yellow.main} !important;
        }
    }
`;

export const TooltipContentText = styled.div`
    line-height: 1.4;
    max-width: 40vw;
`;
