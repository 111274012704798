import React from 'react';
import {
    EmployeeBadges,
    Table,
    TableBodyRowAction,
    TableColumnConfig,
    UserAvatar,
} from '../../../../../components';
import { useLoggedInUser, useTable } from '../../../../../hooks';
import { Employee } from '../../../../../types';

type Props = {
    isLoading?: boolean;
    employees: null | Array<Employee>;
    searchTerm: string;
    onClickEmployee: (employee: Employee) => void;
    onUpdateEmployee: (employee: Employee, isUpdatingPassword?: boolean) => void;
    onUpdateOrganization: (employee: Employee) => void;
    onClickDeleteEmployee: (employee: Employee) => void;
};

const EmployeesList = ({
    isLoading,
    employees,
    searchTerm,
    onClickEmployee,
    onUpdateEmployee,
    onUpdateOrganization,
    onClickDeleteEmployee,
}: Props) => {
    const user = useLoggedInUser();

    const columns: Array<TableColumnConfig<Employee>> = [
        {
            id: 'fullName',
            headerTitle: 'generic.fullName',
            getValue: ({ firstName, lastName }) => `${firstName} ${lastName}`,
            getDisplayedValue: (employee) => <UserAvatar {...employee} />,
            width: 'minmax(max-content, 1fr)',
            isSortable: true,
            isSearchable: true,
            isDefaultSort: true,
        },
        {
            id: 'emailAddress',
            headerTitle: 'generic.email_address',
            getValue: ({ email }) => email,
            width: 'minmax(max-content, 1fr)',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'internalId',
            headerTitle: 'employees.internal_id',
            getValue: ({ internalId }) => internalId,
            width: 'minmax(max-content, 150px)',
            isSortable: true,
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'organization',
            headerTitle: 'generic.organization',
            getValue: ({ organization }) => organization.name,
            width: 'minmax(max-content, 200px)',
            isSortable: true,
            isSearchable: true,
            isCentered: true,
        },
        {
            id: 'contact',
            headerTitle: 'generic.contact',
            getValue: ({ contacts }) =>
                contacts ? contacts.map((contact) => contact.phoneNumber).join(', ') : '',
            width: 'minmax(max-content, 150px)',
            isSearchable: true,
        },
        {
            id: 'roles',
            headerTitle: 'generic.role',
            getValue: ({ roles }, { t }) => roles.map((role) => t(`generic.employee_role.${role}`)).join(' '),
            getDisplayedValue: ({ roles }) => <EmployeeBadges roles={roles} />,
            width: employees?.some((employee) => employee.roles.length === 3) ? '240px' : 'max-content',
            isSortable: true,
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (employee, { t, index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index >= total - 2}
                    actions={[
                        {
                            label: t('employees.modal.update_employee.title'),
                            onClick: () => onUpdateEmployee(employee),
                        },
                        {
                            label: t('employees.modal.update_organization.title'),
                            onClick: () => onUpdateOrganization(employee),
                        },
                        {
                            label: t('employees.modal.change_password.title'),
                            onClick: () => onUpdateEmployee(employee, true),
                        },
                        {
                            label: t('employees.modal.delete.title'),
                            onClick: () => onClickDeleteEmployee(employee),
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.hasPermissions('employees.update'),
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(
        employees ?? [],
        columns,
        {
            searchTerm: searchTerm,
        }
    );

    return (
        <Table
            columns={displayedColumns}
            data={data}
            handleSort={handleSort}
            handleClickRow={(employee) => onClickEmployee(employee)}
            sortedColumn={sortedColumn}
            isSortingDown={isSortingDown}
            isLoading={isLoading}
            styles={{
                table: {
                    minWidth: '800px',
                },
            }}
        />
    );
};

export default EmployeesList;
