import React, { useState } from 'react';
import { Modal, ModalFooter, TextInput } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { Case, CaseQuote } from '../../../../../../types';
import {
    Message,
    EmptyMessage,
    List,
    ListItem,
    InputList,
    InputListItem,
    InputListItemRemove,
    InputListItemContent,
    InputTitle,
    AddDependentCtaContainer,
    AddDependentCta,
} from './AddDependentsModal-styles';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { APIService } from '../../../../../../services';
import { range } from '../../../../../../tools';
import { usePromises } from '../../../../../../hooks';

type AddDependentsModalProps = {
    onClose: () => void;
    selectedQuote: CaseQuote | null;
    currentCase: Case;
    refreshQuotesList: () => Promise<void>;
};

const AddDependentsModal = ({
    onClose,
    selectedQuote,
    currentCase,
    refreshQuotesList,
}: AddDependentsModalProps) => {
    const { t } = useTranslation();
    const [newDependents, setNewDependents] = useState(['']);
    const [promises] = usePromises();

    /**
     * On change handler
     * @param {string} value
     * @param {number} index
     */
    const onChangeHandler = (value: string, index: number) => {
        setNewDependents((prev) => {
            prev[index] = value;
            return prev;
        });
    };

    /**
     * Add dependent handler
     */
    const addDependentHandler = () => {
        setNewDependents((prev) => [...prev, '']);
    };

    /**
     * Remove dependent handler
     * @param {number} index
     */
    const removeDependentHandler = (index: number) => {
        if (newDependents.length > 1) {
            setNewDependents((prev) => prev.filter((_, i) => i !== index));
        }
    };

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        await promises.executePromise(async () => {
            await APIService.cases().addQuoteDependents(
                currentCase.id,
                selectedQuote!.id,
                newDependents.map((dependent, index) => ({
                    fullName:
                        dependent === ''
                            ? `Dependent # ${(selectedQuote?.dependents?.length ?? 0) + index + 1}`
                            : dependent,
                }))
            );
            await refreshQuotesList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.quote.modals.add_dependents.title')}
            onClose={onClose}
            error={promises.error}
            clearError={promises.clearError}
        >
            {selectedQuote?.dependents != null && selectedQuote?.dependents?.length > 0 ? (
                <>
                    <Message>
                        {t('case.quote.modals.add_dependents.message', {
                            fullName:
                                selectedQuote.client != null
                                    ? `${selectedQuote.client.firstName} ${selectedQuote.client.lastName}`
                                    : t('generic.client').toLowerCase(),
                        })}
                        :
                    </Message>
                    <List>
                        {selectedQuote.dependents.map((dependent, index) => (
                            <ListItem key={index}>{dependent.fullName}</ListItem>
                        ))}
                    </List>
                </>
            ) : (
                <EmptyMessage>{t('case.quote.modals.add_dependents.message_empty')}</EmptyMessage>
            )}
            <InputTitle>{t('case.quote.modals.add_dependents.new_dependents')}:</InputTitle>
            <InputList>
                {range(newDependents.length).map((_, index) => (
                    <InputListItem key={index}>
                        <InputListItemRemove
                            onClick={() => removeDependentHandler(index)}
                            isDisabled={newDependents.length < 2}
                        >
                            <AiOutlineCloseCircle />
                        </InputListItemRemove>
                        <InputListItemContent>
                            <TextInput
                                onChange={(e) => onChangeHandler(e.target.value, index)}
                                defaultValue={`Dependent #${
                                    (selectedQuote?.dependents?.length ?? 0) + newDependents.length
                                }`}
                            />
                        </InputListItemContent>
                    </InputListItem>
                ))}
            </InputList>
            <AddDependentCtaContainer>
                <AddDependentCta onClick={addDependentHandler}>
                    {t('case.quote.modals.add_dependents.title')}
                </AddDependentCta>
            </AddDependentCtaContainer>
            <ModalFooter
                confirmationLabel={t('case.quote.modals.add_dependents.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
                isLoading={promises.isExecutingPromise}
            />
        </Modal>
    );
};

export default AddDependentsModal;
