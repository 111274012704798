export enum DynamicFormViewMode {
    MANAGEMENT,
    FILL,
    PREVIEW,
}

export type DynamicFormValues = {
    [key: string]: {
        sectionId: string;
        sectionInternalId: string;
        questions: { [key: string]: string };
    };
};
