import { Employee } from '../../../types';
import { CreateEmployeePayload, EmployeeRole, UpdateEmployeeProfilePayload } from '../../../types';
import { BaseRequestConfig } from '../APIService.types';
import BaseRequest from './_baseRequest';

class EmployeeRequest extends BaseRequest {
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    async getList(roles?: Array<EmployeeRole>): Promise<Array<Employee>> {
        return this.get('/', true, { roles: roles }).then((res: any) => res.employees);
    }

    async createEmployee(employee: CreateEmployeePayload): Promise<{ id: string }> {
        return this.post('/', true, employee);
    }

    async updateEmployeeProfile(id: string, employee: UpdateEmployeeProfilePayload): Promise<{}> {
        return this.patch(`/${id}/profile`, true, employee);
    }

    async updateEmployeeOrganization(id: string, internalId: string): Promise<{}> {
        return this.patch(`/${id}/organization`, true, { internalId: internalId });
    }

    async updateEmployeePassword(id: string, password: string, closeSession = false): Promise<{}> {
        return this.patch(`/${id}/password`, true, { password: password, closeSession: closeSession });
    }

    async archiveEmployee(id: string) {
        return this.delete(`/${id}`, true);
    }
}

export default EmployeeRequest;
