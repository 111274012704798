import React, { useState, forwardRef } from 'react';
import TextInput, { TextInputProps } from '../TextInput';
import { TogglePassword } from './PasswordInput-styles';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const PasswordInput = forwardRef<HTMLInputElement, TextInputProps>(({ ...rest }: TextInputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextInput
            {...rest}
            type={showPassword ? 'text' : 'password'}
            ref={ref}
            trailingIcon={
                <TogglePassword onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </TogglePassword>
            }
        />
    );
});

export default PasswordInput;
