import React from 'react';
import {
    CaseClientRole,
    ClientInformation,
    ClientSummary,
    ExistingClientInformation,
    NewClientInformation,
} from '../../../types';
import { useTranslation } from 'react-i18next';
import { Section, SectionAddClient, SectionTitle } from './NewClientFormList-styles';
import NewClientFormItem from '../NewClientFormItem';

export const DEFAULT_NEW_CLIENT = {
    email: '',
    role: CaseClientRole.EMPLOYEE,
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
};

export const DEFAULT_EXISTING_CLIENT = {
    email: '',
    role: CaseClientRole.EMPLOYEE,
};

type NewClientFormListProps = {
    isLoading: boolean;
    clients: Array<ClientSummary>;
    selectedClients: Array<ExistingClientInformation>;
    setSelectedClients: React.Dispatch<React.SetStateAction<ExistingClientInformation[]>>;
    newClients: Array<NewClientInformation>;
    setNewClients: React.Dispatch<React.SetStateAction<NewClientInformation[]>>;
    openAddClientModal: (selectedClient: NewClientInformation & { index: number }) => void;
};

const NewClientFormList = ({
    isLoading,
    clients,
    selectedClients,
    setSelectedClients,
    newClients,
    setNewClients,
    openAddClientModal,
}: NewClientFormListProps) => {
    const { t } = useTranslation();

    /**
     * Add one empty client
     * @param {Boolean} isExistingClient
     */
    const addClient = (isExistingClient: boolean) => {
        if (isExistingClient === true) {
            setSelectedClients((prev) => [...prev, DEFAULT_EXISTING_CLIENT]);
        } else {
            setNewClients((prev) => [...prev, DEFAULT_NEW_CLIENT]);
        }
    };

    /**
     * Update one client
     * @param {ClientInformation} client
     * @param {Boolean} isExistingClient
     * @param {Number} index
     */
    const updateClient = (client: ClientInformation, isExistingClient: boolean, index: number) => {
        if (isExistingClient === true) {
            setSelectedClients((prev) => {
                const updated = [...prev];
                updated[index] = client;
                return updated;
            });
        } else {
            setNewClients((prev) => {
                const updated = [...prev];
                updated[index] = client as NewClientInformation;
                return updated;
            });
        }
    };

    /**
     * Remove one client
     * @param {Boolean} isExistingClient
     * @param {Number} index
     */
    const removeClient = (isExistingClient: boolean, index: number) => {
        if (isExistingClient === true) {
            if (selectedClients.length > 1) {
                setSelectedClients((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
            }
        } else {
            if (newClients.length > 1) {
                setNewClients((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
            }
        }
    };

    return (
        <>
            <Section>
                <SectionTitle>{t('cases.new_case.add_existing_client')}</SectionTitle>
                {selectedClients.map((selectedClient, index) => (
                    <NewClientFormItem
                        key={index}
                        currentClient={selectedClient}
                        clients={clients}
                        isLoading={isLoading}
                        selectedClients={selectedClients}
                        isAllowedToRemoveClient={selectedClients.length > 1}
                        removeClient={() => removeClient(true, index)}
                        handleClient={(client) => updateClient(client, true, index)}
                    />
                ))}
                {selectedClients.every((client) => client.email.length > 0) && (
                    <SectionAddClient onClick={() => addClient(true)}>
                        {t('case.clients.modals.add.title')}
                    </SectionAddClient>
                )}
            </Section>

            <Section>
                <SectionTitle>{t('cases.new_case.add_new_client')}</SectionTitle>
                {newClients.map((newClient, index) => (
                    <NewClientFormItem
                        key={index}
                        currentClient={newClient}
                        clients={clients}
                        isLoading={isLoading}
                        isAllowedToRemoveClient={newClients.length > 1}
                        removeClient={() => removeClient(false, index)}
                        handleClient={(client) => updateClient(client, false, index)}
                        openAddClientModal={() => openAddClientModal({ ...newClient, index: index })}
                    />
                ))}
                {newClients.every((client) => client.email.length > 0) && (
                    <SectionAddClient onClick={() => addClient(false)}>
                        {t('case.clients.modals.add.title')}
                    </SectionAddClient>
                )}
            </Section>
        </>
    );
};

export default NewClientFormList;
