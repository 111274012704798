import { CaseFolder_L2, Locale, LocaleObject, OCR_VALIDATION_SEVERITY } from '../../../types';
import { CaseFolderManagerContracts_L2, CaseFolderTooltip } from '../CaseFolderManager-contracts';
import CaseFileManager from './CaseFileManager';
import CaseFolderManager_L1 from './CaseFolderManager_L1';

class CaseFolderManager_L2 implements CaseFolderManagerContracts_L2 {
    readonly id: string;
    readonly index: number;
    readonly title: LocaleObject;
    readonly description: LocaleObject;
    readonly isDisplayed: boolean;
    readonly isOCREnabled: boolean;
    readonly validatedAt?: Date;
    readonly files: Array<CaseFileManager>;
    readonly parentFolder: CaseFolderManager_L1;

    constructor(grandChildFolder: CaseFolder_L2, parentFolder: CaseFolderManager_L1) {
        this.id = grandChildFolder.id;
        this.index = grandChildFolder.index;
        this.title = grandChildFolder.title;
        this.description = grandChildFolder.description;
        this.isDisplayed = grandChildFolder.isDisplayed;
        this.isOCREnabled = grandChildFolder.isOCREnabled;
        this.validatedAt = grandChildFolder.validatedAt;
        this.files = grandChildFolder.files.map((file) => new CaseFileManager(file));
        this.parentFolder = parentFolder;
    }

    public isValidated(): boolean {
        return this.validatedAt != null;
    }

    public hasErrors(): boolean {
        return this.files.some((file) => file.hasErrors());
    }

    public hasWarnings(): boolean {
        return this.files.some((file) => file.hasWarnings());
    }

    public getTooltip(): CaseFolderTooltip {
        const messages = this.files.reduce((array, file) => {
            return [...array, ...file.getTooltipMessages()];
        }, [] as Array<{ text: LocaleObject; severity: OCR_VALIDATION_SEVERITY }>);

        return {
            title: {
                [Locale.EN]: `${this.parentFolder.title[Locale.EN]} - ${this.title[Locale.EN]}`,
                [Locale.FR]: `${this.parentFolder.title[Locale.FR]} - ${this.title[Locale.FR]}`,
            },
            messages: messages,
        };
    }
}

export default CaseFolderManager_L2;
