import styled from 'styled-components';
import { FileIconSize } from './FileIcon';

export const Container = styled.div<{ size: FileIconSize }>`
    width: max-content;
    height: max-content;

    svg {
        ${({ size }) => {
            if (size === FileIconSize.LARGE) {
                return {
                    fontSize: '6rem',
                };
            }

            if (size === FileIconSize.MEDIUM) {
                return {
                    fontSize: '4rem',
                };
            }

            if (size === FileIconSize.SMALL) {
                return {
                    fontSize: '2rem',
                };
            }
        }}
    }
`;
