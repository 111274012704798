import React, { useMemo } from 'react';
import { useLocale, useToggle } from '../../../hooks';
import { DynamicFormViewMode } from '../types';
import { CaseFormSectionQuestion, ServerFormQuestionInputType } from '../../../types';
import { FormDateInput, FormGroup, FormGroupLabel, FormSelectInput, FormTextInput } from '../../Form';
import { formatLocalizedText } from '../../../tools';
import { DateInput, InputMode, SelectInput, TextInput } from '../../Inputs';
import { InputContainer, TooltipIcon } from './DynamicFormQuestion-styles';
import LocalizedText from '../../LocalizedText';
import { FaCircleQuestion } from 'react-icons/fa6';
import DynamicFormQuestionTooltipModal from './DynamicFormQuestionTooltipModal';

type DynamicFormQuestionProps = {
    sectionInternalId: string;
    viewMode?: DynamicFormViewMode;
    question: CaseFormSectionQuestion;
    canSubmit?: boolean;
};

const DynamicFormQuestion = ({
    viewMode,
    question,
    sectionInternalId,
    canSubmit,
}: DynamicFormQuestionProps) => {
    const locale = useLocale();
    const { placeholder, tooltip } = useMemo(
        () => ({
            placeholder: formatLocalizedText(question.placeholder, locale),
            tooltip: formatLocalizedText(question.tooltip, locale),
        }),
        [question, locale]
    );
    const [isOpened, openTooltip, closeTooltip] = useToggle(false);

    const renderFormInput = () => {
        switch (question.inputType) {
            case ServerFormQuestionInputType.TEXT_INPUT:
                return <FormTextInput placeholder={placeholder} />;
            case ServerFormQuestionInputType.NUMBER_INPUT:
                return <FormTextInput type="number" placeholder={placeholder} />;
            case ServerFormQuestionInputType.DATE_INPUT:
                return <FormDateInput placeholder={placeholder} />;
            case ServerFormQuestionInputType.SELECT_INPUT:
                return (
                    <FormSelectInput
                        placeholder={placeholder}
                        options={question.options.map((option) => ({
                            label: formatLocalizedText(option.label, locale),
                            value: option.value,
                        }))}
                    />
                );
        }
    };

    const renderRegularInput = () => {
        switch (question.inputType) {
            case ServerFormQuestionInputType.TEXT_INPUT:
                return (
                    <TextInput value="" onChange={() => {}} mode={InputMode.FORM} placeholder={placeholder} />
                );
            case ServerFormQuestionInputType.NUMBER_INPUT:
                return (
                    <TextInput
                        value=""
                        onChange={() => {}}
                        mode={InputMode.FORM}
                        type="number"
                        placeholder={placeholder}
                    />
                );
            case ServerFormQuestionInputType.DATE_INPUT:
                return (
                    <DateInput
                        value={new Date()}
                        mode={InputMode.FORM}
                        onChange={() => {}}
                        placeholder={placeholder}
                    />
                );
            case ServerFormQuestionInputType.SELECT_INPUT:
                return (
                    <SelectInput
                        mode={InputMode.FORM}
                        value=""
                        placeholder={placeholder}
                        onChange={() => {}}
                        options={question.options.map((option) => ({
                            label: formatLocalizedText(option.label, locale),
                            value: option.value,
                        }))}
                    />
                );
        }
    };

    const renderInput = () => {
        if (viewMode === DynamicFormViewMode.FILL) {
            return renderFormInput();
        }

        return <InputContainer>{renderRegularInput()}</InputContainer>;
    };

    return (
        <>
            {isOpened === true && (
                <DynamicFormQuestionTooltipModal question={question} onClose={closeTooltip} />
            )}
            <FormGroup key={question.id} name={`section_${sectionInternalId}.questions.${question.id}`}>
                <FormGroupLabel>
                    {viewMode === DynamicFormViewMode.MANAGEMENT && <>[{question.name}]&nbsp;</>}
                    <LocalizedText text={question.label} />
                    {canSubmit === true && tooltip !== '' && tooltip !== '<p></p>' && (
                        <TooltipIcon onClick={() => openTooltip(true)}>
                            <FaCircleQuestion />
                        </TooltipIcon>
                    )}
                </FormGroupLabel>
                {renderInput()}
            </FormGroup>
        </>
    );
};

export default DynamicFormQuestion;
