import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useLoggedInUser } from '../../hooks';

type Props = {
    children?: React.ReactNode;
};

const UnauthentictedRoute = ({ children }: Props) => {
    const user = useLoggedInUser();

    if (user.isLoggedIn() === true) {
        // The user is authenticated and cannot access the route, redirect to "/"
        return <Navigate to="/" />;
    }

    if (typeof children === 'object') {
        // The current route does not have sub paths
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        // The current route has sub path
        return <Outlet />;
    }
};

export default UnauthentictedRoute;
