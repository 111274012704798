import React, { useState } from 'react';
import { Case, CaseFolder, CaseFolder_L1 } from '../../../../../../types';
import { CheckboxInput, Modal, ModalFooter } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../../hooks';
import { Header, List, ListItem, ListItemInput, SubList, SubListItem } from './UpdateHiddenFilesModal-styles';
import { formatCaseFolderDescription, formatLocalizedText } from '../../../../../../tools';
import { APIService } from '../../../../../../services';

type UpdateHiddenFilesModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
};

const getInitialHiddenFiles = (currentCaseFolder: CaseFolder) => {
    const initialHiddenFiles: Array<string> = [];
    for (const childFolder of currentCaseFolder.folders) {
        for (const grandChildFolder of childFolder.folders) {
            if (grandChildFolder.isDisplayed !== true) {
                initialHiddenFiles.push(grandChildFolder.id);
            }
        }
    }
    return initialHiddenFiles;
};

const UpdateHiddenFilesModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
}: UpdateHiddenFilesModalProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();
    const [hiddenFiles, setHiddenFiles] = useState<Array<string>>(getInitialHiddenFiles(currentCaseFolder));

    const onSubmitHandler = async () => {
        await executePromise(async () => {
            await APIService.cases().updateHiddenFiles(currentCase.id, currentCaseFolder.id, {
                hiddenCategoryFileIds: hiddenFiles,
            });
            await refresh();
            onClose();
        });
    };

    const onSelectFile = (value: boolean, fileId: string) => {
        setHiddenFiles((prev) =>
            value === false ? [...prev, fileId] : prev.filter((prevItem) => prevItem !== fileId)
        );
    };

    const onSelectGroup = (value: boolean, childFolder: CaseFolder_L1) => {
        setHiddenFiles((prev) =>
            value === false
                ? [...prev, ...childFolder.folders.map((grandChildFolder) => grandChildFolder.id)]
                : prev.filter(
                      (prevItem) =>
                          childFolder.folders.some((grandChildFolder) => grandChildFolder.id === prevItem) !==
                          true
                  )
        );
    };

    return (
        <Modal
            onClose={onClose}
            error={error}
            clearError={clearError}
            title={formatCaseFolderDescription(currentCaseFolder, locale)}
        >
            <Header>{t('case.documents.modals.update_hidden_files.title')}:</Header>
            <List>
                {currentCaseFolder.folders.map((childFolder, fileGroupIndex) => (
                    <ListItem key={fileGroupIndex}>
                        <ListItemInput>
                            <CheckboxInput
                                onChange={(value) => onSelectGroup(value, childFolder)}
                                isChecked={childFolder.folders.some(
                                    (grandChildFolder) => hiddenFiles.includes(grandChildFolder.id) !== true
                                )}
                                label={formatLocalizedText(childFolder.title, locale)}
                            />
                        </ListItemInput>

                        <SubList>
                            {childFolder.folders.map((grandChildFolder, fileIndex) => (
                                <SubListItem key={fileIndex}>
                                    <CheckboxInput
                                        onChange={(value) => onSelectFile(value, grandChildFolder.id)}
                                        isChecked={hiddenFiles.includes(grandChildFolder.id) !== true}
                                        label={formatLocalizedText(grandChildFolder.title, locale)}
                                    />
                                </SubListItem>
                            ))}
                        </SubList>
                    </ListItem>
                ))}
            </List>
            <ModalFooter
                confirmationLabel={t('generic.update')}
                onClick={onSubmitHandler}
                isLoading={isExecutingPromise}
                secondary={{
                    onClick: onClose,
                }}
            />
        </Modal>
    );
};

export default UpdateHiddenFilesModal;
