import { useEffect, useState } from 'react';

interface ScrollPosition {
    x: number;
    y: number;
}

export const useScrollPosition = (): ScrollPosition => {
    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({ x: 0, y: 0 });

    useEffect(() => {
        const updateScrollPosition = () => {
            setScrollPosition({ x: window.pageXOffset, y: window.pageYOffset });
        };

        // Listen to the scroll event and update the scroll position
        window.addEventListener('scroll', updateScrollPosition);

        // Cleanup function to remove the scroll event listener
        return () => {
            window.removeEventListener('scroll', updateScrollPosition);
        };
    }, []); // No dependencies, only run once on mount

    return scrollPosition;
};
