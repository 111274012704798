import React from 'react';
import { ServerForm } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { LocalizedText, Table, TableColumnConfig } from '../../../../../components';
import { useLocale, useTable } from '../../../../../hooks';
import { formatLocalizedText } from '../../../../../tools';
import { useOutletContext } from 'react-router-dom';
import { FormsListContext } from '../../FormsList';
import { SiGoogleforms } from 'react-icons/si';
import { Form, FormLink } from './FormsListTable-styles';
import { Routes } from '../../../../../routes';

const FormsListTable = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const { forms, isLoading } = useOutletContext<FormsListContext>();

    const columns: Array<TableColumnConfig<ServerForm>> = [
        {
            id: 'title',
            headerTitle: t('generic.title'),
            width: '1fr',
            getValue: (form) => formatLocalizedText(form.title, locale),
            getDisplayedValue: (form) => (
                <Form>
                    <SiGoogleforms />
                    <FormLink to={`/${Routes.FORMS}/${form.id}`}>
                        <LocalizedText text={form.title} />
                    </FormLink>
                </Form>
            ),
        },
    ];

    const table = useTable(forms, columns);

    return <Table {...table} columns={table.displayedColumns} isLoading={isLoading} />;
};

export default FormsListTable;
