import React from 'react';
import { Container } from './NewCaseClientsList-styles';
import { FiUsers } from 'react-icons/fi';

import { PageHeaderTitle } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import {
    ClientSummary,
    ClientInformation,
    NewClientInformation,
    ExistingClientInformation,
} from '../../../../../types';
import { NewClientFormList } from '../../../../../elements';

type NewCaseClientsListProps = {
    newClients: Array<NewClientInformation>;
    setNewClients: React.Dispatch<React.SetStateAction<NewClientInformation[]>>;
    selectedClients: Array<ClientInformation>;
    setSelectedClients: React.Dispatch<React.SetStateAction<ExistingClientInformation[]>>;
    clients: Array<ClientSummary>;
    isLoading: boolean;
    openAddClientModal: (selectedClient: NewClientInformation & { index: number }) => void;
};

const NewCaseClientsList = ({
    clients,
    isLoading,
    newClients,
    setNewClients,
    selectedClients,
    setSelectedClients,
    openAddClientModal,
}: NewCaseClientsListProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            <PageHeaderTitle>
                <FiUsers />
                {t('generic.clients')}
            </PageHeaderTitle>
            <NewClientFormList
                isLoading={isLoading}
                clients={clients}
                selectedClients={selectedClients}
                setSelectedClients={setSelectedClients}
                newClients={newClients}
                setNewClients={setNewClients}
                openAddClientModal={openAddClientModal}
            />
        </Container>
    );
};

export default NewCaseClientsList;
