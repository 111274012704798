import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
    Banner,
    BannerAppearance,
    Form,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    FormSelectInput,
    FormPasswordInput,
    FormFooter,
} from '../../../components';
import { useCaseClientRoles } from '../../../hooks';
import { emailRegex, passwordRegex, validatePhoneNumber } from '../../../tools';
import { NewClientInformation, Employee, ClientSummary } from '../../../types';

type NewClientFormProps = {
    initialValues: NewClientInformation;
    handleClient: (client: NewClientInformation) => void;
    employees: Array<Employee>;
    clients: Array<ClientSummary>;
    onClose?: () => void;
};

const NewClientForm = ({ handleClient, employees, clients, onClose, initialValues }: NewClientFormProps) => {
    const { t } = useTranslation();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const clientRoles = useCaseClientRoles();
    const [error, setError] = useState<null | ResourceKey>(null);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(t('generic.error.invalid_email'))
            .required(t('generic.error.required_field'))
            .matches(emailRegex, t('generic.error.invalid_email')),
        firstName: Yup.string().required(t('generic.error.required_field')),
        role: Yup.string().required(t('generic.error.required_field')),
        lastName: Yup.string().required(t('generic.error.required_field')),
        password: Yup.string().required(t('generic.error.required_field')).matches(passwordRegex, ' '),
        phoneNumber: Yup.string().test('phoneNumber', t('generic.error.invalid_phone_number'), (value) => {
            if (value) {
                return validatePhoneNumber(value);
            } else {
                return true;
            }
        }),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (employees.some((employee) => employee.email === submittedValues.email)) {
                setError('cases.new_case.error.employee_cannot_be_client');
                return;
            }

            if (clients.some((client) => client.email === submittedValues.email)) {
                setError('cases.new_case.error.client_email_taken');
                return;
            }

            handleClient(submittedValues);
            if (typeof onClose === 'function') {
                onClose();
            }
        },
    });

    return (
        <>
            {error != null && (
                <Banner appearance={BannerAppearance.ERROR} closeBanner={() => setError(null)}>
                    {t(error)}
                </Banner>
            )}
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="email">
                    <FormGroupLabel>{t('generic.email_address')}</FormGroupLabel>
                    <FormTextInput
                        type="email"
                        placeholder={t('generic.email')}
                        ref={emailInputRef}
                        autoComplete="new-password"
                    />
                </FormGroup>
                <FormGroup name="role">
                    <FormGroupLabel>{t('cases.new_case.client_role')}</FormGroupLabel>
                    <FormSelectInput options={clientRoles} />
                </FormGroup>
                <FormGroup name="firstName">
                    <FormGroupLabel>{t('generic.firstName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.firstName')} />
                </FormGroup>
                <FormGroup name="lastName">
                    <FormGroupLabel>{t('generic.lastName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.lastName')} />
                </FormGroup>
                <FormGroup name="password" expand>
                    <FormGroupLabel>{t('generic.password')}</FormGroupLabel>
                    <FormPasswordInput
                        placeholder={t('generic.password')}
                        validate
                        autoComplete="new-password"
                    />
                </FormGroup>
                <FormGroup name="phoneNumber" expand>
                    <FormGroupLabel>{t('generic.contact')}</FormGroupLabel>
                    <FormTextInput placeholder={t('generic.contact')} />
                </FormGroup>
                <FormFooter
                    submitLabel={t('generic.next')}
                    secondary={
                        typeof onClose === 'function'
                            ? {
                                  label: t('generic.cancel'),
                                  onClick: onClose,
                              }
                            : undefined
                    }
                />
            </Form>
        </>
    );
};

export default NewClientForm;
