import styled from 'styled-components';

export const Container = styled.div``;

export const Section = styled.div`
    &:not(:last-child) .accordion--0 {
        border-bottom: none !important;
    }
`;

export const SectionContainer = styled.div`
    background: ${({ theme }) => theme.colors.onSurface.secondary};
    padding: ${({ theme }) => `${theme.box.spacing.xl} ${theme.box.spacing.md}`};
    display: flex;
    justify-content: center;
`;

export const SectionContent = styled.div`
    background: ${({ theme }) => theme.colors.surface.main};
    padding: ${({ theme }) => `${theme.box.spacing.xl} ${theme.box.spacing.lg}`};
    width: 100%;
    max-width: 80rem;
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.box.shadow.light};
`;
