import React from 'react';
import { List, ListItem, ListItemSubTitle, ListItemTitle, ListItemValue } from './SettingsList-styles';

type Props = {
    list: Array<{ title: string; value: string | React.ReactNode; subTitle?: string }>;
};

const SettingsList = ({ list }: Props) => {
    return (
        <List>
            {list.map((item, index) => (
                <ListItem key={index}>
                    <ListItemTitle>{item.title}</ListItemTitle>
                    {item.subTitle != null && <ListItemSubTitle>{item.subTitle}</ListItemSubTitle>}
                    <ListItemValue isText={typeof item.value === 'string'}>{item.value}</ListItemValue>
                </ListItem>
            ))}
        </List>
    );
};

export default SettingsList;
