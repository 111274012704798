import {
    ServerForm,
    ServerFormSection,
    ServerFormGroupPayload,
    ServerFormQuestion,
    ServerFormQuestionPayload,
} from '../../../types';
import { ServerFormConditionPayload, ServerFormPayload } from '../../../types/forms';
import { BaseRequestConfig } from '../APIService.types';
import BaseRequest from './_baseRequest';

class FormsRequest extends BaseRequest {
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    // **************************************************************************
    // Main
    // **************************************************************************

    async getFormsList(): Promise<Array<ServerForm>> {
        return this.get('/main', true).then((res: any) => res.forms);
    }

    async addForm(payload: ServerFormPayload) {
        return this.post('/main', true, payload);
    }

    async updateForm(formId: string, payload: ServerFormPayload) {
        return this.patch(`/main/${formId}`, true, payload);
    }

    async updateFormConditions(formId: string, payload: { conditions: Array<ServerFormConditionPayload> }) {
        return this.patch(`/main/${formId}/conditions`, true, payload);
    }

    async archiveForm(formId: string) {
        return this.delete(`/main/${formId}`, true);
    }

    // **************************************************************************
    // Sections
    // **************************************************************************

    async getSectionsList(): Promise<Array<ServerFormSection>> {
        return this.get('/sections', true).then((res: any) => res.sections);
    }

    async addSection(payload: ServerFormGroupPayload) {
        return this.post('/sections', true, payload);
    }

    async updateSection(sectionId: string, payload: ServerFormGroupPayload) {
        return this.patch(`/sections/${sectionId}`, true, payload);
    }

    async archiveSection(sectionId: string) {
        return this.delete(`/sections/${sectionId}`, true);
    }

    // **************************************************************************
    // Questions
    // **************************************************************************

    async getQuestionsList(): Promise<Array<ServerFormQuestion>> {
        return this.get('/questions', true).then((res: any) => res.questions);
    }

    async addQuestions(payload: { questions: Array<ServerFormQuestionPayload> }) {
        return this.post('/questions', true, payload);
    }

    async updateQuestion(questionId: string, payload: ServerFormQuestionPayload) {
        return this.patch(`/questions/${questionId}`, true, payload);
    }

    async archiveQuestion(questionId: string) {
        return this.delete(`/questions/${questionId}`, true);
    }
}

export default FormsRequest;
