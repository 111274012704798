import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EmployeeRole } from '../../types';
import { UserAvatarSize } from './UserAvatar';

export const ContainerLink = styled(Link)`
    color: ${({ theme }) => theme.colors.text.main};
`;

export const Container = styled.div<{ size: UserAvatarSize }>`
    display: flex;
    align-items: center;

    ${({ size, theme }) => {
        if (size === UserAvatarSize.SMALL) {
            return {
                '.user-avatar': {
                    '&__icon': {
                        width: '2rem',
                        minWidth: '2rem',
                        height: '2rem',
                        minHeight: '2rem',
                        fontSize: theme.font.size.sm,
                        marginRight: theme.box.spacing.xxs,
                    },
                },
            };
        }
    }}
`;

export const AvatarContainer = styled.div<{ roles?: Array<EmployeeRole> }>`
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    min-height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.font.size.sm};
    text-transform: uppercase;
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    user-select: none;

    ${({ roles, theme }) => {
        if (roles?.includes(EmployeeRole.ADMIN)) {
            return {
                backgroundColor: theme.colors.primary.extended_10,
                color: theme.colors.primary.main + '!important',
            };
        } else if (roles?.includes(EmployeeRole.LAWYER)) {
            return {
                backgroundColor: theme.colors.green.extended_10,
                color: theme.colors.green.main + '!important',
            };
        } else {
            return {
                backgroundColor: theme.colors.yellow.extended_10,
                color: theme.colors.yellow.main + '!important',
            };
        }
    }}
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Fullname = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};
    line-height: 1.4;
`;

export const Email = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.secondary};
`;
