import React, { useContext } from 'react';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup/FormGroup';

import { Container } from './FormGroupLabel-styles';

type Props = {
    children: React.ReactNode;
};

const FormGroupLabel = ({ children }: Props) => {
    const formContext = useContext(FormContext);
    const formGroupContext = useContext(FormGroupContext);

    const isRequired = () => {
        if (formContext == null || formGroupContext == null) {
            return false;
        }

        const validationSchema = formContext.validationSchema;

        if (validationSchema == null) {
            return false;
        }

        const keys = formGroupContext.name.split('.');
        let schema = validationSchema?.fields as any;

        for (const key of keys) {
            if (/^\d+$/.test(key)) {
                schema = schema.innerType.fields;
            } else if (key in schema) {
                schema = schema[key];
            }
        }

        return (
            schema?.describe().tests.some((test: any) => test.name === 'required') ||
            // (schema.spec.nullable !== true && schema.spec.optional !== true)
            schema.spec.nullable !== true ||
            schema.spec.optional !== true
        );
    };

    return (
        <Container className={`${isRequired() ? 'required' : ''}`}>
            {children} {isRequired() && <>&nbsp;</>}
        </Container>
    );
};

export default FormGroupLabel;
