import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import theme, { Breakpoints } from '../../../theme';
import { DefaultTheme } from 'styled-components';
import LocalStorageService from '../../LocalStorageService';

const initialSidebarState = LocalStorageService.getSidebarState();

const initialState = {
    theme: theme,
    assets: {
        logo: { url: '' },
        favicon: { url: '' },
    },
    ui: {
        sidebar: {
            displayed: initialSidebarState?.displayed ?? window.innerWidth > Breakpoints.DESKTOP,
            width: initialSidebarState?.width ?? theme.config.sidebar.width.default,
        },
    },
    redirect: {
        url: '',
    },
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        ...initialState,
    },
    reducers: {
        // **************************************************************************************************
        // Update functions
        // **************************************************************************************************
        updateSidebar(state, { payload }: PayloadAction<{ width?: number; displayed?: boolean }>) {
            if (payload.width != null) {
                state.ui.sidebar.width = payload.width;
            }
            if (payload.displayed != null) {
                state.ui.sidebar.displayed = payload.displayed;
            }
            LocalStorageService.saveSidebarState(state.ui.sidebar);
        },
        updateThemeAndAssets: (
            state,
            { payload }: PayloadAction<{ theme: DefaultTheme; logUrl: string; faviconUrl: string }>
        ) => {
            state.theme = payload.theme;
            state.assets.logo.url = payload.logUrl;
            state.assets.favicon.url = payload.faviconUrl;
        },
        updateRedirect(state, { payload }: PayloadAction<{ redirectUrl: string }>) {
            state.redirect.url = payload.redirectUrl;
        },

        // **************************************************************************************************
        // Reset functions
        // **************************************************************************************************
        resetThemeAndAssets: (state) => {
            state.theme = initialState.theme;
            state.assets = initialState.assets;
        },
        resetRedirect: (state) => {
            state.redirect.url = '';
        },
    },
});

export const { updateThemeAndAssets, updateSidebar, updateRedirect, resetThemeAndAssets, resetRedirect } =
    settingsSlice.actions;
export default settingsSlice.reducer;
