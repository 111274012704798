import React, { useState } from 'react';
import { Content, Note, NoteText, NoteTimestamp } from './CaseNotes-styles';
import { CaseChildPageTitle } from '../CasePages-styles';
import { usePromises, useToggle } from '../../../../hooks';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    PageContainer,
    PageHeader,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import { CaseNote } from '../../../../types';
import { AddUpdateCaseNoteModal, RemoveCaseNoteModal } from './modals';
import { useOutletContext } from 'react-router-dom';
import { CasesPagesContext } from '../CasePagesContext';
import { APIService } from '../../../../services';
import { BiNotepad, BiPencil } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import moment from 'moment';
import { TextEditorInput } from '../../../../components/Inputs';

const CaseNotes = () => {
    const { t } = useTranslation();
    const [openedModal, openModal, closeModal] = useToggle<null | 'add-update' | 'remove'>(null);
    const [selectedNote, setSelectedNote] = useState<null | CaseNote>(null);
    const { currentCase } = useOutletContext<CasesPagesContext>();
    const [promises, [notes]] = usePromises(() => APIService.cases().getNotesList(currentCase.id));

    return (
        <>
            {openedModal === 'remove' && (
                <RemoveCaseNoteModal
                    onClose={closeModal}
                    selectedNote={selectedNote!}
                    currentCase={currentCase}
                    refreshNotesList={notes.refresh}
                />
            )}
            {openedModal === 'add-update' && (
                <AddUpdateCaseNoteModal
                    onClose={closeModal}
                    selectedNote={selectedNote!}
                    currentCase={currentCase}
                    refreshNotesList={notes.refresh}
                />
            )}
            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <CaseChildPageTitle>
                        <BiNotepad />
                        {t('case.notes.page_title')}
                    </CaseChildPageTitle>
                    <PrimaryContainedButton
                        onClick={() => {
                            setSelectedNote(null);
                            openModal('add-update');
                        }}
                    >
                        {t('case.notes.modals.add_note.title')}
                    </PrimaryContainedButton>
                </PageHeader>
                <Content>
                    {(notes.data ?? []).map((note) => (
                        <AccordionContainer key={note.id} triggerUpdate={note}>
                            <Accordion level={0} key={note.id}>
                                <AccordionHeader>
                                    <AccordionHeaderTitle>{note.title}</AccordionHeaderTitle>
                                    <AccordionHeaderContent>
                                        <SecondaryOutlinedButton
                                            leadingIcon={<BsTrash />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedNote(note);
                                                openModal('remove');
                                            }}
                                        />
                                        <PrimaryContainedButton
                                            leadingIcon={<BiPencil />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedNote(note);
                                                openModal('add-update');
                                            }}
                                        />
                                    </AccordionHeaderContent>
                                </AccordionHeader>
                                <AccordionBody>
                                    <Note>
                                        <NoteText>
                                            <TextEditorInput initialValue={note.content} isDisabled />
                                        </NoteText>
                                        <NoteTimestamp>
                                            {t('case.notes.createdByAt', {
                                                fullName: note.createdBy,
                                                timestamp: moment(note.createdAt).format('LLL'),
                                            })}
                                        </NoteTimestamp>
                                    </Note>
                                </AccordionBody>
                            </Accordion>
                        </AccordionContainer>
                    ))}
                </Content>
            </PageContainer>
        </>
    );
};

export default CaseNotes;
