import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, usePromises } from '../../../../hooks';
import { passwordRegex } from '../../../../tools';
import { APIService } from '../../../../services';
import { AuthenticationBusiness } from '../../../../business';
import {
    CheckboxInput,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormPasswordInput,
    Modal,
} from '../../../../components';

type Props = {
    onClose: () => void;
};

const SettingsUpdatePasswordModal = ({ onClose }: Props) => {
    const { t } = useTranslation();
    const [{ error, executePromise, clearError }] = usePromises();
    const [logoutAll, setLogoutAll] = useState(false);
    const dispatch = useAppDispatch();

    const validationSchema = Yup.object({
        password: Yup.string().required(t('generic.error.required_field')).matches(passwordRegex, ' '),
        confirm_password: Yup.string()
            .required(t('generic.error.required_field'))
            .test('passwords-match', t('reset_password.confirm.errors.not_matching'), function (value) {
                return value === '' || value === this.parent.password;
            }),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload = {
                    password: submittedValues.password,
                    logoutAll: logoutAll,
                };
                await APIService.users().updatePassword(payload);

                if (logoutAll === true) {
                    await AuthenticationBusiness.logout(dispatch, false);
                }
            });
        },
    });

    return (
        <Modal
            title={t('settings.modals.change_password.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema} oneColumn>
                <FormGroup name="password">
                    <FormGroupLabel>{t('reset_password.confirm.new_password')}</FormGroupLabel>
                    <FormPasswordInput validate autoComplete="new-password" />
                </FormGroup>
                <FormGroup name="confirm_password">
                    <FormGroupLabel>{t('reset_password.confirm.confirm_password')}</FormGroupLabel>
                    <FormPasswordInput autoComplete="new-password" />
                </FormGroup>

                <CheckboxInput
                    isChecked={logoutAll}
                    onChange={(val) => setLogoutAll(val)}
                    label={t('settings.modals.change_password.logout_all')}
                />

                <FormFooter expand submitLabel={t('reset_password.confirm.title')} />
            </Form>
        </Modal>
    );
};

export default SettingsUpdatePasswordModal;
