import styled from 'styled-components';

export const Dropzone = styled.div`
    background-color: ${({ theme }) => theme.colors.background.secondary};
    height: 25rem;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DropzoneInput = styled.input``;

export const DropzoneMessage = styled.p`
    font-size: ${({ theme }) => theme.font.size.md};
`;
