import React, { createContext, useMemo, useState } from 'react';

type Props = {
    children: React.ReactNode;
    triggerUpdate?: any;
};

export type AccordionState = {
    id: string;
    level: number;
    index: number;
    parentIndex: number;
    bodyHeight: number;
    isToggled: boolean;
};

export type AccordionContainerContextType = {
    accordionsState: Array<AccordionState>;
    updateAccordionState: (state: AccordionState) => void;
    maxLevel: number;
    triggerUpdate: any;
};

export const AccordionContainerContext = createContext<AccordionContainerContextType>(null!);

const AccordionContainer = ({ children, triggerUpdate }: Props) => {
    const [accordionsState, setAccordionsState] = useState<Array<AccordionState>>([]);

    const updateAccordionState = (state: AccordionState) => {
        setAccordionsState((prev) => {
            const updatedIndex = prev.findIndex((prevState) => prevState.id === state.id);
            const updated = [...prev];
            if (updatedIndex > -1) {
                updated[updatedIndex] = state;
            } else {
                updated.push(state);
            }
            return updated;
        });
    };

    const maxLevel = useMemo(() => {
        return accordionsState.length > 0 ? Math.max(...accordionsState.map((state) => state.level)) : 0;
    }, [accordionsState]);

    return (
        <AccordionContainerContext.Provider
            value={{
                triggerUpdate: triggerUpdate,
                maxLevel: maxLevel,
                accordionsState: accordionsState,
                updateAccordionState: updateAccordionState,
            }}
        >
            {children}
        </AccordionContainerContext.Provider>
    );
};

export default AccordionContainer;
