import React, { useRef, useMemo } from 'react';
import { DragDropContext, OnDragEndResponder, Droppable, Draggable } from 'react-beautiful-dnd';
import { Container, List, ListItem, ListItemContent, ListItemDragIcon } from './DraggableList-styles';
import { generateId } from '../../tools';
import { RxDragHandleHorizontal } from 'react-icons/rx';

type DraggableListProps<T> = {
    list: Array<T>;
    renderListItem: (item: T, index: number) => React.ReactNode;
    getItemId: (item: T) => string;
    onChange: (list: Array<T>) => void;
};

const DraggableList = <T,>({ list, renderListItem, getItemId, onChange }: DraggableListProps<T>) => {
    const droppableId = useRef(generateId());

    /**
     * On drag end responder
     * @param {DropResult} result
     * @return {void}
     */
    const onDragEndHandler: OnDragEndResponder = ({ destination, source }) => {
        if (destination == null) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        const listCopy = structuredClone(list);
        const removedItem = listCopy.splice(source.index, 1)[0];
        listCopy.splice(destination.index, 0, removedItem);
        onChange(listCopy);
    };

    const isDisabled = useMemo(() => {
        if (list.length < 2) {
            return true;
        }

        if (list.length === 2 && list.some((item) => getItemId(item) === '')) {
            return true;
        }

        return false;
    }, [list]);

    const hasEmptyItem = useMemo(() => list.some((item) => getItemId(item) === ''), [list]);

    return (
        <Container>
            <DragDropContext onDragEnd={onDragEndHandler}>
                <Droppable droppableId={droppableId.current} type="list">
                    {(provided, snapshot) => (
                        <List
                            isDraggingOver={snapshot.isDraggingOver}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="draggable-list"
                        >
                            {list.map((item, index) => {
                                const id = getItemId(item);
                                const itemIsDisabled = isDisabled || id === '';
                                return (
                                    <Draggable
                                        isDragDisabled={itemIsDisabled}
                                        draggableId={id || `generated-${generateId()}`}
                                        index={index}
                                        key={id}
                                    >
                                        {(innerProvided, innerSnapshot) => {
                                            return (
                                                <>
                                                    {id === '' && provided.placeholder}
                                                    <ListItem
                                                        ref={innerProvided.innerRef}
                                                        {...innerProvided.dragHandleProps}
                                                        {...innerProvided.draggableProps}
                                                        isDragging={innerSnapshot.isDragging}
                                                        style={{
                                                            ...innerProvided.draggableProps.style,
                                                            left: 'auto !important',
                                                            top: 'auto !important',
                                                        }}
                                                        className="draggable-list__list__item"
                                                    >
                                                        <ListItemDragIcon
                                                            isDisabled={itemIsDisabled}
                                                            className="draggable-list__list__item__drag-icon"
                                                        >
                                                            <RxDragHandleHorizontal />
                                                        </ListItemDragIcon>
                                                        <ListItemContent>
                                                            {renderListItem(item, index)}
                                                        </ListItemContent>
                                                    </ListItem>
                                                </>
                                            );
                                        }}
                                    </Draggable>
                                );
                            })}
                            {hasEmptyItem !== true && provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>
    );
};

export default DraggableList;
