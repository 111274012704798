import React from 'react';
import { Container } from './ModalParagraph-styles';

type ModalParagraphProps = {
    children: React.ReactNode;
};

const ModalParagraph = ({ children }: ModalParagraphProps) => {
    return <Container>{children}</Container>;
};

export default ModalParagraph;
