import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServerFormQuestion } from '../../../../../types';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import { Modal, ModalParagraph, ModalFooter } from '../../../../../components';

type Props = {
    onClose: () => void;
    refreshQuestionsList: () => Promise<void>;
    selectedQuestion: ServerFormQuestion;
};

const DeleteFormQuestionModal = ({ onClose, refreshQuestionsList, selectedQuestion }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const onClickDelete = async () => {
        await executePromise(async () => {
            await APIService.forms().archiveQuestion(selectedQuestion.id);
            await refreshQuestionsList();
            onClose();
        });
    };

    return (
        <Modal
            title={t('forms.questions.modals.delete.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('forms.questions.modals.delete.message', { questionName: selectedQuestion.name })}
            </ModalParagraph>

            <ModalFooter
                confirmationLabel={t('forms.questions.modals.delete.title')}
                onClick={onClickDelete}
                secondary={{ onClick: onClose }}
                isDestructive
            />
        </Modal>
    );
};

export default DeleteFormQuestionModal;
