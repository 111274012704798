import React, { useMemo } from 'react';
import { CaseEvent } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { Table, TableColumnConfig } from '../../../../../../components';
import { useTable } from '../../../../../../hooks';
import moment from 'moment';

type CaseHistoryTableProps = {
    events: Array<CaseEvent>;
    selectedAuthor: string;
    selectedEventCategory: string;
    searchTerm: string;
    isLoading: boolean;
};

const CaseEventsTable = ({
    events,
    selectedAuthor,
    selectedEventCategory,
    searchTerm,
    isLoading,
}: CaseHistoryTableProps) => {
    const { i18n, t } = useTranslation();

    const columns: Array<TableColumnConfig<CaseEvent>> = useMemo(() => {
        return [
            {
                id: 'date',
                headerTitle: 'generic.date',
                getValue: ({ timestamp }) => timestamp,
                getDisplayedValue: ({ timestamp }) => moment(timestamp).format('LLL'),
                width: 'minmax(180px, max-content)',
                isSortable: true,
                isDefaultSort: true,
            },
            {
                id: 'eventType',
                headerTitle: 'generic.event_type',
                getValue: ({ eventCategory }) => t(`generic.${eventCategory}`),
                width: '200px',
                isSearchable: true,
                isCentered: true,
            },
            {
                id: 'author',
                headerTitle: 'generic.author',
                getValue: ({ author }) => author,
                width: '200px',
                isSearchable: true,
            },
            {
                id: 'details',
                headerTitle: 'generic.details',
                getValue: (event) => event.locale[i18n.language as 'en' | 'fr'],
                width: '1fr',
                isSearchable: true,
            },
        ];
    }, []);

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(events, columns, {
        searchTerm: searchTerm,
        filters: {
            dependencies: [selectedAuthor, selectedEventCategory],
            filterFunctions: [
                (item) => (selectedAuthor === '' ? true : item.author === selectedAuthor),
                (item) =>
                    selectedEventCategory === '' ? true : item.eventCategory === selectedEventCategory,
            ],
        },
    });

    return (
        <Table<CaseEvent>
            columns={displayedColumns}
            data={data}
            handleSort={handleSort}
            sortedColumn={sortedColumn}
            isSortingDown={isSortingDown}
            isLoading={isLoading}
        />
    );
};

export default CaseEventsTable;
