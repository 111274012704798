export enum ErrorsCode {
    // GENERAL ERROR
    INVALID_PARAMETER = 1001,
    INVALID_HEADERS = 1002,
    INVALID_SCOPE = 1003,

    // CRENDETIALS ERROR
    INVALID_CREDENTIALS = 1005,
    INVALID_ACCES_TOKEN = 1006,
    INVALID_REFRESH_TOKEN = 1007,

    INVALID_PERMISSIONS = 1008,

    // BUSINESS ERROR
    EMAIL_ALREADY_TAKEN = 2001,
}
