import { BaseRequestConfig, Methods, BaseRequestConfigHeadersOptions } from '../APIService.types';

/**
 * Generic base request to be extended from
 */
class BaseRequest {
    private BASE_URL;
    private getAPI_URL;
    private getRequestHeaders;
    private processRequest;

    /**
     * Initialize request
     * @param constructor
     */
    constructor(constructor: BaseRequestConfig) {
        this.BASE_URL = constructor.BASE_URL;
        this.getAPI_URL = constructor.getAPI_URL;
        this.getRequestHeaders = constructor.getRequestHeaders;
        this.processRequest = constructor.processRequest;
    }

    /**
     * Generic post request
     * @param PATH_URL
     * @param isAuthenticated
     * @param data
     * @param params
     * @param options
     * @returns
     */
    protected post<T>(
        PATH_URL: string,
        isAuthenticated: boolean,
        data?: object,
        params?: object,
        options?: BaseRequestConfigHeadersOptions
    ) {
        const request = {
            method: Methods.POST,
            url: `${this.getAPI_URL()}${this.BASE_URL}${PATH_URL}`,
            headers: this.getRequestHeaders(isAuthenticated, options),
            params: params,
            data: data,
        };
        return this.processRequest<T>(request, isAuthenticated);
    }

    /**
     * Generic get request
     * @param PATH_URL
     * @param isAuthenticated
     * @param params
     * @returns
     */
    protected get<T>(PATH_URL: string, isAuthenticated: boolean, params?: object) {
        const request = {
            method: Methods.GET,
            url: `${this.getAPI_URL()}${this.BASE_URL}${PATH_URL}`,
            headers: this.getRequestHeaders(isAuthenticated),
            params: params,
        };
        return this.processRequest<T>(request, isAuthenticated);
    }

    /**
     * Generic put request
     * @param PATH_URL
     * @param isAuthenticated
     * @param data
     * @param params
     * @returns
     */
    protected put<T>(PATH_URL: string, isAuthenticated: boolean, data: object, params?: object) {
        const request = {
            method: Methods.PUT,
            url: `${this.getAPI_URL()}${this.BASE_URL}${PATH_URL}`,
            headers: this.getRequestHeaders(isAuthenticated),
            params: params,
            data: data,
        };
        return this.processRequest<T>(request, isAuthenticated);
    }

    /**
     * Generic patch request
     * @param PATH_URL
     * @param isAuthenticated
     * @param data
     * @param params
     * @param options
     * @returns
     */
    protected patch<T>(
        PATH_URL: string,
        isAuthenticated: boolean,
        data: object,
        params?: object,
        options?: BaseRequestConfigHeadersOptions
    ) {
        const request = {
            method: Methods.PATCH,
            url: `${this.getAPI_URL()}${this.BASE_URL}${PATH_URL}`,
            headers: this.getRequestHeaders(isAuthenticated, options),
            params: params,
            data: data,
        };
        return this.processRequest<T>(request, isAuthenticated);
    }

    /**
     * Generic delete request
     * @param PATH_URL
     * @param isAuthenticated
     * @param params
     * @returns
     */
    protected delete<T>(PATH_URL: string, isAuthenticated: boolean, data?: object, params?: object) {
        const request = {
            method: Methods.DELETE,
            url: `${this.getAPI_URL()}${this.BASE_URL}${PATH_URL}`,
            headers: this.getRequestHeaders(isAuthenticated),
            params: params,
            data: data,
        };
        return this.processRequest<T>(request, isAuthenticated);
    }
}

export default BaseRequest;
