import { DynamicFormValues } from '../types';

/**
 * Format payload
 * @param values
 * @return {Array}
 */
export const formatPayload = (
    values: DynamicFormValues
): Array<{
    sectionId: string;
    sectionInternalId: string;
    questions: Array<{ questionId: string; value: string }>;
}> => {
    return Object.values(values).map(({ sectionId, sectionInternalId, questions }) => {
        return {
            sectionId: sectionId,
            sectionInternalId: sectionInternalId.includes('ADDED_') ? '' : sectionInternalId,
            questions: Object.entries(questions).map(([questionId, value]) => ({
                questionId: questionId,
                value: value ?? '',
            })),
        };
    });
};
