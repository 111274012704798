import styled from 'styled-components';

export const Container = styled.div`
    grid-column: 1 / -1;
`;

export const List = styled.div`
    border-radius: 8px;
`;

export const AddOption = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.box.spacing.md};
`;

export const AddOptionLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;
`;
