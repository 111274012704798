import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100%;
    padding-top: ${({ theme }) => theme.box.spacing.lg};
    margin-left: ${({ theme }) => theme.box.spacing.xl};
`;

export const Section = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const SectionTitle = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    display: flex;
    align-items: center;

    :hover {
        .delete_icon {
            display: flex;
        }
    }
`;

export const SectionTitleText = styled.h4`
    font-size: ${({ theme }) => theme.font.size.lg};
`;

export const SectionItem = styled.div<{ isEmployee: boolean }>`
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 14px;
    margin-left: ${({ theme }) => theme.box.spacing.sm};

    ${({ isEmployee, theme }) => {
        if (isEmployee === true) {
            return {
                ':hover': {
                    background: theme.colors.onSurface.main,
                    '.buttons': {
                        display: 'flex',
                    },
                },
            };
        }
    }}
`;

export const SectionItemTooltip = styled.div<{ isDisplayed: boolean }>`
    opacity: ${({ isDisplayed }) => (isDisplayed ? 1 : 0)};
`;

export const SectionItemQuestion = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
`;

export const SectionItemAnswer = styled.div<{ isValidated: boolean }>`
    display: flex;
    input {
        color: ${({ theme }) => theme.colors.text.main} !important;
    }

    .input__check-icon {
        fill: ${({ theme }) => theme.colors.green.main};
        font-size: ${({ theme }) => theme.font.size.md};
    }

    ${({ isValidated }) => {
        if (isValidated === true) {
            return {
                input: {
                    paddingLeft: 0,
                },
            };
        }
    }}
`;

export const Buttons = styled.div`
    display: none;
    align-items: center;
    margin-left: ${({ theme }) => theme.box.spacing.md};
    gap: ${({ theme }) => theme.box.spacing.md};

    > button {
        height: 100%;
    }
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;
