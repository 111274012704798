import React, { useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import { PageContainer, PageHeader, PageHeaderTitle } from '../../../components';
import { ClientsListActions, ClientsListTable } from './components';
import { Actions, Client } from '../../../types';
import { APIService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { usePromises, useToggle } from '../../../hooks';
import {
    CreateClientModal,
    DeleteClientModal,
    ReadClientModal,
    UpdateClientModal,
    UpdateClientOrganizationModal,
    UpdateClientPasswordModal,
} from '../modals';

export enum ClientsListActionsTypes {
    UPDATE_PASSWORD = 'update-password',
    UPDATE_ORGANIZATION = 'update-organization',
}

const ClientsList = () => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [action, openModal, closeModal] = useToggle<null | Actions | ClientsListActionsTypes>(null);
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [promises, [clients]] = usePromises(() => APIService.clients().getList());

    return (
        <>
            {action === Actions.CREATE && (
                <CreateClientModal onClose={closeModal} refreshClientsList={clients.refresh} />
            )}
            {action === Actions.READ && (
                <ReadClientModal
                    onClose={closeModal}
                    selectedClient={selectedClient!}
                    openModal={openModal}
                />
            )}
            {action === Actions.UPDATE && (
                <UpdateClientModal
                    onClose={closeModal}
                    refreshClientsList={clients.refresh}
                    selectedClient={selectedClient!}
                />
            )}
            {action === Actions.DELETE && (
                <DeleteClientModal
                    onClose={closeModal}
                    refreshClientsList={clients.refresh}
                    selectedClient={selectedClient!}
                />
            )}
            {action === ClientsListActionsTypes.UPDATE_ORGANIZATION && (
                <UpdateClientOrganizationModal
                    onClose={closeModal}
                    refreshClientsList={clients.refresh}
                    selectedClient={selectedClient!}
                />
            )}
            {action === ClientsListActionsTypes.UPDATE_PASSWORD && (
                <UpdateClientPasswordModal onClose={closeModal} selectedClient={selectedClient!} />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader>
                    <PageHeaderTitle>
                        <FiUsers />
                        {t(`clients.list`)}
                    </PageHeaderTitle>
                    <ClientsListActions
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        openCreateClientModal={() => openModal(Actions.CREATE)}
                    />
                </PageHeader>
                <ClientsListTable
                    clients={clients.data || []}
                    isLoading={clients.isLoading}
                    searchTerm={searchTerm}
                    onClickClient={(client) => {
                        setSelectedClient(client);
                        openModal(Actions.READ);
                    }}
                    onUpdateOrganization={(client) => {
                        setSelectedClient(client);
                        openModal(ClientsListActionsTypes.UPDATE_ORGANIZATION);
                    }}
                    onClickUpdateClient={(client, isUpdatingPassword) => {
                        setSelectedClient(client);
                        openModal(
                            isUpdatingPassword ? ClientsListActionsTypes.UPDATE_PASSWORD : Actions.UPDATE
                        );
                    }}
                    onClickDeleteClient={(client) => {
                        setSelectedClient(client);
                        openModal(Actions.DELETE);
                    }}
                />
            </PageContainer>
        </>
    );
};

export default ClientsList;
