import styled from 'styled-components';

export const Question = styled.div`
    border-radius: 8px;
    padding: ${({ theme }) => theme.box.spacing.lg};
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.box.spacing.md};
    grid-row-gap: ${({ theme }) => theme.box.spacing.xl};
    position: relative;
`;

export const QuestionRemove = styled.div<{ isDisabled: boolean }>`
    position: absolute;
    right: ${({ theme }) => theme.box.spacing.sm};
    top: ${({ theme }) => theme.box.spacing.sm};
    font-size: ${({ theme }) => theme.font.size.md};

    svg {
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                svg: {
                    color: theme.colors.text.disabled,
                    cursor: 'not-allowed',
                },
            };
        }
    }}
`;

export const AddQuestion = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const AddQuestionLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;
`;
