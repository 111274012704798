import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.config.input.height.default}px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-radius: 0.8rem;
    flex: 1;
    padding-left: ${({ theme }) => theme.box.spacing.xs};
    background-color: ${({ theme }) => theme.colors.surface.main};
`;

export const SketcherContainer = styled.div`
    position: absolute;
    top: calc(100% + 0.5rem);
    left: -0.5%;
    z-index: 5;
    width: 100%;

    .sketch-picker {
        width: 95% !important;
    }
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const ContentLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.main};
    line-height: 1.4;
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    display: flex;
    align-items: center;
`;

export const ContentColor = styled.div<{ color: string }>`
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 3px;
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    background-color: ${({ color }) => color};
`;
