import styled from 'styled-components';

export const List = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 1rem);
    z-index: 1;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    background-color: ${({ theme }) => theme.colors.surface.main};
`;

export const ListQuotes = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ListQuotesItem = styled.div<{ isSelected: boolean; isDependent?: boolean }>`
    display: flex;
    align-items: center;
    position: relative;
    padding: ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.primary.main} !important;
    font-size: ${({ theme }) => theme.font.size.md};
    line-height: 1.4;

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        fill: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }

    :hover {
        background: ${({ theme }) => theme.colors.onSurface.main};
    }

    ${({ isSelected, theme }) => {
        if (isSelected === true) {
            return {
                background: theme.colors.primary.extended_10,
                ':hover': {
                    background: theme.colors.primary.extended_10,
                },
            };
        }
    }}

    ${({ isDependent, theme }) => {
        if (isDependent === true) {
            return {
                paddingLeft: theme.box.spacing.xl,
            };
        }
    }}
`;

export const ListQuotesItemLabel = styled.div`
    color: ${({ theme }) => theme.colors.text.main};
`;

export const ListFiles = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: calc(100% + 1rem);
    top: 0;
    width: 35rem;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    background-color: ${({ theme }) => theme.colors.surface.main};
    z-index: 1;
    max-height: 40vh;
    overflow-y: auto;
`;

export const ListFilesGroup = styled.div`
    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.sm};
    }
`;

export const ListFilesGroupTitle = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-bottom: ${({ theme }) => theme.box.spacing.sm};
`;

export const ListFilesGroupList = styled.div``;

export const ListFilesGroupListItem = styled.div<{ isSelected: boolean }>`
    padding: ${({ theme }) => theme.box.spacing.md};
    display: flex;
    align-items: center;

    :hover {
        background: ${({ theme }) => theme.colors.onSurface.main};
    }

    ${({ isSelected, theme }) => {
        if (isSelected === true) {
            return {
                background: theme.colors.primary.extended_10,
                ':hover': {
                    background: theme.colors.primary.extended_10,
                },
            };
        }
    }}

    svg {
        transform: translateY(-1px);
    }
`;

export const ListFilesGroupListItemLabel = styled.div`
    color: ${({ theme }) => theme.colors.text.main} !important;
    font-size: ${({ theme }) => theme.font.size.md};
    flex: 1;
    display: flex;
    align-items: center;
`;

export const RemoveIcon = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.box.spacing.sm};
    right: ${({ theme }) => theme.box.spacing.sm};
    z-index: 2;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.danger.main};

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
    }
`;

export const UploadResultLabel = styled.div<{ isSuccess: boolean }>`
    position: absolute;
    bottom: ${({ theme }) => theme.box.spacing.sm};
    left: ${({ theme }) => theme.box.spacing.sm};
    z-index: 1;
    background: ${({ theme, isSuccess }) => (isSuccess ? theme.colors.green.main : theme.colors.danger.main)};
    color: ${({ theme }) => theme.colors.surface.main};
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.colors.surface.main};

    svg {
        font-size: ${({ theme }) => theme.font.size.xs};
    }
`;
