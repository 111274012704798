import { LocaleObject } from './locale';

export type OCR_VARIATION = OCR_Passport;

export enum OCR_TYPE {
    PASSPORT = 'passport',
    DS160 = 'DS160',
}

export type OCR_RESULT = {
    key: string;
    label: LocaleObject;
    value: string;
    index?: number;
};

export type OCR_VALIDATION = {
    key: string;
    title: LocaleObject;
    message: LocaleObject;
    severity: OCR_VALIDATION_SEVERITY;
};

export enum OCR_VALIDATION_SEVERITY {
    CRITICAL = 'critical',
    MEDIUM = 'medium',
    LOW = 'low',
}

export type OCR_Passport = {
    lastName: string;
    firstName: string;
    dateOfBirth: string;
    passportNumber: string;
    passportIssueDate: string;
    passportExpiryDate: string;
};

export type OCR_DS160 = {
    companyName: string;
    businessType: string;
    headQuarterAddress: string;
    headQuarterPhoneNumber: string;
    headQuarterFaxNumber: string;
    dateAndPlaceOfEstablishment: string;
    businessNature: string;
    servicesDescription: string;
    foreignParentBusinessNameAddress: string;
    foreignParentBusinessPhoneNumber: string;
    foreignParentBusinessFaxNumber: string;
};

export type OCR<T extends OCR_TYPE> = T extends OCR_TYPE ? OCR_TYPE_MAP[T] : never;

type OCR_TYPE_MAP = {
    [OCR_TYPE.PASSPORT]: OCR_Passport;
    [OCR_TYPE.DS160]: OCR_DS160;
};
