import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../../../../hooks';
// import { BiDotsVertical } from 'react-icons/bi';
import {
    TextInput,
    PrimaryContainedAddButton,
    PageHeaderActions,
    PageHeaderActionsLeft,
    PageHeaderActionsRight,
} from '../../../../../components';

type Props = {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    onClickAddCategoryFile: () => void;
    showTranslation: boolean;
    setShowTranslation: React.Dispatch<React.SetStateAction<boolean>>;
};

const CategoriesFilesListActions = ({ searchTerm, setSearchTerm, onClickAddCategoryFile }: Props) => {
    const { t } = useTranslation();
    const user = useLoggedInUser();

    return (
        <PageHeaderActions>
            <PageHeaderActionsLeft>
                <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    name="searchTerm"
                    placeholder={`${t('categories.files.search')}...`}
                />
                {/* <SecondaryOutlinedButton leadingIcon={<BiDotsVertical />} /> */}
            </PageHeaderActionsLeft>
            <PageHeaderActionsRight>
                {user.hasPermissions('categories.create') && (
                    <PrimaryContainedAddButton onClick={onClickAddCategoryFile}>
                        {t('categories.files.modals.add.title')}
                    </PrimaryContainedAddButton>
                )}
            </PageHeaderActionsRight>
        </PageHeaderActions>
    );
};

export default CategoriesFilesListActions;
