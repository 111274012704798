import React, { useState } from 'react';
import { APIService } from '../../../../services';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { usePromises } from '../../../../hooks';
import * as Yup from 'yup';

// components
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormPasswordInput,
    Modal,
    CheckboxInput,
} from '../../../../components';
import { Content } from './UpdateEmployeePasswordModal-styles';

// types
import { Employee } from '../../../../types';

type Props = {
    onClose: () => void;
    selectedEmployee: Employee;
};

const UpdateEmployeePasswordModal = ({ onClose, selectedEmployee }: Props) => {
    const { t } = useTranslation();
    const [closeSession, setCloseSession] = useState(false);
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        password: Yup.string().required(t('generic.error.required_field')),
        confirmPassword: Yup.string().required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                await APIService.employees().updateEmployeePassword(
                    selectedEmployee.id,
                    submittedValues.password,
                    closeSession
                );
                onClose();
            });
        },
    });

    return (
        <Modal
            title="employees.modal.change_password.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Content>
                {t('employees.modal.change_password.content')} {selectedEmployee.firstName}{' '}
                {selectedEmployee.lastName}.
            </Content>
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="password" expand>
                    <FormGroupLabel>{t('generic.password')}</FormGroupLabel>
                    <FormPasswordInput placeholder={t('generic.password')} validate />
                </FormGroup>
                <FormGroup name="confirmPassword" expand>
                    <FormGroupLabel>{t('generic.confirm_password')}</FormGroupLabel>
                    <FormPasswordInput placeholder={t('generic.confirm_password')} />
                </FormGroup>

                <CheckboxInput
                    label={t('employees.modal.change_password.close_existing_sessions')}
                    isChecked={closeSession}
                    onChange={setCloseSession}
                />

                <FormFooter
                    submitLabel={t('employees.modal.change_password.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateEmployeePasswordModal;
