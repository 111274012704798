import { useState } from 'react';

type Value<T> = T | boolean | null;

type OpenHandler = (value?: any) => void;
type CloseHandler = () => void;
type ToggleHandler = (value?: any) => void;

export const useToggle = <T>(
    initialValue: Value<T>
): [Value<T>, OpenHandler, CloseHandler, ToggleHandler] => {
    const [isOpened, setIsOpened] = useState(initialValue);

    const closeHandler = () => {
        setIsOpened(typeof initialValue === 'boolean' ? false : null);
    };

    const openHandler = (value?: Value<T>) => {
        setIsOpened(value ?? true);
    };

    const toggleHandler = (value?: Value<T>) => {
        if ((value && !!(value instanceof Event)) || !isOpened) {
            openHandler(value);
        } else closeHandler();
    };

    return [isOpened, openHandler, closeHandler, toggleHandler];
};
