import styled from 'styled-components';

export const Container = styled.h2`
    font-size: ${({ theme }) => theme.font.size.xl};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    user-select: none;
    display: flex;
    align-items: center;
    min-width: max-content;
    min-height: 35px;

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.sm};
    }
`;
