import styled from 'styled-components';

export const Container = styled.div`
    width: 22rem;
    min-width: 22rem;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.surface.main};
    margin: ${({ theme }) => theme.box.spacing.sm};
    border-radius: 4px;
    padding: ${({ theme }) => theme.box.spacing.sm};
    box-shadow: ${({ theme }) => theme.box.shadow.light};
`;

export const Title = styled.h3`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
    padding: 0 ${({ theme }) => theme.box.spacing.sm};
`;

export const CasesList = styled.div<{ isDraggingHover: boolean }>`
    min-height: 10rem;
    padding: ${({ theme }) => theme.box.spacing.sm};
    transition: all 0.3s ease-in;
    flex-grow: 1;
    background-color: ${({ theme, isDraggingHover }) =>
        isDraggingHover ? theme.colors.primary.extended_10 : theme.colors.surface.main};
`;
