import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// components
import {
    Form,
    FormCheckboxInputs,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    Modal,
} from '../../../../components';

// types
import { Employee, EmployeeRole } from '../../../../types';
import { APIService } from '../../../../services';
import { formatPhoneNumber, validatePhoneNumber } from '../../../../tools';
import { UpdateEmployeeProfilePayload } from '../../../../types/employee';

type Props = {
    onClose: () => void;
    refreshEmployeesList: () => Promise<void>;
    selectedEmployee: Employee;
};

const UpdateEmployeeModal = ({ onClose, refreshEmployeesList, selectedEmployee }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('generic.error.required_field')),
        lastName: Yup.string().required(t('generic.error.required_field')),
        email: Yup.string()
            .email(t('generic.error.invalid_email'))
            .required(t('generic.error.required_field')),
        roles: Yup.array().of(Yup.string()).min(1, t('generic.error.required_field')),
        phoneNumber: Yup.string().test('phoneNumber', t('generic.error.invalid_phone_number'), (value) => {
            if (value) {
                return validatePhoneNumber(value);
            } else {
                return true;
            }
        }),
    });

    const formik = useFormik({
        initialValues: {
            firstName: selectedEmployee.firstName,
            lastName: selectedEmployee.lastName,
            email: selectedEmployee.email,
            roles: selectedEmployee.roles,
            phoneNumber: selectedEmployee.contacts[0]?.phoneNumber ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload: UpdateEmployeeProfilePayload = {
                    firstName: submittedValues.firstName,
                    lastName: submittedValues.lastName,
                    email: submittedValues.email,
                    roles: submittedValues.roles,
                };
                if (submittedValues.phoneNumber.length > 0) {
                    payload.contacts = [{ phoneNumber: formatPhoneNumber(submittedValues.phoneNumber) }];
                }
                await APIService.employees().updateEmployeeProfile(selectedEmployee.id, payload);
                await refreshEmployeesList();
                onClose();
            });
        },
    });

    return (
        <Modal
            title="employees.modal.update_employee.title"
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="firstName">
                    <FormGroupLabel>{t('generic.firstName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.firstName')} />
                </FormGroup>
                <FormGroup name="lastName">
                    <FormGroupLabel>{t('generic.lastName')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.lastName')} />
                </FormGroup>
                <FormGroup name="email" expand>
                    <FormGroupLabel>{t('generic.email_address')}</FormGroupLabel>
                    <FormTextInput type="email" placeholder={t('generic.email')} />
                </FormGroup>
                <FormGroup name="phoneNumber" expand>
                    <FormGroupLabel>{t('generic.contact')}</FormGroupLabel>
                    <FormTextInput placeholder={t('generic.contact')} />
                </FormGroup>
                <FormGroup name="roles" expand>
                    <FormGroupLabel>{t('generic.role')}</FormGroupLabel>
                    <FormCheckboxInputs
                        options={[
                            { label: 'Admin', value: EmployeeRole.ADMIN },
                            { label: 'Lawyer', value: EmployeeRole.LAWYER },
                            { label: 'Default', value: EmployeeRole.EMPLOYEE },
                        ]}
                    />
                </FormGroup>
                <FormFooter
                    submitLabel={t('employees.modal.update_employee.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateEmployeeModal;
