import React, { createContext, useContext, useState } from 'react';
import { FormContext } from '../Form/Form';
import { Container, Error } from './FormGroup-styles';

type Props = {
    children: React.ReactNode;
    name: string;
    expand?: boolean;
};

type FormGroupContextType = {
    name: string;
    customError: string | null;
    setCustomError: React.Dispatch<React.SetStateAction<string | null>>;
};

export const FormGroupContext = createContext<FormGroupContextType>(null!);

const FormGroup = ({ children, expand, name }: Props) => {
    const context = useContext(FormContext);
    const [customError, setCustomError] = useState<null | string>(null);

    const renderError = () => {
        if (customError != null) {
            return <Error>{customError}</Error>;
        }

        const formik = context?.formik;

        if (formik == null) {
            return null;
        }

        if (formik.touched[name] === true || formik.submitCount > 0) {
            const keys = name.split('.');

            let error = formik.errors as any;

            for (const key of keys) {
                if (error == null) {
                    return null;
                }

                if (/^\d+$/.test(key)) {
                    const index = parseInt(key);
                    error = error[index];
                } else if (key in error) {
                    error = error[key];
                }
            }

            if (typeof error === 'string') {
                return <Error>{error}</Error>;
            }
        }

        return null;
    };

    return (
        <FormGroupContext.Provider value={{ name: name, customError, setCustomError }}>
            <Container expand={expand}>
                {children}
                {renderError()}
            </Container>
        </FormGroupContext.Provider>
    );
};

export default FormGroup;
