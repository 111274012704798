import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Content } from './AccordionBody-styles';
import { AccordionContext } from '../Accordion';
import { AccordionContainerContext } from '../../AccordionContainer';

type AccordionBodyProps = {
    children: React.ReactNode;
};

const AccordionBody = ({ children }: AccordionBodyProps) => {
    const { maxLevel, updateAccordionState, accordionsState, triggerUpdate } =
        useContext(AccordionContainerContext);
    const { accordionId, isToggled, level, index, parentIndex } = useContext(AccordionContext);
    const bodyRef = useRef<HTMLDivElement>(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        updateAccordionState({
            id: accordionId,
            level: level,
            bodyHeight: bodyRef.current?.clientHeight ?? 0,
            isToggled: isToggled,
            index: index,
            parentIndex: parentIndex,
        });
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted === true && level === maxLevel) {
            setTimeout(() => {
                updateAccordionState({
                    id: accordionId,
                    level: level,
                    bodyHeight: bodyRef.current?.clientHeight ?? 0,
                    isToggled: isToggled,
                    index: index,
                    parentIndex: parentIndex,
                });
            });
        }
    }, [triggerUpdate]);

    const currentBodyHeight = useMemo(() => {
        if (isMounted !== true) {
            return 0;
        }
        const processedState = new Set();
        const currentState = accordionsState.find((state) => state.id === accordionId);
        let height = currentState?.bodyHeight ?? 0;

        const getHeight = (currentLevel: number, currentParentIndex: number) => {
            for (let i = currentLevel; i < maxLevel; i++) {
                const relevantStates = accordionsState.filter(
                    (state) =>
                        state.isToggled === true &&
                        state.level === i + 1 &&
                        state.parentIndex === currentParentIndex &&
                        processedState.has(state.id) === false
                );

                if (relevantStates.length === 0) {
                    break;
                }

                for (const relevantState of relevantStates) {
                    const parentRelevantState = accordionsState.find(
                        (state) =>
                            state.index === relevantState.parentIndex &&
                            state.level === relevantState.level - 1
                    );

                    if (parentRelevantState?.isToggled === true) {
                        height += relevantState.bodyHeight;
                        processedState.add(relevantState.id);
                        if (relevantState.level < maxLevel) {
                            getHeight(relevantState.level, relevantState.index);
                        }
                    }
                }
            }

            return height;
        };

        if (isToggled === true && currentState != null && level <= maxLevel) {
            getHeight(level, index);
        }

        return height;
    }, [accordionsState, accordionId, isMounted]);

    return (
        <Container
            className="accordion__body"
            isToggled={isToggled}
            level={level}
            bodyHeight={currentBodyHeight}
        >
            <Content ref={bodyRef}>{children}</Content>
        </Container>
    );
};

export default AccordionBody;
