import { CaseFolder_L1, CategoryQuoteGroupAuthorization, LocaleObject } from '../../../types';
import {
    CaseFolderManagerContracts_L1,
    CaseFolderManagerContracts_L2,
    CaseFolderTooltip,
} from '../CaseFolderManager-contracts';
import CaseFolderManager_L2 from './CaseFolderManager_L2';

class CaseFolderManager_L1 implements CaseFolderManagerContracts_L1 {
    readonly title: LocaleObject;
    readonly authorization: CategoryQuoteGroupAuthorization;
    readonly folders: Array<CaseFolderManager_L2>;

    constructor(childFolder: CaseFolder_L1) {
        this.title = childFolder.title;
        this.authorization = childFolder.authorization;
        this.folders = childFolder.folders.map(
            (grandChildFolder) => new CaseFolderManager_L2(grandChildFolder, this)
        );
    }

    public isValidated(): boolean {
        const relevantFolders = this.getRelevantFolders();
        return relevantFolders.every((folder) => folder.isValidated() === true);
    }

    public hasErrors(): boolean {
        const relevantFolders = this.getRelevantFolders();
        return relevantFolders.some((folder) => folder.hasErrors() === true);
    }

    public hasWarnings(): boolean {
        const relevantFolders = this.getRelevantFolders();
        return relevantFolders.some((folder) => folder.hasWarnings() === true);
    }

    public getTooltips(): Array<CaseFolderTooltip> {
        const tooltips: Array<CaseFolderTooltip> = [];
        const relevantFolders = this.getRelevantFolders();
        for (const relevantFolder of relevantFolders) {
            tooltips.push(relevantFolder.getTooltip());
        }
        return tooltips.filter((tooltip) => tooltip.messages.length > 0);
    }

    // **********************************************************************************************************
    // Helpers
    // **********************************************************************************************************

    private getRelevantFolders(): Array<CaseFolderManagerContracts_L2> {
        return this.folders.filter((folder) => folder.isDisplayed === true);
    }
}

export default CaseFolderManager_L1;
